import Rx from "rxjs";
import { EpicFactory } from "../../core/core-redux/reduxTypes";
import { allLayersSelector } from "../../common/selector/layerSelector";
import { GLOBE_3D_PROJECTION } from "../../common/constants/Projections";
import { changeLayerProperties } from "../../../MapStore2/web/client/actions/layers";
import { Action } from "redux";
import { Layer, RootState } from "../../rootState";
import { CHANGE_MAP_CRS, ChangeMapCrs } from "../../common/mapstore/action";

/*
    Disables Visibility Limits for all layers when switched to 3D mode.
    Workaround for MapStore bug - visibility limits not working in 3D mode.
 */
export const disableResolutionLimitsEpic: EpicFactory<Action, RootState, Action> = (action$, store) =>
    action$.ofType<ChangeMapCrs>(CHANGE_MAP_CRS).switchMap((action) => {
        const layers: Layer[] = allLayersSelector(store.getState());
        const disableResolutionLimits = action.crs === GLOBE_3D_PROJECTION;
        return Rx.Observable.from(layers).map((layer: Layer) => {
            return changeLayerProperties(layer.id, { disableResolutionLimits: disableResolutionLimits });
        });
    });
