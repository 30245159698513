const GEOCALC_GEOMETRY_REQUESTED = 'GEOCALC_GEOMETRY_REQUESTED';

const requestGeoCalcGeometryAction = (calculationId) => {
    return {
        type: GEOCALC_GEOMETRY_REQUESTED,
        calculationId: calculationId
    };
};

export {GEOCALC_GEOMETRY_REQUESTED, requestGeoCalcGeometryAction};
