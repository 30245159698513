import * as React from "react";
import { FormattedNumber } from "../../../core/core-widgets/data-displays/formatting/FormattedNumber";
import { Label } from "../../../core/core-widgets/label/Label";
import { FormattedDMS } from "../../../core/core-widgets/data-displays/formatting/FormattedDMS";
import { Point } from "../../../rootState";

/* haversine formula */
function calculateDistanceInMeters(point1: Point, point2: Point) {
    const EARTH_RADIUS_KM = 6378.137;
    const dLat = ((point1.latlng.lat - point2.latlng.lat) * Math.PI) / 180;
    const dLon = ((point1.latlng.lng - point2.latlng.lng) * Math.PI) / 180;
    const a =
        Math.pow(Math.sin(dLat / 2), 2) +
        Math.cos((point1.latlng.lat * Math.PI) / 180) *
            Math.cos((point2.latlng.lat * Math.PI) / 180) *
            Math.pow(Math.sin(dLon / 2), 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = EARTH_RADIUS_KM * c;
    return d * 1000;
}

interface PointsDistanceProps {
    point1?: Point;
    point2?: Point;
}

export const PointsDistance = ({ point1, point2 }: PointsDistanceProps) => {
    if (point1 === undefined || point2 === undefined) return null;
    return (
        <div>
            <Label>Distance (m):</Label>
            <FormattedNumber value={calculateDistanceInMeters(point1, point2)} fractionDigits={2} className={"px-1"} />
            <br />
            <Label>WGS 84:</Label>
            <FormattedDMS
                longitude={Math.abs(point1.latlng.lng - point2.latlng.lng)}
                latitude={Math.abs(point1.latlng.lat) - point2.latlng.lat}
                className={"px-1"}
            />
        </div>
    );
};
