import { Action } from "redux";
import { Slots } from "../../../common/slot/slots";

export interface UrlParams {
    effectiveDate: string;
    validTo?: string;
    slots: Slots;
    ticket?: string;
    embeddedInPortal: boolean;
    developmentMode: boolean;
    autoLogin: boolean;
    workspaceName?: string;
    viewType?: "base" | "workspace";
    geoCalcIds?: string[];
    filterIds?: string[];
}

export const INIT_URL_PARAMS = "INIT_URL_PARAMS";

export interface InitUrlParamsAction extends Action {
    urlParams: UrlParams;
}

export const initUrlParamsAction = (initUrlParams: UrlParams): InitUrlParamsAction => ({
    type: INIT_URL_PARAMS,
    urlParams: initUrlParams,
});
