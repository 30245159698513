import { asyncFetchDataPromiseActionFactory } from "../../../core/core-redux/actions/asyncFetchDataAction";
import {
    loginToWizardSuiteService,
    LoginToWizardSuiteServiceParams,
    LoginToWizardSuiteServiceResponse,
} from "./loginToWizardSuite.service";

export const LOGIN_TO_WIZARD_SUITE = "LOGIN_TO_WIZARD_SUITE";

export const loginToWizardSuiteActionCreator = asyncFetchDataPromiseActionFactory<
    LoginToWizardSuiteServiceParams,
    LoginToWizardSuiteServiceResponse,
    any
>(LOGIN_TO_WIZARD_SUITE, loginToWizardSuiteService);
