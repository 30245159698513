import { Action } from "redux";
import { EditAction } from "../types";

export const CHANGE_EDIT_ACTION_MODE = "CHANGE_EDIT_ACTION_MODE";

export interface ChangeEditActionModeAction extends Action {
    value: EditAction;
}

export const changeEditModeAction = (value: EditAction): ChangeEditActionModeAction => ({
    type: CHANGE_EDIT_ACTION_MODE,
    value: value,
});
