import { standardApiHandler } from "../../../core/core-api/apiTools";
import { Feature } from "../../../core/geo/types";
import { getFeaturesByParamsAdapter } from "./getFeaturesByParams.adapter";

export type GetFeaturesByParamsServiceParams = Array<{
    typeName?: string | string[];
    ENV?: string;
    startIndex?: number;
    count?: number;
    resultType?: string;
    CQL_FILTER?: string;
    featureId?: number;
    srsName?: string;
    wizardSuiteProperties?: boolean;
    ticket?: string;
}>;
export type GetFeaturesByParamsServiceResponse = {
    crs: unknown;
    features: Feature[];
    numberMatched: number;
    numberReturned: number;
    timeStamp: string;
    totalFeatures: number;
    type: string;
};

export type GetFeaturesByParamsService = (
    params: GetFeaturesByParamsServiceParams
) => Promise<GetFeaturesByParamsServiceResponse[]>;
export const getFeaturesByParamsService: GetFeaturesByParamsService = standardApiHandler(getFeaturesByParamsAdapter);
