import * as React from "react";
import { BASELINE_VIEW, WORKSPACE_VIEW } from "../../../../changes/changesConstants";
import { IntlContext, IntlContextProvider } from "../../../../core/core-widgets/contexts/IntlContext";

export const ViewTypeSelector = (properties) => {
    if (properties.hidden) {
        return null;
    }
    return (
        <IntlContextProvider>
            <IntlContext.Consumer>
                {({ intl }) => {
                    return (
                        <>
                            <div>Display</div>
                            <div>
                                <select
                                    id={"viewSelector"}
                                    onChange={(event) => properties.handleViewTypeChanged(event.target.value)}
                                    value={properties.viewType}
                                    disabled={properties.displayLoading}
                                >
                                    <option value={BASELINE_VIEW}>
                                        {intl.formatMessage({ id: "workspaceChanges.viewSelector.baseline" })}
                                    </option>
                                    <option value={WORKSPACE_VIEW}>
                                        {intl.formatMessage({ id: "workspaceChanges.viewSelector.workspace" })}
                                    </option>
                                </select>
                            </div>
                        </>
                    );
                }}
            </IntlContext.Consumer>
        </IntlContextProvider>
    );
};
