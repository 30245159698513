import { DataSetFilterItemsDataState } from "../reducer/dataSetFilterReducer";
import { IPartialReducer } from "../../../../core/core-redux/reduxTypes";

export const removeDataSetFilterPartialReducer: IPartialReducer<DataSetFilterItemsDataState> = (state, action) => {
    const rootItem = state.items[0];
    rootItem.children = rootItem.children.filter((dataSetFilter: any) => dataSetFilter.id !== action.filterId);
    const filters = state.filters && state.filters.filter((filter: any) => filter.filterId !== action.filterId);
    return {
        ...state,
        items: [rootItem],
        filters: filters,
        asString: rootItem.children.filter((item: any) => item.visibility).join(),
    };
};
