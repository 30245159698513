import * as React from "react";
import MapStoreScaleBox from "../../MapStore2/web/client/components/mapcontrols/scale/ScaleBox";
import Message from "../../MapStore2/web/client/plugins/locale/Message";

export interface ScaleBoxStateProps {
    id?: string;
    style?: any;
    scales?: any[];
    currentZoomLvl?: number;
    minZoom: number;
    readOnly?: boolean;
    label?: any;
    template?: () => void;
    useRawInput?: boolean;
}

export interface ScaleBoxDispatchProps {
    onChange: (zoomLvl: any, mapStateSource: any) => void;
}

export const ScaleBox = (props: ScaleBoxStateProps & ScaleBoxDispatchProps) => {
    return <MapStoreScaleBox label={<Message msgId={"mapScale"} />} {...props} />;
};
