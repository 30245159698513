import { Observable } from "rxjs";
import { deselectFeaturesAction } from "./deselectFeatures.action";
import { selectedFeaturesSelector } from "../../../../common/selector/featuresSelector";
import { EpicFactory } from "../../../../core/core-redux/reduxTypes";
import { RootState } from "../../../../rootState";
import { Action } from "redux";
import { Feature } from "../../../../core/geo/types";
import {
    CHANGE_GROUP_PROPERTIES,
    CHANGE_LAYER_PROPERTIES,
    ChangeGroupPropertiesAction,
    ChangeLayersPropertiesAction,
    isChangeGroupPropertiesAction,
    isChangeLayersPropertiesAction,
} from "../../../../common/mapstore/action/layers";

function getFeatureIdAsString(feature: Feature): string {
    if (typeof feature.id === "string") {
        return feature.id;
    }
    throw new Error("Feature id is not string");
}

function getLayerNameFromLayerId(layerId: string) {
    return layerId.substring(layerId.indexOf(":") + 1, layerId.indexOf("."));
}

function deselectByLayer(action: ChangeLayersPropertiesAction, selectedFeatures: Feature[]) {
    const layer = getLayerNameFromLayerId(action.layer);
    const toBeDeselected = selectedFeatures.filter((feature) => getFeatureIdAsString(feature).startsWith(layer));
    return deselectFeaturesAction(toBeDeselected);
}

function getLayerNameFromFeatureId(feature: Feature) {
    return getFeatureIdAsString(feature).substring(0, getFeatureIdAsString(feature).indexOf("."));
}

function deselectByGroup(state: RootState, action: ChangeGroupPropertiesAction, selectedFeatures: Feature[]) {
    const layerNames = state.layers.flat
        .filter((layer) => layer.group.startsWith(action.group))
        .map((layer) => getLayerNameFromLayerId(layer.id));
    const toBeDeselected = selectedFeatures.filter((feature) =>
        layerNames.includes(getLayerNameFromFeatureId(feature))
    );
    return deselectFeaturesAction(toBeDeselected);
}

/*
 * We are removing selected features based on layer(s) that are being hidden
 * remove feature(s) if layer/group being hidden contains feature(s)
 */
export const deselectFeatureEpic: EpicFactory<Action, RootState, Action> = (action, store) =>
    action
        .ofType<ChangeGroupPropertiesAction | ChangeLayersPropertiesAction>(
            CHANGE_LAYER_PROPERTIES,
            CHANGE_GROUP_PROPERTIES
        )
        .switchMap((_action) => {
            const selectedFeatures = selectedFeaturesSelector(store.getState()) ?? [];

            if (selectedFeatures.length === 0 || _action.newProperties.visibility) {
                return Observable.empty<Action>();
            }
            if (isChangeGroupPropertiesAction(_action)) {
                return Observable.of(deselectByGroup(store.getState(), _action, selectedFeatures));
            }
            if (isChangeLayersPropertiesAction(_action)) {
                return Observable.of(deselectByLayer(_action, selectedFeatures));
            }
            return Observable.empty<Action>();
        });
