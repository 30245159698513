import classNames from "classnames";
import * as React from "react";

import { MarginSpacingSize, SpacingSize } from "./types";
import { Box, IBoxProps } from "../Box/Box";

interface ISpacingBoxProps extends IBoxProps {
    m?: MarginSpacingSize;
    mx?: MarginSpacingSize;
    my?: MarginSpacingSize;
    mt?: MarginSpacingSize;
    mr?: MarginSpacingSize;
    mb?: MarginSpacingSize;
    ml?: MarginSpacingSize;

    p?: SpacingSize;
    px?: SpacingSize;
    py?: SpacingSize;
    pt?: SpacingSize;
    pr?: SpacingSize;
    pb?: SpacingSize;
    pl?: SpacingSize;
}

export const SpacingBox: React.FC<ISpacingBoxProps> = (props) => {
    const { m, mx, my, mt, mr, mb, ml, p, px, py, pt, pr, pb, pl, className, children, ...rest } = props;

    const spacingBoxClassName = classNames(className, {
        [`m-${m}`]: m,
        [`mx-${mx}`]: mx,
        [`my-${my}`]: my,
        [`mt-${mt}`]: mt,
        [`mr-${mr}`]: mr,
        [`mb-${mb}`]: mb,
        [`ml-${ml}`]: ml,
        [`p-${p}`]: p,
        [`px-${px}`]: px,
        [`py-${py}`]: py,
        [`pt-${pt}`]: pt,
        [`pr-${pr}`]: pr,
        [`pb-${pb}`]: pb,
        [`pl-${pl}`]: pl,
    });

    return (
        <Box {...rest} className={spacingBoxClassName}>
            {children}
        </Box>
    );
};
