import { IAsyncDataState } from "../../../core/core-redux/reduxTypes";
import { asyncFetchDataReducerFactory } from "../../../core/core-redux/reducer/asyncFetchDataReducer";
import { GetDataSetFiltersServiceParams } from "./getDataSetFilters.service";
import { GET_DATASET_FILTERS } from "./getDataSetFilters.action";
import DataSetFilterItem from "../../../filter/DataSetFilter/dto/DataSetFilterItem";

export interface GetDataSetFiltersDataState
    extends IAsyncDataState<DataSetFilterItem[], any, GetDataSetFiltersServiceParams> {}

export const getDataSetFiltersReducer = asyncFetchDataReducerFactory<
    DataSetFilterItem[],
    any,
    GetDataSetFiltersServiceParams
>(GET_DATASET_FILTERS);
