import React from "react";
import PropTypes from "prop-types";
import { Draggable } from "../../core/core-widgets/surfaces/Draggable/Draggable";
import TreeItemFinder from "../../layers/util/TreeItemFinder";
import ItemLookUp from "../../layers/component/ItemLookUp";
import ItemTree from "../../layers/component/ItemTree";

class LayerElement extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        className: PropTypes.string,

        treeItems: PropTypes.array,
        readonly: PropTypes.bool,

        lookUpItems: PropTypes.array,
        lookUpTitle: PropTypes.string,
        lookupFilterMessage: PropTypes.string,

        onTreeItemAdd: PropTypes.func,
        onTreeItemRemove: PropTypes.func,
        onTreeItemVisibilityChange: PropTypes.func,
        onTreeItemClick: PropTypes.func,
        onRefresh: PropTypes.func,

        onLookupItemAdd: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.itemTreeRef = React.createRef();

        this.state = {
            lookUpOpened: false,
            treeItems: props.treeItems,
        };
    }

    static defaultProps = {
        id: "layerElement",
        className: "layerElement",
        lookUpItems: [],
        lookUpTitle: "Layer Element",
        lookupFilterMessage: "Search Elements",
    };

    UNSAFE_componentWillReceiveProps(newProps) {
        let treeItemIds = [];
        for (let i = 0; i < newProps.treeItems.length; i++) {
            const itemIds = TreeItemFinder.getItemAndItsChildren(newProps.treeItems[i]).map((item) => item.id);
            treeItemIds = treeItemIds.concat(itemIds);
        }
        this.setState({
            treeItems: newProps.treeItems,
            lookUpItems: newProps.lookUpItems.filter((item) => !treeItemIds.includes(item.id)),
        });
    }

    render() {
        const lookupEnabled = this.state.lookUpOpened && this.state.lookUpItems && this.state.lookUpItems.length > 0;

        return (
            <>
                <Draggable
                    preferredPosition={this.state.lookupPosition}
                    applyWindowBoundaries={true}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        minWidth: 300,
                        wordWrap: "break-word",
                        zIndex: 9999999999999999999,
                        backgroundColor: "white",
                        boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 4px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "auto",
                    }}
                >
                    <ItemLookUp
                        enabled={lookupEnabled}
                        id={`${this.props.id}ItemLookUp`}
                        className={`${this.props.className}ItemLookUp`}
                        title={this.props.lookUpTitle}
                        filterMessage={this.props.lookupFilterMessage}
                        items={this.state.lookUpItems}
                        onClose={this.handleLookUpClose.bind(this)}
                        onAdd={this.props.onLookupItemAdd}
                    />
                </Draggable>

                <ItemTree
                    id={`${this.props.id}ItemTree`}
                    className={this.props.className}
                    items={this.state.treeItems}
                    onExpand={this.handleTreeItemExpanded.bind(this)}
                    onAdd={!this.props.readonly && this.props.onTreeItemAdd ? this.handleLookUpOpen.bind(this) : null}
                    onHide={!this.props.readonly && this.props.onTreeItemVisibilityChange}
                    onRemove={!this.props.readonly && this.props.onTreeItemRemove}
                    onRowClick={this.props.onTreeItemClick}
                    onRefresh={!this.props.readonly && this.props.onRefresh}
                    readonly={this.props.readonly}
                    treeRef={this.itemTreeRef}
                />
            </>
        );
    }

    handleLookUpOpen() {
        if (this.props.onTreeItemAdd) {
            this.props.onTreeItemAdd();
        }
        const itemTreeRectangle = this.itemTreeRef.current?.getBoundingClientRect();
        this.setState({
            lookupPosition: {
                x: itemTreeRectangle?.left + itemTreeRectangle?.width,
                y: itemTreeRectangle?.top,
            },
            lookUpOpened: true,
        });
    }

    handleLookUpClose() {
        this.setState({
            lookUpOpened: false,
        });
    }

    handleTreeItemExpanded(itemId, expand) {
        const items = [...this.state.treeItems];
        const item = TreeItemFinder.findItem(itemId, items);

        item.expanded = expand;
        this.setState({
            treeItems: items,
        });
    }
}

export default LayerElement;
