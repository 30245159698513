import { DRAWING_FEATURE } from "../../../../MapStore2/web/client/actions/draw";
import { geometryChangedAction } from "./geometryChangedAction";
import { EpicFactory } from "../../../core/core-redux/reduxTypes";
import { RootState } from "../../../rootState";
import { Feature } from "../../../core/geo/types";
import { isGeometryCollection } from "../../../core/geo/assertions";
import { geometryRetrievers } from "../../util/geometry/geometryRetriever";
import { ChangeTypes } from "../../util/geometry/types";
import { insertFeatureAction } from "../insertFeature";
import { updateFeatureAction } from "../updateFeature";
import { GeometryCollection, Point } from "geojson";

export const geometryChangedEpic: EpicFactory<{ type: string; features: Feature[] }, RootState, any> = (
    action,
    store
) =>
    action.ofType(DRAWING_FEATURE).switchMap((action) => {
        const editFeature = store.getState().editFeature;

        const changeRetriever = geometryRetrievers[action.features[0].geometry.type];
        const editedFeatures = action.features;

        const featuresBefore = editFeature?.editedFeatures?.features;
        const actions = [];

        for (const index in editedFeatures) {
            const featureNow = editedFeatures[index];
            const featureBefore = featuresBefore?.filter(
                (feature) => feature.properties.id === featureNow.properties.id
            )[0];

            let coordinatesBefore;
            let coordinatesNow;
            let featureProperties;

            if (isGeometryCollection(featureBefore?.geometry) || isGeometryCollection(featureNow.geometry)) {
                coordinatesBefore = ((featureBefore?.geometry as GeometryCollection).geometries[1] as Point)
                    ?.coordinates;
                coordinatesNow = ((featureNow?.geometry as GeometryCollection).geometries[1] as Point).coordinates;
                featureProperties = {
                    radiusBefore: featureBefore?.properties.radius,
                    radiusNow: featureNow?.properties.radius,
                };
            } else {
                coordinatesBefore = featureBefore && featureBefore.geometry.coordinates;
                coordinatesNow = featureNow && featureNow.geometry.coordinates;
            }
            const geometryChange = changeRetriever?.retrieveChange(coordinatesBefore, coordinatesNow, {
                ...editFeature?.options!,
                isCircle: featureNow.properties.isCircle,
                radiusBefore: featureProperties?.radiusBefore,
                radiusNow: featureProperties?.radiusNow,
            });
            if (geometryChange?.type !== ChangeTypes.NONE) {
                if (isGeometryCollection(featureBefore?.geometry)) {
                    if (featureBefore!.geometry.geometries.length > 0) {
                        actions.push(updateFeatureAction(featureBefore!.id, featureNow));
                    } else {
                        actions.push(insertFeatureAction(featureNow));
                    }
                    actions.push(geometryChangedAction(geometryChange!));
                    return actions;
                }

                if (featureBefore) {
                    actions.push(updateFeatureAction(featureBefore.id, featureNow));
                } else {
                    actions.push(insertFeatureAction(featureNow));
                }
                actions.push(geometryChangedAction(geometryChange!));
            }
        }
        return actions;
    });
