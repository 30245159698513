const VIEW_TYPE_CHANGED = "VIEW_TYPE_CHANGED";

function updateViewTypeAction(viewType) {
    return {
        type: "VIEW_TYPE_CHANGED",
        viewType: viewType,
    };
}

module.exports = {
    VIEW_TYPE_CHANGED,
    updateViewTypeAction,
};
