import { Action } from "redux";

export const STORE_TICKET = "STORE_SESSION";

export interface StoreTicketAction extends Action {
    ticket: string;
}

export const storeTicketAction = (ticket: string): StoreTicketAction => {
    return {
        type: STORE_TICKET,
        ticket: ticket,
    };
};
