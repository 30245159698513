import { gisServerUrlSelector } from "../../selector/configurationSelector";
import Rx from "rxjs";
import { sessionSelector } from "../../../session/redux/sessionSelector";
import { EPSG_4326 } from "../../constants/Projections";
import Logger from "../Logger";
import { UrlParameters, Response, GisServerRestParameters } from "./types";

const axios = require("../../../../MapStore2/web/client/libs/ajax");

const logger = new Logger("gisServerInvoker");

const invokeGetOnUrl = (state: any, servicePath: string, urlParams?: UrlParameters | GisServerRestParameters) => {
    const queryString = urlParams
        ? Object.keys(urlParams)
              .map((key) => key + "=" + urlParams[key])
              .join("&")
        : "";

    const gisServerUrl = gisServerUrlSelector(state);

    if (!gisServerUrl) {
        logger.error(`gisServerUrl not set when invoking '${servicePath}'`);
    }
    const url = `${gisServerUrl}/${servicePath}?` + queryString;

    const retrieveFlow = (): any => {
        return Rx.Observable.defer(() =>
            axios({
                method: "get",
                url: url,
                headers: {
                    ticket: sessionSelector(state),
                },
            })
        );
    };
    return retrieveFlow().map((response: Response) => {
        return { data: response.data };
    });
};

export const invokeWfsService = (state: any, urlParams: Partial<UrlParameters>) => {
    const defaultParams = {
        service: "wfs",
        version: "2.0.0",
        request: "GetFeature",
        outputFormat: "application/json",
        srsName: EPSG_4326,
    };

    return invokeGetOnUrl(state, `wfs`, { ...defaultParams, ...urlParams });
};

export const invokeRestService = (state: any, restServicePath: string) => {
    return invokeGetOnUrl(state, `rest/${restServicePath}`);
};

export const invokeRestServiceWithParameters = (
    state: any,
    restServicePath: string,
    urlParams: GisServerRestParameters
) => {
    return invokeGetOnUrl(state, `rest/${restServicePath}`, urlParams);
};
