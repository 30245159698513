import * as React from "react";
import { createSliderWithTooltip, default as RcSlider, Marks, SliderProps as RcSliderProps } from "rc-slider";
import { useDebouncedCallback } from "../hooks/useDebouncedCallback";
import { RCTooltip } from "rc-tooltip";

export const TooltipSlider = createSliderWithTooltip(RcSlider);

export type TypedChangeEventHandler<V> = (value?: V, ...args: any[]) => void;

interface IOverrideProps extends RcSliderProps {
    value?: any;
    defaultValue?: any;
    onChangeValue?: any;
    onAfterChange?: any;
}

export interface ISliderProps<T = number> extends IOverrideProps {
    id?: string;
    value?: T;
    defaultValue?: T;
    onChangeValue?: TypedChangeEventHandler<T>;
    onAfterChange?: TypedChangeEventHandler<T>;
    plaintext?: boolean;
    onDebouncedChange?: TypedChangeEventHandler<T>;
    tipFormatter?: (value: number) => React.ReactNode;
    tipProps?: Partial<RCTooltip.Props>;
}

/**
 * The component BasicSlider is internally implemented by using rc-slider component. It also extends rc-slider's props. Property onChange() is ignored.
 * onChangeValue() and onAfterChange() should be used instead. onAfterChange() is fired only if value was changed.
 */
export function Slider(props: ISliderProps) {
    const { value, onChange, onChangeValue, onAfterChange, disabled, plaintext, onDebouncedChange, marks, ...rest } =
        props;
    const valueChanged = React.useRef<boolean>(false);

    const onDebouncedChangeCallback = React.useCallback(
        (value) => {
            onDebouncedChange?.(value);
        },
        [onDebouncedChange]
    );

    const marksProps: Marks | undefined = React.useMemo(() => {
        if (!marks) return undefined;
        const newMarks: Marks = {};
        const marksKeys = Object.keys(marks);
        if (marksKeys.length === 1) {
            return marks;
        }
        marksKeys
            .map(parseFloat)
            .sort((a, b) => a - b)
            .forEach((point, index) => {
                const markPoint = marks[point];
                const markPointIsObject = typeof markPoint === "object" && !React.isValidElement(markPoint);
                const markLabel = markPointIsObject
                    ? (markPoint as { style: any; label: string | JSX.Element }).label
                    : (markPoint as string | JSX.Element);

                if (!markLabel) {
                    return;
                }
                if (index === 0) {
                    newMarks[point] = { style: { left: "unset", transform: "none !imporant" }, label: markLabel };
                } else if (index === marksKeys.length - 1) {
                    newMarks[point] = {
                        style: {
                            right: "0px",
                            left: "unset",
                            transform: "none !imporant",
                        },
                        label: markLabel,
                    };
                } else {
                    newMarks[point] = markPoint;
                }
            });

        return newMarks;
    }, [marks]);

    const _onDebouncedChange = useDebouncedCallback(onDebouncedChangeCallback, 300);

    return (
        <TooltipSlider
            {...rest}
            {...(value && { value })} // rc-slider does not like undefined value
            disabled={disabled || plaintext}
            onChange={(newValue) => {
                console.log("CHANGE", newValue);
                valueChanged.current = true;
                onChangeValue?.(newValue);
                _onDebouncedChange(newValue);
            }}
            onAfterChange={(newValue) => {
                console.log("onAfterChange");
                if (valueChanged.current) {
                    console.log("_onAfterChange", newValue);
                    valueChanged.current = false;
                    onAfterChange?.(newValue);
                }
            }}
            marks={marksProps}
        />
    );
}
