const GEOCALCULATION_VISIBILITY_TOGGLED = 'GEOCALCULATION_VISIBILITY_TOGGLED';

const geoCalculationVisibilityToggledAction = (geoCalculationId, visibility) => {
    return {
        type: GEOCALCULATION_VISIBILITY_TOGGLED,
        geoCalculationId: geoCalculationId,
        visibility: visibility
    };
};

export {GEOCALCULATION_VISIBILITY_TOGGLED, geoCalculationVisibilityToggledAction};
