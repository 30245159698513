import * as React from "react";
import { DelimitedSection } from "../../core/core-widgets/surfaces/DelimitedSection/DelimitedSection";
import { BasicInput } from "../../core/core-widgets/inputs/BasicInput";
import { BasicButton, ButtonColor } from "../../core/core-widgets/buttons";
import { useState } from "react";
import { SpacingBox } from "../../core/core-widgets/boxes/SpacingBox/SpacingBox";

export interface FeatureFilterDispatchProps {
    onFilterChange: (value: string) => void;
}

export const FeatureFilter = (props: FeatureFilterDispatchProps) => {
    const { onFilterChange } = props;
    const [input, setInput] = useState("");
    return (
        <DelimitedSection title={"Feature filter"}>
            <BasicInput type={"text"} value={input} onChangeValue={(value) => setInput(value)} />
            <SpacingBox my={2}>
                <BasicButton color={ButtonColor.PRIMARY} onClick={() => onFilterChange(input)}>
                    Set filter
                </BasicButton>
            </SpacingBox>
        </DelimitedSection>
    );
};
