import { registerPlugin } from "../common/mapstore/registerPlugin";
import { connect } from "react-redux";
import layersEpic from "./epic/layersEpic";
import groupParamsEpic from "./epic/groupParamsEpic";
import sortLayersEpic from "./epic/sortLayersEpic";
import { LayerProperties, LayerPropertiesDispatchProps, LayerPropertiesStateProps } from "./LayerProperties";
import { storeGisServerUrlAction } from "./redux/gis-server-url/storeGisServerUrl.action";
import { layerPropertiesReducer } from "./redux/reducer/layerProperties.reducer";
import { Layer, RootState } from "../rootState";
import { changeLayerProperties } from "../common/mapstore/action/layers";
import { LayerProperties as LayerProps } from "../common/mapstore/action/layers";

const mapStateToProps = (state: RootState): LayerPropertiesStateProps => {
    return {
        geoserverBackgroundLayers: state.layers.flat.filter((layer: Layer) => layer.gisServerBackground),
        gisServerUrl: state.layerOptions.gisServerUrl,
    };
};

const mapDispatchToProps = (dispatch: any): LayerPropertiesDispatchProps => {
    return {
        storeGisServerUrlAction: (url: string) => dispatch(storeGisServerUrlAction(url)),
        changeLayerPropertiesAction: (layer: string, properties: LayerProps) =>
            dispatch(changeLayerProperties(layer, properties)),
    };
};

export const ConnectedLayerProperties = connect(mapStateToProps, mapDispatchToProps)(LayerProperties);

export const LayerPropertiesPlugin = {
    name: "LayerPropertiesPlugin",
    config: {
        component: ConnectedLayerProperties,
        reducers: { layerOptions: layerPropertiesReducer },
        epics: {
            layersEpic: layersEpic,
            groupParamsEpic: groupParamsEpic,
            sortLayersEpic: sortLayersEpic,
        },
    },
};

export const register = () => registerPlugin(LayerPropertiesPlugin);
