import { Action } from "redux";

export const DRAW_LAYERS_ACTION = "DRAW_LAYERS_ACTION";

export interface DrawLayersAction extends Action {
    enabled: boolean;
}

export const drawLayersAction = (enabled: boolean): DrawLayersAction => ({
    type: DRAW_LAYERS_ACTION,
    enabled: enabled,
});
