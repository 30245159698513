import * as React from "react";
import { useEffect, useState } from "react";
import { PanelHeader } from "./PanelHeader";
import { ThemeColor } from "../theme";
import { GlyphButton } from "../buttons";
import { GlyphIcon } from "../icons";
import classNames from "classnames";

interface CollapsiblePanelProps {
    type?: ThemeColor;
    classname?: string;
    id?: string;
    icon: GlyphIcon;
    open?: boolean;
}

export const CollapsiblePanel: React.FC<CollapsiblePanelProps> = (props) => {
    const { children, type = ThemeColor.PRIMARY, open = true, classname: classnameProps, id, icon } = props;
    const [isOpen, setIsOpen] = useState(true);

    const className = classNames(classnameProps, "expandedContainer");

    useEffect(() => {
        // this is kind of anti pattern initializing local state from props,
        // but because this open prop is coming from config, it won`t change during time so it is okay to use it this way
        setIsOpen(open);
    }, [open]);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            {isOpen && (
                <div id={id} className={className}>
                    <PanelHeader type={type} onClose={toggleOpen} />
                    {children}
                </div>
            )}
            {!isOpen && <GlyphButton glyph={icon} onClick={toggleOpen} />}
        </>
    );
};
