import React from "react";

const VALIDATION_INTERVAL_IN_MILLISECONDS = 60 * 1000;

export interface SessionValidatorStateProps {
    sessionInvalid: boolean;
    axiosInitialized: boolean;
    autoLogin: boolean;
}

export interface SessionValidatorDispatchProps {
    validateSession: () => Promise<boolean>;
    showError: (title: string, message: string) => void;
}

export const SessionValidator = (props: SessionValidatorStateProps & SessionValidatorDispatchProps) => {
    const { validateSession, showError, sessionInvalid, axiosInitialized, autoLogin } = props;

    React.useEffect(() => {
        if (sessionInvalid) {
            showError(
                "Session Invalid",
                "Session expired or invalid, please log in to Wiz@rd Portal and open the webGIS application again"
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionInvalid]);

    React.useEffect(() => {
        if (!axiosInitialized) {
            return;
        }
        const handleValidateSession = () => {
            validateSession();
        };
        if (!autoLogin) {
            handleValidateSession();
        }

        const interval = setInterval(() => {
            handleValidateSession();
        }, VALIDATION_INTERVAL_IN_MILLISECONDS);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [axiosInitialized, autoLogin]);

    return null;
};
