import * as React from "react";
import { Dropdown, DropdownProps, Glyphicon, MenuItem } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/lib/DropdownToggle";
import DropdownMenu from "react-bootstrap/lib/DropdownMenu";
import { GlyphIcon } from "../icons";
import { CSSProperties, useCallback } from "react";
import { ButtonColor } from "./index";

export interface OptionTypeBase {
    [key: string]: any;
}

export interface DropdownButtonProps<OptionType> extends DropdownProps {
    options: OptionType[];
    color?: ButtonColor;
    onItemSelect: (item: OptionType) => void;
    icon?: GlyphIcon;
    text?: string;
    buttonStyle?: CSSProperties; // we should use some pre-defined button styles
    noCaret?: boolean;
    menuStyle?: CSSProperties;
    activeItem?: string | number;

    /* Resolves option data to a string to be displayed as the label by components */
    getOptionLabel?: (option: OptionType) => string;
    /* Resolves option data to a string to compare options and specify value attributes */
    getOptionValue?: (option: OptionType) => string;
}

export const DropdownButton = <OptionType extends OptionTypeBase = { label: string; value: string }>(
    props: DropdownButtonProps<OptionType>
) => {
    const {
        options,
        color = ButtonColor.PRIMARY,
        onItemSelect,
        icon,
        text,
        buttonStyle,
        noCaret,
        menuStyle,
        activeItem,
        getOptionLabel,
        getOptionValue,
        ...rest
    } = props;

    const getOptLabel = useCallback((option: OptionType) => getOptionLabel?.(option) ?? option.label, [getOptionLabel]);
    const getOptValue = useCallback((option: OptionType) => getOptionValue?.(option) ?? option.value, [getOptionValue]);

    return (
        <Dropdown {...rest}>
            <DropdownToggle bsStyle={color} noCaret={noCaret} style={buttonStyle}>
                {icon && <Glyphicon glyph={icon} />}
                {text && text}
            </DropdownToggle>
            <DropdownMenu style={props.menuStyle}>
                {options.map((item) => (
                    <MenuItem
                        key={getOptValue(item)}
                        active={activeItem === getOptValue(item)}
                        onSelect={(item) => {
                            onItemSelect(item);
                        }}
                        eventKey={item}
                    >
                        {getOptLabel(item)}
                    </MenuItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};
