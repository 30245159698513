import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import GeoCalculationItem from '@js/geocalc/dto/GeoCalculationItem';
import {requestGeoCalcListAction} from '@js/geocalc/action/requestGeoCalcListAction';
import {requestGeoCalcGeometryAction} from '@js/geocalc/action/requestGeoCalcGeometryAction';
import {geoCalculationVisibilityToggledAction} from '@js/geocalc/action/geoCalculationVisibiltyToggledAction';
import {geoCalcGeometryRemovedAction} from '@js/geocalc/action/geoCalcGeometryRemovedAction';
import {centerMapOnGeoCalculationAction} from '@js/geocalc/action/centerMapOnGeoCalculationAction';
import geoCalcListReducer from '@js/geocalc/reducer/geoCalcListReducer';
import geoCalcListEpic from './epic/geoCalcListLoadEpic';
import geoCalcInitialLoadEpic from './epic/geoCalcInitialLoadEpic';
import geoCalculationGeometryLoadEpic from './epic/geoCalculationGeometryLoadEpic';
import centerMapOnGeoCalculationEpic from './epic/centerMapOnGeoCalculationEpic';
import {createPlugin} from "@mapstore/utils/PluginsUtils";
import {geoCalcEnabledSelector} from "@js/common/selector/configurationSelector";
import LayerElement from '@js/layers/component/LayerElement';
import { isCesium } from '@mapstore/selectors/maptype';


class GeoCalc extends React.Component {

    static propTypes = {
        enabled: PropTypes.bool,
        treeItems: PropTypes.arrayOf(PropTypes.instanceOf(GeoCalculationItem)),
        allCalculations: PropTypes.arrayOf(PropTypes.instanceOf(GeoCalculationItem)),
        handleRequestGeoCalcList: PropTypes.func,
        handleGeoCalculationAdded: PropTypes.func,
        handleGeoCalculationRemoved: PropTypes.func,
        handleGeoCalculationVisibilityToggled: PropTypes.func,
        handleGeoCalculationZoom: PropTypes.func
    };

    render() {
        if (this.props.enabled) {
            return (
                <div id={"geoCalc"}>
                    <LayerElement className={"geoCalculation"}
                                  id={"geoCalculations"}
                                  lookUpTitle={"Geo Calculations"}
                                  lookupFilterMessage={"Filter Geo Calculations"}
                                  treeItems={this.props.treeItems}
                                  lookUpItems={this.props.allCalculations}
                                  onTreeItemAdd={this.props.handleRequestGeoCalcList}
                                  onTreeItemRemove={this.props.handleGeoCalculationRemoved}
                                  onLookupItemAdd={this.props.handleGeoCalculationAdded}
                                  onTreeItemVisibilityChange={this.props.handleGeoCalculationVisibilityToggled}
                                  onTreeItemClick={this.props.handleGeoCalculationZoom}/>
                </div>

            );
        }
        return null;
    }
}

const GeoCalcPlugin = connect((state) => {
    return {
        enabled: geoCalcEnabledSelector(state) && !isCesium(state),
        allCalculations: state.geoCalcList && state.geoCalcList.allCalculations ? state.geoCalcList.allCalculations : [],
        treeItems: state.geoCalcList && state.geoCalcList.items || []
    };
}, {
    handleRequestGeoCalcList: requestGeoCalcListAction,
    handleGeoCalculationAdded: requestGeoCalcGeometryAction,
    handleGeoCalculationVisibilityToggled: geoCalculationVisibilityToggledAction,
    handleGeoCalculationRemoved: geoCalcGeometryRemovedAction,
    handleGeoCalculationZoom: centerMapOnGeoCalculationAction
})(GeoCalc);


export default createPlugin("GeoCalcPlugin", {
    component: GeoCalcPlugin,
    reducers: {
        geoCalcList: geoCalcListReducer
    },
    epics: {
        geoCalcListEpic: geoCalcListEpic,
        geoCalcInitialLoadEpic: geoCalcInitialLoadEpic,
        geoCalculationGeometryLoadEpic: geoCalculationGeometryLoadEpic,
        centerMapOnGeoCalculationEpic: centerMapOnGeoCalculationEpic
    }
});

