import { Action } from "redux";
import { Feature } from "../../../core/geo/types";

export const INSERT_FEATURE_GEOMETRY = "INSERT_FEATURE_GEOMETRY";

export interface InsertFeatureAction extends Action {
    feature: Feature;
}

export const insertFeatureAction = (feature: Feature): InsertFeatureAction => ({
    type: INSERT_FEATURE_GEOMETRY,
    feature: feature,
});
