import { createReducer } from "../../core/core-redux/reducer/createReducer";
import { SelectedFeaturesFromPortalState } from "./types";
import { selectFeatureFromPortalPartialReducer } from "./select-feature/selectFeatureFromPortal.reducer";
import { SELECT_FEATURE_FROM_PORTAL } from "./select-feature/selectFeatureFromPortal.action";
import { DESELECT_FEATURE_FROM_PORTAL } from "./deselect-feature/deselectFeatureFromPortal.action";
import { deselectFeatureFromPortalPartialReducer } from "./deselect-feature/deselectFeatureFromPortal.reducer";

export const portalReducer = createReducer<SelectedFeaturesFromPortalState>(
    {},
    {
        [SELECT_FEATURE_FROM_PORTAL]: selectFeatureFromPortalPartialReducer,
        [DESELECT_FEATURE_FROM_PORTAL]: deselectFeatureFromPortalPartialReducer,
    }
);
