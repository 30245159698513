import { Action } from "redux";
import { Feature } from "../../../../core/geo/types";

export const SHOW_SELECTED_FEATURE = "SHOW_SELECTED_FEATURE";

export interface ShowSelectedFeatureAction extends Action {
    feature: Feature;
    selectedFromPortal: boolean | undefined;
}

export const showSelectedFeatureAction = (
    feature: Feature,
    selectedFromPortal: boolean | undefined
): ShowSelectedFeatureAction => {
    return {
        type: SHOW_SELECTED_FEATURE,
        feature: feature,
        selectedFromPortal: selectedFromPortal,
    };
};
