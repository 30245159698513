import { EditFeatureState } from "../editFeature.reducer";
import { IPartialReducer } from "../../../core/core-redux/reduxTypes";
import { ChangeTypes } from "../../util/geometry/types";

export const geometryChangedPartialReducer: IPartialReducer<EditFeatureState> = (state, action) => {
    return {
        ...state,
        options: {
            ...state.options,
            editPaused:
                action.change.type === ChangeTypes.ADD_LINE_POINT ||
                action.change.type === ChangeTypes.REMOVE_LINE_POINT ||
                action.change.type === ChangeTypes.ADD_POLYGON_POINT ||
                action.change.type === ChangeTypes.REMOVE_POLYGON_POINT ||
                action.change.type === ChangeTypes.INSERT_LINE ||
                action.change.type === ChangeTypes.INSERT_POINT ||
                action.change.type === ChangeTypes.INSERT_POLYGON ||
                action.change.type === ChangeTypes.INSERT_CIRCLE,
        },
    };
};
