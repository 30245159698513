import { IPartialReducer } from "../../../core/core-redux/reduxTypes";
import { EditFeatureState } from "../editFeature.reducer";

export const setArcModePartialReducer: IPartialReducer<EditFeatureState> = (state, action) => {
    return {
        ...state,
        options: {
            ...state.options,
            arcMode: action.mode,
        },
    };
};
