import * as React from "react";
import { DockPanel } from "../../core/core-widgets/panels/DockPanel";
import { Feature } from "../../core/geo/types";
import { getFrameUrl } from "./utils/getFrameUrl";

export interface FeatureDetailFrameStateProps {
    enabled: boolean;
    wizardPortalUrl: string;
    feature?: Feature;
    ticket?: string;
    effectiveDate: string;
    size?: number;
    zIndex?: number;
}

export interface FeatureDetailFrameDispatchProps {
    onClose: () => void;
}

type FeatureDetailFrameProps = FeatureDetailFrameStateProps & FeatureDetailFrameDispatchProps;

export const FeatureDetailFrame = (props: FeatureDetailFrameProps) => {
    const { enabled, onClose, wizardPortalUrl, ticket, feature, effectiveDate, size = 660, zIndex = 1000 } = props;

    if (feature && enabled) {
        const featureDetailURL = getFrameUrl(wizardPortalUrl, effectiveDate, ticket, feature);
        return (
            <div id={"identify-container"} className={enabled ? "identify-active" : ""}>
                <DockPanel
                    title={"Feature Detail"}
                    open={enabled}
                    size={size}
                    position={"right"}
                    resizable={false}
                    onClose={onClose}
                    zIndex={zIndex}
                >
                    <iframe id={"portal-feature-detail-iframe"} src={featureDetailURL} height={"95%"} width={"100%"} />
                </DockPanel>
            </div>
        );
    }
    return null;
};
