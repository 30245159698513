import React from "react";
import { GeoCalculationsByIdsServiceParams, LoadSlotChangesServiceParams } from "../api/api-packages";
import { UrlParams } from "../url/redux";
import { validate } from "../core/plugins/pluginValidator";
import { plugins } from "../core/plugins/plugins";
import { initAxiosInstance } from "../api/axiosInstance";
import { getPluginsConfiguration } from "../common/mapstore/pluginsConfiguration";

export interface StartupDispatchProps {
    featureToLayersMapping: () => Promise<void>;
    changeTypeColorMapping: () => Promise<void>;
    loadGeoCalculationsByIds: (params: GeoCalculationsByIdsServiceParams) => Promise<void>;
    loadSlotChanges: (params: LoadSlotChangesServiceParams) => Promise<void>;
    loginToWS: () => Promise<void>;
    getCapabilities: () => Promise<void>;
    showError: (title: string, message: string) => void;
    setAxiosInitialized: (initialized: boolean) => void;
}

export interface StartupStateProps {
    ticket?: string;
    config?: unknown;
    urlParams?: UrlParams;
    axiosInitialized: boolean;
}

// const ONE_MINUTE_TIMEOUT = 60 * 1000;

export const Startup = (props: StartupStateProps & StartupDispatchProps) => {
    const {
        ticket,
        axiosInitialized,
        featureToLayersMapping,
        getCapabilities,
        showError,
        setAxiosInitialized,
        // urlParams,
        // loginToWS,
        // changeTypeColorMapping,
        // loadGeoCalculationsByIds,
        // loadSlotChanges,
    } = props;

    // TODO ICH: use this when sessionValidator will be migrated
    // React.useEffect(() => {
    //     const interval = setInterval(() => {
    //         validateSessionService({});
    //     }, ONE_MINUTE_TIMEOUT);
    //     return () => clearInterval(interval);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    React.useEffect(() => {
        const initAxios = async () => {
            await initAxiosInstance();
            setAxiosInitialized(true);
        };
        initAxios();
    }, []);

    React.useEffect(() => {
        if (ticket && axiosInitialized) {
            featureToLayersMapping();
            getCapabilities();
            //validate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticket, axiosInitialized]);

    React.useEffect(() => {
        const checkPlugins = async () => {
            const pluginsConfiguration = await getPluginsConfiguration();
            const result = validate(plugins, pluginsConfiguration.plugins);
            if (pluginsConfiguration.validatePlugins && result.length > 0) {
                console.error(`${result.join()} not registered properly. Did you update localConfig.json?`);
                showError("Some plugins are missing", "Check console for more information.");
            }
        };
        checkPlugins();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const validate = async () => {
    //     try {
    //         const sessionResult = await validateSessionService({});
    //         if (sessionResult) {
    //             initApp();
    //         }
    //     } catch (e) {
    //         if (urlParams?.autoLogin) {
    //             await loginToWS();
    //         }
    //         console.error(e);
    //     }
    // };

    // const initApp = () => {
    //     try {
    //         featureToLayersMapping();
    //         // changeTypeColorMapping();
    //         // getCapabilities();
    //         // if (urlParams?.slots) {
    //         //     loadSlotChanges({
    //         //         slotIds: urlParams.slots.getSlotIds(),
    //         //         forceReload: false,
    //         //     });
    //         // }
    //         //
    //         // if (urlParams?.geoCalcIds) {
    //         //     loadGeoCalculationsByIds({
    //         //         geoCalcIds: urlParams?.geoCalcIds,
    //         //     });
    //         // }
    //     } catch (e) {
    //         console.log(e);
    //     }
    // };

    return null;
};
