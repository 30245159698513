import React from "react";
import PropTypes from "prop-types";
import { Glyphicon as GlyphiconRB } from "react-bootstrap";
import tooltip from "../../../MapStore2/web/client/components/misc/enhancers/tooltip";

const Glyphicon = tooltip(GlyphiconRB);

class TooltipIcon extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        itemId: PropTypes.string | PropTypes.number,
        glyph: PropTypes.string,
        className: PropTypes.string,
        tooltip: PropTypes.string,
    };

    render() {
        if (this.props.tooltip) {
            return (
                <div id={this.props.id} className={this.props.className}>
                    <Glyphicon className={"text-primary"} glyph={this.props.glyph} tooltip={this.props.tooltip} />
                </div>
            );
        }
        return null;
    }
}

export default TooltipIcon;
