const { SET_WFS_3D_LAYER_COUNT } = require("@js/globe/action/setWfs3dLayerCountAction");

const assign = require("object-assign");
const { LAYER_LOADING, LAYER_LOAD } = require("@mapstore/actions/layers");
const { initState } = require("@js/common/util/StateUtil");
const { BASELINE_LAYER_ID, SLOT_LAYER_ID } = require("@js/layers/constants/layerConstants");
const { RELOAD_CHANGES, HIDE_CHANGES_LOADER } = require("@js/changes/action/reloadChangesAction");

function isBaselineOrSlotLayer(action) {
    return action.layerId === BASELINE_LAYER_ID || action.layerId === SLOT_LAYER_ID;
}

function changesLoaderReducer(initialState, action) {
    const state = initState(initialState);
    if (action.type === LAYER_LOADING) {
        if (isBaselineOrSlotLayer(action)) {
            const layerIds = state.layerIds ? [...state.layerIds] : [];
            return assign({}, state, { layerIds: [...layerIds, action.layerId], display: true });
        }
    }
    if (action.type === LAYER_LOAD) {
        if (isBaselineOrSlotLayer(action)) {
            const layerIds = state.layerIds ? [...state.layerIds] : [];

            const newLayerIds = layerIds.filter((layerId) => layerId !== action.layerId);
            if (newLayerIds.length === 0) {
                return assign({}, state, { layerIds: newLayerIds, display: false });
            }
            return assign({}, state, { layerIds: newLayerIds, display: true });
        }
    }
    if (action.type === RELOAD_CHANGES) {
        return assign({}, state, { display: true });
    }
    if (action.type === SET_WFS_3D_LAYER_COUNT) {
        return assign({}, state, { display: true });
    }
    if (action.type === HIDE_CHANGES_LOADER) {
        return assign({}, state, { display: false });
    }
    return state;
}

module.exports = changesLoaderReducer;
