const {initState} = require('@js/common/util/StateUtil');

const createReducer = (handlers) => {
    return function reducer(initialState, action) {
        const state = initState(initialState);
        if (handlers.hasOwnProperty(action.type)) {
            return handlers[action.type](state, action);
        }
        return state;
    };
};

module.exports = createReducer;
