import * as React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { ButtonColor, ButtonSize, ButtonType } from "./types";

interface BasicButtonProps extends ButtonProps {
    color?: ButtonColor;
    btnSize?: ButtonSize;
    btnType?: ButtonType;
}

export const BasicButton = (props: BasicButtonProps) => {
    const { color, btnSize, btnType, ...rest } = props;
    return (
        <Button bsStyle={color} bsSize={btnSize} type={btnType} {...rest}>
            {props.children}
        </Button>
    );
};
