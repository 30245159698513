const assign = require("object-assign");
const { initState } = require("@js/common/util/StateUtil");
const { SAVE_CHANGE_TYPE_COLORING } = require("@js/changes/action/saveChangeTypeColoringAction");

function changeTypeColoringReducer(initialState, action) {
    const state = initState(initialState);
    if (action.type === SAVE_CHANGE_TYPE_COLORING) {
        return assign({}, state, { changeToColorMapping: action.changeToColorMapping });
    }
    return state;
}

module.exports = changeTypeColoringReducer;
