import { standardApiHandler } from "../../../core/core-api/apiTools";
import { loginToWizardSuiteAdapter } from "./loginToWizardSuite.adapter";

export type LoginToWizardSuiteServiceParams = {};
export type LoginToWizardSuiteServiceResponse = string;

export type LoginToWizardSuiteService = (
    params: LoginToWizardSuiteServiceParams
) => Promise<LoginToWizardSuiteServiceResponse>;
export const loginToWizardSuiteService: LoginToWizardSuiteService = standardApiHandler(loginToWizardSuiteAdapter);
