import { Action } from "redux";
import { FilterCondition } from "../types";

export const STORE_WFS_3D_LAYERS = "STORE_WFS_3D_LAYERS";

export interface StoreWfs3dLayersAction extends Action {
    features: any;
    layerGroup: string;
    layerName: string;
    filters?: FilterCondition[];
    featureShortName: string;
}

export const storeWfs3dLayersAction = (
    features: any,
    layerGroup: string,
    layerName: string,
    featureShortName: string,
    filters?: FilterCondition[]
): StoreWfs3dLayersAction => {
    return {
        type: STORE_WFS_3D_LAYERS,
        features: features,
        layerGroup: layerGroup,
        layerName: layerName,
        featureShortName: featureShortName,
        filters: filters,
    };
};
