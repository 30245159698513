import { IAsyncDataState } from "../../../core/core-redux/reduxTypes";
import { COLOR_MAPPING } from "./colorMapping.action";
import { ColorMappingServiceParams, ColorMappingServiceResponse } from "./colorMapping.service";
import { asyncFetchDataReducerFactory } from "../../../core/core-redux/reducer/asyncFetchDataReducer";

export interface ColorMappingDataState
    extends IAsyncDataState<ColorMappingServiceResponse, any, ColorMappingServiceParams> {}

export const colorMappingReducer = asyncFetchDataReducerFactory<
    ColorMappingServiceResponse,
    any,
    ColorMappingServiceParams
>(COLOR_MAPPING);
