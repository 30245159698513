import React from "react";
import PropTypes from "prop-types";
import { ChangeTypeCheckbox } from "@js/changes/component/body/child/ChangeTypeCheckbox";

export class ChangeTypeSelector extends React.Component {
    static propTypes = {
        disabled: PropTypes.bool,
        hidden: PropTypes.bool,
        selectedChangeTypes: PropTypes.array,
        handleChangesTypeChanged: PropTypes.func,
        changeTypeColoring: PropTypes.object,
    };

    render() {
        if (this.props.hidden) {
            return null;
        }

        const changeTypes = [
            { name: "I", display: "new", color: this.props.changeTypeColoring && this.props.changeTypeColoring.I },
            { name: "W", display: "removed", color: this.props.changeTypeColoring && this.props.changeTypeColoring.W },
            { name: "U", display: "modified", color: this.props.changeTypeColoring && this.props.changeTypeColoring.U },
            {
                name: "O",
                display: "originals",
                color: this.props.changeTypeColoring && this.props.changeTypeColoring.O,
            },
            { name: "N", display: "unchanged" },
        ];

        const checkboxes = changeTypes.map((changeType) => (
            <ChangeTypeCheckbox
                key={changeType.name}
                name={changeType.name}
                displayName={changeType.display}
                changeTypeValue={
                    this.props.selectedChangeTypes && this.props.selectedChangeTypes.includes(changeType.name)
                }
                changeTypeChangedFunction={this.props.handleChangesTypeChanged}
                disabled={this.props.disabled}
                layerColor={changeType.color}
            />
        ));

        return <div className="changesVisualizationPanelBodyCheckboxes">{checkboxes}</div>;
    }
}
