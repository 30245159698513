const TARGET_SCREEN_RATIO_MIN = 0.08;
const TARGET_SCREEN_RATIO_MAX = 0.5;
const MAX_ZOOM = 16;

const { reproject } = require("@mapstore/utils/CoordinatesUtils");

const Logger = require("@js/common/util/Logger");
const logger = new Logger("FeatureCenter");
const { EPSG_900913, EPSG_3857 } = require("@js/common/constants/Projections");

function getCoordinatesBorders(coordinates, borders) {
    if (isNaN(coordinates[0]) || isNaN(coordinates[1])) {
        coordinates.forEach((coords) => getCoordinatesBorders(coords, borders));
    } else {
        if (!borders.left || borders.left > coordinates[0]) {
            borders.left = coordinates[0];
        }
        if (!borders.right || borders.right < coordinates[0]) {
            borders.right = coordinates[0];
        }
        if (!borders.top || borders.top > coordinates[1]) {
            borders.top = coordinates[1];
        }
        if (!borders.bottom || borders.bottom < coordinates[1]) {
            borders.bottom = coordinates[1];
        }
    }
    return borders;
}

function getGeometriesBorders(geometries) {
    const borders = {};
    geometries.forEach((geometry) => getCoordinatesBorders(geometry.coordinates, borders));
    return borders;
}

function calculateCenter(geometries) {
    const borders = getGeometriesBorders(geometries);

    const x = borders.left + (borders.right - borders.left) / 2;
    const y = borders.top + (borders.bottom - borders.top) / 2;

    return { center: [x, y], bbox: [Math.abs(borders.left - borders.right), Math.abs(borders.top - borders.bottom)] };
}

function getMapProjection(mapPresent) {
    return mapPresent.projection === EPSG_900913 ? EPSG_3857 : mapPresent.projection;
}

function calculateFeatureCenter(features, mapPresent) {
    const featuresWithNullGeometry = features.filter((feature) => !feature.geometry).map((feature) => feature.id);

    if (featuresWithNullGeometry.length > 0) {
        logger.warn(`features with null geometry: ${featuresWithNullGeometry}`);
    }

    const centerCalculation = calculateCenter(features.map((feature) => feature.geometry));

    const targetScreenRatio =
        (TARGET_SCREEN_RATIO_MIN - TARGET_SCREEN_RATIO_MAX) / features.length + TARGET_SCREEN_RATIO_MAX;

    const mapBbox = mapPresent.bbox.bounds;
    const mapBboxSize = [Math.abs(mapBbox.maxx - mapBbox.minx), Math.abs(mapBbox.maxy - mapBbox.miny)];

    const ratioX = mapBboxSize[0] / centerCalculation.bbox[0];
    const ratioY = mapBboxSize[1] / centerCalculation.bbox[1];

    const targetZoom = mapPresent.zoom + Math.ceil(Math.log2(targetScreenRatio * Math.min(ratioX, ratioY)));

    return {
        center: reproject(centerCalculation.center, EPSG_3857, getMapProjection(mapPresent)),
        zoom: Math.min(targetZoom, MAX_ZOOM),
    };
}

module.exports = {
    calculateFeatureCenter,
};
