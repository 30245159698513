import { AnyAction, IPartialReducer } from "../../../core/core-redux/reduxTypes";
import { LayersPropertiesState } from "../reducer/layerProperties.reducer";

export const storeGisServerUrlPartialReducer: IPartialReducer<LayersPropertiesState> = (
    state: LayersPropertiesState,
    action: AnyAction
) => {
    return {
        gisServerUrl: action.url,
    };
};
