import React from "react";
import PropTypes from "prop-types";
import LayerItem from "../../layers/component/LayerItem";
import Paginator from "../../common/component/Paginator";

class ItemList extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        position: PropTypes.object,
        visibleItems: PropTypes.array,
        hiddenItems: PropTypes.array,
        pageSize: PropTypes.number,
        pageNumber: PropTypes.number,
        onHide: PropTypes.func,
        onAdd: PropTypes.func,
        onRemove: PropTypes.func,
        onPageChange: PropTypes.func,
        onRowClick: PropTypes.func,
    };

    static defaultProps = {
        pageSize: 5,
        position: {
            startItem: 0,
            pageNumber: 1,
            totalPages: 1,
        },
    };

    render() {
        const endItem = this.props.position.endItem || this.props.visibleItems.length;

        const rows = this.props.visibleItems
            .slice(this.props.position.startItem, endItem)
            .map((item) => (
                <LayerItem
                    key={this.props.id + item.id}
                    className={this.props.className}
                    itemVisible={this.props.hiddenItems.includes(item.id)}
                    onAdd={this.props.onAdd}
                    onHide={this.props.onHide}
                    onRemove={this.props.onRemove}
                    onClick={this.props.onRowClick}
                    item={item}
                />
            ));
        return (
            <div className={"itemList"}>
                <div id={this.props.id + "List"}>{rows}</div>
                <Paginator
                    onPageChange={this.props.onPageChange}
                    pageNumber={this.props.position.pageNumber}
                    totalPages={this.props.position.totalPages}
                />
            </div>
        );
    }
}

export default ItemList;
