import * as React from "react";
import { CenterMap, CenterMapDispatchProps, CenterMapStateProps } from "../center-map/CenterMap";
import { HeightAdjuster, HeightAdjusterDispatchProps } from "../height-adjuster/HeightAdjuster";
import { FlexBox } from "../core/core-widgets/boxes/FlexBox/FlexBox";
import { GLOBE_3D_PROJECTION } from "../common/constants/Projections";
import { useState } from "react";
import { ScaleBox, ScaleBoxDispatchProps, ScaleBoxStateProps } from "../scale-box/ScaleBox";
import { ConnectedProjectionSelector } from "../projection/ConnectedProjectionSelector";

export type FooterDispatchProps = CenterMapDispatchProps & HeightAdjusterDispatchProps & ScaleBoxDispatchProps;

export type FooterStateProps = CenterMapStateProps &
    ScaleBoxStateProps & {
        projection: string;
        enabled: boolean;
    };

type FooterProps = FooterDispatchProps & FooterStateProps;

export const Footer = (props: FooterProps) => {
    const { enabled, projection } = props;
    const [sliderValue, setSliderValue] = useState(1);
    return enabled ? (
        <FlexBox paddingTop={"2px"} gap={"2px"}>
            <CenterMap {...props} />
            {projection === GLOBE_3D_PROJECTION && (
                <HeightAdjuster {...props} value={sliderValue} changeValue={(val) => setSliderValue(val)} />
            )}
            <ScaleBox {...props} />
            <ConnectedProjectionSelector />
        </FlexBox>
    ) : null;
};
