import { GeometryRetriever } from "./geometryRetriever";
import { ChangeTypes, CoordinateChange, GeometryChange } from "./types";
import Logger from "../../../common/util/Logger";
import { EditFeatureOptions } from "../../redux";
import { getChangedPointWithIndex } from "./utils";
import { EditAction } from "../../redux/types";
import { GeometryCoordinates } from "../../../core/geo/types";
import { isPolygonCoordinates } from "../../../core/geo/assertions";

const logger = new Logger("polygonRetriever");

const identifyOperation = (
    oldCoordinates: GeometryCoordinates | undefined,
    newCoordinates: GeometryCoordinates,
    featureOptions: EditFeatureOptions
): ChangeTypes => {
    // TODO ICH: refactor based on coordinates
    if (oldCoordinates === undefined) {
        return ChangeTypes.INSERT_POLYGON;
    }
    if (isPolygonCoordinates(oldCoordinates) && isPolygonCoordinates(newCoordinates)) {
        for (const index in oldCoordinates) {
            if (oldCoordinates[index].length < newCoordinates?.[index].length) {
                return ChangeTypes.ADD_POLYGON_POINT;
            } else if (oldCoordinates[index].length > newCoordinates?.[index].length) {
                return ChangeTypes.REMOVE_POLYGON_POINT;
            }
        }
    }

    if (featureOptions?.action === EditAction.REMOVE_VERTEX) {
        return ChangeTypes.NONE;
    }
    if (featureOptions?.action === EditAction.MOVE_FEATURE) {
        return ChangeTypes.MOVE_POLYGON;
    }

    return ChangeTypes.MOVE_POLYGON_POINT;
};

const getPolygonChangedPointWithIndex = (
    oldCoordinates: GeometryCoordinates,
    newCoordinates: GeometryCoordinates
): CoordinateChange => {
    if (isPolygonCoordinates(oldCoordinates) && isPolygonCoordinates(newCoordinates)) {
        for (const groupIndex in oldCoordinates) {
            const change = getChangedPointWithIndex(oldCoordinates[groupIndex], newCoordinates[groupIndex]);
            if (change) {
                return {
                    coordinates: change.coordinates,
                    pointIndex: change.pointIndex,
                    groupIndex: groupIndex as any,
                };
            }
        }
    }

    throw new Error("Could not find index");
};

const handlePointAdded = (
    oldCoordinates: GeometryCoordinates | undefined,
    newCoordinates: GeometryCoordinates
): GeometryChange => {
    const change = getPolygonChangedPointWithIndex(oldCoordinates!, newCoordinates);
    logger.info(`polygon feature point added ${change}`);
    return {
        type: ChangeTypes.ADD_POLYGON_POINT,
        coordinateChange: change,
    };
};

const handlePointRemoved = (
    oldCoordinates: GeometryCoordinates | undefined,
    newCoordinates: GeometryCoordinates
): GeometryChange => {
    const change = getPolygonChangedPointWithIndex(oldCoordinates!, newCoordinates);
    logger.info(`polygon feature point removed: ${change}`);
    return {
        type: ChangeTypes.REMOVE_POLYGON_POINT,
        coordinateChange: change,
    };
};

const handleNoChange = () => {
    logger.info("polygon geometry not changed");
    return { type: ChangeTypes.NONE };
};

const handlePointMoved = (
    oldCoordinates: GeometryCoordinates | undefined,
    newCoordinates: GeometryCoordinates
): GeometryChange => {
    const change = getPolygonChangedPointWithIndex(oldCoordinates!, newCoordinates);
    if (change) {
        logger.info(`polygon point moved: ${change}`);
        return {
            type: ChangeTypes.MOVE_POLYGON_POINT,
            coordinateChange: change,
        };
    }
    return handleNoChange();
};

const removeLastElementInPolygonCoordinates = (newCoordinates: GeometryCoordinates) => {
    const updatedCoordinates: GeometryCoordinates = [];
    if (isPolygonCoordinates(newCoordinates)) {
        for (const groupIndex in newCoordinates) {
            const groupCoordinates = [...newCoordinates[groupIndex]];
            groupCoordinates.pop();
            updatedCoordinates[groupIndex] = groupCoordinates;
        }
    }

    return updatedCoordinates;
};

const handleWholePolygonMoved = (
    oldCoordinates: GeometryCoordinates | undefined,
    newCoordinates: GeometryCoordinates
): GeometryChange => {
    logger.info("whole polygon feature moved");
    return {
        type: ChangeTypes.MOVE_POLYGON,
        coordinateChange: {
            coordinates: removeLastElementInPolygonCoordinates(newCoordinates),
        },
    };
};

const handleInsertPolygon = (
    oldCoordinates: GeometryCoordinates | undefined,
    newCoordinates: GeometryCoordinates
): GeometryChange => {
    logger.info(`inserting polygon with coordinates: ${newCoordinates}`);
    return {
        type: ChangeTypes.INSERT_POLYGON,
        coordinateChange: {
            coordinates: removeLastElementInPolygonCoordinates(newCoordinates),
        },
    };
};

const handlers: {
    [key in ChangeTypes]: (
        oldCoordinates: GeometryCoordinates | undefined,
        newCoordinates: GeometryCoordinates
    ) => GeometryChange;
} = {
    ADD_POLYGON_POINT: handlePointAdded,
    REMOVE_POLYGON_POINT: handlePointRemoved,
    MOVE_POLYGON_POINT: handlePointMoved,
    MOVE_POLYGON: handleWholePolygonMoved,
    INSERT_POLYGON: handleInsertPolygon,
    NONE: handleNoChange,
    // NOT IMPLEMENTED
    MOVE_POINT: () => {
        throw new Error("Not implemented yet. (MOVE_POINT)");
    },
    INSERT_POINT: () => {
        throw new Error("Not implemented yet. (INSERT_POINT)");
    },
    ADD_LINE_POINT: () => {
        throw new Error("Not implemented yet. (ADD_LINE_POINT)");
    },
    INSERT_LINE: () => {
        throw new Error("Not implemented yet. (INSERT_LINE)");
    },
    MOVE_LINE: () => {
        throw new Error("Not implemented yet. (MOVE_LINE)");
    },
    MOVE_LINE_POINT: () => {
        throw new Error("Not implemented yet. (MOVE_LINE_POINT)");
    },
    REMOVE_LINE_POINT: () => {
        throw new Error("Not implemented yet. (REMOVE_LINE_POINT)");
    },
    RADIUS_CHANGED: () => {
        throw new Error("Not implemented yet. (RADIUS_CHANGED)");
    },
    INSERT_CIRCLE: () => {
        throw new Error("Not implemented yet. (INSERT_CIRCLE)");
    },
    MOVE_CIRCLE: () => {
        throw new Error("Not implemented yet. (MOVE_CIRCLE)");
    },
};

export const polygonRetriever: GeometryRetriever = {
    retrieveChange(oldCoordinates, newCoordinates, featureOptions): GeometryChange {
        const operation = identifyOperation(oldCoordinates, newCoordinates, featureOptions);
        return handlers[operation](oldCoordinates, newCoordinates);
    },
};
