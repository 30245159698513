import { buildIdentifyRequest } from "../../../MapStore2/web/client/utils/MapInfoUtils";
import { BASELINE_LAYER_GROUP, WORKSPACE_LAYER_GROUP } from "../../common/selector/layerSelector";
import EnvParamsRetriever from "../../common/util/EnvParamsRetriever";
import { featureInfoCountSelector, workspaceModeSelector } from "../../common/selector/configurationSelector";
import { viewSelector } from "../../changes/selector/workspaceChangesSelector";
import { WORKSPACE_VIEW } from "../../changes/changesConstants";
import FilterSelector from "../../filter/FeatureFilter/redux/selector/FilterSelector";
import { sessionSelector } from "../../session/redux/sessionSelector";
import { currentLocaleSelector } from "../../../MapStore2/web/client/selectors/locale";
import { get } from "lodash";
import { createStructuredSelector } from "reselect";
import { Layer, RootState } from "../../rootState";

const generalInfoFormatSelector = (state: any) => get(state, "mapInfo.configuration.infoFormat", "text/plain");
const mapSelector = (state: any) =>
    (state.map && state.map.present) || state.map || (state.config && state.config.map) || null;
const clickPointSelector = (state: any) => state && state.clickedPoint && state.clickedPoint.point;

const identifyOptionsSelector = createStructuredSelector({
    format: generalInfoFormatSelector,
    map: mapSelector,
    point: clickPointSelector,
    currentLocale: currentLocaleSelector,
});

function buildGisServerRestUrl(state: RootState, identifier: string, layerNames: string, request: any, envParams: any) {
    const restOptions: any = {
        layers: layerNames,
        bBox: request.bbox,
        crs: state.map?.present?.projection,
        validFrom: encodeURIComponent(envParams.validFrom),
        validTo: envParams.validTo ? encodeURIComponent(envParams.validTo) : "",
        slotIds: envParams.slots,
        featureInfoCount: "" + featureInfoCountSelector(state),
        requestedChanges: envParams.requestedChanges,
        dataSetFilters: FilterSelector.getDataSetFiltersAsString(state),
    };

    const queryString = Object.keys(restOptions)
        .filter((key) => restOptions[key] && restOptions[key].length > 0)
        .map((key) => key + "=" + restOptions[key])
        .join("&");
    return {
        url: queryString,
        identifier: identifier,
        ticket: sessionSelector(state),
    };
}

export function buildFeatureInfoRequests(baselineLayers: Layer[], workspaceLayers: Layer[], state: RootState) {
    let { request } = buildIdentifyRequest([...baselineLayers, ...workspaceLayers][0], identifyOptionsSelector(state));

    const requests = [];
    if (baselineLayers.length > 0) {
        const layerNames = [...new Set(baselineLayers.map((layer) => layer.name))].join();
        requests.push(
            buildGisServerRestUrl(
                state,
                BASELINE_LAYER_GROUP,
                layerNames,
                request,
                EnvParamsRetriever.getBaselineEnvParams(state)
            )
        );
    }
    if ((workspaceModeSelector(state) || viewSelector(state) === WORKSPACE_VIEW) && workspaceLayers.length > 0) {
        const layerNames = [...new Set(workspaceLayers.map((layer) => layer.name))].join();
        requests.push(
            buildGisServerRestUrl(
                state,
                WORKSPACE_LAYER_GROUP,
                layerNames,
                request,
                EnvParamsRetriever.getWorkspaceEnvParams(state)
            )
        );
    }
    return requests;
}
