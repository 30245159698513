import Cesium from "../../../../MapStore2/web/client/libs/cesium";
import Logger from "../../../common/util/Logger";
import { getPolylinePositionWithHeight } from "../util/PolylineUtil";

import { buildRouteShapeArray } from "../util/routeGeometryUtil";
import { extractWfs3dProperties } from "../util/Wfs3dPropertiesExtractor";
import { Entity } from "cesium";

const logger = new Logger("RouteParser");

const ROUTE_FILL_COLOR = Cesium.Color.GREEN;

export const parseRoute = (entity: Entity) => {
    const clonedEntity = Cesium.clone(entity);

    const properties = extractWfs3dProperties(entity);

    if (!entity.polyline) {
        logger.warn("not polyline entity tried to be parsed as Route");
    } else {
        clonedEntity.polylineVolume = {
            positions: getPolylinePositionWithHeight(clonedEntity.polyline.positions, properties.lowerLimit),
            shape: buildRouteShapeArray(properties),
            cornerType: Cesium.CornerType.MITERED,
            material: ROUTE_FILL_COLOR.withAlpha(0.2),
            outline: true,
            outlineColor: ROUTE_FILL_COLOR,
        };
        clonedEntity._properties.polylinePositions = entity.polyline.positions;
        clonedEntity.polyline = undefined;
    }
    return clonedEntity;
};
