import Cesium from "../../../../MapStore2/web/client/libs/cesium";
import { Cartesian3, JulianDate, Property } from "cesium";

export const getPolylinePositionWithHeight = (positions: Property, height: number) => {
    const coordinatesWithHeight = positions.getValue(new JulianDate()).flatMap((coordinate: Cartesian3) => {
        const pointInRadians = Cesium.Cartographic.fromCartesian(coordinate);
        return [pointInRadians.longitude, pointInRadians.latitude, height];
    });
    return Cesium.Cartesian3.fromRadiansArrayHeights(coordinatesWithHeight);
};

export const transformPointPositionToVerticalPolylinePositions = (
    position: Property,
    lowerLimit: number,
    upperLimit: number
) => {
    const positionInRadians = Cesium.Cartographic.fromCartesian(position.getValue(new JulianDate()));
    return Cesium.Cartesian3.fromRadiansArrayHeights([
        positionInRadians.longitude,
        positionInRadians.latitude,
        lowerLimit,
        positionInRadians.longitude,
        positionInRadians.latitude,
        upperLimit,
    ]);
};
