import { wfs3dRouteSelector } from "../../common/selector/configurationSelector";
import { getState } from "../../../MapStore2/web/client/utils/StateUtils";

const STORE_NAME = "persisted.reduxStore";

export const selectDefaultRouteHeight = () => {
    return wfs3dRouteSelector(getState(STORE_NAME)).defaultHeight;
};

export const selectDefaultRouteWidth = () => {
    return wfs3dRouteSelector(getState(STORE_NAME)).defaultWidth;
};
