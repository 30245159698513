import { IAsyncDataState } from "../../../core/core-redux/reduxTypes";
import { asyncFetchDataReducerFactory } from "../../../core/core-redux/reducer/asyncFetchDataReducer";
import { FeatureInfoServiceParams, FeatureInfoServiceResponse } from "./featureInfo.service";
import { GET_FEATURE_INFO } from "./featureInfo.action";

export interface GetFeatureInfoDataState
    extends IAsyncDataState<FeatureInfoServiceResponse, any, FeatureInfoServiceParams> {}

export const getFeatureInfoReducer = asyncFetchDataReducerFactory<
    FeatureInfoServiceResponse,
    any,
    FeatureInfoServiceParams
>(GET_FEATURE_INFO);
