import { AxiosPromise } from "axios";
import {
    FeatureLayersMappingServiceResponse,
    GeoCalculationsByIdsServiceResponse,
    ColorMappingServiceResponse,
    LoadSlotChangesServiceResponse,
    LoginToWizardSuiteServiceResponse,
    GetCapabilitiesServiceResponse,
} from "./api-packages";
import { validateSessionEndpoint } from "./api-packages/validate-session/validateSession.endpoint";
import { featureLayersMappingEndpoint } from "./api-packages/feature-layers-mapping/featureLayersMapping.endpoint";
import { colorMappingEndpoint } from "./api-packages/color-mapping/colorMapping.endpoint";
import { geoCalculationsByIdsEndpoint } from "./api-packages/geo-calculations/geoCalculationsByIds.endpoint";
import { loadSlotChangesEndpoint } from "./api-packages/slot-changes/loadSlotChanges.endpoint";
import { loginToWizardSuiteEndpoint } from "./api-packages/login-to-wizard-suite/loginToWizardSuite.endpoint";
import { getCapabilitiesEndpoint } from "./api-packages/capabilities/getCapabilities.endpoint";
import { featureInfoEndpoint } from "./api-packages/feature-info/featureInfo.endpoint";
import { wfsServiceEndpoint } from "./api-packages/wfs-service/wfsService.endpoint";
import { ApiWfsServiceResponse } from "./api-packages/wfs-service/types";
import { ApiFeatureInfoServiceResponse } from "./api-packages/feature-info/types";
import { getFeaturesByParamsEndpoint } from "./api-packages/get-features/getFeaturesByParams.endpoint";
import { getDataSetFilterByIdEndpoint } from "./api-packages/getDataSetFilterById/getDataSetFilterById.endpoint";
import { ApiGetDataSetFiltersServiceResponse } from "./api-packages/getDataSetFilters";
import { getDataSetFiltersEndpoint } from "./api-packages/getDataSetFilters/getDataSetFilters.endpoint";
import { GetDataSetFilterByIdServiceResponse } from "./api-packages/getDataSetFilterById/types";
import { Slots } from "../common/slot/slots";

interface GeoServerApi {
    validateSession: () => AxiosPromise<boolean>;
    featureLayersMapping: () => AxiosPromise<FeatureLayersMappingServiceResponse>;
    colorMapping: () => AxiosPromise<ColorMappingServiceResponse>;
    geoCalculationsByIds: (params: string) => AxiosPromise<GeoCalculationsByIdsServiceResponse>;
    loadSlotChanges: (params: string) => AxiosPromise<LoadSlotChangesServiceResponse>;
    loginToWizardSuite: () => AxiosPromise<LoginToWizardSuiteServiceResponse>;
    getCapabilities: () => AxiosPromise<GetCapabilitiesServiceResponse>;
    featureInfo: (param: string) => AxiosPromise<ApiFeatureInfoServiceResponse>;
    wfsService: (params: string) => AxiosPromise<ApiWfsServiceResponse>;
    getFeaturesByParams: (params: string) => AxiosPromise<ApiWfsServiceResponse>;
    getDataSetFilterById: (
        datasetFilterId: string,
        validFrom: string,
        slots?: Slots
    ) => AxiosPromise<GetDataSetFilterByIdServiceResponse>;
    getDataSetFilters: (forceReload: boolean) => AxiosPromise<ApiGetDataSetFiltersServiceResponse>;
}

export const api: GeoServerApi = {
    validateSession: validateSessionEndpoint,
    featureLayersMapping: featureLayersMappingEndpoint,
    colorMapping: colorMappingEndpoint,
    geoCalculationsByIds: geoCalculationsByIdsEndpoint,
    loadSlotChanges: loadSlotChangesEndpoint,
    loginToWizardSuite: loginToWizardSuiteEndpoint,
    getCapabilities: getCapabilitiesEndpoint,
    featureInfo: featureInfoEndpoint,
    wfsService: wfsServiceEndpoint,
    getFeaturesByParams: getFeaturesByParamsEndpoint,
    getDataSetFilterById: getDataSetFilterByIdEndpoint,
    getDataSetFilters: getDataSetFiltersEndpoint,
};
