import React from "react";
import PropTypes from "prop-types";
import { Glyphicon as GlyphiconRB } from "react-bootstrap";
import tooltip from "../../../MapStore2/web/client/components/misc/enhancers/tooltip";

const Glyphicon = tooltip(GlyphiconRB);

class GlyphButton extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        itemId: PropTypes.string | PropTypes.number,
        boolParameter: PropTypes.bool,
        glyph: PropTypes.string,
        onClick: PropTypes.func,
        className: PropTypes.string,
        tooltip: PropTypes.string,
    };

    render() {
        if (this.props.onClick) {
            return (
                <div
                    id={this.props.id}
                    className={this.props.className}
                    onClick={() => this.props.onClick(this.props.itemId, !this.props.boolParameter)}
                >
                    <Glyphicon
                        className={"text-primary"}
                        glyph={this.props.glyph}
                        tooltip={this.props.tooltip}
                        tooltipPosition={"bottom"}
                    />
                </div>
            );
        }
        return null;
    }
}

export default GlyphButton;
