import { IAsyncDataState } from "../../../core/core-redux/reduxTypes";
import { LOGIN_TO_WIZARD_SUITE } from "./loginToWizardSuite.action";
import { LoginToWizardSuiteServiceParams, LoginToWizardSuiteServiceResponse } from "./loginToWizardSuite.service";
import { asyncFetchDataReducerFactory } from "../../../core/core-redux/reducer/asyncFetchDataReducer";

export interface LoginToWizardSuiteDataState
    extends IAsyncDataState<LoginToWizardSuiteServiceResponse, any, LoginToWizardSuiteServiceParams> {}

export const loginToWizardSuiteReducer = asyncFetchDataReducerFactory<
    LoginToWizardSuiteServiceResponse,
    any,
    LoginToWizardSuiteServiceParams
>(LOGIN_TO_WIZARD_SUITE);
