import { MapPosition } from "../../types";
import { Action } from "redux";
import {
    CLICK_ON_MAP as MAPSTORE_CLICK_ON_MAP,
    CHANGE_MAP_CRS as MAPSTORE_CHANGE_MAP_CRS,
    panTo as panToMapStore,
} from "../../../../MapStore2/web/client/actions/map";
import { changeMapType as changeMapTypeMapStore } from "../../../../MapStore2/web/client/actions/maptype";

export const CLICK_ON_MAP = MAPSTORE_CLICK_ON_MAP;
export const CHANGE_MAP_CRS = MAPSTORE_CHANGE_MAP_CRS;

export const panTo = (position: MapPosition) => panToMapStore(position);

export interface ClickOnMapAction extends Action {
    point: any;
    layer: any;
}

export interface ChangeMapCrs extends Action {
    crs: any;
}

export const changeMapType = (mapType: "cesium" | "openlayers") => changeMapTypeMapStore(mapType);
