import * as React from "react";
import { Tooltip as RBTooltip, TooltipProps as RBTooltipProps } from "react-bootstrap";
import { TooltipPosition } from "./types";
import { OverlayTrigger } from "./OverlayTrigger";

export interface TooltipProps extends RBTooltipProps {
    position?: TooltipPosition;
    body: React.ReactNode;
}

export const Tooltip = (props: TooltipProps) => {
    const { position = TooltipPosition.TOP, body, ...rest } = props;
    const rawTooltip = (
        <RBTooltip placement={position} {...rest}>
            {body}
        </RBTooltip>
    );
    return (
        <OverlayTrigger position={position} overlay={rawTooltip}>
            {/* TODO ICH: HACK, if custom component is not wrapped in extra html element, overlay tooltip is not triggered. See more: https://github.com/react-bootstrap/react-bootstrap/issues/2208#issuecomment-301737531 */}
            <span>{props.children}</span>
        </OverlayTrigger>
    );
};
