import { standardApiHandler } from "../../../core/core-api/apiTools";
import { getDataSetFiltersAdapter } from "./getDataSetFilters.adapter";
import DataSetFilterItem from "../../../filter/DataSetFilter/dto/DataSetFilterItem";

export type GetDataSetFiltersServiceParams = {
    forceReload: boolean;
};

export interface ApiFeatureType {
    baseEntityName: string;
    featureHumanName: string;
    featureName: string;
    shortFeatureName: string;
}

export type ApiGetDataSetFiltersServiceResponse = {
    identifier: string;
    name: string;
    featureTypes: ApiFeatureType[];
    inaccurateFilter: boolean;
}[];

export type GetDataSetFiltersService = (params: GetDataSetFiltersServiceParams) => Promise<DataSetFilterItem[]>;
export const getDataSetFiltersService: GetDataSetFiltersService = standardApiHandler(getDataSetFiltersAdapter);
