import { get, set } from "lodash";
import { doNothingAction } from "../../common/action/doNothingAction";
import { MAP_CONFIG_LOADED } from "../../../MapStore2/web/client/actions/config";
const LAYERS_FLAT_STATE_KEY = "layers.flat";

function sortLayers(state: any) {
    const sortedLayers = [...get(state, LAYERS_FLAT_STATE_KEY)];
    sortedLayers.sort((a, b) => a.zIndex - b.zIndex);
    set(state, LAYERS_FLAT_STATE_KEY, sortedLayers);
}

const sortLayersEpic = (action$: any, store: any) =>
    action$.ofType(MAP_CONFIG_LOADED).map(() => {
        sortLayers(store.getState());
        return doNothingAction();
    });

export default sortLayersEpic;
