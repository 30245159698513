import React from "react";
import PropTypes from "prop-types";
import Button from "../../common/component/Button";
import GlyphIcons from "../../common/constants/GlyphIcons";
import ItemList from "../../layers/component/ItemList";
import { escapeRegExp } from "../../common/util/StringUtil";
import Filter from "../../common/component/Filter";

class ItemLookUp extends React.Component {
    static propTypes = {
        enabled: PropTypes.bool,
        className: PropTypes.string,
        id: PropTypes.string,
        title: PropTypes.string,
        items: PropTypes.array,
        filterMessage: PropTypes.string,
        onClose: PropTypes.func,
        onAdd: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            filter: "",
            items: [],
            position: {
                pageSize: 10,
                totalPages: 0,
                pageNumber: 1,
                startItem: 0,
                endItem: 0,
            },
        };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        const filteredItems = this.filterAndSortItems(newProps.items, this.state.filter);
        const position = this.calculateItemsPosition(
            filteredItems.length,
            this.state.position && this.state.position.pageSize,
            this.state.position.pageNumber
        );
        this.setState({
            filteredItems: filteredItems,
            position: position,
        });
    }

    render() {
        if (!this.props.enabled) {
            return null;
        }

        return (
            <div className={`itemLookUp ${this.props.className}`} id={`${this.props.id}`}>
                <h4 className={"itemLookUpHeader"}>
                    <div className={"itemLookUpTitle"}>{this.props.title}</div>
                    <Button
                        id={this.props.id + "CloseButton"}
                        className={"panelHeaderButton"}
                        onClick={this.props.onClose}
                        tooltip={"Close"}
                        glyph={GlyphIcons.CLOSE}
                    />
                </h4>

                <Filter
                    id={this.props.id + "Filter"}
                    placeholder={this.props.filterMessage}
                    onChange={this.handleFilterChanged.bind(this)}
                    value={this.state.filter}
                />

                <ItemList
                    id={this.props.id}
                    visibleItems={this.state.filteredItems}
                    className={this.props.className}
                    onPageChange={this.handlePageChanged.bind(this)}
                    position={this.state.position}
                    onAdd={this.props.onAdd}
                    hiddenItems={[]}
                />
            </div>
        );
    }

    filterAndSortItems(allCalculations, filterString) {
        const filter = new RegExp(".*" + escapeRegExp(filterString) + ".*", "i");
        return allCalculations
            ? allCalculations
                  .filter((calculation) => !filter || filter.test(calculation.name))
                  .sort((a, b) => a.id - b.id)
            : [];
    }

    handleFilterChanged(event) {
        const filteredItems = this.filterAndSortItems(this.props.items, event.target.value);
        const pageNumber =
            filteredItems.length !== this.props.items.length || filteredItems.length === 0
                ? 1
                : this.state.position.pageNumber;

        const position = this.calculateItemsPosition(
            filteredItems.length,
            this.state.position && this.state.position.pageSize,
            pageNumber
        );
        this.setState({
            filter: event.target.value,
            filteredItems: filteredItems,
            position: position,
        });
    }

    calculateItemsPosition(itemCount, pageSize, pageNumber) {
        const totalPages = Math.ceil(itemCount / pageSize);

        let startItem = (pageNumber - 1) * pageSize;
        let endItem = startItem + pageSize;

        if (startItem >= itemCount) {
            pageNumber = Math.max(pageNumber - 1, 1);
            startItem = (pageNumber - 1) * pageSize;
        }

        return {
            pageSize: pageSize,
            totalPages: totalPages,
            pageNumber: pageNumber,
            startItem: startItem,
            endItem: endItem,
        };
    }

    handlePageChanged(pageNumber) {
        this.setState({
            position: this.calculateItemsPosition(
                this.state.filteredItems.length,
                this.state.position.pageSize,
                pageNumber
            ),
        });
    }
}

export default ItemLookUp;
