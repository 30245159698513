import { Action } from "redux";

export const STORE_WFS_FEATURE_COUNT = "STORE_WFS_FEATURE_COUNT";

export interface StoreWfsFeatureCountAction extends Action {
    layerGroup: any;
    layerName: any;
    featureCount: any;
}

export const storeWfsFeatureCountAction = (
    layerGroup: any,
    layerName: any,
    featureCount: any
): StoreWfsFeatureCountAction => {
    return {
        type: STORE_WFS_FEATURE_COUNT,
        layerGroup: layerGroup,
        layerName: layerName,
        featureCount: featureCount,
    };
};
