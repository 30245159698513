import { EpicFactory } from "../../core/core-redux/reduxTypes";
import { GLOBE_3D_PROJECTION } from "../../common/constants/Projections";
import { Action } from "redux";
import { RootState } from "../../rootState";
import { CHANGE_MAP_CRS, ChangeMapCrs, changeMapType } from "../../common/mapstore/action";

export const globeViewEpic: EpicFactory<Action, RootState, Action> = (action$) =>
    action$.ofType<ChangeMapCrs>(CHANGE_MAP_CRS).map((action) => {
        return action.crs === GLOBE_3D_PROJECTION ? changeMapType("cesium") : changeMapType("openlayers");
    });
