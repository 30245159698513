import { standardApiHandler } from "../../../core/core-api/apiTools";
import { wfsServiceAdapter } from "./wfsService.adapter";
import { Feature } from "../../../core/geo/types";

export type WfsServiceServiceParams = {
    typeName?: string | string[];
    ENV?: string;
    startIndex?: number;
    count?: number;
    resultType?: string;
    CQL_FILTER?: string;
    featureId?: number;
    srsName?: string;
    wizardSuiteProperties?: boolean;
};
export type WfsServiceResponse = {
    crs: unknown;
    features: Feature[];
    numberMatched: number;
    numberReturned: number;
    timeStamp: string;
    totalFeatures: number;
    type: string;
};

export type WfsServiceService = (params: WfsServiceServiceParams) => Promise<WfsServiceResponse>;
export const wfsService: WfsServiceService = standardApiHandler(wfsServiceAdapter);
