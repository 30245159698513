import * as React from "react";
import { ControlLabel, FormControl, FormControlProps } from "react-bootstrap";
import { ChangeEvent } from "react";

interface InputDateProps extends FormControlProps {
    label?: string;
    onChangeValue?: (value: string) => void;
}

export const InputDate = (props: InputDateProps) => {
    const { label, onChange, onChangeValue, ...rest } = props;
    return (
        <>
            {label && <ControlLabel>{label}</ControlLabel>}
            <FormControl
                type={"datetime-local"}
                onChange={(e) => {
                    onChange?.(e);
                    const value = e as unknown as ChangeEvent<HTMLInputElement>;
                    // TODO ICH: We should parse value and return proper date object
                    onChangeValue?.(value.target.value);
                }}
                {...rest}
            />
        </>
    );
};
