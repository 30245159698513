
import { getPolylinePositionWithHeight, transformPointPositionToVerticalPolylinePositions } from "../util/PolylineUtil";
import { buildRouteShapeArray } from "../util/routeGeometryUtil";
import { extractWfs3dPropertiesWithHeightMultiplier } from "../util/Wfs3dPropertiesExtractor";

const updateAirspaceHeight = (entity: any, heightMultiplier: number) => {
    if (entity.polygon) {
        const properties = extractWfs3dPropertiesWithHeightMultiplier(entity, heightMultiplier);
        entity.polygon.extrudedHeight.setValue(properties.upperLimit);
        entity.polygon.height = properties.lowerLimit;
    }
};

const updateRouteHeight = (entity: any, heightMultiplier: number) => {
    if (entity.polylineVolume) {
        const properties = extractWfs3dPropertiesWithHeightMultiplier(entity, heightMultiplier);
        entity.polylineVolume.positions = getPolylinePositionWithHeight(
            entity._properties.polylinePositions,
            properties.lowerLimit
        );

        entity.polylineVolume.shape = buildRouteShapeArray(properties);
    }
};

const updateObstacleHeight = (entity: any, heightMultiplier: number) => {
    if (entity.polyline) {
        const properties = extractWfs3dPropertiesWithHeightMultiplier(entity, heightMultiplier);
        const positions = transformPointPositionToVerticalPolylinePositions(
            entity.position,
            properties.lowerLimit,
            properties.upperLimit
        );
        entity.polyline.positions = positions;
    }
};

const heightUpdaterMapping: { [key in string]: (entity: any, heightMultiplier: number) => void } = {
    AIRSPACE_VOLUME: updateAirspaceHeight,
    RTE_SEG: updateRouteHeight,
    OBSTACLE_PART: updateObstacleHeight,
};

export const updateEntityHeight = (entity: any, heightMultiplier: number) => {
    heightUpdaterMapping[entity.name](entity, heightMultiplier);
};
