import Item from "../../../layers/dto/Item";

class DataSetFilterItem extends Item {
    featureType?: string;
    constructor(item: Item, featureType?: string) {
        super(
            item.id,
            item.name,
            item.visibility,
            item.expanded,
            item.group,
            item.removable,
            item.children,
            item.parent,
            item.warning,
            item.messageKey
        );
        this.featureType = featureType;
    }

    toString() {
        return (
            this.id +
            ":" +
            this.children
                .filter((child: any) => child.visibility)
                .map((child: any) => child.featureType)
                .join(";")
        );
    }
}

export default DataSetFilterItem;
