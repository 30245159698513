import { initState } from "../../common/util/StateUtil";
import updateObject from "../../common/util/UpdateObject";
import { STORE_WFS_3D_LAYERS } from "../action/storeWfs3dLayersAction";
import { SET_WFS_3D_LAYERS_VISIBILITY } from "../action/setWfs3dLayersVisibilityAction";
import { STORE_WFS_FEATURE_COUNT } from "../action/storeWfsFeatureCountAction";
import { SET_WFS_3D_LAYER_COUNT } from "../action/setWfs3dLayerCountAction";
import { ADJUST_HEIGHT } from "../action/adjustHeightAction";
import { UPDATE_WFS_FILTER } from "../update-wfs-filter/updateWfsFilter.action";
import { extractFilterIdsForLayer } from "../feature-filter/featureFilterUtil";

export const wfs3dLayersReducer = (initialState: any, action: any) => {
    const state = initState(initialState);
    if (action.type === STORE_WFS_3D_LAYERS) {
        const layerName = action.layerName;
        const layerGroup = action.layerGroup;
        const featureCollection = action.features;

        const layers = state.layers || [];
        const previousLayers = layers.filter(
            (previousLayer: any) => previousLayer.name === layerName && previousLayer.group === layerGroup
        );

        let layer: any;
        if (previousLayers && previousLayers.length > 0) {
            layer = previousLayers[0];
            featureCollection.features.forEach((feature: any) => layer.features.features.push(feature));
        } else {
            layer = {
                name: layerName,
                featureShortName: action.featureShortName,
                group: layerGroup,
                visible: state.visibleGroup === layerGroup && state.visibleLayers.includes(layerName),
                features: featureCollection,
            };
            layers.push(layer);
        }

        if (
            layer.features.features.length ===
            state.layersFeatureCount[action.layerGroup][action.layerName].featureCount
        ) {
            if (action.filters) {
                const filteredFeatures = extractFilterIdsForLayer(
                    layer.name,
                    layer.featureShortName,
                    layer.features.features,
                    action.filters
                );

                layer.visible = !!filteredFeatures;
                layer.filteredFeatures = filteredFeatures;
            } else {
                layer.filteredFeatures = layer.features.features.map((feature: any) => feature.properties.FEATURE_ID);
            }
        }

        return updateObject(state, { layers: layers });
    } else if (action.type === SET_WFS_3D_LAYERS_VISIBILITY) {
        const selectedLayerGroup = action.layerGroup;
        const layers = state.layers ? [...state.layers] : [];
        if (layers) {
            const layersInNotSelectedGroup = layers.filter((layer) => layer.group !== selectedLayerGroup);
            layersInNotSelectedGroup.forEach((layer) => (layer.visible = false));

            const layersInSelectedGroup = layers.filter((layer) => layer.group === selectedLayerGroup);
            for (let i = 0; i < layersInSelectedGroup.length; i++) {
                layersInSelectedGroup[i].visible = action.visibleLayers.includes(layersInSelectedGroup[i].name);
            }
        }

        return updateObject(state, {
            layers: layers,
            visibleLayers: action.visibleLayers,
            visibleGroup: selectedLayerGroup,
        });
    } else if (action.type === STORE_WFS_FEATURE_COUNT) {
        const layersFeatureCount = state.layersFeatureCount || {};
        const layerGroup = layersFeatureCount[action.layerGroup] || {};

        layerGroup[action.layerName] = { featureCount: action.featureCount };
        layersFeatureCount[action.layerGroup] = layerGroup;

        return updateObject(state, { layersFeatureCount: layersFeatureCount });
    } else if (action.type === SET_WFS_3D_LAYER_COUNT) {
        const layerCount = state.layerCount ? { ...state.layerCount } : {};
        layerCount[action.layerGroup] = action.layerCount;
        return updateObject(state, {
            layerCount: layerCount,
        });
    } else if (action.type === ADJUST_HEIGHT) {
        return updateObject(state, {
            adjustedHeight: action.adjustedHeight,
        });
    } else if (action.type === UPDATE_WFS_FILTER) {
        const layers = state.layers ? [...state.layers] : [];
        const newLayers = [];
        for (const layer of layers) {
            const cloned = { ...layer };
            const filteredFeatures = extractFilterIdsForLayer(
                cloned.name,
                cloned.featureShortName,
                [...cloned.features.features],
                action.filters
            );
            cloned.visible = !!filteredFeatures;
            cloned.filteredFeatures = filteredFeatures;
            cloned.changed = true;
            newLayers.push(cloned);
        }
        return updateObject(state, {
            layers: newLayers,
        });
    }
    return state;
};
