import Cesium from "../../../../MapStore2/web/client/libs/cesium";
import EntityParserFactory from "./EntityParserFactory";

export default class GeoJsonCesiumParser {
    static parse(geoJson: any, layerName: string) {
        const promise = Cesium.GeoJsonDataSource.load(geoJson);

        return promise.then((dataSource: any) => {
            const entities = dataSource.entities.values;

            const newDataSource = new Cesium.CustomDataSource(layerName);
            for (let i = 0; i < entities.length; i++) {
                const entity = EntityParserFactory.parseEntity(entities[i]);
                newDataSource.entities.add(entity);
                newDataSource.show = false;
            }

            return newDataSource;
        });
    }
}
