const GEOCALC_LIST_LOADED = 'GEOCALC_LIST_LOADED';

const geoCalcListLoadedAction = (data) => {
    return {
        type: GEOCALC_LIST_LOADED,
        data: data
    };
};

export {GEOCALC_LIST_LOADED, geoCalcListLoadedAction};
