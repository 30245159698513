import { Feature } from "../../core/geo/types";
import * as React from "react";
import { groupBy } from "lodash";
import { Modal } from "../../core/core-widgets/modals/Modal/Modal";
import { ContentContainer } from "../../core/core-widgets/surfaces/ContentContainer/ContentContainer";
import { FeatureListItem } from "./FeatureListItem";

export interface FeatureListSelectionStateProps {
    features?: Feature[];
}

export interface FeatureListSelectionDispatchProps {
    onSelect: (feature: Feature, selectedFromPortal?: boolean) => void;
    cleanup: () => void;
}

type FeatureListModalProps = FeatureListSelectionStateProps & FeatureListSelectionDispatchProps;

export const FeatureListModal = (props: FeatureListModalProps) => {
    const { features, onSelect, cleanup } = props;

    if (!features || features.length === 0) {
        return null;
    }

    const groupedFeatures = groupBy(features, (x) => x.properties.featureForm);
    return (
        <Modal modalTitle={"SELECT FEATURE"} onClose={props.cleanup}>
            <ContentContainer>
                {Object.keys(groupedFeatures).map((featureKey, groupIndex) => {
                    return (
                        <FeatureListItem
                            key={groupIndex}
                            featureKey={featureKey}
                            features={groupedFeatures[featureKey]}
                            onSelect={onSelect}
                            cleanup={cleanup}
                        />
                    );
                })}
            </ContentContainer>
        </Modal>
    );
};
