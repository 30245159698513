import "../../register";
import MapFooterPlugin from "../../../MapStore2/web/client/plugins/MapFooter";
import MousePositionPlugin from "../../../MapStore2/web/client/plugins/MousePosition";
import NotificationsPlugin from "../../../MapStore2/web/client/plugins/Notifications";
import { registeredPlugins } from "../../common/mapstore/registerPlugin";
import { map } from "../../common/mapstore/pluginMapper";
import { assign } from "lodash";
import Logger from "../../common/util/Logger";
const logger = new Logger("plugins");

export interface MapStorePlugins {
    [key: string]: any;
}

export let plugins: MapStorePlugins = {
    // mapstore plugins
    MapFooterPlugin: MapFooterPlugin,
    MousePositionPlugin: MousePositionPlugin,
    NotificationsPlugin: NotificationsPlugin,
};

export const resolvePlugins = (pluginsConfiguration: { plugins: string[]; validatePlugins: boolean }) => {
    logger.info("Resolving plugins");
    const result = {
        ...plugins,
    };
    registeredPlugins
        .filter((plugin) => pluginsConfiguration.validatePlugins || pluginsConfiguration.plugins.includes(plugin.name))
        .map((plugin) => assign(result, { [plugin.name]: map(plugin) }));
    logger.info(`Resolved plugins ${result}`);
    plugins = result;
};
