import * as React from "react";
import { ControlLabel, FormControl, FormControlProps } from "react-bootstrap";
import { ChangeEvent } from "react";

interface BasicInputProps extends FormControlProps {
    label?: string;
    onChangeValue?: (value: string) => void;
}

export const BasicInput = (props: BasicInputProps) => {
    const { label, onChange, onChangeValue, ...rest } = props;
    return (
        <>
            {label && <ControlLabel>{label}</ControlLabel>}
            <FormControl
                onChange={(e) => {
                    onChange?.(e);
                    const value = e as unknown as ChangeEvent<HTMLInputElement>;
                    onChangeValue?.(value.target.value);
                }}
                {...rest}
            />
        </>
    );
};
