export enum GISMessageType {
    // GIS INVOKES
    SELECT_FEATURE_FROM_GIS = "selectFeatureFromGIS",
    UPDATE_FEATURE_GEOMETRY_FROM_GIS = "updateFeatureGeometryFromGIS",
    FINISH_VISUAL_GEOMETRY_EDITING = "finishVisualGeometryEditing",
}

export enum PortalMessageType {
    // PORTAL INVOKES
    SELECT_FEATURE_FROM_PORTAL = "selectFeatureFromPortal",
    VALIDITY_INTERVAL_CHANGED = "validityIntervalChanged",
    EDIT_GEOMETRY = "editGeometry",
    VIEW_GEOMETRY = "viewGeometry",
    RELOAD_MAP_FROM_PORTAL = "reloadMapFromPortal",
    CANCEL_GEOMETRY_EDIT = "cancelGeometryEditFromPortal",
    APPLY_FILTER = "applyFilter",
}

export interface IFrameMessage<MessageType> {
    type: MessageType;
    data?: unknown;
}
