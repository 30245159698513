import { Action } from "redux";

export const REMOVE_FEATURE_FILTER = "REMOVE_FEATURE_FILTER";

export interface RemoveFeatureFilterAction extends Action {}

export const removeFeatureFilterAction = (): RemoveFeatureFilterAction => {
    return {
        type: REMOVE_FEATURE_FILTER,
    };
};
