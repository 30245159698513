import { validFromSelector, validToSelector } from "@js/common/selector/validityIntervalSelector";
import { workspaceModeSelector } from "@js/common/selector/configurationSelector";
import { contextSelector, viewSelector } from "@js/changes/selector/workspaceChangesSelector";
import { BASELINE_VIEW, CONTEXT_BASELINE } from "@js/changes/changesConstants";
import { workspaceChangeTypesSelector, baselineChangeTypesSelector } from "@js/changes/selector/changeTypeSelector";
import { isCesium } from "@mapstore/selectors/maptype";
import { sessionSelector } from "@js/session/redux/sessionSelector.ts";

const EnvParams = require("@js/common/dto/EnvParams");

export default class EnvParamsRetriever {
    static _isBaselineContextOrView(state) {
        return contextSelector(state) === CONTEXT_BASELINE || viewSelector(state) === BASELINE_VIEW;
    }

    static getBaselineEnvParams(state) {
        const slotIds =
            workspaceModeSelector(state) ||
            this._isBaselineContextOrView(state) ||
            !state.urlParams.slots.haveSlotIds() ||
            isCesium(state)
                ? ""
                : state.urlParams.slots.getSlotIds();
        const requestedChanges =
            workspaceModeSelector(state) || this._isBaselineContextOrView(state) || isCesium(state)
                ? []
                : baselineChangeTypesSelector(state).join();
        return new EnvParams(validFromSelector(state), validToSelector(state), slotIds, requestedChanges);
    }

    static getWorkspaceEnvParams(state) {
        const slotIds = !state.urlParams.slots.haveSlotIds() ? "" : state.urlParams.slots.getSlotIds();
        return new EnvParams(
            validFromSelector(state),
            validToSelector(state),
            slotIds,
            isCesium(state) ? [] : workspaceChangeTypesSelector(state)?.join()
        );
    }
}
