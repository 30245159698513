import { EpicFactory } from "../../core/core-redux/reduxTypes";
import { CHANGE_GROUP_PROPERTIES, CHANGE_LAYER_PROPERTIES } from "../../common/mapstore/action/layers";
import { VIEW_TYPE_CHANGED } from "../../changes/action/updateViewTypeAction";
import { STORE_WFS_FEATURE_COUNT } from "../action/storeWfsFeatureCountAction";
import {
    BASELINE_LAYER_GROUP,
    queriableWfs3dBaselineLayerSelector,
    WORKSPACE_LAYER_GROUP,
} from "../../common/selector/layerSelector";
import { setWfs3dLayersVisibilityAction } from "../action/setWfs3dLayersVisibilityAction";
import { Action } from "redux";
import { RootState } from "../../rootState";
import { BASELINE_VIEW } from "../../changes/changesConstants";

export const wfs3dLayersVisibilityEpic: EpicFactory<Action, RootState, Action> = (action$, store) =>
    action$
        .ofType(CHANGE_GROUP_PROPERTIES, CHANGE_LAYER_PROPERTIES, VIEW_TYPE_CHANGED, STORE_WFS_FEATURE_COUNT)
        .map(() => {
            const state = store.getState();
            const wfsLayers = queriableWfs3dBaselineLayerSelector(state);

            return setWfs3dLayersVisibilityAction(
                wfsLayers.map((layer: any) => layer.wfs3dLayer),
                state.workspaceChanges.view === BASELINE_VIEW ? BASELINE_LAYER_GROUP : WORKSPACE_LAYER_GROUP
            );
        });
