import React from "react";
import GlyphIcons from "../../../../common/constants/GlyphIcons";
import TooltipIcon from "@js/layers/component/TooltipIcon";

export const ChangesLoader = (properties) => {
    if (properties.hidden || !properties.dateOfGeneration) {
        return null;
    }
    return (
        <>
            <div>
                Generated <span className={"utcWarn"}>(UTC)</span>
            </div>
            <div>
                {properties.dateOfGeneration}
                <button
                    type={"button"}
                    className={"no-border btn btn-default refreshButton"}
                    id={"reloadChangesButton"}
                    onClick={properties.onClick}
                    disabled={properties.disabled}
                >
                    <TooltipIcon
                        id={"reloadChangesIcon"}
                        tooltip={"Reload Changes"}
                        glyph={GlyphIcons.REFRESH}
                        className={"reloadChangesIcon"}
                    />
                </button>
            </div>
        </>
    );
};
