import { EpicFactory } from "../../../core/core-redux/reduxTypes";
import { RootState } from "../../../rootState";
import { VIEW_GEOMETRY_ACTION, ViewGeometryAction } from "./viewGeometry.action";
import { selectFeatureForViewing } from "../../../common/mapstore/action/draw";
import { doNothingAction } from "../../../common/action/doNothingAction";
import { Action } from "redux";

export const viewGeometryEpic: EpicFactory<ViewGeometryAction | Action, RootState, Action> = (action, store) =>
    action.ofType(VIEW_GEOMETRY_ACTION).map(() => {
        const editFeature = store.getState().editFeature;
        if (editFeature === undefined || editFeature.editedFeatures === undefined) {
            return doNothingAction();
        }
        return selectFeatureForViewing(editFeature?.editedFeatures, editFeature?.options);
    });
