import {
    AsyncDataAwaitableActionCreator,
    AsyncDataPromiseActionCreator,
    AsyncUnaryFunction,
    IAsyncDataAction,
} from "../reduxTypes";
import { Dispatch } from "redux";
import moment from "moment";
import { asyncPerfLog } from "../../helpers/asyncPerfLog";

export const REQUEST_ASYNC_DATA_ACTION_TYPE = "_REQUEST_ASYNC_DATA";
export const RECEIVE_ASYNC_DATA_RESPONSE_ACTION_TYPE = "_RECEIVE_ASYNC_DATA_RESPONSE";
export const RECEIVE_ASYNC_DATA_ERROR_ACTION_TYPE = "_RECEIVE_ASYNC_DATA_ERROR";

export interface RxActions<REQ, RESP, ERROR> {
    prefix: string;
    asyncFunction: AsyncUnaryFunction<REQ, RESP>;
    requestAsyncDataActionCreator: (requestParams: REQ) => IAsyncDataAction<RESP, ERROR, REQ>;
    receiveAsyncDataActionCreator: (requestParams: REQ, response?: RESP) => IAsyncDataAction<RESP, ERROR, REQ>;
    receiveAsyncDataErrorActionCreator: (requestParams: REQ, error: ERROR) => IAsyncDataAction<RESP, ERROR, REQ>;
}

export const asyncReduxActionsFactory = <REQ, RESP, ERROR>(
    prefix: string,
    asyncFunction: AsyncUnaryFunction<REQ, RESP>,
    requestActionSuffix: string = REQUEST_ASYNC_DATA_ACTION_TYPE,
    receiveDataActionSuffix: string = RECEIVE_ASYNC_DATA_RESPONSE_ACTION_TYPE,
    receiveErrorActionSuffix: string = RECEIVE_ASYNC_DATA_ERROR_ACTION_TYPE
): RxActions<REQ, RESP, ERROR> => {
    const requestAsyncDataActionCreator = (requestParams: REQ): IAsyncDataAction<RESP, ERROR, REQ> => ({
        type: prefix + requestActionSuffix,
        parameters: requestParams,
    });
    const receiveAsyncDataActionCreator = (
        requestParams: REQ,
        response?: RESP
    ): IAsyncDataAction<RESP, ERROR, REQ> => ({
        type: prefix + receiveDataActionSuffix,
        response,
        parameters: requestParams,
    });

    const receiveAsyncDataErrorActionCreator = (
        requestParams: REQ,
        error: ERROR
    ): IAsyncDataAction<RESP, ERROR, REQ> => ({
        type: prefix + receiveErrorActionSuffix,
        error,
        parameters: requestParams,
    });

    return {
        prefix: prefix,
        asyncFunction: asyncFunction,
        requestAsyncDataActionCreator: requestAsyncDataActionCreator,
        receiveAsyncDataActionCreator: receiveAsyncDataActionCreator,
        receiveAsyncDataErrorActionCreator: receiveAsyncDataErrorActionCreator,
    };
};

export const asyncFetchDataPromiseActionFactory = <REQ, RESP, ERROR>(
    prefix: string,
    asyncFunction: AsyncUnaryFunction<REQ, RESP>,
    requestActionSuffix: string = REQUEST_ASYNC_DATA_ACTION_TYPE,
    receiveDataActionSuffix: string = RECEIVE_ASYNC_DATA_RESPONSE_ACTION_TYPE,
    receiveErrorActionSuffix: string = RECEIVE_ASYNC_DATA_ERROR_ACTION_TYPE
): AsyncDataPromiseActionCreator<REQ, RESP> => {
    const requestAsyncDataActionCreator = (requestParams: REQ): IAsyncDataAction<RESP, ERROR, REQ> => ({
        type: prefix + requestActionSuffix,
        parameters: requestParams,
    });
    const receiveAsyncDataActionCreator = (
        requestParams: REQ,
        response?: RESP
    ): IAsyncDataAction<RESP, ERROR, REQ> => ({
        type: prefix + receiveDataActionSuffix,
        response,
        parameters: requestParams,
    });

    const receiveAsyncDataErrorActionCreator = (
        requestParams: REQ,
        error: ERROR
    ): IAsyncDataAction<RESP, ERROR, REQ> => ({
        type: prefix + receiveErrorActionSuffix,
        error,
        parameters: requestParams,
    });

    return (requestParams: REQ) =>
        async (dispatch: Dispatch<any>): Promise<RESP> => {
            // First dispatch the action of (prefixed) type REQUEST_ASYNC_DATA
            const start = moment();
            dispatch(requestAsyncDataActionCreator(requestParams));
            // TODO USE LOGGER instead console.log
            try {
                // Execute the async unary function
                const response = await asyncFunction(requestParams);
                // Success: dispatch the action of (prefixed) type RECEIVE_ASYNC_DATA_RESPONSE
                const end = moment();
                asyncPerfLog(prefix, start, end);

                dispatch(receiveAsyncDataActionCreator(requestParams, response));
                return response;
            } catch (error) {
                // Error: dispatch the action of (prefixed) type RECEIVE_ASYNC_DATA_ERROR
                const end = moment();
                asyncPerfLog(prefix, start, end);
                dispatch(receiveAsyncDataErrorActionCreator(requestParams, error as ERROR));
                throw error;
            }
        };
};

export const asyncFetchDataAwaitableActionFactory = <REQ, RESP, ERROR>(
    prefix: string,
    asyncFunction: AsyncUnaryFunction<REQ, RESP>,
    requestActionSuffix: string = REQUEST_ASYNC_DATA_ACTION_TYPE,
    receiveDataActionSuffix: string = RECEIVE_ASYNC_DATA_RESPONSE_ACTION_TYPE,
    receiveErrorActionSuffix: string = RECEIVE_ASYNC_DATA_ERROR_ACTION_TYPE
): AsyncDataAwaitableActionCreator<REQ, RESP, ERROR> => {
    const requestAsyncDataActionCreator = (requestParams: REQ): IAsyncDataAction<RESP, ERROR, REQ> => ({
        type: prefix + requestActionSuffix,
        parameters: requestParams,
    });
    const receiveAsyncDataActionCreator = (
        requestParams: REQ,
        response?: RESP
    ): IAsyncDataAction<RESP, ERROR, REQ> => ({
        type: prefix + receiveDataActionSuffix,
        response,
        parameters: requestParams,
    });

    const receiveAsyncDataErrorActionCreator = (
        requestParams: REQ,
        error: ERROR
    ): IAsyncDataAction<RESP, ERROR, REQ> => ({
        type: prefix + receiveErrorActionSuffix,
        error,
        parameters: requestParams,
    });

    return (requestParams: REQ) =>
        async (dispatch: Dispatch<any>): Promise<RESP | ERROR> => {
            // First dispatch the action of (prefixed) type REQUEST_ASYNC_DATA
            const start = moment();
            dispatch(requestAsyncDataActionCreator(requestParams));

            try {
                // Execute the async unary function
                const response = await asyncFunction(requestParams);
                // Success: dispatch the action of (prefixed) type RECEIVE_ASYNC_DATA_RESPONSE
                const end = moment();
                asyncPerfLog(prefix, start, end);

                try {
                    dispatch(receiveAsyncDataActionCreator(requestParams, response));
                    return response;
                } catch (error) {
                    console.error({
                        location: prefix + receiveDataActionSuffix,
                        message: "Unexpected error",
                        error,
                    });
                    return error as ERROR;
                }
            } catch (error) {
                // Error: dispatch the action of (prefixed) type RECEIVE_ASYNC_DATA_ERROR
                const end = moment();
                asyncPerfLog(prefix, start, end);
                dispatch(receiveAsyncDataErrorActionCreator(requestParams, error as ERROR));
                return error as ERROR;
            }
        };
};
