import { Projection } from "../../common/types";

interface FeatureOptions {
    featureProjection: Projection;
    stopAfterDrawing: boolean;
    editEnabled: boolean;
    selectEnabled: boolean;
    drawEnabled: boolean;
    translateEnabled: boolean;
    transformToFeatureCollection: boolean;
    geodesic?: boolean;
}

export const DRAW_FEATURE_OPTIONS: FeatureOptions = {
    featureProjection: Projection.EPSG_4326,
    stopAfterDrawing: true,
    editEnabled: true,
    selectEnabled: true,
    drawEnabled: true,
    translateEnabled: true,
    transformToFeatureCollection: true,
    geodesic: true,
};

export const EDIT_FEATURE_OPTIONS: FeatureOptions = {
    featureProjection: Projection.EPSG_4326,
    stopAfterDrawing: true,
    editEnabled: true,
    selectEnabled: true,
    drawEnabled: false,
    translateEnabled: true,
    transformToFeatureCollection: true,
};

export const VIEW_FEATURE_OPTIONS: FeatureOptions = {
    featureProjection: Projection.EPSG_4326,
    stopAfterDrawing: true,
    editEnabled: false,
    selectEnabled: true,
    drawEnabled: false,
    translateEnabled: true,
    transformToFeatureCollection: true,
};
