import React from "react";
import PropTypes from "prop-types";

import LayerItem from "../../layers/component/LayerItem";
import LayerGroup from "../../layers/component/LayerGroup";

class ItemTree extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        className: PropTypes.string,
        position: PropTypes.object,
        visibleItems: PropTypes.array,
        hiddenItems: PropTypes.array,
        pageSize: PropTypes.number,
        pageNumber: PropTypes.number,
        onHide: PropTypes.func,
        onAdd: PropTypes.func,
        onRemove: PropTypes.func,
        onPageChange: PropTypes.func,
        onRowClick: PropTypes.func,
        onExpand: PropTypes.func,
        onRefresh: PropTypes.func,
        items: PropTypes.array,
        readonly: PropTypes.bool,
    };

    static defaultProps = {
        pageSize: 5,
        position: {
            startItem: 0,
            pageNumber: 1,
            totalPages: 1,
        },
    };

    render() {
        const items = this.props.items.map((item) =>
            item.group ? (
                <LayerGroup
                    key={this.props.id + item.id}
                    className={this.props.className}
                    children={item.children}
                    expanded={item.expanded}
                    name={item.name}
                    onExpand={this.props.onExpand}
                    id={item.id}
                    onHide={this.props.onHide}
                    visible={item.visibility}
                    nesting={1}
                    onAdd={this.props.onAdd}
                    onRemove={this.props.onRemove}
                    onRowClick={this.props.onRowClick}
                    onRefresh={this.props.onRefresh}
                    item={item}
                />
            ) : (
                <LayerItem
                    key={this.props.id + item.id}
                    className={this.props.className}
                    itemVisible={item.visibility}
                    onHide={this.props.onHide}
                    onRemove={this.props.onRemove}
                    onClick={this.props.onRowClick}
                    item={item}
                />
            )
        );
        return <div className={this.props.readonly ? "treeViewReadonly" : ""} ref={this.props.treeRef}>{items}</div>;
    }
}

export default ItemTree;
