export enum FlexDirection {
    ROW = "row",
    ROW_REVERSE = "row-reverse",
    COLUMN = "column",
    COLUMN_REVERSE = "column-reverse",
}

export enum FlexJustifyContent {
    START = "flex-start",
    END = "flex-end",
    CENTER = "center",
    BETWEEN = "space-between",
    AROUND = "space-around",
    EVENLY = "space-evenly",
}

export enum FlexAlignItems {
    START = "flex-start",
    END = "flex-end",
    CENTER = "center",
    BASELINE = "baseline",
    STRETCH = "stretch",
    AUTO = "auto",
}

export const FlexAlignSelf = { ...FlexAlignItems };

export enum FlexGrowthType {
    GROW = "grow",
    SHRINK = "shrink",
}

export enum FlexWrap {
    WRAP = "wrap",
    NOWRAP = "nowrap",
    WRAP_REVERSE = "wrap-reverse",
}

export enum FlexAlignContent {
    START = "flex-start",
    END = "flex-end",
    CENTER = "center",
    STRETCH = "stretch",
    BETWEEN = "space-between",
    AROUND = "space-around",
}
