import { GeometryChange } from "../../util/geometry/types";
import { Action } from "redux";

export const GEOMETRY_CHANGED = "GEOMETRY_CHANGED";

export interface GeometryChangedAction extends Action {
    change: GeometryChange;
}

export const geometryChangedAction = (change: GeometryChange): GeometryChangedAction => {
    return {
        type: GEOMETRY_CHANGED,
        change: change,
    };
};
