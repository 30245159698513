import * as React from "react";
import { GlyphIcon } from "../icons";
import { Glyphicon } from "react-bootstrap";
import { GlyphiconProps } from "react-bootstrap/lib/Glyphicon";
import { BasicButton } from "./BasicButton";
import { ButtonColor } from "./types";
import classNames from "classnames";

export interface GlyphButtonProps extends GlyphiconProps {
    glyph: GlyphIcon;
    color?: ButtonColor;
}

export const GlyphButton = (props: GlyphButtonProps) => {
    const { glyph, color = ButtonColor.PRIMARY, className: classnameProps, ...rest } = props;
    const className = classNames(classnameProps, "square-button");
    //const iconColor = color ? `text-${color}` : "";
    return (
        <BasicButton color={color} className={className} {...rest}>
            <Glyphicon glyph={glyph} />
        </BasicButton>
    );
};
