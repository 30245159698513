import { Action } from "redux";

export const REMOVE_DATA_SET_FILTER_FROM_TREE_VIEW = "REMOVE_DATA_SET_FILTER_FROM_TREE_VIEW";

export interface RemoveDataSetFilterFromTreeViewAction extends Action {
    filterId: number;
}

export const removeDataSetFilterFromTreeViewAction = (filterId: number): RemoveDataSetFilterFromTreeViewAction => {
    return {
        type: REMOVE_DATA_SET_FILTER_FROM_TREE_VIEW,
        filterId: filterId,
    };
};
