import {
    CHANGE_GROUP_PROPERTIES as MAPSTORE_CHANGE_GROUP_PROPERTIES,
    CHANGE_LAYER_PROPERTIES as MAPSTORE_CHANGE_LAYER_PROPERTIES,
} from "../../../../MapStore2/web/client/actions/layers";
import { Action } from "redux";

export const CHANGE_GROUP_PROPERTIES = MAPSTORE_CHANGE_GROUP_PROPERTIES;
export const CHANGE_LAYER_PROPERTIES = MAPSTORE_CHANGE_LAYER_PROPERTIES;

export interface LayerProperties {
    visibility: boolean;
    url: string;
    thumbURL: string;
}

export interface GroupProperties {
    visibility: boolean;
}

export interface ChangeGroupPropertiesAction extends Action {
    newProperties: GroupProperties;
    group: string;
}

export const changeGroupProperties = (groupId: string, properties: GroupProperties): ChangeGroupPropertiesAction => {
    return {
        type: CHANGE_GROUP_PROPERTIES,
        newProperties: properties,
        group: groupId,
    };
};

export interface ChangeLayersPropertiesAction extends Action {
    newProperties: LayerProperties;
    layer: string;
}

export const changeLayerProperties = (layerId: string, properties: LayerProperties): ChangeLayersPropertiesAction => {
    return {
        type: CHANGE_LAYER_PROPERTIES,
        newProperties: properties,
        layer: layerId,
    };
};

//assertions
export const isChangeGroupPropertiesAction = (param: any): param is ChangeGroupPropertiesAction => {
    return param.type === CHANGE_GROUP_PROPERTIES;
};

export const isChangeLayersPropertiesAction = (param: any): param is ChangeLayersPropertiesAction => {
    return param.type === CHANGE_LAYER_PROPERTIES;
};
