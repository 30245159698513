import { asyncFetchDataPromiseActionFactory } from "../../../core/core-redux/actions/asyncFetchDataAction";
import {
    getCapabilitiesService,
    GetCapabilitiesServiceParams,
    GetCapabilitiesServiceResponse,
} from "./getCapabilities.service";

export const GET_CAPABILITIES = "GET_CAPABILITIES";

export const getCapabilitiesActionCreator = asyncFetchDataPromiseActionFactory<
    GetCapabilitiesServiceParams,
    GetCapabilitiesServiceResponse,
    any
>(GET_CAPABILITIES, getCapabilitiesService);
