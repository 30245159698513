import { ThemeColor } from "../theme";

export type ButtonColor = ThemeColor;
export const ButtonColor = { ...ThemeColor };

export enum ButtonSize {
    XSMALL = "xs",
    SMALL = "sm",
    LARGE = "lg",
}

export enum ButtonType {
    BUTTON = "button",
    SUBMIT = "submit",
    RESET = "reset",
}
