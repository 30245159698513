import { Feature as GeoFeature, FeatureCollection as GeoFeatureCollection, Geometry, Position } from "geojson";

export enum GeometryType {
    POINT = "Point",
    MULTI_POINT = "MultiPoint",
    LINE_STRING = "LineString",
    MULTI_LINE_STRING = "MultiLineString",
    POLYGON = "Polygon",
    MULTI_POLYGON = "MultiPolygon",
    GEOMETRY_COLLECTION = "GeometryCollection",
}

export enum Features {
    AIRSPACE = "Ase",
    GEOBORDER = "Gbo",
    ROUTE = "Rte",
}

export type FeatureStyle = {
    weight: number;
    fillOpacity: number;
    fillColor: string;
    color: string;
    radius: number;
};

export enum LineSegmentType {
    ARC_BY_EDGE = "ArcByEdge",
    ARC_BY_ANGLES = "ArcByAngles",
    FOLLOWS_GEO_BORDER = "FollowsGeoBorder",
    GREAT_CIRCLE = "GreatCircle",
}

export interface Vertex {
    index: number;
    point: Position;
}

export interface LineSegment {
    type: LineSegmentType;
    properties: ArcByEdge | ArcByAngles | FollowsGeoBorder | GreatCircle;
}

export interface ArcByEdge extends Vertex {
    arcPoint?: Position;
}

export interface ArcByAngles extends Vertex {
    center: Position;
    radius: number;
    bearing1: number;
    bearing2: number;
}

export interface FollowsGeoBorder extends Vertex {
    geoBorderId: string;
    geoBorderCoordinates: Position[];
    slicedVerticesSize: number;
}

interface GreatCircle extends Vertex {}

export type FeatureProps = {
    id: string;
    style?: FeatureStyle;
    densifyGeometry?: boolean;
    newFeature?: boolean;
    featureType?: string;
    featureId?: number;
    featureForm?: string;
    featureShortName?: string;
    slotId?: number;
    naturalKey?: string;
    layer?: string;
    isCircle?: boolean;
    isArc?: boolean;
    radius?: number;
    arcDescriptor?: {
        centerPoint: number[];
        radius: number;
        bearing1: number;
        bearing2: number;
    };
    segments?: LineSegment[];
};

export type GeometryCoordinates = Position | Position[] | Position[][] | Position[][][];

export type FeatureGeometry = Geometry;

export type Feature = GeoFeature<FeatureGeometry, FeatureProps>;
export type FeatureCollection = GeoFeatureCollection<FeatureGeometry, FeatureProps>;
