import { RootState } from "../rootState";

import { connect } from "react-redux";
import { registerPlugin } from "../common/mapstore/registerPlugin";
import { AutoLogin, AutoLoginDispatchProps, AutoLoginStateProps } from "./AutoLogin";
import { loginToWizardSuiteActionCreator, loginToWizardSuiteReducer } from "../api/api-packages/login-to-wizard-suite";
import { storeTicketAction } from "./redux/store-ticket/storeTicket.action";
import { showError } from "../common/mapstore/action";

const mapStateToProps = (state: RootState): AutoLoginStateProps => {
    return {
        autoLogin: state.urlParams.autoLogin,
        axiosInitialized: state.axiosState.initialized,
        loginState: state.loginToWizardSuite.state,
    };
};

const mapDispatchToProps = (dispatch: any): AutoLoginDispatchProps => {
    return {
        loginToWizardSuite: () => dispatch(loginToWizardSuiteActionCreator({})),
        storeTicket: (ticket: string) => dispatch(storeTicketAction(ticket)),
        showError: (title, message) => dispatch(showError({ title: title, message: message, autoDismiss: 55 })),
    };
};

export const ConnectedSessionValidator = connect(mapStateToProps, mapDispatchToProps)(AutoLogin);

export const AutoLoginPlugin = {
    name: "AutoLoginPlugin",
    config: {
        component: ConnectedSessionValidator,
        reducers: {
            loginToWizardSuite: loginToWizardSuiteReducer,
        },
    },
};
export const register = () => registerPlugin(AutoLoginPlugin);
