import { default as Axios } from "axios";
import { SystemConfig } from "./axiosInstance";

let gisServerUrl: string;

const getGisServerUrlFromEnvironmentParams = async () => {
    const { data: gisServerUrlFromEnvironmentParams } = await Axios.get<string>(
        `${window.location.origin}/rest/config/gisServerUrl`
    ).catch(() => {
        return { data: undefined };
    });
    return gisServerUrlFromEnvironmentParams;
};

const getGisServerUrlFromSystemConfig = async () => {
    // we need to load systemConfig asynchronously so we can replace urls during installation process
    const { data: systemConfig } = await Axios.get<SystemConfig>("../../configs/systemConfig.json");
    return systemConfig.gisServerUrl;
};

const getGisServerUrlFromConfig = async () => {
    const gisServerUrl = await getGisServerUrlFromEnvironmentParams();
    if (gisServerUrl) {
        return gisServerUrl;
    }
    return await getGisServerUrlFromSystemConfig();
};

export const getGisServerUrl = async () => {
    if (gisServerUrl) {
        return gisServerUrl;
    }
    gisServerUrl = await getGisServerUrlFromConfig();
    return gisServerUrl;
};
