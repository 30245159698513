import { RootState } from "../rootState";
import { Dispatch } from "redux";
import { NotificationPosition, panTo, showError } from "../common/mapstore/action";
import { renderDotAction } from "../center-map/redux/centerMap.action";
import { connect } from "react-redux";
import { centerMapReducer } from "../center-map/redux/centerMap.reducer";
import { registerPlugin } from "../common/mapstore/registerPlugin";
import { Footer, FooterDispatchProps, FooterStateProps } from "./Footer";
import { adjustHeightAction } from "../globe/action/adjustHeightAction";
import { minZoomSelector } from "../../MapStore2/web/client/selectors/map";
import { changeZoomLevel } from "../../MapStore2/web/client/actions/map";
import { getScales } from "../../MapStore2/web/client/utils/MapUtils";
import { isFooterEnabled } from "../common/selector/configurationSelector";

const mapStateToProps = (state: RootState): FooterStateProps => {
    return {
        enabled: isFooterEnabled(state),
        position: state?.map?.present?.center,
        projection: state.map?.present?.projection!,
        minZoom: minZoomSelector(state),
        currentZoomLvl: Math.round(state.map?.present?.zoom ?? 0),
        scales: getScales(
            (state.map?.present && state.map?.present.projection) || "EPSG:3857",
            (state.map?.present &&
                state.map?.present.mapOptions &&
                state.map?.present.mapOptions.view &&
                state.map?.present.mapOptions.view.DPI) ||
                null
        ),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): FooterDispatchProps => {
    return {
        panTo: (position) => dispatch(panTo(position)),
        showError: (title, message) =>
            dispatch(showError({ title: title, message: message, position: NotificationPosition.BOTTOM_CENTER })),
        shouldRenderDot: (renderDot) => dispatch(renderDotAction(renderDot)),
        adjustHeight: (value) => {
            dispatch(adjustHeightAction(value));
        },
        onChange: (zoomLvl, mapStateSource) => dispatch(changeZoomLevel(zoomLvl, mapStateSource)),
    };
};

export const ConnectedFooter = connect(mapStateToProps, mapDispatchToProps)(Footer);

export const FooterPlugin = {
    name: "FooterPlugin",
    config: {
        component: ConnectedFooter,
        containers: {
            MapFooter: {
                name: "Footer",
                position: 3,
                tool: true,
                priority: 1,
            },
        },
        reducers: { centerMapData: centerMapReducer },
    },
};

export const register = () => registerPlugin(FooterPlugin);
