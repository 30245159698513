import { Action } from "redux";

export const RENDER_DOT_ACTION = "RENDER_DOT_ACTION";

export interface CenterMapAction extends Action {
    renderDot: boolean;
}

export const renderDotAction = (renderDot: boolean): CenterMapAction => ({
    type: RENDER_DOT_ACTION,
    renderDot: renderDot,
});
