import { Action } from "redux";

export const STORE_GIS_SERVER_URL = "STORE_GIS_SERVER_URL";

export interface StoreGisServerUrlAction extends Action {
    url: string;
}

export const storeGisServerUrlAction = (url: string): StoreGisServerUrlAction => {
    return {
        type: STORE_GIS_SERVER_URL,
        url: url,
    };
};
