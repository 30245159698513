export default class TreeItemFinder {
    static findItem(itemId: any, items: any): any {
        for (const itemIndex in items) {
            const item = items[itemIndex];
            if (item.id === itemId) {
                return item;
            }
            if (item.group) {
                const fItem = this.findItem(itemId, item.children);
                if (fItem) {
                    return fItem;
                }
            }
        }
        return null;
    }

    static getItemAndItsChildren(item: any) {
        if (item.group) {
            const items = [];
            for (const itemIndex in item.children) {
                const fItem = item.children[itemIndex];
                const children = this.getItemAndItsChildren(fItem);
                children.forEach((child) => items.push(child));
            }
            items.push(item);
            return items;
        }
        return [item];
    }
}
