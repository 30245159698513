import Logger from "../../../common/util/Logger";
import Cesium from "../../../../MapStore2/web/client/libs/cesium";
import { getPolylinePositionWithHeight, transformPointPositionToVerticalPolylinePositions } from "../util/PolylineUtil";
import { extractWfs3dProperties } from "../util/Wfs3dPropertiesExtractor";
const logger = new Logger("AirspaceParser");

const VERTICAL_STRUCTURE_COLOR = Cesium.Color.RED;

export const parseVerticalStructure = (entity: any) => {
    const clonedEntity = Cesium.clone(entity);

    const properties = extractWfs3dProperties(entity);

    if (clonedEntity.polygon) {
        clonedEntity.polygon.extrudedHeight = properties.upperLimit;
        clonedEntity.polygon.height = properties.lowerLimit;
        clonedEntity.polygon.material = VERTICAL_STRUCTURE_COLOR.withAlpha(0.2);
        clonedEntity.polygon.outlineColor = VERTICAL_STRUCTURE_COLOR;
    } else if (clonedEntity.polyline) {
        clonedEntity.polyline.positions._value = getPolylinePositionWithHeight(
            clonedEntity.polyline.positions,
            properties.lowerLimit
        );
        clonedEntity.polyline.width = 10;
        clonedEntity.polyline.material = new Cesium.PolylineOutlineMaterialProperty({
            color: VERTICAL_STRUCTURE_COLOR.withAlpha(0.2),
            outlineWidth: 2,
            outlineColor: VERTICAL_STRUCTURE_COLOR,
        });
    } else if (clonedEntity.billboard) {
        clonedEntity.billboard = undefined;
        if (properties.upperLimit === 0 && properties.lowerLimit === 0) {
            clonedEntity.point = new Cesium.PointGraphics({
                color: VERTICAL_STRUCTURE_COLOR,
                pixelSize: 10,
            });
        } else {
            const positions = transformPointPositionToVerticalPolylinePositions(
                clonedEntity.position,
                properties.lowerLimit,
                properties.upperLimit
            );

            clonedEntity.polyline = new Cesium.PolylineGraphics({
                positions: positions,
                material: new Cesium.PolylineOutlineMaterialProperty({
                    color: VERTICAL_STRUCTURE_COLOR.withAlpha(0.2),
                    outlineWidth: 2,
                    outlineColor: VERTICAL_STRUCTURE_COLOR,
                }),
                width: 10,
                followSurface: new Cesium.ConstantProperty(false),
            });
        }
    } else {
        logger.warn("not polygon, polyline nor billboard entity tried to be parsed as VerticalStructure");
    }
    return clonedEntity;
};
