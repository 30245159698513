import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createPlugin } from "@mapstore/utils/PluginsUtils";
import { changesEnabledSelector, workspaceModeSelector } from "@js/common/selector/configurationSelector";
import { updateViewTypeAction } from "@js/changes/action/updateViewTypeAction";
import { updateChangesTypesAction } from "@js/changes/action/updateChangeTypeAction";
import { reloadChangesAction } from "@js/changes/action/reloadChangesAction";
import { validFromSelector } from "@js/common/selector/validityIntervalSelector";
import { updateValidFromAction } from "@js/portal/action/updateValidityIntervalAction";
import ChangesHeader from "@js/changes/component/header/ChangesHeader";
import ChangesBody from "@js/changes/component/body/ChangesBody";
import { WORKSPACE_VIEW, CONTEXT_WORKSPACE } from "@js/changes/changesConstants";

import changeTypeColoringReducer from "@js/changes/reducer/changeTypeColoringReducer";
import changesLoaderReducer from "@js/changes/reducer/changesLoaderReducer";
import changeTypeReducer from "@js/changes/reducer/updateChangeTypeReducer";
import workspaceChangesReducer from "@js/changes/reducer/workspaceChangesReducer";

import contextEpic from "@js/changes/epic/contextEpic";
import changeTypeColoringEpic from "@js/changes/epic/changeColoringEpic";
import reloadChangesEpic from "@js/changes/epic/reloadChangesEpic";
import viewTypeEpic from "@js/changes/epic/viewTypeEpic";
import changeTypeEpic from "@js/changes/epic/changeTypeEpic";
import { isCesium } from "@mapstore/selectors/maptype";

class Changes extends React.Component {
    static propTypes = {
        viewType: PropTypes.string,
        handleViewTypeChanged: PropTypes.func,
        handleChangesTypeChanged: PropTypes.func,
        handleReloadChanges: PropTypes.func,
        handleEffectiveDateChanged: PropTypes.func,
        context: PropTypes.string,
        workspaceName: PropTypes.string,
        generated: PropTypes.string,
        effectiveDate: PropTypes.string,
        selectedChangeTypes: PropTypes.array,
        displayLoading: PropTypes.bool,
        enabled: PropTypes.bool,
        changeTypeColoring: PropTypes.object,
    };

    static defaultProps = {
        viewType: WORKSPACE_VIEW,
        selectedChangeTypes: null,
        context: CONTEXT_WORKSPACE,
        effectiveDate: "Not defined",
    };

    render() {
        if (!this.props.enabled) {
            return null;
        }

        return (
            <div className={"changesVisualizationPanel" + (this.props.displayLoading ? " loading" : "")}>
                <ChangesHeader {...this.props} />
                <ChangesBody {...this.props} />
            </div>
        );
    }
}

const ChangesPlugin = connect(
    (state) => {
        const isChangesPanelEnabled = workspaceModeSelector(state) === false;
        if (isChangesPanelEnabled) {
            return {
                viewType: state.workspaceChanges.view,
                selectedChangeTypes: state.changeType.changeType,
                effectiveDate: validFromSelector(state),
                context: state.workspaceChanges.context,
                generated: state.workspaceChanges.dateOfGeneration,
                workspaceName: state.urlParams.slots.haveSlotIds() ? state.urlParams.workspaceName : "",
                enabled: changesEnabledSelector(state),
                displayLoading: state.changesLoader.display,
                changeTypeColoring: state.changeTypeColoring && state.changeTypeColoring.changeToColorMapping,
                isCesium: isCesium(state),
            };
        }

        return { enabled: false };
    },
    {
        handleViewTypeChanged: updateViewTypeAction,
        handleChangesTypeChanged: updateChangesTypesAction,
        handleReloadChanges: reloadChangesAction,
        handleEffectiveDateChanged: updateValidFromAction,
    }
)(Changes);

export default createPlugin("ChangesPlugin", {
    component: ChangesPlugin,
    reducers: {
        changeTypeColoring: changeTypeColoringReducer,
        changesLoader: changesLoaderReducer,
        changeType: changeTypeReducer,
        workspaceChanges: workspaceChangesReducer,
    },
    epics: {
        contextEpic: contextEpic,
        changeTypeColoringEpic: changeTypeColoringEpic,
        reloadChangesEpic: reloadChangesEpic,
        viewTypeEpic: viewTypeEpic,
        changeTypeEpic: changeTypeEpic,
    },
});
