import { ShowSelectedFeatureAction } from "../../feature/feature-detail-frame/redux/show-selected-feature/showSelectedFeature.action";
import { calculateFeatureCenter } from "../../common/util/FeatureCenter";
import { zoomToPoint } from "../../../MapStore2/web/client/actions/map";
import { EpicFactory } from "../../core/core-redux/reduxTypes";
import { Action } from "redux";
import { RootState } from "../../rootState";
import { SELECT_FEATURE_FROM_PORTAL } from "../redux/select-feature/selectFeatureFromPortal.action";

export const centerMapOnFeaturesEpic: EpicFactory<Action, RootState, Action> = (action$, store) =>
    action$.ofType<ShowSelectedFeatureAction>(SELECT_FEATURE_FROM_PORTAL).map(() => {
        const state = store.getState();
        const mapPresent = state.map?.present;
        const calculation = calculateFeatureCenter(state.selectedFeaturesFromPortal?.features, mapPresent);
        return zoomToPoint(calculation.center, calculation.zoom, mapPresent?.projection!);
    });
