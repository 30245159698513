import { IFrameMessage } from "./IFrameMessage";

export interface IFrameProxy<InputMessageType, OutputMessageType> {
    init: (
        sourceFrame: Window,
        targetFrame: Window,
        onMessageCallback: (data: IFrameMessage<InputMessageType>) => void
    ) => void;
    clear: () => void;
    send: (message: IFrameMessage<OutputMessageType>) => void;
}

export class IFrameManager<InputMessageType, OutputMessageType>
    implements IFrameProxy<InputMessageType, OutputMessageType>
{
    private onMessageCallback?: (event: MessageEvent<IFrameMessage<InputMessageType>>) => void;
    private sourceFrame?: Window;
    private targetFrame?: Window;
    private readonly pendingMessages: IFrameMessage<OutputMessageType>[];

    constructor() {
        this.sourceFrame = undefined;
        this.targetFrame = undefined;
        this.pendingMessages = [];
    }

    init(
        sourceFrame: Window,
        targetFrame: Window,
        _onMessageCallback: (data: IFrameMessage<InputMessageType>) => void
    ) {
        this.sourceFrame = sourceFrame;
        this.targetFrame = targetFrame;
        this.clear();
        this.onMessageCallback = (event: MessageEvent<IFrameMessage<InputMessageType>>) => {
            _onMessageCallback(event.data);
        };
        this.sourceFrame.addEventListener("message", this.onMessageCallback);
        if (this.pendingMessages.length > 0) {
            this.pendingMessages.forEach((message) => this.send(message));
        }
    }

    send(message: IFrameMessage<OutputMessageType>) {
        if (this.targetFrame === undefined) {
            console.error("Cannot send message because target frame is undefined!");
            this.pendingMessages.push(message);
            console.log("Current pending messages:", this.pendingMessages);
            return;
        }
        console.log("Sending message", message);
        this.targetFrame.postMessage(message, "*");
    }

    clear() {
        this.sourceFrame?.removeEventListener("message", this.onMessageCallback!);
    }
}
