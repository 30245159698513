import { IPartialReducer } from "../../../../core/core-redux/reduxTypes";
import { FeatureFilterDataState } from "../reducer/featureFilterReducer";

export const removeFeatureFilterPartialReducer: IPartialReducer<FeatureFilterDataState> = (state, action) => {
    const treeItems = [...state.treeItems];
    treeItems[0].children = [];
    return {
        ...state,
        treeItems: treeItems,
        filters: [],
        asString: undefined,
    };
};
