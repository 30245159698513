export enum GlyphIcon {
    EYE_OPEN = "eye-open",
    EYE_CLOSE = "eye-close",
    PLUS = "plus",
    REMOVE = "remove",
    SEARCH = "search",
    GEO_CALCULATION = "1-measure-bearing",
    CLOSE = "1-close",
    FOLDER_OPEN = "folder-open",
    FOLDER_CLOSE = "folder-close",
    REFRESH = "refresh",
    WARNING = "warning-sign",
    ALERT = "alert",
    CENTER_MAP = "1-position-1",
    DENSIFY = "1-vector-alt",
    ADD_VERTEX = "1-point-add",
    REMOVE_VERTEX = "1-point-dashed",
    MOVE = "move",
    OK = "ok",
    LAYER = "1-layer",
    PROJECTION = "crs",
    RESIZE_FULL = "resize-full",
}
