import * as React from "react";
import { useState } from "react";
import { Tooltip } from "../core/core-widgets/tooltips";
import { GlyphButton } from "../core/core-widgets/buttons";
import { GlyphIcon, ThemeColor } from "../core/core-widgets";
import { Slider } from "../core/core-widgets/slider/Slider";
import { FlexBox } from "../core/core-widgets/boxes/FlexBox/FlexBox";
import { FlexAlignItems } from "../core/core-widgets/boxes/FlexBox/types";

export interface HeightAdjusterDispatchProps {
    adjustHeight: (value: number) => void;
}

export interface HeightAdjusterStateProps {
    value: number;
    changeValue: (value: number) => void;
}

export const HeightAdjuster = (props: HeightAdjusterDispatchProps & HeightAdjusterStateProps) => {
    const { value, adjustHeight, changeValue } = props;
    const [showSlider, setShowSlider] = useState<boolean>(false);

    const handleToggleButtonClick = () => {
        setShowSlider(!showSlider);
    };
    return (
        <FlexBox alignItems={FlexAlignItems.CENTER} className={"heightAdjuster"}>
            {showSlider && (
                <>
                    <Slider
                        style={{
                            minWidth: "200px",
                        }}
                        className={"mx-2"}
                        min={1}
                        max={100}
                        value={value}
                        step={5}
                        onChangeValue={(value) => {
                            if (value) changeValue(value);
                        }}
                        onAfterChange={(value) => {
                            if (value) adjustHeight(value);
                        }}
                    />
                </>
            )}
            <Tooltip body={"Show/Hide Height Adjuster"}>
                <GlyphButton
                    style={{
                        width: "25px",
                        height: "25px",
                    }}
                    id={"toggleHeightAdjuster"}
                    color={ThemeColor.PRIMARY}
                    onClick={() => handleToggleButtonClick()}
                    glyph={GlyphIcon.RESIZE_FULL}
                />
            </Tooltip>
        </FlexBox>
    );
};
