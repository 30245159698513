const { MAP_CONFIG_LOADED } = require("@mapstore/actions/config");
const { setChangeTypesAction } = require("@js/changes/action/updateChangeTypeAction");
const { defaultChangeTypesSelector } = require("@js/common/selector/configurationSelector");

const changeTypeEpic = (action$, store) =>
    action$.ofType(MAP_CONFIG_LOADED).map(() => {
        const state = store.getState();

        return setChangeTypesAction(defaultChangeTypesSelector(state));
    });

module.exports = changeTypeEpic;
