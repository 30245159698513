import { IAsyncDataState } from "../../../core/core-redux/reduxTypes";
import { asyncFetchDataReducerFactory } from "../../../core/core-redux/reducer/asyncFetchDataReducer";
import { GET_DATASET_FILTER_BY_ID } from "./getDataSetFilterById.action";
import { GetDataSetFilterByIdServiceParams, GetDataSetFilterByIdServiceResponse } from "./types";

export interface GetDataSetFilterByIdDataState
    extends IAsyncDataState<GetDataSetFilterByIdServiceResponse, any, GetDataSetFilterByIdServiceParams> {}

export const getDataSetFilterByIdReducer = asyncFetchDataReducerFactory<
    GetDataSetFilterByIdServiceResponse,
    any,
    GetDataSetFilterByIdServiceParams
>(GET_DATASET_FILTER_BY_ID);
