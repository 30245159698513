import {
    SHOW_SELECTED_FEATURE,
    ShowSelectedFeatureAction,
} from "../../feature/feature-detail-frame/redux/show-selected-feature/showSelectedFeature.action";
import { allLayersSelector } from "../../common/selector/layerSelector";
import { doNothingAction } from "../../common/action/doNothingAction";
import { queryableLayersSelector } from "../../../MapStore2/web/client/selectors/layers";
import { changeLayerProperties } from "../../../MapStore2/web/client/actions/layers";
import { EpicFactory } from "../../core/core-redux/reduxTypes";
import { Action } from "redux";
import { Layer, RootState } from "../../rootState";
import { Feature } from "../../core/geo/types";

// TODO ICH: make this common function, duplicate from deselectFeatures.epic
function getFeatureIdAsString(feature: Feature): string {
    if (typeof feature.id === "string") {
        return feature.id;
    }
    throw new Error("Feature id is not string");
}

function getLayerNameWithGroupFromLayerId(layerId: string) {
    return layerId.substring(layerId.indexOf(":") + 1);
}

export const setSelectedLayersVisibleEpic: EpicFactory<Action, RootState, Action> = (action$, store) =>
    action$.ofType<ShowSelectedFeatureAction>(SHOW_SELECTED_FEATURE).map((action) => {
        if (action.feature) {
            const queriableLayers = queryableLayersSelector(store.getState()).map((layer: Layer) =>
                getLayerNameWithGroupFromLayerId(layer.id)
            );

            const featureId = getFeatureIdAsString(action.feature);
            const featureName = featureId.substring(0, featureId.indexOf("."));
            const layerName = featureName + "." + (action.feature.properties.slotId === 0 ? "Baseline" : "Workspace");
            if (!queriableLayers.includes(layerName)) {
                const layerToUnhide = allLayersSelector(store.getState())
                    .filter((layer: Layer) => getLayerNameWithGroupFromLayerId(layer.id) === layerName)
                    .map((layer: Layer) => layer.id)[0];
                return changeLayerProperties(layerToUnhide, { visibility: true });
            }
        }
        return doNothingAction();
    });
