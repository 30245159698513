import * as React from "react";
import { GlobalSpinner } from "./GlobalSpinner";

export interface GlobalSpinnerContextValue {
    start: () => void;
    stop: () => void;
}

const logNoProvider = () => console.warn("Missing GlobalSpinnerProvider");

export const GlobalSpinnerContext = React.createContext<GlobalSpinnerContextValue>({
    start: logNoProvider,
    stop: logNoProvider,
});

export const useGlobalSpinnerContext = () => React.useContext(GlobalSpinnerContext);

export const GlobalSpinnerProvider: React.FC = (props) => {
    const [count, setCount] = React.useState(0);
    const ctx: GlobalSpinnerContextValue = React.useMemo(
        () => ({
            start: () => setCount((value) => value + 1),
            stop: () => setCount((value) => value - 1),
        }),
        [setCount]
    );
    return (
        <GlobalSpinnerContext.Provider value={ctx}>
            {props.children}
            {count > 0 && <GlobalSpinner />}
        </GlobalSpinnerContext.Provider>
    );
};
