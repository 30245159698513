import { Action } from "redux";
import { Feature } from "../../../../core/geo/types";

export const LOAD_FEATURE_INFO = "LOAD_FEATURE_INFO";

export interface LoadFeatureInfoAction extends Action {
    features: Feature[];
}

export function loadFeatureInfoAction(features: Feature[]): LoadFeatureInfoAction {
    return {
        type: LOAD_FEATURE_INFO,
        features: features,
    };
}
