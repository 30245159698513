export default class TreeItemVisibilityHandler {
    static setTreeItemVisibility(treeItem: any, visibility: any) {
        this.setItemAndChildrenVisibility(treeItem, visibility);
        this.setItemAndParentsVisibility(treeItem, visibility);
    }

    static setItemAndParentsVisibility(treeItem: any, visibility?: any) {
        if (treeItem.children && treeItem.children.length > 0) {
            treeItem.visibility = treeItem.children.filter((child: any) => child.visibility).length > 0;
        } else {
            treeItem.visibility = visibility;
        }

        if (treeItem.parent) {
            this.setItemAndParentsVisibility(treeItem.parent);
        }
    }

    static setItemAndChildrenVisibility(treeItem: any, visibility: any) {
        treeItem.visibility = visibility;
        if (treeItem.children && treeItem.children.length > 0) {
            treeItem.children.forEach((child: any) => {
                this.setItemAndChildrenVisibility(child, visibility);
            });
        }
    }
}
