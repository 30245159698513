import { Action } from "redux";
import { Feature } from "../../../../core/geo/types";

export const DESELECT_FEATURES = "DESELECT_FEATURES";

export interface DeselectFeaturesAction extends Action {
    features: Feature[];
}

export const deselectFeaturesAction = (features: Feature[]): DeselectFeaturesAction => {
    return {
        type: DESELECT_FEATURES,
        features: features,
    };
};
