import { api } from "../../apiProvider";
import { EPSG_4326 } from "../../../common/constants/Projections";
import { GetFeaturesByParamsService } from "./getFeaturesByParams.service";

export const getFeaturesByParamsAdapter: GetFeaturesByParamsService = async (params) => {
    const defaultParams = {
        service: "wfs",
        version: "2.0.0",
        request: "GetFeature",
        outputFormat: "application/json",
        srsName: EPSG_4326,
    };

    const mergedParams = params.map((data) => {
        return { ...defaultParams, ...data };
    });

    const requestParams = mergedParams.map((params) => {
        return Object.keys(params)
            .map((key) => {
                return key + "=" + (params as any)[key];
            })
            .join("&");
    });

    const promises = requestParams.map((params) => api.getFeaturesByParams(params));
    const resp = await Promise.all(promises);
    return resp.map((t) => ({
        ...t.data,
        features: t.data.features.map((apiFeature) => ({
            ...apiFeature,
            properties: {
                id: `${apiFeature.properties.FEATURE_ID}`,
                featureType: apiFeature.properties.FEATURE_FORM,
                featureId: apiFeature.properties.FEATURE_ID,
                featureForm: apiFeature.properties.FEATURE_FORM,
                featureShortName: apiFeature.properties.FEATURE_SHORT_NAME,
                slotId: apiFeature.properties.SLOT_ID,
                naturalKey: apiFeature.properties.NATURAL_KEY,
                layer: apiFeature.properties.LAYER_NAME,
            },
        })),
    }));
};
