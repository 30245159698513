import { standardApiHandler } from "../../../core/core-api/apiTools";
import { loadSlotChangesAdapter } from "./loadSlotChanges.adapter";

export type LoadSlotChangesServiceParams = {
    slotIds: string[] | string;
    forceReload: boolean;
};
export type LoadSlotChangesServiceResponse = string;

export type LoadSlotChangesService = (params: LoadSlotChangesServiceParams) => Promise<LoadSlotChangesServiceResponse>;
export const loadSlotChangesService: LoadSlotChangesService = standardApiHandler(loadSlotChangesAdapter);
