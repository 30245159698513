import React from "react";
import Dock from "react-dock";
import { PanelHeader } from "./PanelHeader";
import { ThemeColor } from "../theme";

type DockProps = React.ComponentProps<typeof Dock>;

export interface DockPanelProps extends Partial<DockProps> {
    type?: ThemeColor;
    title: string;
    open: boolean;
    resizable?: boolean;
    onClose?: () => void;
}

export const DockPanel: React.FC<DockPanelProps> = (props) => {
    const {
        title,
        open,
        children,
        type = ThemeColor.PRIMARY,
        fluid = false,
        resizable = false,
        onClose,
        ...rest
    } = props;
    const dock = (
        <Dock fluid={fluid} position={props.position} dimMode={"none"} isVisible={open} zIndex={1030} {...rest}>
            <PanelHeader type={type} onClose={onClose}>
                {title}
            </PanelHeader>
            {children}
        </Dock>
    );
    return resizable ? <div className={"react-dock-no-resize"}>{dock}</div> : dock;
};
