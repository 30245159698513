import { EditFeatureState } from "../editFeature.reducer";
import { IPartialReducer } from "../../../core/core-redux/reduxTypes";
import Logger from "../../../common/util/Logger";

const logger = new Logger("changeEditActionModePartialReducer");

export const changeEditActionModePartialReducer: IPartialReducer<EditFeatureState> = (state, action) => {
    logger.info(`setting edit action mode to ${action.value}`);
    return {
        ...state,
        options: {
            ...state.options,
            action: action.value,
        },
    };
};
