import { asyncFetchDataPromiseActionFactory } from "../../../core/core-redux/actions/asyncFetchDataAction";
import { colorMappingService, ColorMappingServiceParams, ColorMappingServiceResponse } from "./colorMapping.service";

export const COLOR_MAPPING = "COLOR_MAPPING";

export const colorMappingActionCreator = asyncFetchDataPromiseActionFactory<
    ColorMappingServiceParams,
    ColorMappingServiceResponse,
    any
>(COLOR_MAPPING, colorMappingService);
