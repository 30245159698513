import DataSetFilterItem from "../../../DataSetFilter/dto/DataSetFilterItem";
import { Action } from "redux";
import { FilterCondition } from "../../../../globe/types";

export const APPLY_FEATURE_FILTER = "APPLY_FEATURE_FILTER";

export interface ApplyFeatureFilterAction extends Action {
    item: DataSetFilterItem;
    filter: FilterCondition;
}

export const applyFeatureFilterAction = (
    item: DataSetFilterItem,
    filter: FilterCondition
): ApplyFeatureFilterAction => {
    return {
        type: APPLY_FEATURE_FILTER,
        item: item,
        filter: filter,
    };
};
