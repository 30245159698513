const { MAP_CONFIG_LOADED } = require("@mapstore/actions/config");
const { updateContextAction } = require("@js/changes/action/updateContextAction");

const contextEpic = (action$, store) =>
    action$.ofType(MAP_CONFIG_LOADED).map(() => {
        const state = store.getState();

        return updateContextAction(state.urlParams.slots.haveSlotIds() ? "workspace" : "baseline");
    });

module.exports = contextEpic;
