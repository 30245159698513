import { zoomToPoint } from "@mapstore/actions/map";
import { calculateFeatureCenter } from "@js/common/util/FeatureCenter";
import { GEOCALC_GEOMETRY_LOADED } from "@js/geocalc/action/geoCalcGeometryLoadedAction";
import { CENTER_MAP_TO_GEOCALCULATION } from "@js/geocalc/action/centerMapOnGeoCalculationAction";
import { doNothingAction } from "@js/common/action/doNothingAction";

const centerMapOnGeoCalculationEpic = (action$, store) =>
    action$.ofType(GEOCALC_GEOMETRY_LOADED, CENTER_MAP_TO_GEOCALCULATION).map((action) => {
        const state = store.getState();

        let features;
        if (action.type === CENTER_MAP_TO_GEOCALCULATION) {
            features = state.geoCalcList.featureLayer.features[0].features.filter(
                (calculation) => calculation.calculationId === action.calculationId
            );
        } else {
            features = action.geoCalculations.reduce((acc, calc) => acc.concat(calc.geometry.features), []);
        }

        if (features && features.length > 0) {
            const mapPresent = store.getState().map.present;
            const calculation = calculateFeatureCenter(features, mapPresent);

            return zoomToPoint(calculation.center, calculation.zoom, mapPresent.projection);
        }

        return doNothingAction();
    });

export default centerMapOnGeoCalculationEpic;
