import { IAsyncDataState } from "../../../core/core-redux/reduxTypes";
import { FEATURE_LAYERS_MAPPING } from "./featureLayersMapping.action";
import { FeatureLayersMappingServiceParams, FeatureLayersMappingServiceResponse } from "./featureLayersMapping.service";
import { asyncFetchDataReducerFactory } from "../../../core/core-redux/reducer/asyncFetchDataReducer";

export interface FeatureLayersMappingDataState
    extends IAsyncDataState<FeatureLayersMappingServiceResponse, any, FeatureLayersMappingServiceParams> {}

export const featureLayersMappingReducer = asyncFetchDataReducerFactory<
    FeatureLayersMappingServiceResponse,
    any,
    FeatureLayersMappingServiceParams
>(FEATURE_LAYERS_MAPPING);
