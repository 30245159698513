import { FeatureStyle, GeometryType } from "./types";

const defaultStyle = {
    weight: 1,
    radius: 0,
    fillOpacity: 0.2,
    fillColor: "#3388ff",
    color: "green",
};

export const geometryStyle: { [key in GeometryType]: FeatureStyle } = {
    Point: {
        weight: 1,
        radius: 3,
        fillOpacity: 1,
        fillColor: "green",
        color: "green",
    },
    MultiPoint: defaultStyle,
    LineString: defaultStyle,
    MultiLineString: defaultStyle,
    Polygon: defaultStyle,
    MultiPolygon: defaultStyle,
    GeometryCollection: defaultStyle,
};
