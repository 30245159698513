const SAVE_CHANGE_TYPE_COLORING = "SAVE_CHANGE_TYPE_COLORING";

function saveChangeTypeColoring(changeToColorMapping) {
    return {
        type: SAVE_CHANGE_TYPE_COLORING,
        changeToColorMapping: changeToColorMapping,
    };
}

module.exports = {
    saveChangeTypeColoring,
    SAVE_CHANGE_TYPE_COLORING,
};
