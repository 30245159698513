import { registerPlugin } from "../common/mapstore/registerPlugin";
import { connect } from "react-redux";
import highlightLayerReducer from "./reducer/highlightLayerReducer";
import highlightLayerEpic from "./epic/highlightLayerEpic";

const Highlight = () => {
    return null;
};

export const ConnectedHighlight = connect()(Highlight);

export const HighlightPlugin = {
    name: "HighlightPlugin",
    config: {
        component: ConnectedHighlight,
        reducers: {
            highlight: highlightLayerReducer,
        },
        epics: {
            highlightLayerEpic: highlightLayerEpic,
        },
    },
};
export const register = () => registerPlugin(HighlightPlugin);
