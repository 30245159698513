import { IPartialReducer } from "../../../../core/core-redux/reduxTypes";
import DataSetFilterItem from "../../../DataSetFilter/dto/DataSetFilterItem";
import Item from "../../../../layers/dto/Item";
import { FeatureFilterDataState } from "../reducer/featureFilterReducer";

export const applyFeatureFilterPartialReducer: IPartialReducer<FeatureFilterDataState> = (state, action) => {
    const rootItem = new DataSetFilterItem(
        new Item(action.item.id, "Quick / Advanced Filter", true, true, true, false, [action.item.children[0]])
    );

    return {
        ...state,
        treeItems: [rootItem],
        filters: [action.filter],
        asString: action.item.toString(),
    };
};
