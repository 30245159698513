import { removeNode } from "../../../MapStore2/web/client/actions/layers";
import { BASELINE_LAYER_GROUP, WORKSPACE_LAYER_GROUP } from "../../common/selector/layerSelector";
import { MAP_CONFIG_LOADED } from "../../../MapStore2/web/client/actions/config";
import { doNothingAction } from "../../common/action/doNothingAction";

const groupParamsEpic = (action$: any, store: any) =>
    action$.ofType(MAP_CONFIG_LOADED).map(() => {
        const groupsWorkspace = store
            .getState()
            .layers.groups.filter((group: any) => group.name.startsWith(WORKSPACE_LAYER_GROUP));
        const groupWorkspace = groupsWorkspace && groupsWorkspace.length > 0 ? groupsWorkspace[0] : null;
        const slotName = store.getState().urlParams.slots.getSlotName();
        if (
            slotName !== BASELINE_LAYER_GROUP &&
            groupWorkspace &&
            slotName &&
            store.getState().layers.groups.length > 1
        ) {
            groupWorkspace.title = slotName;
        }
        if (!store.getState().urlParams.slots.haveSlotIds() && groupWorkspace) {
            return removeNode(groupWorkspace.id, "groups");
        }

        return doNothingAction();
    });

export default groupParamsEpic;
