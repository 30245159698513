import GeoJsonCesiumParser from "@js/globe/cesium/entity-parser/GeoJsonCesiumParser";
import { updateEntityHeight } from "@js/globe/cesium/height-update/heightUpdateUtil";
const Layers = require("@mapstore/utils/cesium/Layers");

export const WFS_3D_LAYER_IDENTIFIER = "wfs3d";

function loadDataSources(map, newOptions) {
    if (newOptions.reloadWfs3dLayers) {
        map.dataSources.removeAll();
    }
    const loadedDataSourcesNames = map.dataSources._dataSources.map((ds) => ds.name);
    const dataSourcesToAdd = newOptions.data
        .filter((ds) => !loadedDataSourcesNames.includes(ds.name))
        .map((data) => GeoJsonCesiumParser.parse(data.features, data.name));

    dataSourcesToAdd.forEach((ds) => map.dataSources.add(ds));
}

Layers.registerType(WFS_3D_LAYER_IDENTIFIER, {
    create: (options, map) => {
        return null;
    },
    update: function (layer, newOptions, oldOptions, map) {
        if (newOptions.data) {
            const newLayers = newOptions.data.map((layer) => {
                return { name: layer.name, features: layer.filteredFeatures, visible: layer.visible };
            });

            loadDataSources(map, newOptions);

            for (let index = 0; index < map.dataSources.length; index++) {
                const dataSource = map.dataSources.get(index);
                Promise.resolve(dataSource).then(function (value) {
                    const newLayer = newLayers.filter((layer) => layer.name === dataSource.name)[0];
                    const filteredIds = newLayer.features;
                    const visible = newLayer.visible;

                    for (const entity of dataSource.entities.values) {
                        entity.show = visible && filteredIds.includes(entity._properties.FEATURE_ID);
                        if (newOptions.shouldUpdate && newOptions.adjustedHeight) {
                            updateEntityHeight(entity, newOptions.adjustedHeight);
                        }
                    }
                });
            }
        }

        return null;
    },
});
