import * as React from "react";
import { convertDMS } from "../../../helpers/dmsConverter";

interface FormattedDMSProps extends React.HTMLAttributes<HTMLSpanElement> {
    longitude: number;
    latitude: number;
}

export const FormattedDMS = ({ longitude, latitude, ...rest }: FormattedDMSProps) => {
    return <span {...rest}>{convertDMS(latitude, longitude)}</span>;
};
