import {
    Feature,
    FeatureCollection,
    Geometry,
    GeometryCollection,
    LineString,
    MultiLineString,
    MultiPoint,
    MultiPolygon,
    Point,
    Polygon,
    Position,
} from "geojson";
import { GeometryCoordinates, GeometryType } from "./types";

export const isPoint = (value: Geometry): value is Point => {
    return value.type === GeometryType.POINT;
};
export const isMultiPoint = (value: Geometry): value is MultiPoint => {
    return value.type === GeometryType.MULTI_POINT;
};
export const isLineString = (value: Geometry): value is LineString => {
    return value.type === GeometryType.LINE_STRING;
};
export const isMultiLineString = (value: Geometry): value is MultiLineString => {
    return value.type === GeometryType.MULTI_LINE_STRING;
};
export const isPolygon = (value: Geometry): value is Polygon => {
    return value.type === GeometryType.POLYGON;
};
export const isMultiPolygon = (value: Geometry): value is MultiPolygon => {
    return value.type === GeometryType.MULTI_POLYGON;
};

export const isGeometryCollection = (value?: Geometry): value is GeometryCollection => {
    if (value === undefined) return false;
    return value.type === GeometryType.GEOMETRY_COLLECTION;
};

export const isFeature = (value: any): value is Feature => {
    return value.type === "Feature";
};

export const isFeatureCollection = (value: any): value is FeatureCollection => {
    return value.type === "FeatureCollection";
};

export const isPointCoordinates = (value?: GeometryCoordinates): value is Position => {
    return Array.isArray(value);
};

export const isPolygonCoordinates = (value?: GeometryCoordinates): value is Position[][] => {
    return Array.isArray(value) && Array.isArray(value[0]) && Array.isArray(value[0][0]);
};
