import { GEOCALC_LIST_REQUESTED } from "@js/geocalc/action/requestGeoCalcListAction";
import { geoCalcListLoadedAction } from "@js/geocalc/action/geoCalcListLoadedAction";
import { doNothingAction } from "@js/common/action/doNothingAction";
import GeoCalculationItem from "@js/geocalc/dto/GeoCalculationItem";
import Logger from "@js/common/util/Logger";
import Item from "@js/layers/dto/Item";
import { invokeRestService } from "@js/common/util/web-service/gisServerServices.ts";

const logger = new Logger("geoCalcListLoadEpic");

const geoCalcListLoadEpic = (action$, store) =>
    action$.ofType(GEOCALC_LIST_REQUESTED).switchMap(() => {
        const state = store.getState();
        return invokeRestService(state, "sdo/geoCalculations")
            .map((response) => {
                const calculations = response.data.map(
                    (calculation) =>
                        new GeoCalculationItem(
                            new Item(calculation.calculationId, calculation.calculationName, true, true, false, true)
                        )
                );
                return geoCalcListLoadedAction(calculations);
            })
            .catch((e) => {
                logger.error("Error retrieving geoCalculations list from GIS Server.", e);
                return [doNothingAction()];
            });
    });

export default geoCalcListLoadEpic;
