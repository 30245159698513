import { asyncReduxActionsFactory } from "../../core/core-redux/actions/asyncFetchDataAction";
import {
    loadAllFeaturesInfoService,
    LoadAllFeaturesInfoServiceParams,
    LoadAllFeaturesInfoServiceResponse,
} from "./loadAllFeaturesInfo.service";

export const LOAD_ALL_FEATURES_INFO = "LOAD_ALL_FEATURES_INFO";

export const loadAllFeaturesActions = asyncReduxActionsFactory<
    LoadAllFeaturesInfoServiceParams,
    LoadAllFeaturesInfoServiceResponse,
    any
>(LOAD_ALL_FEATURES_INFO, loadAllFeaturesInfoService);
