import { IAsyncDataState } from "../../../core/core-redux/reduxTypes";
import { GEO_CALCULATIONS_BY_IDS } from "./geoCalculationsByIds.action";
import { GeoCalculationsByIdsServiceParams, GeoCalculationsByIdsServiceResponse } from "./geoCalculationsByIds.service";
import { asyncFetchDataReducerFactory } from "../../../core/core-redux/reducer/asyncFetchDataReducer";

export interface GeoCalculationsByIdsDataState
    extends IAsyncDataState<GeoCalculationsByIdsServiceResponse, any, GeoCalculationsByIdsServiceParams> {}

export const geoCalculationsByIdsReducer = asyncFetchDataReducerFactory<
    GeoCalculationsByIdsServiceResponse,
    any,
    GeoCalculationsByIdsServiceParams
>(GEO_CALCULATIONS_BY_IDS);
