export enum EditAction {
    ADD_VERTEX = "ADD_VERTEX",
    REMOVE_VERTEX = "REMOVE_VERTEX",
    MOVE_FEATURE = "MOVE_FEATURE",
    NONE = "NONE",
}

export enum ArcMode {
    NONE = "NONE",
    ARC_BY_EDGE = "ARC_BY_EDGE",
    ARC_BY_CENTER = "ARC_BY_CENTER",
}
