import { IAsyncDataState } from "../../../core/core-redux/reduxTypes";

import { asyncFetchDataReducerFactory } from "../../../core/core-redux/reducer/asyncFetchDataReducer";
import { ValidateSessionServiceParams, ValidateSessionServiceResponse } from "./validateSession.service";
import { VALIDATE_SESSION } from "./validateSession.action";

export interface ValidateSessionMappingDataState
    extends IAsyncDataState<ValidateSessionServiceResponse, any, ValidateSessionServiceParams> {}

export const validateSessionReducer = asyncFetchDataReducerFactory<
    ValidateSessionServiceResponse,
    any,
    ValidateSessionServiceParams
>(VALIDATE_SESSION);
