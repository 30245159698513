import { OwsOperation, OwsConstraint } from "./types";
import { GetCapabilitiesDataState } from "./getCapabilities.reducer";

export const WFS_CAPABILITIES = "wfs:WFS_Capabilities";

export const gisServerMaxFeatureCountSelector = (state: {
    gisServerCapabilities?: GetCapabilitiesDataState;
}): string | undefined => {
    return state.gisServerCapabilities?.receivedData?.[WFS_CAPABILITIES]?.["ows:OperationsMetadata"]["ows:Operation"]
        .filter((operation: OwsOperation) => operation.$.name === "GetFeature")[0]
        ["ows:Constraint"].filter((constraint: OwsConstraint) => constraint.$.name === "CountDefault")[0][
        "ows:DefaultValue"
    ];
};
