import { connect } from "react-redux";
import { EditFeatureDispatchProps, EditFeatureStateProps, EditFeatureToolbar } from "./component/EditFeatureToolbar";
import { Dispatch } from "redux";
import { finishVisualGeometryEditAction } from "./action/finishVisualGeometryEditAction";
import { embeddedInPortalSelector } from "../url/selector/urlParamsSelector";
import { registerPlugin } from "../common/mapstore/registerPlugin";
import { RootState } from "../rootState";
import {
    editFeatureReducer,
    editGeometryEpic,
    finishGeometryEpic,
    finishVisualGeometryEpic,
    geometryChangedEpic,
    insertFeatureEpic,
    isEditModeEnabled,
    setGeometryStyleEpic,
    viewGeometryEpic,
    changeEditModeAction,
    densifyGeometryAction,
    EditFeatureOptions,
} from "./redux";
import { EditAction } from "./redux/types";
import { FeatureCollection } from "../core/geo/types";
import { selectFeatureForEditing } from "../common/mapstore/action/draw";
import { setArcModeAction } from "./redux/setArcMode";
import { isArcModeEnabledSelector } from "../common/selector/configurationSelector";

const mapStateToProps = (state: RootState): EditFeatureStateProps => {
    return {
        editEnabled: isEditModeEnabled(state) && embeddedInPortalSelector(state),
        editFeature: state.editFeature!,
        editAction: state.editFeature?.options.action ?? EditAction.NONE,
        editPaused: state.editFeature?.options.editPaused ?? false,
        arcModeEnabled: isArcModeEnabledSelector(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): EditFeatureDispatchProps => {
    return {
        finish: () => dispatch(finishVisualGeometryEditAction()),
        changeEditActionMode: (value) => dispatch(changeEditModeAction(value)),
        densifyGeometry: () => dispatch(densifyGeometryAction()),
        setArcMode: (mode) => dispatch(setArcModeAction(mode)),
        selectFeatureForEditing: (features: FeatureCollection, options: EditFeatureOptions) =>
            dispatch(selectFeatureForEditing(features, options)),
    };
};

export const ConnectedEditFeature = connect(mapStateToProps, mapDispatchToProps)(EditFeatureToolbar);

export const EditFeaturePlugin = {
    name: "EditFeaturePlugin",
    config: {
        component: ConnectedEditFeature,
        reducers: {
            editFeature: editFeatureReducer,
        },
        epics: {
            editGeometryEpic,
            setGeometryStyleEpic,
            finishGeometryEpic,
            geometryChangedEpic,
            viewGeometryEpic,
            insertFeatureEpic,
            finishVisualGeometryEpic,
        },
    },
};
export const register = () => registerPlugin(EditFeaturePlugin);
