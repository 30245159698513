import * as React from "react";

interface GlobalSpinnerProps {}

export const GlobalSpinner = (props: GlobalSpinnerProps) => {
    return (
        <div className={"wheelWrapper"}>
            <span>
                <div className={"_ms2_init_spinner _ms2_init_center"}>
                    <div />
                </div>
                <div className={"_ms2_init_text _ms2_init_center"} />
            </span>
        </div>
    );
};
