import Rx from "rxjs";
import { EpicFactory } from "../../core/core-redux/reduxTypes";
import { wmsLayerSelector } from "../../common/selector/layerSelector";
import { changeLayerProperties } from "../../../MapStore2/web/client/actions/layers";
import { GLOBE_3D_PROJECTION } from "../../common/constants/Projections";
import { CHANGE_MAP_CRS, ChangeMapCrs } from "../../common/mapstore/action";
import { Action } from "redux";
import { RootState } from "../../rootState";

export const hideWmsLayersEpic: EpicFactory<Action, RootState, Action> = (action$, store) =>
    action$
        .ofType<ChangeMapCrs>(CHANGE_MAP_CRS)
        .filter((a) => a.crs === GLOBE_3D_PROJECTION)
        .switchMap((action) => {
            const state = store.getState();
            const layers = wmsLayerSelector(state);

            return Rx.Observable.from(layers).map((layer: any) => {
                return changeLayerProperties(layer.id, { visibility: false });
            });
        });
