import { EditFeatureState } from "../editFeature.reducer";
import { IPartialReducer } from "../../../core/core-redux/reduxTypes";
import Logger from "../../../common/util/Logger";
import { mapFeatureToCollection } from "../../../core/geo/geometryMapper";
import { isGeometryCollection } from "../../../core/geo/assertions";

const logger = new Logger("updateFeaturePartialReducer");

export const updateFeaturePartialReducer: IPartialReducer<EditFeatureState> = (state, action) => {
    const feature = state.editedFeatures?.features.find((feat) => feat.id === action.featureUid);

    if (feature === undefined) return state;

    if (!isGeometryCollection(feature.geometry) && !isGeometryCollection(action.feature.geometry)) {
        logger.info(
            `updating feature ${feature.properties.featureId} coordinates from: ${feature.geometry.coordinates} to: ${action.feature.geometry.coordinates}`
        );
    } else {
        logger.info(
            `updating geometry collection ${feature.properties.featureId} coordinates from: ${feature.geometry} to: ${action.feature.geometry}`
        );
    }
    return {
        ...state,
        editedFeatures: mapFeatureToCollection(action.feature),
    };
};
