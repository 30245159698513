import { standardApiHandler } from "../../../core/core-api/apiTools";
import { featureLayersMappingAdapter } from "./featureLayersMapping.adapter";

export type FeatureLayersMappingServiceParams = {};
export type FeatureLayersMappingServiceResponse = {
    [key: string]: string[];
};

export type FeatureLayersMappingService = ({}) => Promise<FeatureLayersMappingServiceResponse>;
export const featureLayersMappingService: FeatureLayersMappingService = standardApiHandler(featureLayersMappingAdapter);
