import { useEffect, useRef } from "react";
import { Feature } from "../core/geo/types";
import { IFrameMessage, PortalMessageType } from "../libs/common/IFrameMessage";
import { portalDispatcher } from "./dispatcher";
import { PortalFeatureDescriptor } from "./redux/select-feature/selectFeatureFromPortal.action";
import { EPSG_3857 } from "../common/constants/Projections";
import Logger from "../common/util/Logger";
import { FeatureLayersMappingServiceResponse } from "../api/api-packages/feature-layers-mapping";
import { MapPosition } from "../common/types";
import { getFeaturesByParamsService } from "../api/api-packages/get-features/getFeaturesByParams.service";
import DataSetFilterItem from "../filter/DataSetFilter/dto/DataSetFilterItem";
import { Slots } from "../common/slot/slots";
import { Filter } from "../filter/types";

const logger = new Logger("PortalCommunicator");

export interface PortalCommunicatorStateProps {
    embeddedInPortal: boolean;
    featureLayersMapping?: FeatureLayersMappingServiceResponse;
    visibleLayerNames?: string[];
    envParams: string;
    ticket?: string;
    mapPresent?: {
        center: MapPosition;
        zoom: number;
        projection: string;
    };
    validFrom: string;
    slots?: Slots;
}

export interface PortalCommunicatorDispatchProps {
    onUpdateValidityInterval: (from: string, to: string) => void;
    onFeaturesSelectedFromPortal: (features: Feature[]) => void;
    onDeselectAllFeatures: () => void;
    onEditGeometry: (feature: Feature) => void;
    onViewGeometry: (feature: Feature) => void;
    onReloadMap: () => void;
    onCancelGeometryEdit: () => void;
    onApplyFeatureFilter: (filterId: string, validFrom: string, slots?: Slots) => Promise<Filter>;
    onApplyFeatureFilterAction: (item: DataSetFilterItem, filter: any) => void;
}

export const PortalCommunicator = (props: PortalCommunicatorStateProps & PortalCommunicatorDispatchProps) => {
    const {
        embeddedInPortal,
        featureLayersMapping,
        visibleLayerNames,
        envParams,
        ticket,
        mapPresent,
        onUpdateValidityInterval,
        onFeaturesSelectedFromPortal,
        onDeselectAllFeatures,
        onEditGeometry,
        onViewGeometry,
        onReloadMap,
        onCancelGeometryEdit,
        onApplyFeatureFilter,
        onApplyFeatureFilterAction,
        slots,
        validFrom,
    } = props;

    const featureLayersMappingRef = useRef(featureLayersMapping);
    const visibleLayerNamesRef = useRef(visibleLayerNames);
    const mapPresentRef = useRef(mapPresent);

    useEffect(() => {
        featureLayersMappingRef.current = featureLayersMapping;
    }, [featureLayersMapping]);

    useEffect(() => {
        visibleLayerNamesRef.current = visibleLayerNames;
    }, [visibleLayerNames]);

    useEffect(() => {
        mapPresentRef.current = mapPresent;
    }, [mapPresent]);

    const getWfsQueryOptionsForFeature = (feature: PortalFeatureDescriptor) => {
        //layers based on requested feature type
        const queryLayers = featureLayersMappingRef.current?.[feature.featureType];

        if (!queryLayers) {
            logger.warn("Feature: " + feature.featureType + " does not have layer defined in featureLayersMapping");
        }

        const layerNames = visibleLayerNamesRef.current?.filter((layer) => queryLayers?.includes(layer));

        if (layerNames && layerNames.length > 0) {
            return {
                featureId: feature.featureId,
                layers: layerNames,
                slotId: feature.slotId,
                featureType: feature.featureType,
            };
        } else {
            return undefined;
        }
    };

    useEffect(() => {
        if (!embeddedInPortal || envParams === "" || !ticket) return;

        const handleSelectFeatureFromPortal = async (data: { features: PortalFeatureDescriptor[] }) => {
            if (data.features.length === 0) {
                return onDeselectAllFeatures();
            }
            const wfsLayerOptionsToQuery = data.features.map((feature) => getWfsQueryOptionsForFeature(feature));

            if (wfsLayerOptionsToQuery.length === 0) {
                return;
            }

            const results = await getFeaturesByParamsService(
                wfsLayerOptionsToQuery.map((data) => {
                    return {
                        typeName: data?.layers,
                        featureId: data?.featureId,
                        srsName: EPSG_3857,
                        ENV: encodeURIComponent(envParams),
                        wizardSuiteProperties: true,
                        ticket: ticket,
                    };
                })
            );

            const features = results
                .map((features) => features.features)
                .reduce((total, current) => [...total, ...current]);
            onFeaturesSelectedFromPortal(features);
        };

        const handleApplyFilter = async (filterId: string) => {
            const result = await onApplyFeatureFilter(filterId, validFrom, slots);
			result.item.children.forEach((item: DataSetFilterItem) => (item.removable = true));
            onApplyFeatureFilterAction(result.item, result.condition);
            console.log(result);
        };

        const handler: { [key in PortalMessageType]: (data: any) => void } = {
            selectFeatureFromPortal: (data: { features: PortalFeatureDescriptor[] }) =>
                handleSelectFeatureFromPortal(data),
            validityIntervalChanged: (data: { from: string; to: string }) =>
                onUpdateValidityInterval(data.from, data.to),
            editGeometry: (data: { geoJsonFeature: Feature }) => onEditGeometry(data.geoJsonFeature),
            viewGeometry: (data: { geoJsonFeature: Feature }) => onViewGeometry(data.geoJsonFeature),
            applyFilter: (data: { filterId: string }) => handleApplyFilter(data.filterId),
            reloadMapFromPortal: onReloadMap,
            cancelGeometryEditFromPortal: onCancelGeometryEdit,
        };

        const messageHandler = (data: IFrameMessage<PortalMessageType>) => {
            console.log("GIS client Received message", data);
            handler[data.type]?.(data.data);
        };

        portalDispatcher.start(window, window.parent, messageHandler);
        return () => portalDispatcher.clear();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [embeddedInPortal, envParams, ticket]);

    return null;
};
