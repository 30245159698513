import { GISMessageType, IFrameMessage, PortalMessageType } from "../libs/common/IFrameMessage";
import { IFrameManager, IFrameProxy } from "../libs/common/IFrameManager";
import { GeometryChange } from "../editFeature/util/geometry/types";
import { PortalFeatureDescriptor } from "./redux/select-feature/selectFeatureFromPortal.action";

export interface IPortalDispatcher {
    start: (
        sourceFrame: Window,
        targetFrame: Window,
        onMessageCallback: (data: IFrameMessage<PortalMessageType>) => void
    ) => void;
    selectFeatures: (features: PortalFeatureDescriptor[]) => void;
    updateFeatureGeometry: (featureGeometryChange: GeometryChange) => void;
    finishVisualGeometryEditing: () => void;
}

export class PortalDispatcher implements IPortalDispatcher {
    private readonly iFrameProxy: IFrameProxy<PortalMessageType, GISMessageType>;

    constructor(iFrameProxy: IFrameProxy<PortalMessageType, GISMessageType>) {
        this.iFrameProxy = iFrameProxy;
    }

    start(
        sourceFrame: Window,
        targetFrame: Window,
        onMessageCallback: (data: IFrameMessage<PortalMessageType>) => void
    ) {
        this.iFrameProxy.init(sourceFrame, targetFrame, onMessageCallback);
    }

    selectFeatures(features: PortalFeatureDescriptor[]) {
        const message = {
            type: GISMessageType.SELECT_FEATURE_FROM_GIS,
            data: features,
        };
        this.iFrameProxy.send(message);
    }

    updateFeatureGeometry(featureGeometryChange: GeometryChange) {
        const message = {
            type: GISMessageType.UPDATE_FEATURE_GEOMETRY_FROM_GIS,
            data: featureGeometryChange,
        };
        this.iFrameProxy.send(message);
    }

    finishVisualGeometryEditing() {
        const message = {
            type: GISMessageType.FINISH_VISUAL_GEOMETRY_EDITING,
        };
        this.iFrameProxy.send(message);
    }

    clear() {
        this.iFrameProxy.clear();
    }
}

export const portalDispatcher = new PortalDispatcher(new IFrameManager<PortalMessageType, GISMessageType>());
