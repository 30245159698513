import { IPartialReducer } from "../../../../core/core-redux/reduxTypes";
import { FeatureDetailState } from "../types";

export const showSelectedFeaturePartialReducer: IPartialReducer<FeatureDetailState> = (state, action) => {
    if (state.features) {
        if (!action.feature || state.features.some((feature) => feature.id === action.feature.id)) {
            return {
                ...state,
                features: [...state.features.filter((feature) => action.feature && feature.id !== action.feature.id)],
            };
        }
        return {
            ...state,
            features: [...state.features, action.feature],
        };
    }
    return {
        ...state,
        features: [action.feature],
    };
};
