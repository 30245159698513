import React from "react";
import PropTypes from "prop-types";
import LayerItem from "../../layers/component/LayerItem";
import Item from "../../layers/dto/Item";

class LayerGroup extends React.Component {
    static propTypes = {
        id: PropTypes.string | PropTypes.number,
        className: PropTypes.string,
        children: PropTypes.array,
        expanded: PropTypes.bool,
        name: PropTypes.string,
        onExpand: PropTypes.func,
        visible: PropTypes.bool,
        onRemove: PropTypes.func,
        onRowClick: PropTypes.func,
        onHide: PropTypes.func,
        nesting: PropTypes.number,
        onAdd: PropTypes.func,
        removable: PropTypes.bool,
        onRefresh: PropTypes.func,
        item: PropTypes.instanceOf(Item),
    };

    render() {
        const nesting = 15 * this.props.nesting;
        const children = this.props.children.map((item) =>
            item.group ? (
                <LayerGroup
                    className={this.props.className}
                    children={item.children}
                    expanded={item.expanded}
                    nesting={this.props.nesting + 1}
                    onExpand={this.props.onExpand}
                    onRemove={this.props.onRemove}
                    onHide={this.props.onHide}
                    visible={item.visibility}
                    id={item.id}
                    key={`group-${item.id}`}
                    item={item}
                />
            ) : (
                <LayerItem
                    key={this.props.id + item.id}
                    className={this.props.className}
                    itemVisible={item.visibility}
                    onHide={this.props.onHide}
                    onRemove={this.props.onRemove}
                    onClick={this.props.onRowClick}
                    item={item}
                />
            )
        );

        return (
            <div id={"group"}>
                <LayerItem
                    className={`${this.props.className}`}
                    itemVisible={this.props.visible}
                    onHide={this.props.onHide}
                    onExpand={this.props.onExpand}
                    itemExpanded={this.props.expanded}
                    onAdd={this.props.onAdd}
                    onRemove={this.props.onRemove}
                    onClick={null}
                    onRefresh={this.props.onRefresh}
                    item={this.props.item}
                ></LayerItem>
                {this.props.expanded ? <div style={{ marginLeft: nesting }}>{children}</div> : null}
            </div>
        );
    }
}

export default LayerGroup;
