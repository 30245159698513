import { IPartialReducer } from "../../../core/core-redux/reduxTypes";
import { EditFeatureState } from "../editFeature.reducer";
import Logger from "../../../common/util/Logger";

const logger = new Logger("densifyGeometryPartialReducer");

export const densifyGeometryPartialReducer: IPartialReducer<EditFeatureState> = (state, action) => {
    const densifyGeometry = !state.options?.densifyGeometry ?? true;
    const mappedFeatures =
        state.editedFeatures?.features.map((feature) => {
            return {
                ...feature,
                properties: {
                    ...feature.properties,
                    densifyGeometry: densifyGeometry,
                },
            };
        }) ?? [];
    logger.info(`setting densify geometry mode to: ${densifyGeometry}`);
    return {
        ...state,
        editedFeatures: {
            type: "FeatureCollection",
            features: mappedFeatures,
        },
        options: {
            ...state.options,
            densifyGeometry: densifyGeometry,
        },
    };
};
