import { createReducer } from "../../core/core-redux/reducer/createReducer";
import { FeatureInfoState } from "./types";
import { CLEANUP_FEATURE_INFO } from "../feature-list-selection/redux/cleanup-feature-info/cleanupFeatureInfo.action";
import { cleanupFeatureInfoPartialReducer } from "../feature-list-selection/redux/cleanup-feature-info/cleanupFeatureInfo.reducer";
import { LOAD_FEATURE_INFO } from "../feature-list-selection/redux/load-feature-info/loadFeatureInfo.action";
import { loadFeatureInfoPartialReducer } from "../feature-list-selection/redux/load-feature-info/loadFeatureInfo.reducer";

export const featureInfosReducer = createReducer<FeatureInfoState>(
    {},
    {
        [CLEANUP_FEATURE_INFO]: cleanupFeatureInfoPartialReducer,
        [LOAD_FEATURE_INFO]: loadFeatureInfoPartialReducer,
    }
);
