import React, { ChangeEvent, useState } from "react";
import { MapPosition } from "../common/types";
import { checkLatitude, checkLongitude } from "../common/validators/coordValidator";
import { Tooltip } from "../core/core-widgets/tooltips";
import { BasicButton, GlyphButton } from "../core/core-widgets/buttons";
import { GlyphIcon } from "../core/core-widgets/icons";
import { ThemeColor } from "../core/core-widgets";
import { isNumeric } from "../common/validators/numberValidator";

export interface CenterMapDispatchProps {
    panTo: (newPosition: MapPosition) => void;
    showError: (title: string, message: string) => void;
    shouldRenderDot: (renderDot: boolean) => void;
}

export interface CenterMapStateProps {
    position?: MapPosition;
}

type CenterProps = CenterMapDispatchProps & CenterMapStateProps;

export const CenterMap = (props: CenterProps) => {
    const { position, panTo, showError, shouldRenderDot } = props;
    const [positionX, setPositionX] = useState<number | undefined>(undefined);
    const [positionY, setPositionY] = useState<number | undefined>(undefined);
    const [showInputs, setShowInputs] = useState<boolean>(false);

    React.useEffect(() => {
        setPositionX(parseFloat(position?.x.toPrecision(6)!));
        setPositionY(parseFloat(position?.y.toPrecision(6)!));
    }, [position?.x, position?.y]);

    const updatePosition = () => {
        const x = positionX!;
        const y = positionY!;
        if (!checkLatitude(x as any) || !checkLongitude(y as any)) {
            showError("Invalid coordinates", `Entered coordinates are not valid. [${x},${y}]`);
            return;
        }
        panTo({ x: x, y: y, crs: position!.crs });
    };

    const handlePositionX = (e: ChangeEvent<HTMLInputElement>) => {
        if (isNumeric(e.target.value)) {
            setPositionX(e.target.valueAsNumber);
        }
    };

    const handlePositionY = (e: ChangeEvent<HTMLInputElement>) => {
        if (isNumeric(e.target.value)) {
            setPositionY(e.target.valueAsNumber);
        }
    };

    const handleToggleButtonClick = () => {
        shouldRenderDot(!showInputs);
        setShowInputs(!showInputs);
    };

    return (
        <div>
            {showInputs && (
                <>
                    <input id={"inputPositionY"} type={"number"} value={positionY} onChange={handlePositionY} />
                    <input id={"inputPositionX"} type={"number"} value={positionX} onChange={handlePositionX} />
                    <BasicButton id={"panToButton"} className={"mx-3"} onClick={updatePosition}>
                        Go
                    </BasicButton>
                </>
            )}
            <Tooltip body={"Show/Hide Map Center Tool"}>
                <GlyphButton
                    style={{
                        width: "25px",
                        height: "25px",
                    }}
                    id={"toggleVisibilityCenterMapButton"}
                    color={ThemeColor.PRIMARY}
                    onClick={() => handleToggleButtonClick()}
                    glyph={GlyphIcon.CENTER_MAP}
                />
            </Tooltip>
        </div>
    );
};
