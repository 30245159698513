import assign from "object-assign";
import { initState } from "../../common/util/StateUtil";
import { UPDATE_VALID_FROM, VALIDITY_INTERVAL_CHANGE } from "../action/updateValidityIntervalAction";
import { formatAsDateTimeWithTimeZone } from "../../core/helpers/dateTime";

export function validityIntervalReducer(initialState: any, action: any) {
    const state = initState(initialState);
    if (action.type === VALIDITY_INTERVAL_CHANGE) {
        return assign(state, {
            validFrom: formatAsDateTimeWithTimeZone(action.fromDate),
            validTo: formatAsDateTimeWithTimeZone(action.toDate),
        });
    } else if (action.type === UPDATE_VALID_FROM) {
        return assign(state, {
            validFrom: formatAsDateTimeWithTimeZone(action.fromDate),
        });
    }
    return state;
}
