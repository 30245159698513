import { FeatureFilter, FilterCondition } from "../types";

type DbFeature = {
    properties: {
        PARENT_ID: number | undefined;
        FEATURE_ID: number;
    };
};

function getFeatureIdsToShow(filter: FeatureFilter, featuresToFilter: DbFeature[]) {
    let featuresToShow;
    if (filter.excludeIds.length > 0) {
        featuresToShow = featuresToFilter.filter((feature) => {
            const featureId = feature.properties.PARENT_ID ?? feature.properties.FEATURE_ID;
            return !filter.excludeIds.includes(featureId.toString());
        });
    } else {
        featuresToShow = featuresToFilter;
    }

    if (filter.includeIds.length > 0) {
        featuresToShow = featuresToShow.filter((feature) => {
            const featureId = feature.properties.PARENT_ID ?? feature.properties.FEATURE_ID;
            return filter.includeIds.includes(featureId.toString());
        });
    }
    return featuresToShow.map((feature) => feature.properties.FEATURE_ID);
}

function filterFeatures(filters: FeatureFilter[], featuresToFilter: DbFeature[]) {
    return filters.reduce(
        (total: number[], current) => [...total, ...getFeatureIdsToShow(current, featuresToFilter)],
        []
    );
}

export const extractFilterIdsForLayer = (
    layerName: string,
    featureShortName: string,
    features: DbFeature[],
    filters: FilterCondition[]
) => {
    if (!filters || filters.length <= 0) {
        return features.map((feature) => feature.properties.FEATURE_ID);
    }
    const enabledFilters = filters.filter((filter) => filter.enabled);
    if (enabledFilters.length <= 0) {
        return features.map((feature) => feature.properties.FEATURE_ID);
    }

    const isIncludeAllLayers = enabledFilters.some((filter) => filter.featureFilters.includeAllLayers);

    const layerFilters = enabledFilters
        .flatMap((filter) => filter.featureFilters.filterIds)
        .filter((filter) => filter.featureType === featureShortName && filter.enabled);

    if (layerFilters.length > 0) {
        const featuresToShow = filterFeatures(layerFilters, [...features]);
        return featuresToShow.length > 0 ? featuresToShow : null;
    }

    if (isIncludeAllLayers) {
        return features.map((feature) => feature.properties.FEATURE_ID);
    }

    return null;
};
