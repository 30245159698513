import { GeometryCoordinates, GeometryType } from "../../../core/geo/types";
import { pointRetriever } from "./pointRetriever";
import { GeometryChange } from "./types";
import { EditFeatureOptions } from "../../redux";
import { lineStringRetriever } from "./lineStringRetriever";
import { polygonRetriever } from "./polygonRetriever";
import { geometryCollectionRetriever } from "./geometryCollectionRetriever";

export interface GeometryRetriever {
    retrieveChange: (
        oldCoordinates: GeometryCoordinates | undefined,
        newCoordinates: GeometryCoordinates,
        featureOptions: EditFeatureOptions
    ) => GeometryChange;
}

export const geometryRetrievers: { [key in GeometryType]: GeometryRetriever | undefined } = {
    LineString: lineStringRetriever,
    Point: pointRetriever,
    Polygon: polygonRetriever,
    GeometryCollection: geometryCollectionRetriever,
    MultiLineString: undefined,
    MultiPoint: undefined,
    MultiPolygon: undefined,
};
