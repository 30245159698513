import React, { useCallback } from "react";
import LayerElement from "../../layers/component/LayerElement";
import DataSetFilterItem from "./dto/DataSetFilterItem";
import { Notification } from "../../common/mapstore/action";
import { Slots } from "../../common/slot/slots";
import { Filter } from "../types";

export interface DataSetFilterStateProps {
    enabled: boolean;
    allDataSetFilters: DataSetFilterItem[];
    treeItems: DataSetFilterItem[];
    readonly: boolean;
    validFrom: string;
    slots?: Slots;
    preloadFilters?: string[];
    preloadOnly: boolean;
    ticket?: string;
    showOnlyFirstLevel: boolean;
    axiosInitialized: boolean;
}

export interface DataSetFilterDispatchProps {
    onLoadDataSetFilterList: () => void;
    onAddDataSetFilter: (filter: Filter) => void;
    onRemoveDataSetFilter: (filterId: number) => void;
    onDataSetFilterVisibilityToggled: (filterId: number, visibility: boolean) => void;
    onRefreshDataSetFilters: () => void;
    showWarning: (notification: Notification) => void;
    onApplyFeatureFilter: (filterId: string, validFrom: string, slots?: Slots) => Promise<Filter>;
}

export const DataSetFilter = (props: DataSetFilterStateProps & DataSetFilterDispatchProps) => {
    const {
        enabled,
        allDataSetFilters,
        treeItems,
        readonly,
        onLoadDataSetFilterList,
        onAddDataSetFilter,
        onDataSetFilterVisibilityToggled,
        onRemoveDataSetFilter,
        onRefreshDataSetFilters,
        showWarning,
        onApplyFeatureFilter,
        validFrom,
        slots,
        preloadFilters,
        preloadOnly,
        ticket,
        showOnlyFirstLevel,
        axiosInitialized,
    } = props;

    const handleApplyFilter = useCallback(async (filterId: string, filter: Filter) => {
        const result = await onApplyFeatureFilter(filterId, validFrom, slots);
        if (result.item) {
            const filterItem = result.item;
            filterItem.group = !showOnlyFirstLevel;
            filter.item.addChild(filterItem);
            filter.condition = result.condition;
            filter.item.visibility = true;
            onAddDataSetFilter(filter);
            if (filterItem.warning) {
                showWarning({
                    title: "DataSet Filter '" + filterItem.name + "' Warning",
                    message: filterItem.warning,
                    autoDismiss: 10,
                    position: "tc",
                });
            }
        }
    }, []);

    React.useEffect(() => {
        if (axiosInitialized && preloadFilters) {
            preloadFilters.forEach((filter) => handleApplyFilter(filter, { item: treeItems[0] }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preloadFilters, ticket, axiosInitialized]);

    const handleOnAddDataSetFilter = useCallback(
        (filterId: string) => {
            const filter = allDataSetFilters.find((f) => f.id === filterId);
            if (filter) {
                handleApplyFilter(filterId, { item: new DataSetFilterItem(treeItems[0]) });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [allDataSetFilters, treeItems]
    );

    if (!enabled) {
        return null;
    }

    return (
        <div id={"dataSet"}>
            <LayerElement
                className={"dataSetFilters"}
                id={"dataSetFilters"}
                lookUpTitle={"DataSet Filters"}
                lookupFilterMessage={"Filter Data Set Filters"}
                treeItems={treeItems}
                lookUpItems={allDataSetFilters}
                onTreeItemAdd={preloadOnly ? undefined : onLoadDataSetFilterList}
                onLookupItemAdd={handleOnAddDataSetFilter}
                onTreeItemVisibilityChange={onDataSetFilterVisibilityToggled}
                onTreeItemRemove={onRemoveDataSetFilter}
                onRefresh={preloadOnly ? undefined : onRefreshDataSetFilters}
                readonly={readonly}
            />
        </div>
    );
};
