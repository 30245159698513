import { connect } from "react-redux";
import { RootState } from "../rootState";
import { dataSetFilterReducerFactory } from "../filter/DataSetFilter/redux/reducer/dataSetFilterReducer";
import { featureFilterReducer } from "../filter/FeatureFilter/redux/reducer/featureFilterReducer";
import { registerPlugin } from "../common/mapstore/registerPlugin";
import { layersContainerExpandedSelector } from "../common/selector/configurationSelector";
import { Layers, LayersDispatchProps, LayersStateProps } from "./Layers";
import { Dispatch } from "redux";
import { disabledBaselineLayersAction } from "./action/disableBaselineLayers";
import { changeGroupProperties } from "../common/mapstore/action/layers";
import { isCesium } from "../../MapStore2/web/client/selectors/maptype";

const mapStateToProps = (state: RootState): LayersStateProps => {
    return {
        expanded: layersContainerExpandedSelector(state),
        disableBaselineLayers:
            state.featureFilter.treeItems[0].children.length > 0 ||
            state.dataSetFilters.items.items[0].children.length > 0,
        isCesium: isCesium(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): LayersDispatchProps => {
    return {
        disableBaselineLayersAction: (disabled, isCesium) => {
            dispatch(disabledBaselineLayersAction(disabled));
            if (disabled) {
                dispatch(changeGroupProperties(isCesium ? "ALL_3D" : "ALL", { visibility: true }));
            }
        },
    };
};

export const ConnectedLayers = connect(mapStateToProps, mapDispatchToProps)(Layers);

export const LayersPlugin = {
    name: "LayersPlugin",
    config: {
        component: ConnectedLayers,
        reducers: {
            ...require("@js/geocalc/GeoCalc").default.reducers,
            ...require("@js/changes/Changes").default.reducers,
            dataSetFilters: dataSetFilterReducerFactory(),
            featureFilter: featureFilterReducer,
            singleWmsRequest: require("@js/layers/reducer/singleWmsRequestReducer").default,
        },
        epics: {
            ...require("@js/geocalc/GeoCalc").default.epics,
            ...require("@js/changes/Changes").default.epics,
        },
    },
};
export const register = () => registerPlugin(LayersPlugin);
