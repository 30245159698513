
const GlyphIcons = {
    EYE_OPEN: 'eye-open',
    EYE_CLOSE: 'eye-close',
    PLUS: 'plus',
    REMOVE: 'remove',
    SEARCH: 'search',
    GEO_CALCULATION: '1-measure-bearing',
    CLOSE: '1-close',
    FOLDER_OPEN: 'folder-open',
    FOLDER_CLOSE: 'folder-close',
    REFRESH: 'refresh',
    WARNING: 'warning-sign'
};

export default GlyphIcons;
