import Rx from "rxjs";
import { FeatureLayersMappingServiceResponse, gisServerMaxFeatureCountSelector } from "../../api/api-packages";
import { EpicFactory } from "../../core/core-redux/reduxTypes";
import { STORE_WFS_FEATURE_COUNT } from "../action/storeWfsFeatureCountAction";
import { BASELINE_LAYER_GROUP, WORKSPACE_LAYER_GROUP } from "../../common/selector/layerSelector";
import { wfsMaxFeatureCountSelector } from "../../common/selector/configurationSelector";
import EnvParamsRetriever from "../../common/util/EnvParamsRetriever";
import { invokeWfsService } from "../../common/util/web-service/gisServerServices";
import { storeWfs3dLayersAction } from "../action/storeWfs3dLayersAction";
import { doNothingAction } from "../../common/action/doNothingAction";
import Logger from "../../common/util/Logger";
import FilterSelector from "../../filter/FeatureFilter/redux/selector/FilterSelector";
import { LayersState } from "../../rootState";

const logger = new Logger("wfs3dLayersEpic");

const getLayerRequests = (
    layerName: any,
    featureShortName: string,
    layerGroup: string,
    featureCount: number,
    maxFeatureCount: number,
    environmentParams: any
) => {
    const layerRequests = [];
    for (let i = 0; i < featureCount; i += maxFeatureCount) {
        layerRequests.push({
            layerGroup: layerGroup,
            layerName: layerName,
            featureShortName: featureShortName,
            startIndex: i,
            count: maxFeatureCount,
            environmentParams: environmentParams,
        });
    }

    return layerRequests;
};

function getParentFeatureShortName(
    layers: LayersState,
    wfs3dLayerName: string,
    featureToLayersMapping: FeatureLayersMappingServiceResponse
) {
    const parentLayer = layers.flat.find((layer) => layer.wfs3dLayer && layer.wfs3dLayer.includes(wfs3dLayerName));
    return Object.entries(featureToLayersMapping).filter((value) => value[1].includes(parentLayer!.name))[0][0];
}

export const wfs3dLayersEpic: EpicFactory = (action$, store) =>
    action$.ofType(STORE_WFS_FEATURE_COUNT).switchMap(() => {
        const state = store.getState();
        const layersFeatureCount = state.wfs3dLayers.layersFeatureCount;

        const groupCount = Object.entries(layersFeatureCount).length;
        const baselineLayerCount = layersFeatureCount[BASELINE_LAYER_GROUP];
        const workspaceLayerCount = layersFeatureCount[WORKSPACE_LAYER_GROUP];

        if (
            layersFeatureCount[BASELINE_LAYER_GROUP] &&
            (groupCount === 1 || layersFeatureCount[WORKSPACE_LAYER_GROUP]) &&
            Object.entries(baselineLayerCount).length === state.wfs3dLayers.layerCount[BASELINE_LAYER_GROUP] &&
            (groupCount === 1 ||
                Object.entries(workspaceLayerCount).length === state.wfs3dLayers.layerCount[WORKSPACE_LAYER_GROUP])
        ) {
            const layers: any[] = [];
            const maxFeatureCount = Math.min(
                gisServerMaxFeatureCountSelector(state) as unknown as number,
                wfsMaxFeatureCountSelector(state)
            );

            const featureToLayersMapping = state.featureLayersMapping?.receivedData;

            for (const [key, value] of Object.entries(baselineLayerCount)) {
                const environmentParams = EnvParamsRetriever.getBaselineEnvParams(state);
                const layerRequests = getLayerRequests(
                    key,
                    getParentFeatureShortName(state.layers, key, featureToLayersMapping),
                    BASELINE_LAYER_GROUP,
                    (value as any).featureCount,
                    maxFeatureCount,
                    environmentParams
                );
                layerRequests.forEach((layerRequest) => layers.push(layerRequest));
            }

            if (workspaceLayerCount) {
                for (const [key, value] of Object.entries(layersFeatureCount[WORKSPACE_LAYER_GROUP])) {
                    const layerRequests = getLayerRequests(
                        key,
                        getParentFeatureShortName(state.layers, key, featureToLayersMapping),
                        WORKSPACE_LAYER_GROUP,
                        (value as any).featureCount,
                        maxFeatureCount,
                        EnvParamsRetriever.getWorkspaceEnvParams(state)
                    );
                    layerRequests.forEach((layerRequest) => layers.push(layerRequest));
                }
            }

            return Rx.Observable.from(layers).mergeMap((layer) => {
                const params = {
                    typeName: layer.layerName,
                    ENV: layer.environmentParams.toRequestParams(),
                    startIndex: layer.startIndex,
                    count: layer.count,
                };

                return invokeWfsService(state, params)
                    .map((response: any) => {
                        const features = {
                            type: "FeatureCollection",
                            features: response.data.features,
                        };
                        return storeWfs3dLayersAction(
                            features,
                            layer.layerGroup,
                            layer.layerName,
                            layer.featureShortName,
                            FilterSelector.getDataSetFilters(state)
                        );
                    })
                    .catch((e: any) => {
                        logger.error("Error retrieving wfs feature from GIS Server.", e);
                        return [doNothingAction()];
                    });
            });
        }
        return [doNothingAction()];
    });
