import * as React from "react";
import { GlyphButton, GlyphButtonProps } from "./GlyphButton";
import { ButtonColor } from "./types";

interface SelectableButtonProps {
    isSelected?: boolean;
}

export const SelectableButton = (props: SelectableButtonProps & GlyphButtonProps) => {
    const { glyph, isSelected = false, className, ...rest } = props;
    const btnClassName = `${className} ${isSelected ? "selectedButton" : ""}`;
    return <GlyphButton color={ButtonColor.PRIMARY} className={btnClassName} {...rest} glyph={glyph} />;
};
