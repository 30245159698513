import { Action } from "redux";

export const ADJUST_HEIGHT = "ADJUST_HEIGHT";

export interface AdjustHeightAction extends Action {
    adjustedHeight: number;
}

export const adjustHeightAction = (height: number): AdjustHeightAction => {
    return {
        type: ADJUST_HEIGHT,
        adjustedHeight: height,
    };
};
