import { IPartialReducer } from "../../../core/core-redux/reduxTypes";
import { EditFeatureState } from "../editFeature.reducer";
import { EditMode } from "../../types";
import { EditAction } from "../types";

export const finishGeometryPartialReducer: IPartialReducer<EditFeatureState> = (state, action) => {
    return {
        ...state,
        editMode: EditMode.NONE,
        editedFeatures: undefined,
        geometryType: undefined,
        options: { action: EditAction.NONE, editPaused: false },
    };
};
