const assign = require("object-assign");
const { CHANGE_TYPE_CHANGED, SET_CHANGE_TYPES } = require("@js/changes/action/updateChangeTypeAction");
const Logger = require("@js/common/util/Logger");

const logger = new Logger("updateChangeTypeReducer");

function updateChangeTypeReducer(state = {}, action = {}) {
    switch (action.type) {
        case CHANGE_TYPE_CHANGED: {
            let newChangeType = [...state.changeType];

            if (action.value) {
                if (!newChangeType.includes(action.changeType)) {
                    newChangeType.push(action.changeType);
                }
            } else {
                if (newChangeType.includes(action.changeType)) {
                    newChangeType = newChangeType.filter((value) => value !== action.changeType);
                }
            }

            logger.info("Change Type changed to: " + newChangeType);

            return assign({}, state, {
                changeType: newChangeType,
            });
        }
        case SET_CHANGE_TYPES: {
            return assign({}, state, {
                changeType: action.changeTypes,
            });
        }
        default:
            return state;
    }
}

module.exports = updateChangeTypeReducer;
