import createReducer from '@js/common/util/Reducers';
import updateObject from '@js/common/util/UpdateObject';
import {GEOCALC_LIST_LOADED} from '@js/geocalc/action/geoCalcListLoadedAction';
import {GEOCALC_GEOMETRY_LOADED} from '@js/geocalc/action/geoCalcGeometryLoadedAction';
import {GEOCALC_GEOMETRY_REMOVED} from '@js/geocalc/action/geoCalcGeometryRemovedAction';
import {GEOCALCULATION_VISIBILITY_TOGGLED} from '@js/geocalc/action/geoCalculationVisibiltyToggledAction';
import {createGeoCalculationCollection} from '@js/geocalc/util/FeatureCollection';
import uuid from "uuid";
import TreeItemFinder from '@js/layers/util/TreeItemFinder';
import Logger from '@js/common/util/Logger';
import TreeItemVisibilityHandler from "@js/layers/util/TreeItemVisibilityHandler";
import Item from "@js/layers/dto/Item";
import GeoCalculationItem from "@js/geocalc/dto/GeoCalculationItem";

const logger = new Logger("geoCalcListReducer");

const loadGeoCalculations = (state, action) => {
    return updateObject(state,
        {
            geoCalculations: action.data,
            allCalculations: action.data
        }
    );
};

const loadVisualisedGeoCalculation = (state, action) => {
    const treeItemsVisualisedBefore = state.items ? state.items[0].children : [];

    action.geoCalculations.forEach(geoCalculation => geoCalculation.visibility = true);
    const treeItems = [...treeItemsVisualisedBefore, ...action.geoCalculations];

    const rootItem = new GeoCalculationItem(new Item("geoCalculationRoot", "Geo Calculations", action.geoCalculations && action.geoCalculations.length > 0, true, true, false, treeItems));

    const geoCalculations = [rootItem];

    logger.info(`loading geoCalculation '${action.geoCalculations.map(calculation => calculation.id)} geometry`);
    const previousFeatures = state.featureLayer ? state.featureLayer.features[0].features : [];
    const previousTextFeatures = state.textLayer ? state.textLayer.features[0].features : [];

    const allFeatures = createGeoCalculationCollection(previousFeatures, action.geoCalculations, action.style.feature, "feature");
    const allLabels = createGeoCalculationCollection(previousTextFeatures, action.geoCalculations, action.style.label, "text");

    return updateObject(state,
        {
            items: geoCalculations,
            featureLayer: allFeatures,
            textLayer: allLabels
        }
    );
};

const removeGeoCalculation = (state, action) => {
    logger.info(`removing geoCalculation: ${action.geoCalculation}`);
    const treeItemsVisualisedBefore = state.items ? state.items : [];
    treeItemsVisualisedBefore[0].children = treeItemsVisualisedBefore[0].children.filter(calc => calc.id !== action.geoCalculation);

    logger.info(`removing geoCalculation '${action.geoCalculation}' geometry`);
    const featureLayer = state.featureLayer;
    const textLayer = state.textLayer;

    featureLayer.features[0].features = featureLayer.features[0].features.filter(feat => feat.calculationId !== action.geoCalculation);
    textLayer.features[0].features = textLayer.features[0].features.filter(feat => feat.calculationId !== action.geoCalculation);

    return updateObject(state, {
        featureLayer: featureLayer,
        textLayer: textLayer,
        items: [...treeItemsVisualisedBefore]
    });
};

const setGeoCalculationVisibility = (state, action) => {

    logger.info(`toggling geoCalculation '${action.geoCalculationId}' geometry visibility to '${action.visibility}`);

    const items = [...state.items];
    const item = TreeItemFinder.findItem(action.geoCalculationId, items);

    TreeItemVisibilityHandler.setTreeItemVisibility(item, action.visibility);

    const affectedItems = TreeItemFinder.getItemAndItsChildren(item);
    const ids = affectedItems.map(i=> i.id);
    const featureLayer = state.featureLayer;
    const textLayer = state.textLayer;

    featureLayer.features[0].features
        .filter(feat => ids.includes(feat.calculationId))
        .forEach(feature => feature.properties.style.hidden = !action.visibility);

    featureLayer.features[0].id = uuid();

    textLayer.features[0].features
        .filter(text => ids.includes(text.calculationId))
        .forEach(text => text.properties.style.hidden = !action.visibility);

    textLayer.features[0].id = uuid();

    return updateObject(state, {items: items,
        featureLayer: featureLayer,
        textLayer: textLayer});

};

const geoCalcListReducer = createReducer({
    [GEOCALC_LIST_LOADED]: loadGeoCalculations,
    [GEOCALC_GEOMETRY_LOADED]: loadVisualisedGeoCalculation,
    [GEOCALC_GEOMETRY_REMOVED]: removeGeoCalculation,
    [GEOCALCULATION_VISIBILITY_TOGGLED]: setGeoCalculationVisibility,
});

export default geoCalcListReducer;
