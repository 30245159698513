import { IPartialReducer } from "../../../core/core-redux/reduxTypes";
import { SelectedFeaturesFromPortalState } from "../types";

export const deselectFeatureFromPortalPartialReducer: IPartialReducer<SelectedFeaturesFromPortalState> = (
    state,
    action
) => {
    return {
        features: [],
    };
};
