import { Action, Dispatch, Reducer } from "redux";
import { Epic } from "redux-observable";

export interface AnyAction extends Action {
    [extraProps: string]: any;
}

// TODO ICH: make action generic as well, after bumping redux version
export type IPartialReducer<S> = (state: S, action: AnyAction) => Partial<S>;

export type ReducerFactory<TState> = () => Reducer<TState>;

export type EpicFactory<
    Input extends Action = any,
    State = any,
    Output extends Input = Input,
    Dependencies = any
> = Epic<Input, State, Dependencies, Output>;

export type AsyncUnaryFunction<REQ, RESP> = (input: REQ) => Promise<RESP>;

/** action creator that throws an error */
export type AsyncDataPromiseActionCreator<REQ, RESP = any> = (
    requestParams: REQ
) => (dispatch: Dispatch<any>) => Promise<RESP>;

export type AsyncDataAwaitableActionCreator<REQ, RESP = any, ERROR = any> = (
    requestParams: REQ
) => (dispatch: Dispatch<any>, getState: () => any) => Promise<RESP | ERROR>;

export enum AsyncState {
    INITIAL = "INITIAL",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
    ERROR = "ERROR",
}

export interface IAsyncDataAction<RESP, ERROR, PARAMS = {}> extends Action {
    response?: RESP;
    error?: ERROR;
    parameters?: PARAMS;
    status?: number;
}

export interface IAsyncDataState<DATA, ERROR = any, PARAMS = {}> {
    state: AsyncState;
    receivedData?: DATA;
    error?: ERROR;
    parameters?: PARAMS;
    status?: number;
}
