const RELOAD_CHANGES = "RELOAD_CHANGES";
const SET_CHANGES_GENERATED = "SET_CHANGES_GENERATED";
const HIDE_CHANGES_LOADER = "HIDE_CHANGES_LOADER";

function reloadChangesAction() {
    return {
        type: RELOAD_CHANGES,
    };
}

function setChangesGeneratedDateAction(date) {
    return {
        type: SET_CHANGES_GENERATED,
        date: date,
    };
}

function hideChangesLoaderAction() {
    return {
        type: HIDE_CHANGES_LOADER,
    };
}

module.exports = {
    RELOAD_CHANGES,
    reloadChangesAction,
    SET_CHANGES_GENERATED,
    setChangesGeneratedDateAction,
    HIDE_CHANGES_LOADER,
    hideChangesLoaderAction,
};
