import React from "react";
import moment from "moment-timezone";
import AeroDatePicker from "@js/changes/component/header/child/AeroDatePicker";
import { FormattedMessage } from "react-intl";

export const EffectiveDatePicker = (properties) => {
    return (
        <>
            <div>
                <FormattedMessage id={"workspaceChanges.effectiveDateLabel"} /> <span className="utcWarn">(UTC)</span>
            </div>
            <div>
                <AeroDatePicker
                    className="datePicker"
                    id="effectiveDatePicker"
                    timezone="UTC"
                    selectedDate={moment(properties.effectiveDate).toDate()}
                    dateFormat="yyyy-MM-dd"
                    disabled={properties.disabled}
                    onDateChange={(value) => properties.onDateChange(value)}
                    highlightAiracDates={true}
                />
            </div>
        </>
    );
};

