import { parseAirspace } from "./AirspaceParser";
import { parseRoute } from "./RouteParser";
import { parseVerticalStructure } from "./VerticalStructureParser";

export default class EntityParserFactory {
    static parsers: { [key in string]: (entity: any) => void } = {
        AIRSPACE_VOLUME: parseAirspace,
        RTE_SEG: parseRoute,
        OBSTACLE_PART: parseVerticalStructure,
    };

    static parseEntity(entity: any) {
        const parser: any = this.parsers[entity.name](entity);
        if (parser) {
            return parser;
        }
        throw new Error(`parser not defined for entity: ${entity.name}`);
    }
}
