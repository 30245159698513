import { connect } from "react-redux";
import { RootState } from "../../rootState";
import { featureInfoCountSelector } from "../../common/selector/configurationSelector";
import { Action, Dispatch } from "redux";
import { showSelectedFeatureAction } from "../feature-detail-frame/redux/show-selected-feature/showSelectedFeature.action";
import { registerPlugin } from "../../common/mapstore/registerPlugin";
import {
    FeatureListSelectionDispatchProps,
    FeatureListSelectionStateProps,
    FeatureListModal,
} from "./FeatureListModal";
import { featureInfoEpic } from "../redux/feature-info/featureInfo.epic";
import { clickedPointReducer } from "../redux/clicked-point/clickedPoint.reducer";
import { featureInfosReducer } from "../redux/featureInfos.reducer";
import { cleanupFeatureInfoAction } from "./redux/cleanup-feature-info/cleanupFeatureInfo.action";
import { loadAllFeaturesInfoReducer } from "../redux/feature-info/featureInfo.reducer";

const mapStateToProps = (state: RootState): FeatureListSelectionStateProps => {
    const maxFeatureCount: number = featureInfoCountSelector(state);
    return {
        features: state.featureInfos.features?.slice(0, maxFeatureCount),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): FeatureListSelectionDispatchProps => {
    return {
        onSelect: (feature, selectedFromPortal) => dispatch(showSelectedFeatureAction(feature, selectedFromPortal)),
        cleanup: () => dispatch(cleanupFeatureInfoAction()),
    };
};

export const ConnectedFeatureListSelectionModal = connect(mapStateToProps, mapDispatchToProps)(FeatureListModal);

export const FeatureListSelectionPlugin = {
    name: "FeatureListSelectionPlugin",
    config: {
        component: ConnectedFeatureListSelectionModal,
        reducers: {
            clickedPoint: clickedPointReducer,
            featureInfos: featureInfosReducer,
            featureInfoApiState: loadAllFeaturesInfoReducer,
        },
        epics: {
            featureInfoEpic,
        },
    },
};

export const register = () => registerPlugin(FeatureListSelectionPlugin);
