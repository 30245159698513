import * as React from "react";

import { useGlobalSpinnerContext } from "./GlobalSpinnerContext";

export function GlobalSpinnerIndicator() {
    const ctx = useGlobalSpinnerContext();
    React.useEffect(
        () => {
            ctx.start();
            return ctx.stop;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    return null;
}
