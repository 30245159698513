export const standardApiHandler = <Req, Resp>(api: (req: Req) => Promise<Resp>) => {
    return async (req: Req) => {
        try {
            return await api(req);
        } catch (error) {
            console.error("standardApiHandler", JSON.stringify(error));
            throw error;
        }
    };
};
