import { asyncFetchDataPromiseActionFactory } from "../../../core/core-redux/actions/asyncFetchDataAction";
import { featureInfoService, FeatureInfoServiceParams, FeatureInfoServiceResponse } from "./featureInfo.service";

export const GET_FEATURE_INFO = "GET_FEATURE_INFO";

export const getFeatureInfoActionCreator = asyncFetchDataPromiseActionFactory<
    FeatureInfoServiceParams,
    FeatureInfoServiceResponse,
    any
>(GET_FEATURE_INFO, featureInfoService);
