import { createReducer } from "../../core/core-redux/reducer/createReducer";
import { RENDER_DOT_ACTION } from "./centerMap.action";
import { IPartialReducer } from "../../core/core-redux/reduxTypes";

interface CenterMapState {
    renderDot: boolean;
}

const centerMapPartialReducer: IPartialReducer<CenterMapState> = (state, action) => {
    return {
        renderDot: action.renderDot,
    };
};

export const centerMapReducer = createReducer<CenterMapState>(
    {
        renderDot: false,
    },
    { [RENDER_DOT_ACTION]: centerMapPartialReducer }
);
