const UPDATE_CONTEXT = "UPDATE_CONTEXT";

function updateContextAction(context) {
    return {
        type: UPDATE_CONTEXT,
        context: context,
    };
}

module.exports = {
    UPDATE_CONTEXT,
    updateContextAction,
};
