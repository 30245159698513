import { createReducer } from "../../../../core/core-redux/reducer/createReducer";
import { DRAW_LAYERS_ACTION } from "./drawLayers/drawLayers.action";
import { drawLayersPartialReducer } from "./drawLayers/drawLayers.reducer";
import { storeGeometryPartialReducer } from "./storeGeometry/storeGeometry.reducer";
import { STORE_GEOMETRY_ACTION } from "./storeGeometry/storeGeometry.action";
import { Feature, FeatureStyle } from "../../../../core/geo/types";
import { Projection } from "../../../../common/types";
import { Point } from "../../../../rootState";

export interface Layer {
    type: string;
    style: FeatureStyle;
    projection: Projection;
    featuresCrs?: string;
    features?: Feature[];
}

export interface DrawLayersState {
    enabled: boolean;
    points?: Point[];
    layers?: Layer[];
}

export const drawLayersReducer = createReducer<DrawLayersState>(
    {
        enabled: false,
        points: undefined,
        layers: undefined,
    },
    {
        [DRAW_LAYERS_ACTION]: drawLayersPartialReducer,
        [STORE_GEOMETRY_ACTION]: storeGeometryPartialReducer,
    }
);
