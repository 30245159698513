import { Action } from "redux";

export const SET_WFS_3D_LAYERS_VISIBILITY = "SET_WFS_3D_LAYERS_VISIBILITY";

export interface SetWfs3dLayersVisibilityAction extends Action {
    visibleLayers: any;
    layerGroup: any;
}

export const setWfs3dLayersVisibilityAction = (visibleLayers: any, layerGroup: any): SetWfs3dLayersVisibilityAction => {
    return {
        type: SET_WFS_3D_LAYERS_VISIBILITY,
        visibleLayers: visibleLayers,
        layerGroup: layerGroup,
    };
};
