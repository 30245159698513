import { IPartialReducer } from "../../../../core/core-redux/reduxTypes";
import { Feature } from "../../../../core/geo/types";
import { FeatureDetailState } from "../types";

export const deselectFeaturesPartialReducer: IPartialReducer<FeatureDetailState> = (state, action) => {
    const deselectFeatureIds = action.features.map((feature: Feature) => feature.id);
    const features = state.features?.filter((feature: Feature) => !deselectFeatureIds.includes(feature.id));
    return {
        ...state,
        features: features,
    };
};
