import * as React from "react";
import { DockPanel } from "../core/core-widgets/panels/DockPanel";
import { SelectFeature, SelectFeatureDispatchProps } from "./components/SelectFeature";
import { ChangeValidityInterval, ChangeValidityIntervalProps } from "./components/ChangeValidityInterval";
import { DrawingTool, DrawingToolDispatchProps } from "./components/Drawing/DrawingTool";
import { FeatureActions, FeatureActionsDispatchProps } from "./components/FeatureActions";
import { FeatureFilter, FeatureFilterDispatchProps } from "./components/FeatureFilter";
import { OtherActions, OtherActionsDispatchProps } from "./components/OtherActions";
import { IntlContextProvider } from "../core/core-widgets/contexts/IntlContext";
import { Point } from "../rootState";
import { ContentContainer } from "../core/core-widgets/surfaces/ContentContainer/ContentContainer";

export interface DevelopmentToolsStateProps {
    enabled: boolean;
    currentZoomLevel: number;
    drawingToolEnabled: boolean;
    points?: Point[];
}

export type DevelopmentToolsDispatchProps = SelectFeatureDispatchProps &
    ChangeValidityIntervalProps &
    DrawingToolDispatchProps &
    FeatureActionsDispatchProps &
    FeatureFilterDispatchProps &
    OtherActionsDispatchProps;

export const DevelopmentTools = (props: DevelopmentToolsStateProps & DevelopmentToolsDispatchProps) => {
    const {
        enabled,
        currentZoomLevel,
        drawingToolEnabled,
        points,
        onFeaturesSelected,
        onChangeValidityInterval,
        onDrawModeChange,
        onEditFeature,
        onViewFeature,
        onFilterChange,
        onReloadMap,
    } = props;

    if (!enabled) return null;

    return (
        <IntlContextProvider>
            <DockPanel title={"Development Tools"} open position={"right"} size={400} dockStyle={{ height: "96.85%" }}>
                <ContentContainer>
                    <SelectFeature onFeaturesSelected={onFeaturesSelected} />
                    <ChangeValidityInterval onChangeValidityInterval={onChangeValidityInterval} />
                    <DrawingTool enabled={drawingToolEnabled} points={points} onDrawModeChange={onDrawModeChange} />
                    <FeatureActions onEditFeature={onEditFeature} onViewFeature={onViewFeature} />
                    <FeatureFilter onFilterChange={onFilterChange} />
                    <OtherActions currentZoomLevel={currentZoomLevel} onReloadMap={onReloadMap} />
                </ContentContainer>
            </DockPanel>
        </IntlContextProvider>
    );
};
