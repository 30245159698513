import { AnyAction, IPartialReducer } from "../../../core/core-redux/reduxTypes";
import { LayersPropertiesState } from "../reducer/layerProperties.reducer";

export const cqlFilterPartialReducer: IPartialReducer<LayersPropertiesState> = (
    state: LayersPropertiesState,
    action: AnyAction
) => {
    return {
        cqlFilter: action.cqlFilter,
    };
};
