import { applyHighlightLayersAction } from "../action/applyHighlightLayersAction";
import { RELOAD_MAP } from "../action/reloadMapAction";
import CqlFilterUtil from "../util/CqlFilterUtil";
import ReloadLayerUtil from "../util/ReloadLayerUtil";
import EnvParamsRetriever from "../../common/util/EnvParamsRetriever";
import { gisServerUrlSelector } from "../../common/selector/configurationSelector";
import { SHOW_SELECTED_FEATURE } from "../../feature/feature-detail-frame/redux/show-selected-feature/showSelectedFeature.action";
import { SELECT_FEATURE_FROM_PORTAL } from "../../portal/redux/select-feature/selectFeatureFromPortal.action";
import { DESELECT_FEATURES } from "../../feature/feature-detail-frame/redux/deselect-features/deselectFeatures.action";
import { DESELECT_ALL_FEATURES } from "../../feature/feature-detail-frame/redux/deselect-all-features/deselectAllFeatures.action";
import { RootState } from "../../rootState";
import { Feature } from "../../core/geo/types";
import { DESELECT_FEATURE_FROM_PORTAL } from "../../portal/redux/deselect-feature/deselectFeatureFromPortal.action";

const prepareHighlightLayer = (selectedFeatures: any, envParams: any, geoserverUrl: any, forceReload: any) => {
    envParams.requestedChanges = "";
    const highlightLayer = {
        name: selectedFeatures.map((feature: any) => feature.properties.layer).join(),
        params: {
            ENV: envParams.toRequestParams() + ";HIGHLIGHT:true",
        },
        type: "wms",
        url: geoserverUrl,
        visibility: true,
        singleTile: true,
        format: "image/png",
    };
    const featureIds = selectedFeatures.map((feature: any) => feature.properties.featureId);
    const cqlFilter = `FEATURE_ID IN (${featureIds.join()})`;
    CqlFilterUtil.applyCqlFilterToLayer(highlightLayer, cqlFilter);
    if (forceReload) {
        ReloadLayerUtil.forceReloadLayer(highlightLayer);
    }
    return highlightLayer;
};

function getSelectedFeatures(state: RootState): Feature[] {
    if (state.selectedFeatures?.features && state.selectedFeatures.features.length > 0) {
        return state.selectedFeatures.features;
    }

    if (state.selectedFeaturesFromPortal?.features && state.selectedFeaturesFromPortal.features.length > 0) {
        return state.selectedFeaturesFromPortal.features;
    }

    return [];
}

const highlightLayerEpic = (action$: any, store: any) =>
    action$
        .ofType(
            SHOW_SELECTED_FEATURE,
            SELECT_FEATURE_FROM_PORTAL,
            DESELECT_FEATURES,
            DESELECT_ALL_FEATURES,
            RELOAD_MAP,
            DESELECT_FEATURE_FROM_PORTAL
        )
        .map((action: any) => {
            const state: RootState = store.getState();
            const selectedFeatures = getSelectedFeatures(state);

            if (selectedFeatures.length === 0) {
                return applyHighlightLayersAction(undefined, undefined);
            }
            const selectedFeaturesInWorkspace = selectedFeatures.filter(
                (feature: any) => feature.properties.slotId > 0 && feature.properties.featureId
            );
            const baselineSelectedFeatures = selectedFeatures.filter(
                (feature: any) => feature.properties.slotId === 0 && feature.properties.featureId
            );
            const forceReload = action.type === RELOAD_MAP;
            let workspaceHighlightLayer;
            if (selectedFeaturesInWorkspace.length > 0) {
                workspaceHighlightLayer = prepareHighlightLayer(
                    selectedFeaturesInWorkspace,
                    EnvParamsRetriever.getWorkspaceEnvParams(state),
                    `${gisServerUrlSelector(state)}/wms`,
                    forceReload
                );
            }
            let baselineHighlightLayer;
            if (baselineSelectedFeatures.length > 0) {
                const environmentParams = EnvParamsRetriever.getBaselineEnvParams(state);
                environmentParams.slots = [];
                baselineHighlightLayer = prepareHighlightLayer(
                    baselineSelectedFeatures,
                    environmentParams,
                    `${gisServerUrlSelector(state)}/wms`,
                    forceReload
                );
            }

            return applyHighlightLayersAction(baselineHighlightLayer, workspaceHighlightLayer);
        });

export default highlightLayerEpic;
