import { registerPlugin } from "../common/mapstore/registerPlugin";
import { connect } from "react-redux";
import { RootState } from "../rootState";
import { Spinner } from "./Spinner";
import { AsyncState } from "../core/core-redux/reduxTypes";

const mapStateToProps = (state: RootState) => {
    return {
        show: state.featureInfoApiState?.state === AsyncState.IN_PROGRESS || state.wfs3dLayers.cesiumState === "BUSY",
    };
};

export const ConnectedSpinner = connect(mapStateToProps)(Spinner);

export const SpinnerPlugin = {
    name: "SpinnerPlugin",
    config: {
        component: ConnectedSpinner,
    },
};
export const register = () => registerPlugin(SpinnerPlugin);
