import { asyncReduxActionsFactory } from "../../core/core-redux/actions/asyncFetchDataAction";
import {
    getFeatureCountService,
    GetFeatureCountServiceResponse,
    GetFeatureCountServiceParams,
} from "./getFeatureCount.service";

export const GET_FEATURE_COUNT = "GET_FEATURE_COUNT";

export const getFeatureCountActions = asyncReduxActionsFactory<
    GetFeatureCountServiceParams,
    GetFeatureCountServiceResponse,
    any
>(GET_FEATURE_COUNT, getFeatureCountService);
