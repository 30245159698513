export class Slots {
    readonly ids: string[];
    readonly name: string;
    readonly allSlots: boolean;

    constructor(ids: string[], name: string, allSlots: boolean) {
        this.ids = ids;
        this.name = name;
        this.allSlots = allSlots;
    }

    haveSlotIds = () => {
        return (this.ids && this.ids.length > 0) || this.allSlots;
    };

    getSlotIds = () => {
        return this.allSlots ? "ALL" : this.ids;
    };

    getSlotName = () => {
        return this.name;
    };
}
