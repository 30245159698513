import { Feature } from "../../../core/geo/types";
import { formatAsDateTimeWithMiliSeconds } from "../../../core/helpers/dateTime";

interface WizardPortalOptions {
    p_p_id: string;
    ticket?: string;
    effectiveDate: string;
    "wf.crudDetailRecordType"?: string;
    "wf.crudDetailRecordId"?: number;
    slotId?: number;
    page: string;
    hideMenu: boolean;
}

export function getFrameUrl(
    wizardPortalUrl: string,
    effectiveDate: string,
    ticket: string | undefined,
    feature: Feature
) {
    const wizardPortalOptions: WizardPortalOptions = {
        p_p_id: "ShowPage_WAR_WizardPortal",
        ticket: ticket,
        effectiveDate: formatAsDateTimeWithMiliSeconds(effectiveDate)!,
        "wf.crudDetailRecordType": feature.properties.featureForm,
        "wf.crudDetailRecordId": feature.properties.featureId,
        slotId: feature.properties.slotId,
        page: "webSDO_manageFeature_view",
        hideMenu: true,
    };

    const queryString = Object.keys(wizardPortalOptions)
        .map((key) => key + "=" + wizardPortalOptions[key as keyof WizardPortalOptions])
        .join("&");

    return `${wizardPortalUrl}/WizardPortal/wpf/portlet/applicationTop.xhtml?${queryString}`;
}
