import { dataSetFilterSelector } from "../../common/selector/configurationSelector";
import { connect } from "react-redux";
import { addDataSetFilterToTreeViewAction } from "./redux/add-dataset-filter/addDataSetFilterToTreeView.action";
import { toggleDataSetFilterVisibilityAction } from "./redux/toggle-dataset-filter-visibility/toggleDataSetFilterVisibility.action";
import { removeDataSetFilterFromTreeViewAction } from "./redux/remove-dataset-filter/removeDataSetFilterFromTreeView.action";
import { RootState } from "../../rootState";
import { DataSetFilter, DataSetFilterDispatchProps, DataSetFilterStateProps } from "./DataSetFilter";
import { getDatasetFiltersActionCreator } from "../../api/api-packages/getDataSetFilters";
import { showWarning } from "../../common/mapstore/action";
import { getDatasetFilterByIdActionCreator } from "../../api/api-packages/getDataSetFilterById";
import FilterSelector from "../FeatureFilter/redux/selector/FilterSelector";
import { validFromSelector } from "../../common/selector/validityIntervalSelector";
import { viewSelector } from "../../changes/selector/workspaceChangesSelector";
import { BASELINE_VIEW } from "../../changes/changesConstants";

const mapStateToProps = (state: RootState): DataSetFilterStateProps => {
    const preloadOnly = dataSetFilterSelector(state)?.preloadOnly;
    return {
        enabled:
            dataSetFilterSelector(state)?.enabled &&
            (!preloadOnly || (state.urlParams.filterIds && state.urlParams.filterIds.length > 0)),
        treeItems: state.dataSetFilters.items.items || [],
        allDataSetFilters: state.dataSetFilters.allItems.receivedData ?? [],
        readonly: FilterSelector.isFeatureFilterPopulatedSelector(state),
        validFrom: validFromSelector(state),
        slots: viewSelector(state) === BASELINE_VIEW ? undefined : state.urlParams.slots,
        preloadFilters: state.urlParams.filterIds,
        preloadOnly: preloadOnly,
        ticket: state.session?.ticket,
        showOnlyFirstLevel: dataSetFilterSelector(state)?.showOnlyFirstLevel,
        axiosInitialized: state.axiosState.initialized,
    };
};

const mapDispatchToProps = (dispatch: any): DataSetFilterDispatchProps => {
    return {
        onLoadDataSetFilterList: () => dispatch(getDatasetFiltersActionCreator({ forceReload: false })),
        onAddDataSetFilter: (filter) => dispatch(addDataSetFilterToTreeViewAction(filter)),
        onDataSetFilterVisibilityToggled: (filterId, visibility) =>
            dispatch(toggleDataSetFilterVisibilityAction(filterId, visibility)),
        onRemoveDataSetFilter: (filterId) => dispatch(removeDataSetFilterFromTreeViewAction(filterId)),
        onRefreshDataSetFilters: () => dispatch(getDatasetFiltersActionCreator({ forceReload: true })),
        showWarning: (warning) => dispatch(showWarning(warning)),
        onApplyFeatureFilter: (filterId, validFrom, slots) =>
            dispatch(getDatasetFilterByIdActionCreator({ id: filterId, validFrom: validFrom, slots: slots })),
    };
};

export const ConnectedDataSetFilter = connect(mapStateToProps, mapDispatchToProps)(DataSetFilter);
