import classNames from "classnames";
import * as React from "react";

export interface IBoxProps {
    /**
     * Children property can only contain one `React.ReactElement`.
     */
    children: JSX.Element;

    /**
     * If true, the box will recycle its children DOM element. It's using React.cloneElement internally.
     * @default true
     */
    clone?: boolean;

    /**
     * Works only if clone property is set to false. `wrapper` is the component used for the root node.
     * It's either a string to use a DOM element or a component.
     * @default div
     */
    wrapper?: React.ElementType;

    /**
     * CSS classes to pass onto child component or the `wrapper`
     */
    className?: string;
}

/**
 * The Box component serves as a wrapper component for most of the CSS utility needs. Main purpose of `Box` component is to pass CSS classes to it's
 * child or wrap it for it if necessary. Internally child component is cloned with `React.cloneElement` and additional CSS classes.
 */
export const Box: React.FC<IBoxProps> = (props: IBoxProps) => {
    const { children, className, clone = true, wrapper: Wrapper } = props;

    if (clone) {
        return React.cloneElement(children, {
            className: classNames(children.props.className, className),
        });
    }

    const WrappingComponent = Wrapper || "div";

    return <WrappingComponent className={className}>{children}</WrappingComponent>;
};
