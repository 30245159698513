import { Action } from "redux";

export const VALIDITY_INTERVAL_CHANGE = "VALIDITY_INTERVAL_CHANGE";
export const UPDATE_VALID_FROM = "UPDATE_VALID_FROM";

export interface UpdateValidityIntervalAction extends Action {
    fromDate: string;
    toDate: string;
}

export function updateValidityIntervalAction(fromDate: string, toDate: string): UpdateValidityIntervalAction {
    return {
        type: VALIDITY_INTERVAL_CHANGE,
        fromDate: fromDate,
        toDate: toDate,
    };
}

export interface UpdateValidFromAction extends Action {
    fromDate: string;
}

// TODO ICH: move this action to changes plugin...
export function updateValidFromAction(validFrom: string): UpdateValidFromAction {
    return {
        type: UPDATE_VALID_FROM,
        fromDate: validFrom,
    };
}
