import DataSetFilterItem from "../../dto/DataSetFilterItem";
import { createReducer } from "../../../../core/core-redux/reducer/createReducer";
import { ADD_DATA_SET_FILTER_TO_TREE_VIEW } from "../add-dataset-filter/addDataSetFilterToTreeView.action";
import { addDataSetFilterPartialReducer } from "../add-dataset-filter/addDataSetFilterToTreeView.reducer";
import { removeDataSetFilterPartialReducer } from "../remove-dataset-filter/removeDataSetFilterFromTreeView.reducer";
import { REMOVE_DATA_SET_FILTER_FROM_TREE_VIEW } from "../remove-dataset-filter/removeDataSetFilterFromTreeView.action";
import { TOGGLE_DATA_SET_FILTER_VISIBILITY } from "../toggle-dataset-filter-visibility/toggleDataSetFilterVisibility.action";
import { toggleDataSetFilterVisibilityPartialReducer } from "../toggle-dataset-filter-visibility/toggleDataSetFilterVisibility.reducer";
import { GetDataSetFiltersDataState, getDataSetFiltersReducer } from "../../../../api/api-packages/getDataSetFilters";
import { ReducerFactory } from "../../../../core/core-redux/reduxTypes";
import { combineReducers } from "redux";
import { FilterCondition } from "../../../../globe/types";
import Item from "../../../../layers/dto/Item";

export interface DataSetFilterItemsDataState {
    items: DataSetFilterItem[];
    filters?: FilterCondition[];
    asString?: string;
}

export const dataSetFilterItemsReducer = createReducer<DataSetFilterItemsDataState>(
    {
        items: [new DataSetFilterItem(new Item("datasetFiltersRoot", undefined, false, true, true, false, [], null, false, "filters.tree.rootItemName"))],
    },
    {
        [ADD_DATA_SET_FILTER_TO_TREE_VIEW]: addDataSetFilterPartialReducer,
        [REMOVE_DATA_SET_FILTER_FROM_TREE_VIEW]: removeDataSetFilterPartialReducer,
        [TOGGLE_DATA_SET_FILTER_VISIBILITY]: toggleDataSetFilterVisibilityPartialReducer,
    }
);

export interface DataSetFilterReduxState {
    items: DataSetFilterItemsDataState;
    allItems: GetDataSetFiltersDataState;
}

export const dataSetFilterReducerFactory: ReducerFactory<DataSetFilterReduxState> = () =>
    combineReducers<DataSetFilterReduxState>({
        items: dataSetFilterItemsReducer,
        allItems: getDataSetFiltersReducer,
    });
