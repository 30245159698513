const React = require("react");
const PropTypes = require("prop-types");

const { Button: ButtonB } = require("react-bootstrap");
const tooltip = require("../../../MapStore2/web/client/components/misc/enhancers/tooltip").default;
const MapStoreButton = tooltip(ButtonB);
const { Glyphicon } = require("react-bootstrap");

class Button extends React.Component {
    static propTypes = {
        onClick: PropTypes.func,
        tooltip: PropTypes.string,
        glyph: PropTypes.string,
        id: PropTypes.string,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        tooltip: "",
        tooltipPosition: "bottom",
        glyph: "",
        disabled: false,
    };

    render() {
        if (this.props.onClick) {
            return (
                <MapStoreButton
                    id={this.props.id}
                    key={this.props.id}
                    className={`square-button ms-close btn btn-primary ${this.props.className}`}
                    onClick={this.props.onClick}
                    disabled={this.props.disabled}
                    tooltip={this.props.tooltip}
                    tooltipPosition={"bottom"}
                >
                    <Glyphicon glyph={this.props.glyph} />
                </MapStoreButton>
            );
        } else {
            return null;
        }
    }
}

module.exports = Button;
