import { GeometryRetriever } from "./geometryRetriever";
import { ChangeTypes, GeometryChange } from "./types";
import { isPointCoordinates } from "../../../core/geo/assertions";
import { numberComparator } from "../../../core/comparators/numberComparator";

export const geometryCollectionRetriever: GeometryRetriever = {
    retrieveChange(oldCoordinates, newCoordinates, featureOptions): GeometryChange {
        if (oldCoordinates === undefined) {
            return {
                type: ChangeTypes.INSERT_CIRCLE,
                coordinateChange: {
                    coordinates: newCoordinates,
                },
                radius: featureOptions.radiusNow,
            };
        }

        if (
            featureOptions.isCircle &&
            featureOptions.radiusBefore &&
            featureOptions.radiusNow &&
            !numberComparator(featureOptions.radiusBefore, featureOptions.radiusNow, 0.000001)
        ) {
            return { type: ChangeTypes.RADIUS_CHANGED, radius: featureOptions.radiusNow };
        }

        if (
            isPointCoordinates(oldCoordinates) &&
            isPointCoordinates(newCoordinates) &&
            numberComparator(oldCoordinates[0], newCoordinates[0]) &&
            numberComparator(oldCoordinates[1], newCoordinates[1])
        ) {
            return { type: ChangeTypes.NONE };
        }
        return {
            type: ChangeTypes.MOVE_CIRCLE,
            coordinateChange: {
                coordinates: newCoordinates,
            },
        };
    },
};
