import Item from "@js/layers/dto/Item";

class GeoCalculationItem extends Item {
    constructor(item, geometry) {
        super(item.id, item.name, item.visibility, item.expanded, item.group, item.removable, item.children, item.parent);
        this.geometry = geometry;
    }

}

export default GeoCalculationItem;
