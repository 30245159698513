export default class Wfs3dProperties {
    lowerLimit: number;
    upperLimit: number;
    leftWidth: number;
    rightWidth: number;
    constructor(lowerLimit: number, upperLimit: number, leftWidth: number, rightWidth: number) {
        this.lowerLimit = lowerLimit;
        this.upperLimit = upperLimit;
        this.leftWidth = leftWidth;
        this.rightWidth = rightWidth;
    }
}
