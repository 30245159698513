import { GeometryRetriever } from "./geometryRetriever";
import Logger from "../../../common/util/Logger";
import { ChangeTypes, GeometryChange } from "./types";
import { numberComparator } from "../../../core/comparators/numberComparator";
import { isPointCoordinates } from "../../../core/geo/assertions";

const logger = new Logger("pointRetriever");

export const pointRetriever: GeometryRetriever = {
    retrieveChange(oldCoordinates, newCoordinates, featureOptions): GeometryChange {
        if (oldCoordinates === undefined) {
            return {
                type: ChangeTypes.INSERT_POINT,
                coordinateChange: {
                    coordinates: newCoordinates,
                },
            };
        }

        if (
            isPointCoordinates(oldCoordinates) &&
            isPointCoordinates(newCoordinates) &&
            numberComparator(oldCoordinates[0], newCoordinates[0]) &&
            numberComparator(oldCoordinates[1], newCoordinates[1])
        ) {
            logger.info("Point feature NOT MOVED");
            return { type: ChangeTypes.NONE };
        }
        logger.info(`Point feature moved, before: ${oldCoordinates}, now: ${newCoordinates}`);
        return {
            type: ChangeTypes.MOVE_POINT,
            coordinateChange: {
                coordinates: newCoordinates,
            },
        };
    },
};
