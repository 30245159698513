import { DebouncedFunc, debounce, DebounceSettings } from "lodash";
import { useCallback } from "react";

export const useDebouncedCallback = <T extends (...args: any) => any>(
    func: T,
    wait?: number,
    options?: DebounceSettings
): DebouncedFunc<T> => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(debounce(func, wait, options), [func, wait]);
};
