import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

class TextButton extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        itemId: PropTypes.string | PropTypes.number,
        onClick: PropTypes.func,
        messageKey: PropTypes.string,
        text: PropTypes.string,
        className: PropTypes.string,
    };

    render() {
        return (
            <div
                id={this.props.id}
                className={`itemText ${this.props.className} ${this.props.onClick ? "itemTextClickable" : ""}`}
                onClick={this.handleClick.bind(this)}
            >
                {this.props.messageKey ? <FormattedMessage id={this.props.messageKey} /> : this.props.text}
            </div>
        );
    }

    handleClick() {
        if (this.props.onClick) {
            this.props.onClick(this.props.itemId);
        }
    }
}

export default TextButton;
