import { Action } from "redux";

export const SET_AXIOS_INITIALIZED = "SET_AXIOS_INITIALIZED";

export interface SetAxiosInitializedAction extends Action {
    initialized: boolean;
}

export const setAxiosInitializedAction = (initialized: boolean): SetAxiosInitializedAction => {
    return {
        type: SET_AXIOS_INITIALIZED,
        initialized: initialized,
    };
};
