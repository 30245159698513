const React = require("react");

const { EffectiveDatePicker } = require("@js/changes/component/header/child/EffectiveDatePicker");
const { ContextHolder } = require("@js/changes/component/header/child/ContextHolder");
const { ChangesLoader } = require("@js/changes/component/header/child/ChangesLoader");

const { CONTEXT_WORKSPACE } = require("@js/changes/changesConstants");

const ChangesHeader = (properties) => {
    return (
        <div className={"changesVisualizationPanelHeader"}>
            <div className={"changesVisualizationPanelHeaderInner"}>
                <ContextHolder context={properties.context} workspaceName={properties.workspaceName} />

                <EffectiveDatePicker
                    effectiveDate={properties.effectiveDate}
                    disabled={properties.displayLoading || properties.context === CONTEXT_WORKSPACE}
                    onDateChange={properties.handleEffectiveDateChanged}
                />

                <ChangesLoader
                    hidden={properties.context !== CONTEXT_WORKSPACE || properties.isCesium}
                    dateOfGeneration={properties.generated}
                    onClick={properties.handleReloadChanges}
                    disabled={properties.displayLoading}
                />
            </div>
        </div>
    );
};

module.exports = ChangesHeader;
