
import {queryableLayersSelector, layersSelector} from "@mapstore/selectors/layers";
import {workspaceChangeTypesSelector, baselineChangeTypesSelector} from "@js/changes/selector/changeTypeSelector";
import {workspaceModeSelector} from "@js/common/selector/configurationSelector";
import {isCesium} from '@mapstore/selectors/maptype';

const BACKGROUND_LAYER_GROUP = "background";
const BASELINE_LAYER_GROUP = "Baseline";
const WORKSPACE_LAYER_GROUP = "Workspace";

const selectableLayersSelector = state => queryableLayersSelector(state)
    .filter(layer => layer.group.startsWith(BASELINE_LAYER_GROUP) || state.urlParams && state.urlParams.slots && state.urlParams.slots.haveSlotIds())
    .filter(layer => !layer.notSelectable);

const queriableWorspaceLayerSelector = state => queryableLayersSelector(state).filter(layer => workspaceChangeTypesSelector(state) && ( workspaceChangeTypesSelector(state).length > 0 || isCesium(state)) && layer.group.startsWith(BASELINE_LAYER_GROUP) || workspaceModeSelector(state) && layer.group.startsWith(WORKSPACE_LAYER_GROUP));
const queriableBaselineLayerSelector = state => queryableLayersSelector(state).filter(layer => (baselineChangeTypesSelector(state) && (baselineChangeTypesSelector(state).length > 0 || isCesium(state)) || workspaceModeSelector(state)) && layer.group.startsWith(BASELINE_LAYER_GROUP));

const wfs3dWorkspaceLayerSelector = state => layersSelector(state).filter(layer =>  isCesium(state) && layer.wfs3dLayer && state.urlParams && state.urlParams.slots && state.urlParams.slots.haveSlotIds());
const wfs3dBaselineLayerSelector = state => layersSelector(state).filter(layer =>  isCesium(state) && layer.wfs3dLayer);

const queriableWfs3dWorkspaceLayerSelector = state => queryableLayersSelector(state).filter(layer => layer.wfs3dLayer);
const queriableWfs3dBaselineLayerSelector = state => queryableLayersSelector(state).filter(layer => layer.wfs3dLayer);

const queriableWmsWorkspaceLayerSelector = state => queriableWorspaceLayerSelector(state).filter(layer =>  !isCesium(state) || !layer.wfs3dLayer);
const queriableWmsBaselineLayerSelector = state => queriableBaselineLayerSelector(state).filter(layer => !isCesium(state) || !layer.wfs3dLayer);

const wmsLayerSelector = state => layersSelector(state).filter(layer => layer.group !== 'background' && (!isCesium(state) || !layer.wfs3dLayer));

const allLayersSelector = state => layersSelector(state).filter(layer => layer.group !== BACKGROUND_LAYER_GROUP);

const allWorkspaceLayersSelector = state => allLayersSelector(state).filter(layer => !workspaceModeSelector(state) || layer.group && layer.group.startsWith(WORKSPACE_LAYER_GROUP));
const allBaselineLayersSelector = state => allLayersSelector(state).filter(layer => layer.group && layer.group.startsWith(BASELINE_LAYER_GROUP));

export {
    queriableWmsWorkspaceLayerSelector,
    queriableWmsBaselineLayerSelector,
    wfs3dWorkspaceLayerSelector,
    wfs3dBaselineLayerSelector,
    queriableWfs3dWorkspaceLayerSelector,
    queriableWfs3dBaselineLayerSelector,
    allWorkspaceLayersSelector,
    allBaselineLayersSelector,
    allLayersSelector,
    BASELINE_LAYER_GROUP,
    WORKSPACE_LAYER_GROUP,
    selectableLayersSelector,
    wmsLayerSelector
};
