import { EpicFactory } from "../../../../../core/core-redux/reduxTypes";
import { RootState } from "../../../../../rootState";
import { CLICK_ON_MAP, ClickOnMapAction } from "../../../../../common/mapstore/action";
import { doNothingAction } from "../../../../../common/action/doNothingAction";
import { storeGeometryAction } from "../storeGeometry/storeGeometry.action";
import { createLayer, createLineString, createPoint } from "../../../../../core/geo/geometryFactory";
import { Layer } from "../drawLayersReducer";
import { CRS, Projection } from "../../../../../common/types";

export const drawLayersEpic: EpicFactory<ClickOnMapAction, RootState, any> = (action, store) =>
    action.ofType<ClickOnMapAction>(CLICK_ON_MAP).map((clickOnMapAction) => {
        if (!store.getState().drawLayers.enabled) {
            return doNothingAction();
        }
        const layers: Layer[] = [];

        const newPoint = createPoint([clickOnMapAction.point.rawPos[0], clickOnMapAction.point.rawPos[1]]);

        layers.push(createLayer(newPoint, Projection.EPSG_4326, CRS.EPSG_3857));

        const state = store.getState().drawLayers;

        if (!state.points || state.points.length === 2) {
            return storeGeometryAction([...layers], [clickOnMapAction.point]);
        }

        const newLine = createLineString([
            [state.points[0].rawPos[0], state.points[0].rawPos[1]],
            [clickOnMapAction.point.rawPos[0], clickOnMapAction.point.rawPos[1]],
        ]);

        layers.push(createLayer(newLine, Projection.EPSG_4326, CRS.EPSG_3857));
        return storeGeometryAction([...(state?.layers ?? []), ...layers], [...state.points, clickOnMapAction.point]);
    });
