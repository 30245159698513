import * as React from "react";
import { FormattedNumber } from "./FormattedNumber";

interface FormattedPointProps {
    x: number;
    y: number;
}

export const FormattedPoint = (props: FormattedPointProps) => {
    const { x, y } = props;
    return (
        <div>
            X: <FormattedNumber value={x} fractionDigits={4} className={"px-1"} />
            Y: <FormattedNumber value={y} fractionDigits={4} className={"px-1"} />
        </div>
    );
};
