import { doNothingAction } from "../../common/action/doNothingAction";
import { GEOMETRY_CHANGED, GeometryChangedAction } from "../../editFeature/redux/geometryChanged/geometryChangedAction";
import { portalDispatcher } from "../dispatcher";
import { EpicFactory } from "../../core/core-redux/reduxTypes";
import { Action } from "redux";
import { RootState } from "../../rootState";

export const updateFeatureGeometryOnPortalEpic: EpicFactory<Action, RootState, Action> = (action$) =>
    action$.ofType<GeometryChangedAction>(GEOMETRY_CHANGED).map((action) => {
        portalDispatcher.updateFeatureGeometry(action.change);
        return doNothingAction();
    });
