import { RootState } from "../../rootState";
import { EditMode } from "../types";

export const isEditModeEnabled = (state: RootState) => {
    return state.editFeature?.editMode === EditMode.GRAPHICAL;
};

export const isEditOrViewModeEnabled = (state: RootState) => {
    return state.editFeature?.editMode === EditMode.GRAPHICAL || state.editFeature?.editMode === EditMode.NUMERICAL;
};
