import { Observable } from "rxjs";
import { AsyncState, IAsyncDataAction } from "../core-redux/reduxTypes";
import { RxActions } from "../core-redux/actions/asyncFetchDataAction";
import Logger from "../../common/util/Logger";

const logger = new Logger("actionCreator");

export interface Signal<T> {
    state: AsyncState;
    receivedData?: T;
    error?: any;
}

export function fromPromiseAsSignal<T>(myPromise: Promise<T>): Observable<Signal<T>> {
    return Observable.fromPromise(myPromise)
        .map((value) => {
            return {
                state: AsyncState.COMPLETED,
                receivedData: value,
            };
        })
        .catch((err) => {
            console.log(err);
            return Observable.of({ state: AsyncState.ERROR });
        })
        .startWith({ state: AsyncState.IN_PROGRESS });
}

export function fromPromisesAsSignal<T>(args: Promise<T>[]): Observable<Signal<T[]>> {
    return Observable.forkJoin(args)
        .map((value) => {
            return {
                state: AsyncState.COMPLETED,
                receivedData: value,
            };
        })
        .catch((err) => {
            console.log(err);
            return Observable.of({ state: AsyncState.ERROR });
        })
        .startWith({ state: AsyncState.IN_PROGRESS });
}

export function fromActionCreator<REQ, RESP, ERROR>(
    action: RxActions<REQ, RESP, ERROR>,
    params: REQ
): Observable<IAsyncDataAction<RESP, ERROR, REQ>> {
    return Observable.fromPromise(action.asyncFunction(params))
        .map((value) => {
            return action.receiveAsyncDataActionCreator(params, value);
        })
        .catch((err) => {
            console.log(err);
            logger.error(JSON.stringify(err));
            return Observable.of(action.receiveAsyncDataErrorActionCreator(params, err));
        })
        .startWith(action.requestAsyncDataActionCreator(params));
}
