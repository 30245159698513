import { FeatureStyle } from "../types";
import { RootState } from "../../rootState";
import { editedFeatureStyleSelector } from "../../common/selector/configurationSelector";

export const DEFAULT_STYLE: FeatureStyle = {
    weight: 2,
    fillOpacity: 1,
    color: "black",
    fillColor: "#178ee0",
    radius: 6,
};

export const getFeatureStyle = (state: RootState): FeatureStyle => {
    return editedFeatureStyleSelector(state) || DEFAULT_STYLE;
};
