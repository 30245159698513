import { createPlugin } from "../../../MapStore2/web/client/utils/PluginsUtils";
import { MapStorePlugin } from "./types";

export const map = (component: MapStorePlugin<any>) => {
    const { config } = component;
    return createPlugin(component.name, {
        component: config.component,
        options: config.options ?? {},
        containers: config.containers ?? {},
        reducers: config.reducers ?? {},
        epics: config.epics ?? {},
        lazy: config.lazy ?? false,
        enabler: config.enabler as any,
        loader: config.loader,
    });
};
