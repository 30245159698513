import { Action } from "redux";

export const DESELECT_ALL_FEATURES = "DESELECT_ALL_FEATURES";

export interface DeselectAllFeaturesAction extends Action {}

export const deselectAllFeaturesAction = (): DeselectAllFeaturesAction => {
    return {
        type: DESELECT_ALL_FEATURES,
    };
};
