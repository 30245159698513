import { createStructuredSelector } from "reselect";

import { mapSelector, projectionDefsSelector, isMouseMoveCoordinatesActiveSelector } from "@mapstore/selectors/map";
import { mapTypeSelector, isOpenlayers, isCesium } from "@mapstore/selectors/maptype";
import { layerSelectorWithMarkers } from "@mapstore/selectors/layers";
import { securityTokenSelector } from "@mapstore/selectors/security";
import { currentLocaleLanguageSelector } from "@mapstore/selectors/locale";
import {
    isLocalizedLayerStylesEnabledSelector,
    localizedLayerStylesNameSelector,
} from "@mapstore/selectors/localizedLayerStyles";
import { isEditOrViewModeEnabled } from "../../editFeature/redux/editFeature.selector";
import { BASELINE_VIEW } from "@js/changes/changesConstants";
import { BASELINE_LAYER_GROUP, WORKSPACE_LAYER_GROUP } from "@js/common/selector/layerSelector";

/**
 * Map state to props selector for Map plugin
 */
export default createStructuredSelector({
    projectionDefs: projectionDefsSelector,
    map: mapSelector,
    mapType: mapTypeSelector,
    layers: layerSelectorWithMarkers,
    loadingError: (state) =>
        state.mapInitialConfig && state.mapInitialConfig.loadingError && state.mapInitialConfig.loadingError.data,
    securityToken: securityTokenSelector,
    elevationEnabled: isMouseMoveCoordinatesActiveSelector,
    shouldLoadFont: isOpenlayers,
    isLocalizedLayerStylesEnabled: isLocalizedLayerStylesEnabledSelector,
    localizedLayerStylesName: localizedLayerStylesNameSelector,
    currentLocaleLanguage: currentLocaleLanguageSelector,
    // avitech selectors
    features: () => [],
    baselineLayer: (state) => state.singleWmsRequest && state.singleWmsRequest.baselineLayer,
    workspaceLayer: (state) => state.singleWmsRequest && state.singleWmsRequest.workspaceLayer,
    additionalLayers: (state) =>
        state.geoCalcList && state.geoCalcList.featureLayer && state.geoCalcList.textLayer
            ? [state.geoCalcList.featureLayer, state.geoCalcList.textLayer]
            : [],
    drawLayers: (state) => (state.drawLayers && state.drawLayers.layers) || [],
    baselineHighlightLayer: (state) =>
        state.highlight && !isCesium(state) && !isEditOrViewModeEnabled(state) && state.highlight.baselineLayer
            ? state.highlight.baselineLayer
            : null,
    workspaceHighlightLayer: (state) =>
        state.highlight && !isCesium(state) && !isEditOrViewModeEnabled(state) && state.highlight.workspaceLayer
            ? state.highlight.workspaceLayer
            : null,
    baselineWfs3dLayer: (state) =>
        state.wfs3dLayers &&
        state.wfs3dLayers.layers &&
        (state.workspaceChanges && state.workspaceChanges.view === BASELINE_VIEW
            ? state.wfs3dLayers.layers.filter((layer) => layer.group === BASELINE_LAYER_GROUP)
            : state.wfs3dLayers.layers.filter((layer) => layer.group === WORKSPACE_LAYER_GROUP)),
    centerLayer: (state) => {
        return state.centerMapData?.renderDot
            ? {
                  type: "vector",
                  id: "CenterMapMarker",
                  features: [
                      {
                          type: "FeatureCollection",
                          properties: {
                              visibility: true,
                          },
                          features: [
                              {
                                  type: "Feature",
                                  geometry: {
                                      coordinates: [state.map?.present?.center?.x, state.map?.present?.center?.y],
                                      type: "Point",
                                  },
                                  properties: {
                                      style: {
                                          radius: 2,
                                          fillOpacity: 1,
                                          fillColor: "red",
                                      },
                                  },
                              },
                          ],
                      },
                  ],
              }
            : {};
    },
    // TODO ICH: make default value in redux store so we can get rid of this default param
    adjustedHeight: (state) => state.wfs3dLayers?.adjustedHeight ?? 1,
    view: (state) => state.workspaceChanges && state.workspaceChanges.view,
});
