export default class Item {
    id: any;
    name: any;
    visibility: any;
    expanded: any;
    group: any;
    children: any;
    parent: any;
    removable: any;
    warning: any;
    messageKey?: string;
    constructor(
        id: any,
        name: any,
        visibility: any,
        expanded: any,
        group: any,
        removable: any,
        children?: any,
        parent?: any,
        warning?: any,
        messageKey?: string
    ) {
        this.id = id;
        this.name = name;
        this.visibility = visibility;
        this.expanded = expanded;
        this.group = group;
        this.children = children;
        this.parent = parent;
        this.removable = removable;
        this.warning = warning;
        this.messageKey = messageKey;

        if (children) {
            children.forEach((child: any) => (child.parent = this));
        }
    }

    addChild(child: any) {
        if (!this.children) {
            this.children = [];
        }
        child.parent = this;
        this.children.push(child);
    }
}
