import { EpicFactory } from "../../../core/core-redux/reduxTypes";
import { INSERT_FEATURE_GEOMETRY, InsertFeatureAction } from "./insertFeature.action";
import { RootState } from "../../../rootState";
import { selectFeatureForEditing } from "../../../common/mapstore/action/draw";
import { doNothingAction } from "../../../common/action/doNothingAction";
import { Action } from "redux";

export const insertFeatureEpic: EpicFactory<InsertFeatureAction | Action, RootState, Action> = (action, store) =>
    action.ofType(INSERT_FEATURE_GEOMETRY).map(() => {
        const editFeature = store.getState().editFeature;
        if (editFeature === undefined || editFeature.editedFeatures === undefined) {
            return doNothingAction();
        }
        return selectFeatureForEditing(editFeature.editedFeatures, editFeature.options);
    });
