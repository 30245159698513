import Cesium from "../../../../MapStore2/web/client/libs/cesium";
import Wfs3dProperties from "../dto/Wfs3dProperties";
import { selectDefaultRouteHeight, selectDefaultRouteWidth } from "../../selector/Wfs3dConfigurationSelector";

const getHeight = (properties: Wfs3dProperties) => {
    const height = properties.upperLimit - properties.lowerLimit;
    return height <= 0 ? selectDefaultRouteHeight() : height;
};

const getWidth = (properties: Wfs3dProperties) => {
    const width = properties.leftWidth + properties.rightWidth;
    return width <= 0 ? selectDefaultRouteWidth() : width;
};

export const buildRouteShapeArray = (properties: Wfs3dProperties) => {
    const height = getHeight(properties);
    const width = getWidth(properties);
    return [
        new Cesium.Cartesian2(0, 0),
        new Cesium.Cartesian2(width, 0),
        new Cesium.Cartesian2(width, height),
        new Cesium.Cartesian2(0, height),
    ];
};
