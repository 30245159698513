const { invokeRestServiceWithParameters } = require("@js/common/util/web-service/gisServerServices.ts");
const {
    RELOAD_CHANGES,
    setChangesGeneratedDateAction,
    hideChangesLoaderAction,
} = require("@js/changes/action/reloadChangesAction");
const { doNothingAction } = require("@js/common/action/doNothingAction");
const moment = require("moment");
const Logger = require("@js/common/util/Logger");
const { SET_AXIOS_INITIALIZED } = require("@js/startup/redux/setAxiosInitialized.action");
const { STORE_GIS_SERVER_URL } = require("@js/layers/redux/gis-server-url/storeGisServerUrl.action");
const { gisServerUrlSelector } = require("@js/common/selector/configurationSelector");

const logger = new Logger("reloadChangesEpic");

const reloadChangesEpic = (action$, store) =>
    action$.ofType(RELOAD_CHANGES, SET_AXIOS_INITIALIZED, STORE_GIS_SERVER_URL).switchMap((action) => {
        const state = store.getState();

        if (!state.urlParams.slots.haveSlotIds()) {
            return [hideChangesLoaderAction()];
        }

        if (!state.axiosState.initialized || !gisServerUrlSelector(state)) {
            return [doNothingAction()];
        }

        const restOptions = {
            slotIds: state.urlParams.slots.getSlotIds(),
            forceReload: action.type === RELOAD_CHANGES,
        };

        return invokeRestServiceWithParameters(state, "sdo/loadSlotChanges", restOptions)
            .switchMap((response) => {
                return response.data
                    ? [
                          setChangesGeneratedDateAction(moment(response.data).utc().format("YYYY-MM-DD HH:mm:ss")),
                          hideChangesLoaderAction(),
                      ]
                    : [doNothingAction(), hideChangesLoaderAction()];
            })
            .catch((e) => {
                logger.error("Error reloading changes on GIS Server.", e);
                return [doNothingAction(), hideChangesLoaderAction()];
            });
    });

module.exports = reloadChangesEpic;
