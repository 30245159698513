import { Action } from "redux";
import { Feature } from "../../../core/geo/types";

export const SELECT_FEATURE_FROM_PORTAL = "SELECT_FEATURE_FROM_PORTAL";

export type PortalFeatureDescriptor = {
    featureId: number;
    featureType: string;
    slotId: number;
};

export interface SelectFeatureFromPortalAction extends Action {
    features: Feature[];
}

export const selectFeatureFromPortalAction = (features: Feature[]): SelectFeatureFromPortalAction => {
    return {
        type: SELECT_FEATURE_FROM_PORTAL,
        features: features,
    };
};
