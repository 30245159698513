const log4javascript = require("log4javascript");
const uuid = require("uuid/v1");
const { browserName, fullBrowserVersion } = require("react-device-detect");

log4javascript.logLog.setQuietMode(true);

const BATCH_SIZE = 20;

class Logger {
    logger;
    loggerName;

    constructor(loggerName) {
        this.logger = log4javascript.getLogger();
        this.loggerName = loggerName || "anonymous";

        if (!this.logger.identifier) {
            this.logger.identifier = uuid();
        }

        this._addAppenders();
    }

    _createAjaxAppender() {
        const ajaxAppender = new log4javascript.AjaxAppender(`${window.location.origin}/rest/config/logJson`);

        ajaxAppender.setBatchSize(BATCH_SIZE);
        ajaxAppender.setSendAllOnUnload(true);
        const layout = new log4javascript.JsonLayout(false, false);

        layout.setCustomField("identifier", () => {
            return this.logger.identifier;
        });

        ajaxAppender.setLayout(layout);
        return ajaxAppender;
    }

    _addAppenders() {
        const appenders = this.logger.getEffectiveAppenders();
        if (!appenders || appenders.length <= 0) {
            this.logger.addAppender(this._createAjaxAppender());
            this.logger.addAppender(new log4javascript.BrowserConsoleAppender());
            this.info(
                `Creating logger in browser: ${browserName} version: ${fullBrowserVersion} with id: ${this.logger.identifier}`
            );
        }
    }

    _getFileLineAndColumn() {
        const stack = new Error().stack;
        const callerLine = stack.split("\n")[3];
        const fileLineAndColumn = callerLine.match(/([\w-_.]+\.(js|jsx|ts|tsx)):(\d*):(\d*)/);
        return fileLineAndColumn ? fileLineAndColumn[0] : callerLine;
    }

    _getExceptionText(exception) {
        return exception ? exception.stack || exception.data || exception.message || exception : "";
    }

    error(message, exception) {
        const exceptionText = this._getExceptionText(exception);
        this.logger.error(
            this.loggerName,
            this._getFileLineAndColumn(),
            message,
            exceptionText ? "\n" + exceptionText : ""
        );
    }

    warn(message) {
        this.logger.warn(this.loggerName, this._getFileLineAndColumn(), message);
    }

    info(message) {
        this.logger.info(this.loggerName, this._getFileLineAndColumn(), message);
    }

    sendAllCachedMessages() {
        for (let i = 0; i < BATCH_SIZE; i++) {
            this.logger.info();
        }
        this.logger.getEffectiveAppenders()[0].sendAll();
    }
}

window.logger = new Logger();

window.onerror = (msg, url, lineNo, columnNo, error) => {
    window.logger.error(msg, error);
    window.logger.sendAllCachedMessages();
};

module.exports = Logger;
