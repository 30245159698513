import { default as Axios } from "axios";

interface PluginsConfiguration {
    desktop: Array<string | { name: string }>;
    validatePlugins: boolean;
}

let plugins: PluginsConfiguration;

const loadPluginsConfiguration = async () => {
    const { data: localConfig } = await Axios.get<{ plugins: PluginsConfiguration }>("../../configs/localConfig.json");
    plugins = localConfig.plugins;
};

export const getPluginsConfiguration = async () => {
    if (!plugins) {
        await loadPluginsConfiguration();
    }
    const pluginNames = plugins.desktop.map((plugin: string | { name: string }) => {
        return (typeof plugin === "object" ? plugin.name : plugin) + "Plugin";
    });

    return {
        plugins: pluginNames,
        validatePlugins: plugins.validatePlugins,
    };
};
