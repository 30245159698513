import { LoadAllFeaturesInfoService } from "./loadAllFeaturesInfo.service";
import {
    featureInfoService,
    FeatureInfoServiceResponse,
} from "../../api/api-packages/feature-info/featureInfo.service";
import { wfsService, WfsServiceResponse } from "../../api/api-packages/wfs-service/wfsService.service";
import * as _ from "lodash";

export const loadAllFeaturesInfoAdapter: LoadAllFeaturesInfoService = async (params) => {
    const featureInfoServices: Promise<FeatureInfoServiceResponse>[] = params.wmsParams.map((item) =>
        featureInfoService({ url: item.url })
    );
    const services: Promise<FeatureInfoServiceResponse | WfsServiceResponse>[] = featureInfoServices;
    if (params.wfsParams) {
        services.push(wfsService(params.wfsParams));
    }
    const data = await Promise.all(services);
    const features = data.map((x) => x.features).reduce((total, current) => [...total, ...current]);
    return _.uniqWith(
        features,
        (featureA, featureB) =>
            featureA.properties.featureId === featureB.properties.featureId &&
            featureA.properties.featureShortName === featureB.properties.featureShortName
    );
};
