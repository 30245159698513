import { standardApiHandler } from "../../../core/core-api/apiTools";
import { geoCalculationsByIdsAdapter } from "./geoCalculationsByIds.adapter";
import GeoCalculationItem from "../../../geocalc/dto/GeoCalculationItem";

export type GeoCalculationsByIdsServiceParams = {
    geoCalcIds: string[];
};
export type GeoCalculationsByIdsServiceResponse = [
    {
        calculationId: number;
        calculationName: string;
        geometry: string;
    }
];

export type GeoCalculationsByIdsService = (params: GeoCalculationsByIdsServiceParams) => Promise<GeoCalculationItem[]>;
export const geoCalculationsByIdsService: GeoCalculationsByIdsService = standardApiHandler(geoCalculationsByIdsAdapter);
