import { api } from "../../apiProvider";
import { FeatureInfoService } from "./featureInfo.service";

export const featureInfoAdapter: FeatureInfoService = async (params) => {
    const resp = await api.featureInfo(params.url);
    return {
        features: resp.data.features.map((apiFeature) => ({
            ...apiFeature,
            properties: {
                id: `${apiFeature.properties.FEATURE_SHORT_NAME}|${apiFeature.properties.FEATURE_ID}`,
                featureType: apiFeature.properties.FEATURE_FORM,
                featureId: apiFeature.properties.FEATURE_ID,
                featureForm: apiFeature.properties.FEATURE_FORM,
                featureShortName: apiFeature.properties.FEATURE_SHORT_NAME,
                slotId: apiFeature.properties.SLOT_ID,
                naturalKey: apiFeature.properties.NATURAL_KEY,
                layer: apiFeature.properties.LAYER_NAME,
            },
        })),
    };
};
