import { Action } from "redux";
import { Layer, RootState } from "../../../rootState";
import {
    queriableWmsBaselineLayerSelector,
    queriableWmsWorkspaceLayerSelector,
    selectableLayersSelector,
} from "../../../common/selector/layerSelector";
import { EpicFactory } from "../../../core/core-redux/reduxTypes";
import { Observable } from "rxjs";
import { CLICK_ON_MAP } from "../../../../MapStore2/web/client/actions/map";
import { isEditOrViewModeEnabled } from "../../../editFeature/redux";
import { selectedFeaturesSelector } from "../../../../MapStore2/web/client/selectors/featuregrid";
import EnvParamsRetriever from "../../../common/util/EnvParamsRetriever";
import { buildFeatureInfoRequests } from "../buildFeatureInfoRequests";
import { Feature } from "../../../core/geo/types";
import { sessionSelector } from "../../../session/redux/sessionSelector";
import { fromActionCreator } from "../../../core/core-rx/types";
import * as _ from "lodash";
import { loadAllFeaturesActions } from "../../api/loadAllFeaturesInfo.reduxActions";
import { showSelectedFeatureAction } from "../../feature-detail-frame/redux/show-selected-feature/showSelectedFeature.action";
import { loadFeatureInfoAction } from "../../feature-list-selection/redux/load-feature-info/loadFeatureInfo.action";
import { deselectAllFeaturesAction } from "../../feature-detail-frame/redux/deselect-all-features/deselectAllFeatures.action";
import { cleanupFeatureInfoAction } from "../../feature-list-selection/redux/cleanup-feature-info/cleanupFeatureInfo.action";
import { deselectFeatureFromPortalAction } from "../../../portal/redux/deselect-feature/deselectFeatureFromPortal.action";

interface FeatureDescriptor3D {
    PARENT_ID?: number;
    PARENT_LAYER_NAME?: string;
    FEATURE_ID: number;
    CODE_TYPE?: string;
    EFFECTIVE_DATE: string;
    ENTITY_NAME: string;
    SLOT_ID: number;
    LOWER_LIMIT: number;
    UPPER_LIMIT: number;
    LAYER_NAME: string;
}

interface ClickAction extends Action {
    point: {
        latlng: {
            lat: number;
            lng: number;
        };
        modifiers: {
            alt: boolean;
            ctrl: boolean;
            metaKey: boolean;
            shift: boolean;
        };
        pixel: {
            x: number;
            y: number;
        };
        rawPos: number[];
        features3D?: FeatureDescriptor3D[];
    };
}

const isSelectedNotMultiSelectableFeature = (state: RootState, selectedFeatures: Feature[]) => {
    const selectedLayerNames = selectedFeatures.map((feature) => feature.properties.layer);
    const selectedLayers = selectedLayerNames
        .map((ln) => selectableLayersSelector(state).find((layer: Layer) => layer.name === ln))
        .filter((layer) => layer);
    return selectedLayers && selectedLayers.some((layer) => layer.notMultiSelectable);
};

const prepareRequest2D = (state: RootState, isCtrlClick: boolean) => {
    const selectedFeatures = selectedFeaturesSelector(state);
    const noFeatureSelected = selectedFeatures && selectedFeatures.length <= 0;
    const baselineLayers: Layer[] = queriableWmsBaselineLayerSelector(state)
        .filter(
            (layer: Layer) => !layer.notSelectable && (!isCtrlClick || noFeatureSelected || !layer.notMultiSelectable)
        )
        .sort((a: Layer, b: Layer) => b.zIndex - a.zIndex);

    const slotLayers: Layer[] = queriableWmsWorkspaceLayerSelector(state)
        .filter(
            (layer: Layer) =>
                !layer.notSelectable &&
                state.urlParams.slots.haveSlotIds() &&
                (!isCtrlClick || noFeatureSelected || !layer.notMultiSelectable)
        )
        .sort((a: Layer, b: Layer) => b.zIndex - a.zIndex);

    let requestDescriptors: { url: string; identifier: string; ticket: string | undefined }[];
    if (
        (baselineLayers.length <= 0 && slotLayers.length <= 0) ||
        (isCtrlClick && isSelectedNotMultiSelectableFeature(state, selectedFeatures))
    ) {
        requestDescriptors = [];
    } else {
        requestDescriptors = buildFeatureInfoRequests(baselineLayers, slotLayers, state);
    }
    return requestDescriptors;
};

const prepareRequest3D = (state: RootState, features?: FeatureDescriptor3D[]) => {
    if (features === undefined) return undefined;

    const featuresDescriptor: { id: number; layerName: string }[] =
        features?.map((feature) => {
            return {
                id: feature.PARENT_ID ?? feature.FEATURE_ID,
                layerName: feature.PARENT_LAYER_NAME ?? feature.LAYER_NAME,
            };
        }) ?? [];
    const groupedFeatures = _.groupBy(featuresDescriptor, (descriptor) => descriptor.layerName);
    return {
        typeName: Object.keys(groupedFeatures).join(), // "Ahp,ahgr"
        CQL_FILTER: Object.keys(groupedFeatures)
            .map((key) => `FEATURE_ID in (${groupedFeatures[key].map((feature) => feature.id).join()})`)
            .join(";"), // "FEATURE_ID in (1,2,3); FEATURE_ID in (4,5,6)"
        ENV: encodeURIComponent(EnvParamsRetriever.getWorkspaceEnvParams(state).toRequestParams()),
        wizardSuiteProperties: true,
        ticket: sessionSelector(state),
    };
};

export const featureInfoEpic: EpicFactory<ClickAction | Action, RootState, Action> = (
    action,
    store
): Observable<Action> =>
    action
        .ofType<ClickAction>(CLICK_ON_MAP)
        .filter(() => !isEditOrViewModeEnabled(store.getState()))
        .switchMap((_action) => {
            const state = store.getState();
            const isCtrlClick = _action.point.modifiers && _action.point.modifiers.ctrl;

            return fromActionCreator(loadAllFeaturesActions, {
                wmsParams: prepareRequest2D(state, isCtrlClick),
                wfsParams: prepareRequest3D(state, _action.point.features3D),
            });
        })
        .switchMap((data) => {
            const actions: Action[] = [data];
            if (data.response) {
                const featureAction =
                    data.response.length === 1
                        ? showSelectedFeatureAction(data.response[0], false)
                        : loadFeatureInfoAction(data.response);
                console.log(data);
                actions.push(featureAction);
            } else {
                actions.push(deselectAllFeaturesAction());
                actions.push(deselectFeatureFromPortalAction());
                actions.push(cleanupFeatureInfoAction());
            }
            return actions;
        });
