import { Action } from "redux";
import { ArcMode } from "../types";

export const SET_ARC_MODE = "SET_ARC_MODE";

export interface SetArcModeAction extends Action {
    mode: ArcMode;
}

export const setArcModeAction = (mode: ArcMode): SetArcModeAction => ({
    type: SET_ARC_MODE,
    mode: mode,
});
