export enum EditMode {
    GRAPHICAL = "graphical",
    NUMERICAL = "numerical",
    NONE = "none",
}

export interface FeatureStyle {
    weight: number;
    fillOpacity: number;
    fillColor: string;
    color: string;
    radius: number;
}
