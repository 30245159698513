import { api } from "../../apiProvider";
import { GetDataSetFiltersService } from "./getDataSetFilters.service";
import { mapToLayerItems } from "../../../filter/mapper";

export const getDataSetFiltersAdapter: GetDataSetFiltersService = async (params) => {
    const resp = await api.getDataSetFilters(params.forceReload);

    return resp.data.map((item) => {
        return mapToLayerItems(item);
    });
};
