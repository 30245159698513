import * as React from "react";
import { createContext } from "react";
import { InjectedIntl, intlShape } from "react-intl";

interface IIntlContext {
    intl: InjectedIntl;
}

export const IntlContext = createContext({} as IIntlContext);

export class IntlContextProvider extends React.Component<{ children?: React.ReactNode }> {
    public static contextTypes = {
        intl: intlShape.isRequired,
    };

    public render() {
        const { children } = this.props;

        return <IntlContext.Provider value={this.context}>{children}</IntlContext.Provider>;
    }
}
