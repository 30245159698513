import { asyncFetchDataPromiseActionFactory } from "../../../core/core-redux/actions/asyncFetchDataAction";
import {
    validateSessionService,
    ValidateSessionServiceParams,
    ValidateSessionServiceResponse,
} from "./validateSession.service";

export const VALIDATE_SESSION = "VALIDATE_SESSION";

export const validateSessionActionCreator = asyncFetchDataPromiseActionFactory<
    ValidateSessionServiceParams,
    ValidateSessionServiceResponse,
    any
>(VALIDATE_SESSION, validateSessionService);
