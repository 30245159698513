import { initState } from "../../common/util/StateUtil";
import updateObject from "../../common/util/UpdateObject";
import { APPLY_HIGHLIGHT_LAYERS } from "../action/applyHighlightLayersAction";

const highlightLayerReducer = (initialState: any, action: any) => {
    const state = initState(initialState);
    if (action.type === APPLY_HIGHLIGHT_LAYERS) {
        return updateObject(state, {
            baselineLayer: action.baselineLayer,
            workspaceLayer: action.workspaceLayer,
        });
    }
    return state;
};

export default highlightLayerReducer;
