import React, { useEffect } from "react";
import { ConnectedFeatureFilter } from "../filter/FeatureFilter/ConnectedFeatureFilter";
import { CollapsiblePanel } from "../core/core-widgets/panels/CollapsiblePanel";
import { ContentContainer } from "../core/core-widgets/surfaces/ContentContainer/ContentContainer";
import { GlyphIcon } from "../core/core-widgets/icons";
import { ConnectedDataSetFilter } from "../filter/DataSetFilter/ConnectedDataSetFilter";
import { DelimitedSection } from "../core/core-widgets/surfaces/DelimitedSection/DelimitedSection";
const { TOCPlugin } = require("@mapstore/plugins/TOC").default;
const { BackgroundSelectorPlugin } = require("@mapstore/plugins/BackgroundSelector").default;
const { GeoCalcPlugin } = require("@js/geocalc/GeoCalc").default;
const { ChangesPlugin } = require("@js/changes/Changes").default;

export interface LayersStateProps {
    expanded: boolean;
    disableBaselineLayers: boolean;
    isCesium: boolean;
}

export interface LayersDispatchProps {
    disableBaselineLayersAction: (disabled: boolean, isCesium: boolean) => void;
}

export const Layers = ({
    expanded,
    disableBaselineLayers,
    disableBaselineLayersAction,
    isCesium,
}: LayersStateProps & LayersDispatchProps) => {
    useEffect(() => {
        disableBaselineLayersAction(disableBaselineLayers, isCesium);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disableBaselineLayers, isCesium]);

    return (
        <div id={"layers"}>
            <CollapsiblePanel open={expanded} classname={"layersContainer"} icon={GlyphIcon.LAYER}>
                <ContentContainer>
                    <BackgroundSelectorPlugin
                        size={{ width: 600, height: 500 }}
                        dimensions={{
                            side: 55,
                            sidePreview: 55,
                            frame: 3,
                            margin: 5,
                            label: false,
                            vertical: false,
                        }}
                    />
                    <ChangesPlugin />
                    <div style={disableBaselineLayers ? { pointerEvents: "none" } : {}}>
                        <TOCPlugin />
                    </div>

                    <DelimitedSection>
                        <GeoCalcPlugin />
                    </DelimitedSection>
                    <DelimitedSection>
                        <ConnectedDataSetFilter />
                    </DelimitedSection>
                    <DelimitedSection>
                        <ConnectedFeatureFilter />
                    </DelimitedSection>
                </ContentContainer>
            </CollapsiblePanel>
        </div>
    );
};
