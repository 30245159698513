import Logger from "../../../common/util/Logger";
import { GeometryRetriever } from "./geometryRetriever";
import { ChangeTypes, GeometryChange } from "./types";
import { EditFeatureOptions } from "../../redux";
import { getChangedPointWithIndex } from "./utils";
import { EditAction } from "../../redux/types";
import { GeometryCoordinates } from "../../../core/geo/types";

const logger = new Logger("lineStringRetriever");

const identifyOperation = (
    oldCoordinates: GeometryCoordinates | undefined,
    newCoordinates: GeometryCoordinates,
    featureOptions?: EditFeatureOptions
): ChangeTypes => {
    // TODO ICH: refactor, rozhodovat sa na zaklade koordinatov
    if (oldCoordinates === undefined) {
        return ChangeTypes.INSERT_LINE;
    }
    if (oldCoordinates.length < (newCoordinates?.length ?? 0)) {
        return ChangeTypes.ADD_LINE_POINT;
    }
    if (oldCoordinates.length > (newCoordinates?.length ?? 0)) {
        return ChangeTypes.REMOVE_LINE_POINT;
    }
    if (featureOptions?.action === EditAction.REMOVE_VERTEX) {
        return ChangeTypes.NONE;
    }
    if (featureOptions?.action === EditAction.MOVE_FEATURE) {
        return ChangeTypes.MOVE_LINE;
    }

    return ChangeTypes.MOVE_LINE_POINT;
};

const handlePointAdded = (
    oldCoordinates: GeometryCoordinates | undefined,
    newCoordinates: GeometryCoordinates
): GeometryChange => {
    const change = getChangedPointWithIndex(oldCoordinates!, newCoordinates);
    logger.info(`line feature point added: ${change}`);
    return {
        type: ChangeTypes.ADD_LINE_POINT,
        coordinateChange: change,
    };
};

const handlePointRemoved = (
    oldCoordinates: GeometryCoordinates | undefined,
    newCoordinates: GeometryCoordinates
): GeometryChange => {
    const change = getChangedPointWithIndex(oldCoordinates!, newCoordinates);
    logger.info(`line feature point removed: ${change}`);
    return {
        type: ChangeTypes.REMOVE_LINE_POINT,
        coordinateChange: change,
    };
};

const handleWholeLineMoved = (
    oldCoordinates: GeometryCoordinates | undefined,
    newCoordinates: GeometryCoordinates
): GeometryChange => {
    logger.info("whole line feature moved");
    return {
        type: ChangeTypes.MOVE_LINE,
        coordinateChange: {
            coordinates: newCoordinates,
        },
    };
};

const handleNoChange = (): GeometryChange => {
    logger.info("line geometry not changed");
    return { type: ChangeTypes.NONE };
};

const handlePointMoved = (
    oldCoordinates: GeometryCoordinates | undefined,
    newCoordinates: GeometryCoordinates
): GeometryChange => {
    const change = getChangedPointWithIndex(oldCoordinates!, newCoordinates);
    // TODO ICH: why handle like this
    if (change) {
        logger.info(`line point moved: ${change}`);
        return {
            type: ChangeTypes.MOVE_LINE_POINT,
            coordinateChange: change,
        };
    }
    return handleNoChange();
};

const handleLineInserted = (
    oldCoordinates: GeometryCoordinates | undefined,
    newCoordinates: GeometryCoordinates
): GeometryChange => {
    logger.info(`inserting line with coordinates: ${newCoordinates}`);
    return {
        type: ChangeTypes.INSERT_LINE,
        coordinateChange: {
            coordinates: newCoordinates,
        },
    };
};

const handlers: {
    [key in ChangeTypes]: (
        oldCoordinates: GeometryCoordinates | undefined,
        newCoordinates: GeometryCoordinates
    ) => GeometryChange;
} = {
    ADD_LINE_POINT: handlePointAdded,
    REMOVE_LINE_POINT: handlePointRemoved,
    MOVE_LINE_POINT: handlePointMoved,
    MOVE_LINE: handleWholeLineMoved,
    INSERT_LINE: handleLineInserted,
    NONE: handleNoChange,
    // NOT IMPLEMENTED
    MOVE_POINT: () => {
        throw new Error("Not implemented yet. (MOVE_POINT)");
    },
    INSERT_POINT: () => {
        throw new Error("Not implemented yet. (INSERT_POINT)");
    },
    INSERT_POLYGON: () => {
        throw new Error("Not implemented yet. (INSERT_POLYGON)");
    },
    MOVE_POLYGON: () => {
        throw new Error("Not implemented yet. (MOVE_POLYGON)");
    },
    MOVE_POLYGON_POINT: () => {
        throw new Error("Not implemented yet. (MOVE_POLYGON_POINT)");
    },
    ADD_POLYGON_POINT: () => {
        throw new Error("Not implemented yet. (ADD_POLYGON_POINT)");
    },
    REMOVE_POLYGON_POINT: () => {
        throw new Error("Not implemented yet. (REMOVE_POLYGON_POINT)");
    },
    RADIUS_CHANGED: () => {
        throw new Error("Not implemented yet. (RADIUS_CHANGED)");
    },
    INSERT_CIRCLE: () => {
        throw new Error("Not implemented yet. (INSERT_CIRCLE)");
    },
    MOVE_CIRCLE: () => {
        throw new Error("Not implemented yet. (MOVE_CIRCLE)");
    },
};

export const lineStringRetriever: GeometryRetriever = {
    retrieveChange(oldCoordinates, newCoordinates, featureOptions): GeometryChange {
        const operation = identifyOperation(oldCoordinates, newCoordinates, featureOptions);
        return handlers[operation](oldCoordinates, newCoordinates);
    },
};
