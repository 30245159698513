import * as React from "react";
import { ModalHeader as RBModalHeader, ModalHeaderProps } from "react-bootstrap";
import { ThemeColor } from "../../theme";
import classNames from "classnames";

export type ModalHeaderType = ThemeColor;

interface IModalHeaderProps extends ModalHeaderProps {
    headerType?: ModalHeaderType;
}

export const ModalHeader = (props: IModalHeaderProps) => {
    const { children, headerType, className, ...rest } = props;

    const modalHeaderClassName = classNames(className, {
        [`modal-header-${headerType}`]: headerType,
    });

    return (
        <RBModalHeader className={modalHeaderClassName} {...rest}>
            {children}
        </RBModalHeader>
    );
};
