import { IPartialReducer } from "../../../../core/core-redux/reduxTypes";
import TreeItemFinder from "../../../../layers/util/TreeItemFinder";
import TreeItemVisibilityHandler from "../../../../layers/util/TreeItemVisibilityHandler";
import { FeatureFilterDataState } from "../reducer/featureFilterReducer";

export const toggleFeatureFilterVisibilityPartialReducer: IPartialReducer<FeatureFilterDataState> = (state, action) => {
    const treeItems = [...state.treeItems];
    const item = TreeItemFinder.findItem(action.filterId, treeItems);

    TreeItemVisibilityHandler.setTreeItemVisibility(item, action.visibility);

    return {
        ...state,
        treeItems: treeItems,
        asString: action.visibility ? treeItems[0].toString() : undefined,
    };
};
