import { createReducer } from "../../../core/core-redux/reducer/createReducer";
import { STORE_TICKET } from "./storeTicket.action";
import { AnyAction, IPartialReducer } from "../../../core/core-redux/reduxTypes";

export interface SessionState {
    ticket?: string;
}

const storeTicketPartialReducer: IPartialReducer<SessionState> = (state: SessionState, action: AnyAction) => {
    return {
        ticket: action.ticket,
    };
};

export const storeTicketReducer = createReducer<SessionState>(
    {
        ticket: undefined,
    },
    { [STORE_TICKET]: storeTicketPartialReducer }
);
