import * as React from "react";
import { SelectableButton } from "../../core/core-widgets/buttons/SelectableButton";
import { EditFeatureOptions, EditFeatureState } from "../redux";
import { GlyphIcon, Tooltip, TooltipPosition } from "../../core/core-widgets";
import { FeatureCollection, GeometryType } from "../../core/geo/types";
import { ArcMode, EditAction } from "../redux/types";

enum EditFeatureAction {
    DENSIFY = "densify",
    ADD_VERTEX = "add_vertex",
    REMOVE_VERTEX = "remove_vertex",
    MOVE = "move",
    FINISH = "finish",
    ARC_BY_EDGE = "arc_by_edge",
}

type EditFeatureActionDescriptor = {
    [key in EditFeatureAction]: {
        id: string;
        onClick: () => void;
        glyph: GlyphIcon;
        isSelected?: boolean;
        tooltipText: string;
        disabled: boolean;
        hidden?: boolean;
    };
};

export interface EditFeatureStateProps {
    editEnabled: boolean;
    editFeature: EditFeatureState;
    editAction: EditAction;
    editPaused: boolean;
    arcModeEnabled: boolean;
}

export interface EditFeatureDispatchProps {
    finish: () => void;
    densifyGeometry: () => void;
    changeEditActionMode: (value: EditAction) => void;
    setArcMode: (mode: ArcMode) => void;
    selectFeatureForEditing: (features: FeatureCollection, options: EditFeatureOptions) => void;
}

export const EditFeatureToolbar = (props: EditFeatureStateProps & EditFeatureDispatchProps) => {
    const {
        editEnabled,
        editFeature,
        editAction,
        densifyGeometry,
        changeEditActionMode,
        finish,
        editPaused,
        selectFeatureForEditing,
        setArcMode,
        arcModeEnabled,
    } = props;

    React.useEffect(() => {
        if (editFeature.editedFeatures && !editFeature.newFeature) {
            selectFeatureForEditing(editFeature.editedFeatures, editFeature.options);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editPaused]);

    if (!editEnabled) return null;

    const isLineOrPolygon =
        editFeature.geometryType === GeometryType.LINE_STRING || editFeature.geometryType === GeometryType.POLYGON;

    const handleAddVertex = () => {
        const action = editAction === EditAction.ADD_VERTEX ? EditAction.NONE : EditAction.ADD_VERTEX;
        changeEditActionMode(action);
    };

    const handleRemoveVertex = () => {
        const action = editAction === EditAction.REMOVE_VERTEX ? EditAction.NONE : EditAction.REMOVE_VERTEX;
        changeEditActionMode(action);
    };

    const handleMove = () => {
        const action = editAction === EditAction.MOVE_FEATURE ? EditAction.NONE : EditAction.MOVE_FEATURE;
        changeEditActionMode(action);
    };

    const changeArcMode = (mode: ArcMode) => {
        if (editFeature.options?.arcMode === mode) {
            setArcMode(ArcMode.NONE);
        } else {
            setArcMode(mode);
        }
    };

    const actions: EditFeatureActionDescriptor = {
        [EditFeatureAction.DENSIFY]: {
            id: "densifyGeometryButton",
            onClick: densifyGeometry,
            glyph: GlyphIcon.DENSIFY,
            isSelected: editFeature.options?.densifyGeometry,
            tooltipText: "Densify geometry",
            disabled: !isLineOrPolygon,
        },
        [EditFeatureAction.ADD_VERTEX]: {
            id: "addVertexButton",
            onClick: handleAddVertex,
            glyph: GlyphIcon.ADD_VERTEX,
            isSelected: editFeature.options?.action === EditAction.ADD_VERTEX,
            tooltipText: "Add vertex",
            disabled: !isLineOrPolygon,
        },
        [EditFeatureAction.REMOVE_VERTEX]: {
            id: "removeVertexButton",
            onClick: handleRemoveVertex,
            glyph: GlyphIcon.REMOVE_VERTEX,
            isSelected: editFeature.options?.action === EditAction.REMOVE_VERTEX,
            tooltipText: "Remove vertex",
            disabled: !isLineOrPolygon,
        },
        [EditFeatureAction.MOVE]: {
            id: "moveFeatureButton",
            onClick: handleMove,
            glyph: GlyphIcon.MOVE,
            isSelected: editFeature.options?.action === EditAction.MOVE_FEATURE,
            tooltipText: "Move Feature",
            disabled: !isLineOrPolygon,
            hidden: true,
        },
        [EditFeatureAction.FINISH]: {
            id: "finishVisualEditingButton",
            onClick: finish,
            glyph: GlyphIcon.OK,
            tooltipText: "Finish visual editing",
            disabled: editFeature.newFeature!,
        },
        [EditFeatureAction.ARC_BY_EDGE]: {
            id: "arcByEdgeButton",
            onClick: () => changeArcMode(ArcMode.ARC_BY_EDGE),
            glyph: GlyphIcon.ALERT,
            tooltipText: "Arc by edge",
            isSelected: editFeature.options?.arcMode === ArcMode.ARC_BY_EDGE,
            disabled: editFeature.geometryType !== GeometryType.POLYGON,
            hidden: !arcModeEnabled,
        },
    };

    return (
        <div className={"editToolbar"}>
            {Object.values(EditFeatureAction)
                .filter((action) => !actions[action].hidden)
                .map((action) => {
                    const config = actions[action];
                    return (
                        <Tooltip body={config.tooltipText} position={TooltipPosition.BOTTOM}>
                            <SelectableButton
                                id={config.id}
                                onClick={config.onClick}
                                glyph={config.glyph}
                                isSelected={config.isSelected}
                                disabled={config.disabled}
                                className={"toolbarItem"}
                            />
                        </Tooltip>
                    );
                })}
        </div>
    );
};
