import { Action } from "redux";
import { FeatureStyle } from "../../types";

export const SET_GEOMETRY_EDIT_STYLE = "SET_GEOMETRY_EDIT_STYLE";

export interface SetGeometryStyleAction extends Action {
    style: FeatureStyle;
}

export const setGeometryStyleAction = (style: FeatureStyle): SetGeometryStyleAction => ({
    type: SET_GEOMETRY_EDIT_STYLE,
    style: style,
});
