import { Feature } from "../../../core/geo/types";
import { Action } from "redux";

export const VIEW_GEOMETRY_ACTION = "VIEW_GEOMETRY";

export interface ViewGeometryAction extends Action {
    geoJsonFeature: Feature;
}

export const viewGeometryAction = (feature: Feature): ViewGeometryAction => ({
    type: VIEW_GEOMETRY_ACTION,
    geoJsonFeature: feature,
});
