import { geoCalcGeometryLoadedAction } from "@js/geocalc/action/geoCalcGeometryLoadedAction";
import { doNothingAction } from "@js/common/action/doNothingAction";
import { geoCalcStyleSelector, geoCalcEnabledSelector } from "@js/common/selector/configurationSelector";
import GeoCalculationItem from "@js/geocalc/dto/GeoCalculationItem";

import Logger from "@js/common/util/Logger";
import Item from "@js/layers/dto/Item";
import { STORE_TICKET } from "@js/session/redux/store-ticket/storeTicket.action.ts";
import { invokeRestServiceWithParameters } from "@js/common/util/web-service/gisServerServices.ts";
import { STORE_GIS_SERVER_URL } from "@js/layers/redux/gis-server-url/storeGisServerUrl.action";

const logger = new Logger("geoCalcInitialLoadEpic");

const geoCalcInitialLoadEpic = (action$, store) =>
    action$.ofType(STORE_TICKET, STORE_GIS_SERVER_URL).switchMap(() => {
        const state = store.getState();

        if (!geoCalcEnabledSelector(state)) {
            return [doNothingAction()];
        }

        if (!state.urlParams.geoCalcIds) {
            return [geoCalcGeometryLoadedAction([], geoCalcStyleSelector(state))];
        }

        const restOptions = {
            geoCalculationIds: state.urlParams.geoCalcIds,
        };

        return invokeRestServiceWithParameters(state, "sdo/geoCalculationsByIds", restOptions)
            .map((response) => {
                const calculations = response.data.map(
                    (calculation) =>
                        new GeoCalculationItem(
                            new Item(calculation.calculationId, calculation.calculationName, true, true, false, true),
                            JSON.parse(calculation.geometry)
                        )
                );
                return geoCalcGeometryLoadedAction(calculations, geoCalcStyleSelector(state));
            })
            .catch((e) => {
                logger.error("Error retrieving Geo Calculations from GIS Server.", e);
                return [doNothingAction()];
            });
    });

export default geoCalcInitialLoadEpic;
