import * as React from "react";
import { FlexBox } from "../boxes/FlexBox/FlexBox";
import { FlexAlignItems, FlexJustifyContent } from "../boxes/FlexBox/types";
import { ThemeColor } from "../theme";
import { GlyphIcon } from "../icons";
import { GlyphButton } from "../buttons";
import { Tooltip, TooltipPosition } from "../tooltips";
import { SpacingBox } from "../boxes/SpacingBox/SpacingBox";

interface PanelHeaderProps {
    type: ThemeColor;
    onClose?: () => void;
    content?: JSX.Element;
}

export const PanelHeader: React.FC<PanelHeaderProps> = (props) => {
    const className = `bg-${props.type}`;
    return (
        <SpacingBox pl={1}>
            <FlexBox
                justifyContent={FlexJustifyContent.BETWEEN}
                alignItems={FlexAlignItems.CENTER}
                className={className}
            >
                <h4>{props.children}</h4>
                {props.onClose && (
                    <Tooltip body={"Close"} position={TooltipPosition.BOTTOM}>
                        <GlyphButton id={"toggleVisibilityButton"} onClick={props.onClose} glyph={GlyphIcon.CLOSE} />
                    </Tooltip>
                )}
            </FlexBox>
        </SpacingBox>
    );
};
