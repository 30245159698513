import { asyncFetchDataAwaitableActionFactory } from "../../../core/core-redux/actions/asyncFetchDataAction";
import { getDataSetFilterByIdService } from "./getDataSetFilterById.service";
import { GetDataSetFilterByIdServiceParams } from "./types";
import { Filter } from "../../../filter/types";

export const GET_DATASET_FILTER_BY_ID = "GET_DATASET_FILTER_BY_ID";

export const getDatasetFilterByIdActionCreator = asyncFetchDataAwaitableActionFactory<
    GetDataSetFilterByIdServiceParams,
    Filter,
    any
>(GET_DATASET_FILTER_BY_ID, getDataSetFilterByIdService);
