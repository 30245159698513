import { Action } from "redux";
import { Layer } from "../drawLayersReducer";
import { Point } from "../../../../../rootState";

export const STORE_GEOMETRY_ACTION = "STORE_GEOMETRY_ACTION";

export interface StoreGeometryAction extends Action {
    layers: Layer[];
    points: Point[];
}

export const storeGeometryAction = (layers: Layer[], points: Point[]): StoreGeometryAction => ({
    type: STORE_GEOMETRY_ACTION,
    layers: layers,
    points: points,
});
