class EnvParams {
    constructor(validFrom, validTo, slots, requestedChanges) {
        this.validFrom = validFrom;
        this.validTo = validTo;
        this.slots = slots;
        this.requestedChanges = requestedChanges;
    }

    toRequestParams = () => {
        return `VALID_FROM:'${this.validFrom}';VALID_TO:'${this.validTo ?? ""}';SLOTS:'${this.slots}';REQUESTED_CHANGES:'${this.requestedChanges}'`;
    };
}

module.exports = EnvParams;
