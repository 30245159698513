import React from "react";
import LayerElement from "../../layers/component/LayerElement";
import DataSetFilterItem from "../DataSetFilter/dto/DataSetFilterItem";

export interface FeatureFilterStateProps {
    enabled: boolean;
    treeItems: DataSetFilterItem[];
}

export interface FeatureFilterDispatchProps {
    handleFeatureFilterVisibilityChange: (filterId: number, visibility: boolean) => void;
    handleRemoveFeatureFilter: () => void;
}

export const FeatureFilter = (props: FeatureFilterStateProps & FeatureFilterDispatchProps) => {
    const { enabled, treeItems, handleFeatureFilterVisibilityChange, handleRemoveFeatureFilter } = props;

    if (!enabled) {
        return null;
    }

    return (
        <div id={"featureTypeFilter"}>
            <LayerElement
                className={"featureTypeFilter"}
                id={"featureTypeFilter"}
                treeItems={treeItems}
                onTreeItemVisibilityChange={handleFeatureFilterVisibilityChange}
                onTreeItemRemove={handleRemoveFeatureFilter}
            />
        </div>
    );
};
