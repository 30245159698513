import { AxiosPromise } from "axios";
import { apiWrapper } from "../../axiosInstance";
import { GetDataSetFilterByIdServiceResponse } from "./types";
import { Slots } from "../../../common/slot/slots";

export const getDataSetFilterByIdEndpoint = (
    datasetFilterId: string,
    validFrom: string,
    slots?: Slots
): AxiosPromise<GetDataSetFilterByIdServiceResponse> => {
    const slotIds = slots ? slots.getSlotIds() : 0;
    const urlParams = `dataSetFilterId=${datasetFilterId}&slotIds=${slotIds}&validFrom=${encodeURIComponent(
        validFrom
    )}`;
    return apiWrapper.instance!.get(`rest/sdo/dataSetFilterById?${urlParams}`);
};
