import { Action } from "redux";

export const TOGGLE_FEATURE_FILTER_VISIBILITY = "TOGGLE_FEATURE_FILTER_VISIBILITY";

interface ToggleFeatureFilterVisibilityAction extends Action {
    filterId: number;
    visibility: boolean;
}

export const toggleFeatureFilterVisibilityAction = (
    filterId: number,
    visibility: boolean
): ToggleFeatureFilterVisibilityAction => {
    return {
        type: TOGGLE_FEATURE_FILTER_VISIBILITY,
        filterId: filterId,
        visibility: visibility,
    };
};
