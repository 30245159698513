import * as React from "react";
import RDDraggable from "react-draggable";
import { CSSProperties } from "react";
import { WindowEvents } from "../../../../common/events/EventConstants";
import { Dimensions, DraggableVector, Position } from "./types";

interface DraggableProps {
    style?: CSSProperties;
    className?: string;
    preferredPosition?: Position;
    applyWindowBoundaries?: boolean;
}

const defaultStyle: CSSProperties = {
    position: "absolute",
    zIndex: 100000000,
    top: 0,
    left: "-100%",
    width: 500,
    wordWrap: "break-word",
    backgroundColor: "white",
};

export const Draggable: React.FC<DraggableProps> = (props) => {
    const { style = defaultStyle } = props;

    const nodeRef = React.useRef<HTMLInputElement>(null);

    const [draggableVector, setDraggableVector] = React.useState<DraggableVector>({});

    React.useEffect(() => {
        const handler = () => {
            if (nodeRef.current) {
                const childDomRectangle = nodeRef.current.getBoundingClientRect();
                const position = adjustPosition(
                    { x: childDomRectangle.x, y: childDomRectangle.y },
                    {
                        width: childDomRectangle.width,
                        height: childDomRectangle.height,
                    }
                );
                setDraggableVector({
                    position: position,
                });
            }
        };
        window.addEventListener(WindowEvents.RESIZE, handler);
        return () => {
            window.removeEventListener(WindowEvents.RESIZE, handler);
        };
    }, []);

    /*
     * hook useEffect did not react on nodeRef dimensions change.
     * therefore ResizeObserver was added.
     */
    React.useEffect(() => {
        const resizeObserver = new ResizeObserver((event) => {
            if (event[0] && event[0].contentBoxSize[0]) {
                const contentBoxSize = event[0].contentBoxSize[0];
                setDraggableVector({
                    dimensions: {
                        width: contentBoxSize.inlineSize,
                        height: contentBoxSize.blockSize,
                    },
                });
            }
        });
        const nodeReference = nodeRef.current!;
        resizeObserver.observe(nodeReference!);
        return () => {
            resizeObserver.unobserve(nodeReference);
        };
    }, []);

    React.useEffect(() => {
        if (props.preferredPosition && draggableVector.dimensions) {
            setDraggableVector({
                position: adjustPosition(props.preferredPosition, draggableVector.dimensions),
            });
        }
    }, [props.preferredPosition, draggableVector.dimensions]);

    const adjustPosition = (position: Position, childDimensions: Dimensions) => {
        let adjustedX = position.x;
        if (position.x + childDimensions.width > window.innerWidth) {
            const delta = position.x + childDimensions.width - window.innerWidth;
            adjustedX = position.x - delta;
        }

        let adjustedY = position.y;
        if (position.y + childDimensions.height > window.innerHeight) {
            const delta = position.y + childDimensions.height - window.innerHeight;
            adjustedY = position.y - delta;
        }
        return { x: Math.max(adjustedX, 0), y: Math.max(adjustedY, 0) };
    };

    const calculateBounds = () => {
        if (nodeRef.current) {
            const childDomRectangle = nodeRef.current.getBoundingClientRect();
            return {
                top: 0,
                bottom: window.innerHeight - childDomRectangle.height,
                left: 0,
                right: window.innerWidth - childDomRectangle.width,
            };
        }
        return undefined;
    };

    return (
        <RDDraggable
            bounds={props.applyWindowBoundaries ? calculateBounds() : undefined}
            position={draggableVector.position}
            onStart={() =>
                setDraggableVector({
                    position: undefined,
                })
            }
        >
            <div ref={nodeRef} className={props.className} style={style}>
                {props.children}
            </div>
        </RDDraggable>
    );
};
