import { RootState } from "../../../../rootState";

export default class FilterSelector {
    static getDataSetFiltersAsString(state: RootState) {
        return state.featureFilter && state.featureFilter.asString
            ? state.featureFilter.asString
            : state.dataSetFilters && state.dataSetFilters.items.asString;
    }

    static isFeatureFilterPopulatedSelector(state: RootState) {
        return (
            state.featureFilter &&
            state.featureFilter.treeItems &&
            state.featureFilter.treeItems[0] &&
            state.featureFilter.treeItems[0].children &&
            state.featureFilter.treeItems[0].visibility &&
            state.featureFilter.treeItems[0].children.length > 0
        );
    }

    static getDataSetFilters(state: RootState) {
        return this.isFeatureFilterPopulatedSelector(state)
            ? state.featureFilter.filters
            : state.dataSetFilters && state.dataSetFilters.items && state.dataSetFilters.items.filters;
    }
}
