import { asyncFetchDataPromiseActionFactory } from "../../../core/core-redux/actions/asyncFetchDataAction";
import {
    featureLayersMappingService,
    FeatureLayersMappingServiceParams,
    FeatureLayersMappingServiceResponse,
} from "./featureLayersMapping.service";

export const FEATURE_LAYERS_MAPPING = "FEATURE_LAYERS_MAPPING";

export const featureLayersMappingActionCreator = asyncFetchDataPromiseActionFactory<
    FeatureLayersMappingServiceParams,
    FeatureLayersMappingServiceResponse,
    any
>(FEATURE_LAYERS_MAPPING, featureLayersMappingService);
