import { IAsyncDataState } from "../../../core/core-redux/reduxTypes";
import { asyncFetchDataReducerFactory } from "../../../core/core-redux/reducer/asyncFetchDataReducer";
import {
    LoadAllFeaturesInfoServiceParams,
    LoadAllFeaturesInfoServiceResponse,
} from "../../api/loadAllFeaturesInfo.service";
import { LOAD_ALL_FEATURES_INFO } from "../../api/loadAllFeaturesInfo.reduxActions";

export interface LoadAllFeaturesInfoDataState
    extends IAsyncDataState<LoadAllFeaturesInfoServiceResponse, any, LoadAllFeaturesInfoServiceParams> {}

export const loadAllFeaturesInfoReducer = asyncFetchDataReducerFactory<
    LoadAllFeaturesInfoServiceResponse,
    any,
    LoadAllFeaturesInfoServiceParams
>(LOAD_ALL_FEATURES_INFO);
