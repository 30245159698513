import * as React from "react";
import { DelimitedSection } from "../../core/core-widgets/surfaces/DelimitedSection/DelimitedSection";
import { BasicInput } from "../../core/core-widgets/inputs/BasicInput";
import { useState } from "react";
import { BasicButton, ButtonColor } from "../../core/core-widgets/buttons";
import { ButtonGroup } from "../../core/core-widgets/buttons/ButtonGroup";
import { Feature, FeatureGeometry, FeatureProps, GeometryType } from "../../core/geo/types";
import { Feature as GeoFeature } from "geojson";

export interface FeatureActionsDispatchProps {
    onEditFeature: (feature: Feature) => void;
    onViewFeature: (feature: Feature) => void;
}

export const FeatureActions = (props: FeatureActionsDispatchProps) => {
    const { onEditFeature, onViewFeature } = props;
    const [input, setInput] = useState("");

    const handleEditFeature = () => {
        // TODO ICH: make feature validator
        const feature = JSON.parse(input) as Feature;
        onEditFeature(feature);
    };

    const handleDrawPolygon = () => {
        let feature: GeoFeature<FeatureGeometry, FeatureProps> = {
            type: "Feature",
            geometry: {
                type: GeometryType.POLYGON,
                coordinates: [],
            },
            properties: {
                id: "",
                featureType: "Ase",
                featureId: 1,
            },
        };
        onEditFeature(feature);
    };

    const handleDrawLine = () => {
        let feature: GeoFeature<FeatureGeometry, FeatureProps> = {
            type: "Feature",
            geometry: {
                type: GeometryType.LINE_STRING,
                coordinates: [],
            },
            properties: {
                id: "",
                featureType: "Rte",
                featureId: 1,
            },
        };
        onEditFeature(feature);
    };

    const handleDrawPoint = () => {
        let feature: GeoFeature<FeatureGeometry, FeatureProps> = {
            type: "Feature",
            geometry: {
                type: GeometryType.POINT,
                coordinates: [],
            },
            properties: {
                id: "",
                featureType: "Rte",
                featureId: 1,
            },
        };
        onEditFeature(feature);
    };

    const handleViewFeature = () => {
        // TODO ICH: make geoJSON validator
        const feature = JSON.parse(input) as Feature;
        onViewFeature(feature);
    };

    return (
        <DelimitedSection title={"Feature actions"}>
            <BasicInput
                componentClass={"textarea"}
                type={"text"}
                value={input}
                onChangeValue={(value) => setInput(value)}
            />
            <ButtonGroup>
                <BasicButton color={ButtonColor.PRIMARY} className={"my-2"} onClick={handleEditFeature}>
                    Edit feature
                </BasicButton>
                <BasicButton color={ButtonColor.PRIMARY} className={"my-2"} onClick={handleViewFeature}>
                    View feature
                </BasicButton>
                <BasicButton color={ButtonColor.PRIMARY} className={"my-2"} onClick={handleDrawPolygon}>
                    Draw polygon
                </BasicButton>
                <BasicButton color={ButtonColor.PRIMARY} className={"my-2"} onClick={handleDrawLine}>
                    Draw line
                </BasicButton>
                <BasicButton color={ButtonColor.PRIMARY} className={"my-2"} onClick={handleDrawPoint}>
                    Draw point
                </BasicButton>
            </ButtonGroup>
        </DelimitedSection>
    );
};
