import { DataSetFilterItemsDataState } from "../reducer/dataSetFilterReducer";
import { IPartialReducer } from "../../../../core/core-redux/reduxTypes";
import DataSetFilterItem from "../../dto/DataSetFilterItem";

export const addDataSetFilterPartialReducer: IPartialReducer<DataSetFilterItemsDataState> = (state, action) => {
    const oldFilters = state.filters ?? [];
    const filters = action.filter.condition ? [...oldFilters, action.filter.condition] : [...oldFilters];
    return {
        ...state,
        items: [action.filter.item],
        filters: filters,
        asString: action.filter.item.children.filter((item: DataSetFilterItem) => item.visibility).join(),
    };
};
