export enum ChangeTypes {
    INSERT_POINT = "INSERT_POINT",
    MOVE_POINT = "MOVE_POINT",

    INSERT_LINE = "INSERT_LINE",
    MOVE_LINE = "MOVE_LINE",
    MOVE_LINE_POINT = "MOVE_LINE_POINT",
    ADD_LINE_POINT = "ADD_LINE_POINT",
    REMOVE_LINE_POINT = "REMOVE_LINE_POINT",

    INSERT_POLYGON = "INSERT_POLYGON",
    MOVE_POLYGON = "MOVE_POLYGON",
    MOVE_POLYGON_POINT = "MOVE_POLYGON_POINT",
    ADD_POLYGON_POINT = "ADD_POLYGON_POINT",
    REMOVE_POLYGON_POINT = "REMOVE_POLYGON_POINT",

    INSERT_CIRCLE = "INSERT_CIRCLE",
    MOVE_CIRCLE = "MOVE_CIRCLE",
    RADIUS_CHANGED = "RADIUS_CHANGED",

    NONE = "NONE",
}

export type CoordinateChange = {
    coordinates: any;
    pointIndex?: number;
    groupIndex?: number;
};

export type GeometryChange = {
    type: ChangeTypes;
    coordinateChange?: CoordinateChange;
    radius?: number;
};
