import { changeDrawingStatus } from "../../../../MapStore2/web/client/actions/draw";
import {
    DRAW_FEATURE_OPTIONS,
    EDIT_FEATURE_OPTIONS,
    VIEW_FEATURE_OPTIONS,
} from "../../../editFeature/util/featureOptions";
import { Feature, FeatureCollection, GeometryType } from "../../../core/geo/types";
import { EditFeatureOptions } from "../../../editFeature/redux";
import { Action } from "redux";

enum DrawStatus {
    DRAW_OR_EDIT_STATUS = "drawOrEdit",
    CLEAN = "clean",
}

const OWNER_EDIT_FEATURE = "editFeature";

const CIRCLE = "Circle";
const featureDrawMethod = (feature: Feature) => {
    if (feature.geometry.type === GeometryType.GEOMETRY_COLLECTION && feature.properties.isCircle) {
        return CIRCLE;
    }
    return feature.geometry.type;
};

export interface ChangeDrawingStatusAction extends Action {
    status: DrawStatus;
    method: GeometryType;
    owner: string;
    features: FeatureCollection[];
    options: unknown;
    style: unknown;
}

export const initForNewFeature = (features: FeatureCollection) => {
    return changeDrawingStatus(
        DrawStatus.DRAW_OR_EDIT_STATUS,
        featureDrawMethod(features.features[0]),
        OWNER_EDIT_FEATURE,
        [features],
        DRAW_FEATURE_OPTIONS
    );
};

export const selectFeatureForEditing = (features: FeatureCollection, options: EditFeatureOptions) => {
    return changeDrawingStatus(
        DrawStatus.DRAW_OR_EDIT_STATUS,
        featureDrawMethod(features.features[0]),
        OWNER_EDIT_FEATURE,
        [features],
        {
            ...EDIT_FEATURE_OPTIONS,
            selected: features.features[0],
            ...options,
        }
    );
};

export const selectFeatureForViewing = (features: FeatureCollection, options: EditFeatureOptions) => {
    return changeDrawingStatus(
        DrawStatus.DRAW_OR_EDIT_STATUS,
        featureDrawMethod(features.features[0]),
        OWNER_EDIT_FEATURE,
        [features],
        {
            ...VIEW_FEATURE_OPTIONS,
            selected: features.features[0],
            ...options,
        }
    );
};

export const cleanFeaturesFromEditing = () => {
    return changeDrawingStatus(DrawStatus.CLEAN, "", OWNER_EDIT_FEATURE, [], {});
};
