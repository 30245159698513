import {
    SHOW_SELECTED_FEATURE,
    ShowSelectedFeatureAction,
} from "../../feature/feature-detail-frame/redux/show-selected-feature/showSelectedFeature.action";
import { selectedFeaturesSelector } from "../../common/selector/featuresSelector";
import { doNothingAction } from "../../common/action/doNothingAction";
import { EpicFactory } from "../../core/core-redux/reduxTypes";
import { Action } from "redux";
import { RootState } from "../../rootState";
import { portalDispatcher } from "../dispatcher";
import { PortalFeatureDescriptor } from "../redux/select-feature/selectFeatureFromPortal.action";

export const selectFeatureToPortalEpic: EpicFactory<Action, RootState, Action> = (action$, store) =>
    action$.ofType<ShowSelectedFeatureAction>(SHOW_SELECTED_FEATURE).map((action) => {
        if (!!store.getState().urlParams.embeddedInPortal && !action.selectedFromPortal) {
            const selectedFeatures = selectedFeaturesSelector(store.getState()) ?? [];
            const featureDescriptors: PortalFeatureDescriptor[] = selectedFeatures.map((feature) => ({
                slotId: feature.properties.slotId!,
                featureType: feature.properties.featureShortName!,
                featureId: feature.properties.featureId!,
            }));

            portalDispatcher.selectFeatures(featureDescriptors);
        }
        return doNothingAction();
    });
