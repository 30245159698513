import { Action } from "redux";
import { Filter } from "../../../types";

export const ADD_DATA_SET_FILTER_TO_TREE_VIEW = "ADD_DATA_SET_FILTER_TO_TREE_VIEW";

export interface AddDataSetFilterToTreeViewAction extends Action {
    filter: Filter;
}

export const addDataSetFilterToTreeViewAction = (
    filter: Filter
): AddDataSetFilterToTreeViewAction => {
    return {
        type: ADD_DATA_SET_FILTER_TO_TREE_VIEW,
        filter: filter,
    };
};
