import React from "react";
import { connect } from "react-redux";
import { updateRouteOn3dSwitch } from "../../MapStore2/web/client/epics/globeswitcher";
import { registerPlugin } from "../common/mapstore/registerPlugin";
import { globeViewEpic } from "./epic/globeViewEpic";
import { hideWmsLayersEpic } from "./epic/hideWmsLayersEpic";
import { disableResolutionLimitsEpic } from "./epic/disableResolutionLimitsEpic";

class GlobeView extends React.Component {
    static propTypes = {};

    render() {
        return null;
    }
}

export const ConnectedGlobeView = connect(() => {
    return {};
}, {})(GlobeView);

export const GlobeViewPlugin = {
    name: "GlobeViewPlugin",
    config: {
        component: ConnectedGlobeView,
        epics: {
            globeViewEpic,
            updateRouteOn3dSwitch,
            hideWmsLayersEpic,
            disableResolutionLimitsEpic,
        },
    },
};
export const register = () => registerPlugin(GlobeViewPlugin);
