import React from "react";
import { FormattedMessage } from "react-intl";

export const ContextHolder = (properties) => {
    return (
        <>
            <div id={"contextWrapper"}>
                <FormattedMessage id={`workspaceChanges.context.${properties.context}`} />
            </div>
            <div id={"workspaceNameWrapper"}>{properties.workspaceName}</div>
        </>
    );
};
