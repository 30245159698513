import { Action } from "redux";
export const SET_WFS_3D_LAYER_COUNT = "SET_WFS_3D_LAYER_COUNT";

export interface SetWfs3dLayerCountAction extends Action {
    layerGroup: string;
    layerCount: number;
}

export const setWfs3dLayerCountAction = (layerGroup: string, layerCount: number): SetWfs3dLayerCountAction => {
    return {
        type: SET_WFS_3D_LAYER_COUNT,
        layerGroup: layerGroup,
        layerCount: layerCount,
    };
};
