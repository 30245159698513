import DataSetFilterItem from "../../../DataSetFilter/dto/DataSetFilterItem";
import { createReducer } from "../../../../core/core-redux/reducer/createReducer";
import { APPLY_FEATURE_FILTER } from "../apply-feature-filter/applyFeatureFilter.action";
import { REMOVE_FEATURE_FILTER } from "../remove-feature-filter/removeFeatureFilter.action";
import { TOGGLE_FEATURE_FILTER_VISIBILITY } from "../toggle-feature-filter-visibility/toggleFeatureFilterVisibility.action";
import { applyFeatureFilterPartialReducer } from "../apply-feature-filter/applyFeatureFilter.reducer";
import { removeFeatureFilterPartialReducer } from "../remove-feature-filter/removeFeatureFilter.reducer";
import { toggleFeatureFilterVisibilityPartialReducer } from "../toggle-feature-filter-visibility/toggleFeatureFilterVisibility.reducer";
import Item from "../../../../layers/dto/Item";
import { FilterCondition } from "../../../../globe/types";

export interface FeatureFilterDataState {
    treeItems: DataSetFilterItem[];
    filters?: FilterCondition[];
    asString?: string;
}

export const featureFilterReducer = createReducer<FeatureFilterDataState>(
    {
        treeItems: [
            new DataSetFilterItem(
                new Item(
                    "quickAdvancedFilterRoot",
                    undefined,
                    false,
                    true,
                    true,
                    false,
                    [],
                    null,
                    false,
                    "quickFilter.tree.rootItemName"
                )
            ),
        ],
    },
    {
        [APPLY_FEATURE_FILTER]: applyFeatureFilterPartialReducer,
        [REMOVE_FEATURE_FILTER]: removeFeatureFilterPartialReducer,
        [TOGGLE_FEATURE_FILTER_VISIBILITY]: toggleFeatureFilterVisibilityPartialReducer,
    }
);
