import { EditFeatureState } from "../editFeature.reducer";
import { IPartialReducer } from "../../../core/core-redux/reduxTypes";
import { EditMode } from "../../types";
import { mapFeatureToCollection } from "../../../core/geo/geometryMapper";
import { GeometryType } from "../../../core/geo/types";

export const viewGeometryPartialReducer: IPartialReducer<EditFeatureState> = (state, action) => {
    const feature = action.geoJsonFeature;
    let editedFeature;
    if (feature) {
        editedFeature = mapFeatureToCollection({
            ...feature,
            properties: {
                ...feature.properties,
                style: state.style,
                densifyGeometry: false,
            },
        });
    }

    return {
        ...state,
        editedFeatures: editedFeature,
        editMode: EditMode.NUMERICAL,
        geometryType: feature && (feature.geometry.type as GeometryType),
    };
};
