import * as React from "react";
import { useState } from "react";
import { DelimitedSection } from "../../core/core-widgets/surfaces/DelimitedSection/DelimitedSection";
import { ButtonGroup } from "../../core/core-widgets/buttons/ButtonGroup";
import { BasicButton, ButtonColor } from "../../core/core-widgets/buttons";
import { Glyphicon } from "react-bootstrap";
import { Draggable } from "../../core/core-widgets/surfaces/Draggable/Draggable";
import {
    getResolutionsForScales,
    getScales,
    getZoomFromResolution,
} from "../../../MapStore2/web/client/utils/MapUtils";
import { FlexBox } from "../../core/core-widgets/boxes/FlexBox/FlexBox";
import { FlexDirection, FlexJustifyContent } from "../../core/core-widgets/boxes/FlexBox/types";
import { Label } from "../../core/core-widgets/label/Label";
import { allGlyphIcons } from "../allGlyphIcons";

interface OtherActionsProps {
    currentZoomLevel: number;
}

export interface OtherActionsDispatchProps {
    onReloadMap: () => void;
}

export const OtherActions = (props: OtherActionsProps & OtherActionsDispatchProps) => {
    const { currentZoomLevel, onReloadMap } = props;
    const [showIcons, setShowIcons] = useState(false);
    const [showResolutions, setShowResolutions] = useState(false);
    const [resolutionsData, setResolutionsData] = useState([] as { resolution: any; zoom: any; scale: any }[]);
    const showIconsButtonColor = showIcons ? ButtonColor.WARNING : ButtonColor.PRIMARY;
    const showResolutionsButtonColor = showResolutions ? ButtonColor.WARNING : ButtonColor.PRIMARY;
    const glyphs = allGlyphIcons.map((glyph) => <Glyphicon style={{ margin: 5 }} glyph={glyph} title={glyph} />) ?? [];

    const handleLoadResolutions = () => {
        const scales: any[] = getScales(undefined, undefined);
        const data = scales.map((scale) => {
            const resolution = getResolutionsForScales([scale], undefined as any, undefined as any)[0];
            const zoom = getZoomFromResolution(resolution);
            return {
                resolution: resolution,
                zoom: zoom,
                scale: scale,
            };
        });
        setResolutionsData(data);
        setShowResolutions(!showResolutions);
    };

    return (
        <>
            <DelimitedSection title={"Other actions"}>
                <ButtonGroup>
                    <BasicButton color={showIconsButtonColor} onClick={() => setShowIcons(!showIcons)}>
                        {`${showIcons ? "Hide" : "Show"} icons`}
                    </BasicButton>
                    <BasicButton color={ButtonColor.PRIMARY} onClick={onReloadMap}>
                        Reload Map
                    </BasicButton>
                    <BasicButton color={showResolutionsButtonColor} onClick={handleLoadResolutions}>
                        Get resolutions
                    </BasicButton>
                </ButtonGroup>
            </DelimitedSection>
            {showIcons && <Draggable>{glyphs}</Draggable>}
            {showResolutions && (
                <Draggable>
                    <FlexBox direction={FlexDirection.ROW} justifyContent={FlexJustifyContent.AROUND}>
                        <div>
                            <Label>Zoom Level</Label>
                            {resolutionsData?.map((x) => {
                                const isCurrentZoom = currentZoomLevel === x.zoom;
                                return <div className={isCurrentZoom ? "currentZoom" : ""}>{x.zoom}</div>;
                            })}
                        </div>
                        <div>
                            <Label>Resolution</Label>
                            {resolutionsData?.map((x) => {
                                const isCurrentZoom = currentZoomLevel === x.zoom;
                                return <div className={isCurrentZoom ? "currentZoom" : ""}>{x.resolution}</div>;
                            })}
                        </div>
                        <div>
                            <Label>Scale</Label>
                            {resolutionsData?.map((x) => {
                                const isCurrentZoom = currentZoomLevel === x.zoom;
                                return <div className={isCurrentZoom ? "currentZoom" : ""}>{x.scale}</div>;
                            })}
                        </div>
                    </FlexBox>
                </Draggable>
            )}
        </>
    );
};
