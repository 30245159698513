import { Action } from "redux";

export const CLEANUP_FEATURE_INFO = "CLEANUP_FEATURE_INFO";

export interface CleanupFeatureInfoAction extends Action {}

export function cleanupFeatureInfoAction(): CleanupFeatureInfoAction {
    return {
        type: CLEANUP_FEATURE_INFO,
    };
}
