import { IPartialReducer } from "../../../../../core/core-redux/reduxTypes";
import { DrawLayersState } from "../drawLayersReducer";

export const storeGeometryPartialReducer: IPartialReducer<DrawLayersState> = (state, action) => {
    return {
        ...state,
        layers: action.layers,
        points: action.points,
    };
};
