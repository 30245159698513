import { DRAWING_FEATURE } from "../../../../MapStore2/web/client/actions/draw";
import { EpicFactory } from "../../../core/core-redux/reduxTypes";
import { EDIT_GEOMETRY, EditGeometryAction } from "./editGeometry.action";
import { RootState } from "../../../rootState";
import { Action } from "redux";
import { Observable } from "rxjs";
import { doNothingAction } from "../../../common/action/doNothingAction";
import {
    ChangeDrawingStatusAction,
    initForNewFeature,
    selectFeatureForEditing,
} from "../../../common/mapstore/action/draw";
import { CHANGE_EDIT_ACTION_MODE } from "../change-edit-action-mode";
import { setGeometryCreatedAction } from "../setGeometryCreated";
import { DENSIFY_GEOMETRY_ACTION } from "../densifyGeometry";
import { SET_ARC_MODE } from "../setArcMode";
import { setCqlFilterAction } from "../../../layers/redux/cql-filter/setCqlFilter.action";

const hideEditedFeaturesFromMap = (featureIds: any) => {
    return setCqlFilterAction(`FEATURE_ID NOT IN (${featureIds})`);
};

type AllActions = EditGeometryAction | Action | ChangeDrawingStatusAction;

export const editGeometryEpic: EpicFactory<AllActions, RootState, Action | ChangeDrawingStatusAction> = (
    action,
    store
): Observable<Action> =>
    action
        .ofType(EDIT_GEOMETRY, DRAWING_FEATURE, CHANGE_EDIT_ACTION_MODE, DENSIFY_GEOMETRY_ACTION, SET_ARC_MODE)
        .switchMap((action) => {
            const editFeature = store.getState().editFeature;
            let actions: Action[] = [];
            if (editFeature === undefined || editFeature.editedFeatures === undefined) {
                actions.push(doNothingAction());
                return actions;
            }

            if (action.type === DRAWING_FEATURE && editFeature.featureCreated) {
                actions.push(doNothingAction());
            } else {
                actions = [
                    editFeature.newFeature
                        ? initForNewFeature(editFeature.editedFeatures)
                        : selectFeatureForEditing(editFeature.editedFeatures, editFeature.options),
                ];
                if (action.type === EDIT_GEOMETRY) {
                    const editedFeatureIds = editFeature.editedFeatures?.features
                        .map((feature) => feature.properties.featureId)
                        .filter((id) => id && id > 0);

                    if (editedFeatureIds) {
                        actions.push(hideEditedFeaturesFromMap(editedFeatureIds));
                    }
                    actions.push(setGeometryCreatedAction());
                }
            }
            return actions;
        });
