import { connect } from "react-redux";
import { featureFilterEnabledSelector } from "../../common/selector/configurationSelector";
import { toggleFeatureFilterVisibilityAction } from "./redux/toggle-feature-filter-visibility/toggleFeatureFilterVisibility.action";
import { removeFeatureFilterAction } from "./redux/remove-feature-filter/removeFeatureFilter.action";
import { FeatureFilter, FeatureFilterDispatchProps, FeatureFilterStateProps } from "./FeatureFilter";
import { RootState } from "../../rootState";
import { Dispatch, Action } from "redux";

const mapStateToProps = (state: RootState): FeatureFilterStateProps => {
    return {
        enabled: featureFilterEnabledSelector(state) && state.urlParams.embeddedInPortal,
        treeItems: state.featureFilter.treeItems || [],
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): FeatureFilterDispatchProps => {
    return {
        handleFeatureFilterVisibilityChange: (filterId, visibility) =>
            dispatch(toggleFeatureFilterVisibilityAction(filterId, visibility)),
        handleRemoveFeatureFilter: () => dispatch(removeFeatureFilterAction()),
    };
};

export const ConnectedFeatureFilter = connect(mapStateToProps, mapDispatchToProps)(FeatureFilter);
