import { RootState } from "../rootState";
import { connect } from "react-redux";
import { registerPlugin } from "../common/mapstore/registerPlugin";
import { DevelopmentTools, DevelopmentToolsDispatchProps, DevelopmentToolsStateProps } from "./DevelopmentTools";
import { Dispatch } from "redux";
import { updateValidityIntervalAction } from "../portal/action/updateValidityIntervalAction";
import { editGeometryAction } from "../editFeature/redux/editGeometry";
import { viewGeometryAction } from "../editFeature/redux/viewGeometry";
import { reloadMapAction } from "../layers/action/reloadMapAction";
import { drawLayersAction } from "./components/Drawing/redux/drawLayers/drawLayers.action";
import { drawLayersEpic } from "./components/Drawing/redux/drawLayers/drawLayers.epic";
import { drawLayersReducer } from "./components/Drawing/redux/drawLayersReducer";
import { PortalMessageType } from "../libs/common/IFrameMessage";

const mapStateToProps = (state: RootState): DevelopmentToolsStateProps => {
    return {
        enabled: state.urlParams.developmentMode,
        currentZoomLevel: state.map?.present?.zoom ?? 0,
        drawingToolEnabled: state.drawLayers.enabled,
        points: state.drawLayers.points,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): DevelopmentToolsDispatchProps => {
    return {
        onFeaturesSelected: (features) =>
            window.postMessage({
                type: PortalMessageType.SELECT_FEATURE_FROM_PORTAL,
                data: { features: features },
            }),
        onChangeValidityInterval: (dateFrom, dateTo) =>
            dispatch(updateValidityIntervalAction(dateFrom ?? "", dateTo ?? "")),
        onDrawModeChange: (value) => dispatch(drawLayersAction(value)),
        onEditFeature: (value) => dispatch(editGeometryAction(value)),
        onViewFeature: (value) => dispatch(viewGeometryAction(value)),
        onReloadMap: () => dispatch(reloadMapAction()),
        onFilterChange: (value) =>
            window.postMessage({
                type: PortalMessageType.APPLY_FILTER,
                data: { filterId: value },
            }),
    };
};

export const ConnectedDevelopmentTools = connect(mapStateToProps, mapDispatchToProps)(DevelopmentTools);

export const DevelopmentToolsPlugin = {
    name: "DevelopmentToolsPlugin",
    config: {
        component: ConnectedDevelopmentTools,
        reducers: {
            drawLayers: drawLayersReducer,
        },
        epics: {
            drawLayersEpic,
        },
    },
};

export const register = () => registerPlugin(DevelopmentToolsPlugin);
