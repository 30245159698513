import { createReducer } from "../../../core/core-redux/reducer/createReducer";
import { SHOW_SELECTED_FEATURE } from "./show-selected-feature/showSelectedFeature.action";
import { showSelectedFeaturePartialReducer } from "./show-selected-feature/showSelectedFeature.reducer";
import { DESELECT_FEATURES } from "./deselect-features/deselectFeatures.action";
import { DESELECT_ALL_FEATURES } from "./deselect-all-features/deselectAllFeatures.action";
import { deselectFeaturesPartialReducer } from "./deselect-features/deselectFeatures.reducer";
import { deselectAllFeaturesPartialReducer } from "./deselect-all-features/deselectAllFeatures.reducer";
import { FeatureDetailState } from "./types";

export const featureDetailReducer = createReducer<FeatureDetailState>(
    {},
    {
        [SHOW_SELECTED_FEATURE]: showSelectedFeaturePartialReducer,
        [DESELECT_FEATURES]: deselectFeaturesPartialReducer,
        [DESELECT_ALL_FEATURES]: deselectAllFeaturesPartialReducer,
    }
);
