import Rx from "rxjs";
import { EpicFactory } from "../../core/core-redux/reduxTypes";
import { GLOBE_3D_PROJECTION } from "../../common/constants/Projections";
import { doNothingAction } from "../../common/action/doNothingAction";
import {
    BASELINE_LAYER_GROUP,
    wfs3dBaselineLayerSelector,
    wfs3dWorkspaceLayerSelector,
    WORKSPACE_LAYER_GROUP,
} from "../../common/selector/layerSelector";
import EnvParamsRetriever from "../../common/util/EnvParamsRetriever";
import { storeWfsFeatureCountAction } from "../action/storeWfsFeatureCountAction";
import { setWfs3dLayerCountAction } from "../action/setWfs3dLayerCountAction";
import { CHANGE_MAP_CRS, ChangeMapCrs } from "../../common/mapstore/action";
import { Action } from "redux";
import { Layer, RootState } from "../../rootState";
import { fromActionCreator } from "../../core/core-rx/types";
import { getFeatureCountActions } from "../api/getFeatureCount.reduxActions";

export const wfsDataCountEpic: EpicFactory<Action, RootState, Action> = (action$, store) =>
    action$
        .ofType<ChangeMapCrs>(CHANGE_MAP_CRS)
        .filter((action) => action.crs === GLOBE_3D_PROJECTION)
        .switchMap((action) => {
            const state = store.getState();
            const workspaceWfsLayers = wfs3dWorkspaceLayerSelector(state);
            const baselineWfsLayers = wfs3dBaselineLayerSelector(state);

            const allLayers: any[] = [];

            baselineWfsLayers.forEach((layer: Layer) => allLayers.push({ group: BASELINE_LAYER_GROUP, layer: layer }));
            workspaceWfsLayers.forEach((layer: Layer) =>
                allLayers.push({ group: WORKSPACE_LAYER_GROUP, layer: layer })
            );

            if (allLayers.length > 0) {
                return Rx.Observable.from(allLayers)
                    .mergeMap((layerDefinition) => {
                        const layerGroup = layerDefinition.group;
                        const wfs3dLayerName = layerDefinition.layer.wfs3dLayer;
                        const environmentParams =
                            layerGroup === BASELINE_LAYER_GROUP
                                ? EnvParamsRetriever.getBaselineEnvParams(state)
                                : EnvParamsRetriever.getWorkspaceEnvParams(state);
                        const params = {
                            typeName: layerDefinition.layer.wfs3dLayer,
                            ENV: environmentParams.toRequestParams(),
                            resultType: "hits",
                        };

                        return fromActionCreator(getFeatureCountActions, params).map((data) => {
                            if (data.response !== undefined) {
                                return storeWfsFeatureCountAction(layerGroup, wfs3dLayerName, data.response);
                            }
                            return doNothingAction();
                        });
                    })
                    .startWith(
                        setWfs3dLayerCountAction(BASELINE_LAYER_GROUP, baselineWfsLayers.length),
                        setWfs3dLayerCountAction(WORKSPACE_LAYER_GROUP, workspaceWfsLayers.length)
                    );
            }
            return [doNothingAction()];
        });
