import { createReducer } from "../../core/core-redux/reducer/createReducer";
import { FeatureCollection, GeometryType } from "../../core/geo/types";
import { EditMode, FeatureStyle } from "../types";
import { EDIT_GEOMETRY, editGeometryPartialReducer } from "./editGeometry";
import { VIEW_GEOMETRY_ACTION, viewGeometryPartialReducer } from "./viewGeometry";
import { FINISH_GEOMETRY_EDIT, finishGeometryPartialReducer } from "./finishGeometryEdit";
import { SET_GEOMETRY_CREATED, setGeometryCreatedPartialReducer } from "./setGeometryCreated";
import { SET_GEOMETRY_EDIT_STYLE, setGeometryStylePartialReducer } from "./setGeometryStyle";
import { DENSIFY_GEOMETRY_ACTION, densifyGeometryPartialReducer } from "./densifyGeometry";
import { UPDATE_FEATURE_GEOMETRY, updateFeaturePartialReducer } from "./updateFeature";
import { INSERT_FEATURE_GEOMETRY, insertFeaturePartialReducer } from "./insertFeature";
import { ArcMode, EditAction } from "./types";
import { CHANGE_EDIT_ACTION_MODE, changeEditActionModePartialReducer } from "./change-edit-action-mode";
import { DEFAULT_STYLE } from "../util/featureStyle";
import { geometryChangedPartialReducer } from "./geometryChanged/geometryChanged.reducer";
import { GEOMETRY_CHANGED } from "./geometryChanged/geometryChangedAction";
import { SET_ARC_MODE, setArcModePartialReducer } from "./setArcMode";

export interface EditFeatureOptions {
    densifyGeometry?: boolean;
    action?: EditAction;
    isCircle?: boolean;
    radiusBefore?: number;
    radiusNow?: number;
    editPaused: boolean;
    arcMode?: ArcMode;
}

export interface EditFeatureState {
    style: FeatureStyle;
    // TODO ICH: we support editing only one feature at time, so we can store only feature not collection and then transform feature to collection only when mapstore action is triggered
    editedFeatures?: FeatureCollection;
    options: EditFeatureOptions;
    editMode?: EditMode;
    geometryType?: GeometryType;
    featureCreated?: boolean;
    newFeature?: boolean;
}

export const editFeatureReducer = createReducer<EditFeatureState>(
    {
        style: DEFAULT_STYLE,
        editedFeatures: undefined,
        options: {
            densifyGeometry: true,
            action: EditAction.NONE,
            editPaused: false,
            arcMode: ArcMode.NONE,
        },
    },
    {
        [EDIT_GEOMETRY]: editGeometryPartialReducer,
        [VIEW_GEOMETRY_ACTION]: viewGeometryPartialReducer,
        [FINISH_GEOMETRY_EDIT]: finishGeometryPartialReducer,
        [SET_GEOMETRY_CREATED]: setGeometryCreatedPartialReducer,
        [SET_GEOMETRY_EDIT_STYLE]: setGeometryStylePartialReducer,
        [CHANGE_EDIT_ACTION_MODE]: changeEditActionModePartialReducer,
        [DENSIFY_GEOMETRY_ACTION]: densifyGeometryPartialReducer,
        [UPDATE_FEATURE_GEOMETRY]: updateFeaturePartialReducer,
        [INSERT_FEATURE_GEOMETRY]: insertFeaturePartialReducer,
        [GEOMETRY_CHANGED]: geometryChangedPartialReducer,
        [SET_ARC_MODE]: setArcModePartialReducer,
    }
);
