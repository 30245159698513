import * as React from "react";
import { BasicInput } from "../../core/core-widgets/inputs/BasicInput";
import { BasicButton, ButtonColor } from "../../core/core-widgets/buttons";
import { DelimitedSection } from "../../core/core-widgets/surfaces/DelimitedSection/DelimitedSection";
import { useState } from "react";
import { PortalFeatureDescriptor } from "../../portal/redux/select-feature/selectFeatureFromPortal.action";

export interface SelectFeatureDispatchProps {
    onFeaturesSelected: (feature: PortalFeatureDescriptor[]) => void;
}

export const SelectFeature = ({ onFeaturesSelected }: SelectFeatureDispatchProps) => {
    const [input, setInput] = useState('[{"featureId": 26, "featureType": "Ase", "slotId": 0}]');

    const handleClick = () => {
        // TODO ICH: make feature validator
        const features: PortalFeatureDescriptor[] = JSON.parse(input);
        onFeaturesSelected(features);
    };

    return (
        <DelimitedSection title={"Select feature"}>
            <BasicInput
                componentClass={"textarea"}
                type={"text"}
                value={input}
                onChangeValue={(value) => setInput(value)}
            />
            <BasicButton color={ButtonColor.PRIMARY} className={"my-2"} onClick={handleClick}>
                Select Feature
            </BasicButton>
        </DelimitedSection>
    );
};
