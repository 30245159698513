import classNames from "classnames";
import * as React from "react";

export interface IDelimitedSectionProps
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    title?: string;
}

export const DelimitedSection = (props: IDelimitedSectionProps) => {
    const { children, className: classNameProps, title, ...rest } = props;

    const className = classNames(classNameProps, "delimited-section");

    return (
        <div {...rest} className={className}>
            {title && <h4>{title}</h4>}
            {children}
        </div>
    );
};
