import { isCesium } from "@mapstore/selectors/maptype";

import { workspaceModeSelector } from "@js/common/selector/configurationSelector";
import { contextSelector, viewSelector } from "@js/changes/selector/workspaceChangesSelector";
import { BASELINE_VIEW, CONTEXT_BASELINE } from "@js/changes/changesConstants";

const selectedChangeTypesSelector = (state) =>
    workspaceModeSelector(state) ? [] : state.changeType && state.changeType.changeType;
const workspaceChangeTypesSelector = (state) =>
    isCesium(state)
        ? []
        : selectedChangeTypesSelector(state) &&
          selectedChangeTypesSelector(state).filter((changeType) => ["I", "U", "N"].includes(changeType));
const baselineChangeTypesSelector = (state) =>
    isCesium(state)
        ? []
        : contextSelector(state) === CONTEXT_BASELINE || viewSelector(state) === BASELINE_VIEW
        ? ["O", "W"]
        : selectedChangeTypesSelector(state) &&
          selectedChangeTypesSelector(state).filter((changeType) => ["O", "W"].includes(changeType));
export { workspaceChangeTypesSelector, baselineChangeTypesSelector };
