import * as React from "react";
import { IntlContext } from "../../contexts/IntlContext";
import { formatNumber } from "../../utils/formatNumber";

interface FormattedNumberProps extends React.HTMLAttributes<HTMLSpanElement> {
    value?: number;
    /** number of floating point fraction decimal digits */
    fractionDigits?: number;
    /** if true, floating point number is always displayed with all fraction decimal digits (necessary zeros are added) */
    fullPrecision?: boolean;
}

export const FormattedNumber: React.FC<FormattedNumberProps> = (props) => {
    const { value, fractionDigits, fullPrecision, children, ...rest } = props;
    return (
        <IntlContext.Consumer>
            {({ intl }) => {
                return <span {...rest}>{formatNumber(intl, value, fullPrecision, fractionDigits)}</span>;
            }}
        </IntlContext.Consumer>
    );
};
