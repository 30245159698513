import { DataSetFilterItemsDataState } from "../reducer/dataSetFilterReducer";
import { IPartialReducer } from "../../../../core/core-redux/reduxTypes";
import TreeItemFinder from "../../../../layers/util/TreeItemFinder";
import TreeItemVisibilityHandler from "../../../../layers/util/TreeItemVisibilityHandler";
import { FilterCondition } from "../../../../globe/types";

function updateFeatureFiltersVisibility(
    filter: FilterCondition,
    isEnabled: boolean,
    filterId?: string,
    featureType?: string
) {
    return filter.featureFilters.filterIds.map((filterIds) => ({
        ...filterIds,
        enabled:
            (!filterId || filter.filterId === filterId) && (!featureType || filterIds.featureType === featureType)
                ? isEnabled
                : filterIds.enabled,
    }));
}

const updateFiltersVisibility = (
    filters: FilterCondition[],
    isEnabled: boolean,
    filterId?: string,
    featureType?: string
) => {
    return filters.map((filter) => ({
        ...filter,
        featureFilters: {
            ...filter.featureFilters,
            filterIds: updateFeatureFiltersVisibility(filter, isEnabled, filterId, featureType),
        },
        enabled: updateFeatureFiltersVisibility(filter, isEnabled, filterId, featureType).some(
            (filter) => filter.enabled
        ),
    }));
};

function isRootItem(toggledItem: any) {
    return !toggledItem.parent;
}

const getFiltersWithUpdatedVisibility = (toggledItem: any, filters: FilterCondition[], isEnabled: boolean) => {
    if (isRootItem(toggledItem)) {
        return updateFiltersVisibility(filters, isEnabled);
    }

    if (toggledItem.group) {
        return updateFiltersVisibility(filters, isEnabled, toggledItem.id);
    }

    return updateFiltersVisibility(filters, isEnabled, toggledItem.parent.id, toggledItem.featureType);
};

export const toggleDataSetFilterVisibilityPartialReducer: IPartialReducer<DataSetFilterItemsDataState> = (
    state,
    action
) => {
    const items = state.items;
    const toggledItem = TreeItemFinder.findItem(action.filterId, items);

    TreeItemVisibilityHandler.setTreeItemVisibility(toggledItem, action.visibility);

    let filters = state.filters ? [...state.filters] : [];

    return {
        ...state,
        items: [...items],
        filters: getFiltersWithUpdatedVisibility(toggledItem, filters, action.visibility),
        asString: items[0].children.filter((item: any) => item.visibility).join(),
    };
};
