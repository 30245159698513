import { Action, Reducer } from "redux";
import { IPartialReducer } from "../reduxTypes";

export function createReducer<S>(initialState: S, handlers: { [actionType: string]: IPartialReducer<S> }): Reducer<S> {
    return <A extends Action>(state: S = initialState, action: A): S => {
        if (typeof handlers[action.type] === "function") {
            const resultOfPartialReducer: Partial<S> = handlers[action.type](state, action);
            if (typeof resultOfPartialReducer === "object" && resultOfPartialReducer === null) {
                return state;
            }

            return Object.assign({}, state, resultOfPartialReducer);
        }
        return state;
    };
}
