import { Action } from "redux";

export const FINISH_VISUAL_GEOMETRY_EDIT = "FINISH_VISUAL_GEOMETRY_EDIT";

export interface FinishVisualGeometryEditAction extends Action {}

export const finishVisualGeometryEditAction = (): FinishVisualGeometryEditAction => {
    return {
        type: FINISH_VISUAL_GEOMETRY_EDIT,
    };
};
