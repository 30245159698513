const React = require("react");

const { ChangeTypeSelector } = require("@js/changes/component/body/child/ChangeTypeSelector");
const Loader = require("@js/changes/component/body/child/Loader");
const { ViewTypeSelector } = require("@js/changes/component/body/child/ViewTypeSelector");

const { WORKSPACE_VIEW, CONTEXT_WORKSPACE } = require("@js/changes/changesConstants");

const ChangesBody = (properties) => {
    return (
        <div className={"changesVisualizationPanelBody"}>
            <ViewTypeSelector
                hidden={properties.context !== CONTEXT_WORKSPACE}
                handleViewTypeChanged={properties.handleViewTypeChanged}
                viewType={properties.viewType}
            />

            <ChangeTypeSelector
                selectedChangeTypes={properties.selectedChangeTypes}
                disabled={properties.displayLoading}
                handleChangesTypeChanged={properties.handleChangesTypeChanged}
                changeTypeColoring={properties.changeTypeColoring}
                hidden={properties.viewType !== WORKSPACE_VIEW || properties.isCesium}
            />

            <Loader displayLoading={properties.displayLoading} />
        </div>
    );
};

module.exports = ChangesBody;
