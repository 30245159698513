import * as React from "react";
import { BasicButton, ButtonColor } from "../../core/core-widgets/buttons";
import { DelimitedSection } from "../../core/core-widgets/surfaces/DelimitedSection/DelimitedSection";
import { InputDate } from "../../core/core-widgets/inputs/InputDate";
import { useState } from "react";

export interface ChangeValidityIntervalProps {
    onChangeValidityInterval: (dateFrom: string | undefined, dateTo: string | undefined) => void;
}

export const ChangeValidityInterval = (props: ChangeValidityIntervalProps) => {
    const { onChangeValidityInterval } = props;
    const [valueFrom, setValueFrom] = useState<string>();
    const [valueTo, setValueTo] = useState<string>();
    const handleClick = () => {
        onChangeValidityInterval(valueFrom, valueTo);
    };

    return (
        <DelimitedSection title={"Validity interval"}>
            <InputDate label={"Date from"} value={valueFrom} onChangeValue={(value) => setValueFrom(value)} />
            <InputDate label={"Date to"} value={valueTo} onChangeValue={(value) => setValueTo(value)} />
            <BasicButton color={ButtonColor.PRIMARY} className={"my-2"} onClick={handleClick}>
                Change interval
            </BasicButton>
        </DelimitedSection>
    );
};
