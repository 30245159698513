export enum CRS {
    EPSG_4326 = "EPSG:4326",
    EPSG_3857 = "EPSG:3857",
}

export type MapPosition = {
    x: number;
    y: number;
    crs: CRS;
};

export enum Projection {
    EPSG_4326 = "EPSG:4326",
    EPSG_3857 = "EPSG:3857",
    EPSG_900913 = "EPSG:900913",
    GLOBE_3D = "3D",
}
