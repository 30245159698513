import { Action } from "redux";
import { Feature } from "../../../core/geo/types";

export const EDIT_GEOMETRY = "EDIT_GEOMETRY";

export interface EditGeometryAction extends Action {
    geoJsonFeature: Feature;
}

export const editGeometryAction = (feature: Feature): EditGeometryAction => ({
    type: EDIT_GEOMETRY,
    geoJsonFeature: feature,
});
