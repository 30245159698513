import * as React from "react";
import { Feature } from "../../core/geo/types";

interface FeatureListItemProps {
    featureKey: string;
    features: Feature[];
    onSelect: (feature: Feature, selectedFromPortal?: boolean) => void;
    cleanup: () => void;
}

export const FeatureListItem = (props: FeatureListItemProps) => {
    const { featureKey, features, onSelect, cleanup } = props;
    return (
        <div className={"groupWrapper"}>
            <span className={"featureType"}>{featureKey.substring(0, featureKey.indexOf("Form"))} </span>
            {features.map((value, index) => {
                return (
                    <div
                        className={"selectFeatureWrapper"}
                        key={"sfw" + index}
                        onClick={() => {
                            onSelect(value);
                            cleanup();
                        }}
                    >
                        <span className={"selectFeatureSpan"} key={"sfs" + index}>
                            {value.properties.naturalKey}
                        </span>
                    </div>
                );
            })}
        </div>
    );
};
