const NONE = 'NONE';

function doNothingAction() {
    return {
        type: NONE
    };
}

module.exports = {
    doNothingAction,
    NONE
};
