import { Action } from "redux";
import { FilterCondition } from "../types";

export const UPDATE_WFS_FILTER = "UPDATE_WFS_FILTER";

export interface UpdateWfsFilterAction extends Action {
    filters: FilterCondition[];
}

export const updateWfsFilterAction = (filters: FilterCondition[]): UpdateWfsFilterAction => {
    return {
        type: UPDATE_WFS_FILTER,
        filters: filters,
    };
};
