import { connect } from "react-redux";
import {
    FeatureDetailFrame,
    FeatureDetailFrameDispatchProps,
    FeatureDetailFrameStateProps,
} from "./FeatureDetailFrame";
import { registerPlugin } from "../../common/mapstore/registerPlugin";
import { Dispatch, Action } from "redux";
import { RootState } from "../../rootState";
import { wizardPortalUrlSelector } from "../../common/selector/configurationSelector";
import { sessionSelector } from "../../session/redux/sessionSelector";
import { featureDetailReducer } from "./redux/featureDetail.reducer";
import { deselectAllFeaturesAction } from "./redux/deselect-all-features/deselectAllFeatures.action";
import { deselectFeatureEpic } from "./redux/deselect-features/deselectFeatures.epic";
import { getFeatureInfoReducer } from "../../api/api-packages/feature-info/featureInfo.reducer";

const mapStateToProps = (state: RootState): FeatureDetailFrameStateProps => {
    const lastFeature = state.selectedFeatures?.features?.at(-1);
    return {
        enabled: !state.urlParams.embeddedInPortal,
        wizardPortalUrl: wizardPortalUrlSelector(state),
        feature: lastFeature,
        ticket: sessionSelector(state),
        effectiveDate: state.urlParams.effectiveDate,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): FeatureDetailFrameDispatchProps => {
    return {
        onClose: () => dispatch(deselectAllFeaturesAction()),
    };
};

export const ConnectedFeatureDetailFrame = connect(mapStateToProps, mapDispatchToProps)(FeatureDetailFrame);

export const FeatureDetailFramePlugin = {
    name: "FeatureDetailFramePlugin",
    config: {
        component: ConnectedFeatureDetailFrame,
        reducers: {
            selectedFeatures: featureDetailReducer,
            wmsFeatures: getFeatureInfoReducer,
        },
        epics: {
            deselectFeatureEpic,
        },
    },
};

export const register = () => registerPlugin(FeatureDetailFramePlugin);
