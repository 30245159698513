import React, { useState } from "react";
import { getGisServerUrl } from "../api/gisServerUrl";
import { Layer } from "../rootState";
import { LayerProperties as LayerProps } from "../common/mapstore/action/layers";

export interface LayerPropertiesDispatchProps {
    storeGisServerUrlAction: (url: string) => void;
    changeLayerPropertiesAction: (layer: string, properties: LayerProps) => void;
}

export interface LayerPropertiesStateProps {
    gisServerUrl: string;
    geoserverBackgroundLayers: Layer[];
}

export const LayerProperties = (props: LayerPropertiesDispatchProps & LayerPropertiesStateProps) => {
    const { storeGisServerUrlAction, changeLayerPropertiesAction, geoserverBackgroundLayers, gisServerUrl } = props;

    const [backgroundUrlUpdated, setBackgroundUrlUpdated] = useState(false);

    React.useEffect(() => {
        if (!backgroundUrlUpdated && geoserverBackgroundLayers && gisServerUrl) {
            geoserverBackgroundLayers.forEach((layer) =>
                changeLayerPropertiesAction(layer.id, {
                    url: gisServerUrl + "/wms",
                    thumbURL: layer.thumbURL.replace(/.*(www.*)/, `${gisServerUrl}/$1`),
                    visibility: layer.visibility,
                })
            );
            setBackgroundUrlUpdated(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geoserverBackgroundLayers, gisServerUrl]);

    React.useEffect(() => {
        const handleStoreGisServerUrl = async () => {
            const url = await getGisServerUrl();
            storeGisServerUrlAction(url);
        };
        handleStoreGisServerUrl();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};
