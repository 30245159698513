import * as React from "react";
import { DelimitedSection } from "../../../core/core-widgets/surfaces/DelimitedSection/DelimitedSection";
import { BasicButton, ButtonColor } from "../../../core/core-widgets/buttons";
import { DrawResults } from "./DrawResults";
import { Point } from "../../../rootState";

interface DrawingToolProps {
    enabled: boolean;
    points?: Point[];
}

export interface DrawingToolDispatchProps {
    onDrawModeChange: (value: boolean) => void;
}

export const DrawingTool = (props: DrawingToolProps & DrawingToolDispatchProps) => {
    const { enabled, points, onDrawModeChange } = props;
    const buttonColor = enabled ? ButtonColor.WARNING : ButtonColor.PRIMARY;
    const handleClick = () => {
        onDrawModeChange(!enabled);
    };
    return (
        <DelimitedSection title={"Drawing tool"}>
            {enabled && <DrawResults points={points} />}
            <BasicButton color={buttonColor} onClick={handleClick} className={"my-2"}>
                {`Toggle draw mode ${enabled ? "off" : "on"}`}
            </BasicButton>
        </DelimitedSection>
    );
};
