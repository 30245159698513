import DataSetFilterItem from "./DataSetFilter/dto/DataSetFilterItem";
import Item from "../layers/dto/Item";
import {
    ApiFeatureFilter,
    DataSetFilterDefinition,
    GetDataSetFilterByIdServiceResponse,
} from "../api/api-packages/getDataSetFilterById/types";
import { FeatureFilter, FilterCondition } from "../globe/types";

const warning =
    "The preview function doesn't currently support exclude filter, spatial or source conditions and filter condition parameters. Due to the complexity of on-demand evaluation of such a condition the shown results may not be accurate and may not match the actual exported data based on this filter.";

export const mapToLayerItems = (data: DataSetFilterDefinition): DataSetFilterItem => {
    return new DataSetFilterItem(
        new Item(
            data.identifier,
            data.name,
            true,
            true,
            true,
            true,
            data.featureTypes.map((featureType: any) => {
                return new DataSetFilterItem(
                    new Item(
                        featureType.shortFeatureName + data.identifier,
                        featureType.featureHumanName,
                        true,
                        true,
                        false,
                        false
                    ),
                    featureType.shortFeatureName
                );
            }),
            null,
            data.inaccurateFilter ? warning : null
        )
    );
};

const mapCondition = (data: ApiFeatureFilter): FeatureFilter => {
    return {
        featureType: data.featureType,
        excludeIds: data.filterIds.flatMap((filterId) => filterId.excludeIds),
        includeIds: data.filterIds.flatMap((filterId) => filterId.includeIds),
        enabled: true,
    };
};

const mapConditions = (data: ApiFeatureFilter[]): FeatureFilter[] => {
    return data.map((filter) => mapCondition(filter));
};

export const mapToFilterCondition = (data: GetDataSetFilterByIdServiceResponse): FilterCondition => {
    data.gisFilterCondition.filterIds.map((filterId) => filterId.featureType);

    return {
        filterId: data.dataSetFilterDefinition.identifier,
        featureFilters: {
            includeAllLayers: data.gisFilterCondition.includeAllLayers,
            filterIds: mapConditions(data.gisFilterCondition.filterIds),
        },
        enabled: true,
    };
};
