import * as React from "react";

import {
    FlexAlignContent,
    FlexAlignItems,
    FlexAlignSelf,
    FlexDirection,
    FlexGrowthType,
    FlexJustifyContent,
    FlexWrap,
} from "./types";

interface IFlexBoxProps {
    inline?: boolean;
    direction?: FlexDirection;
    justifyContent?: FlexJustifyContent;
    alignItems?: FlexAlignItems;
    alignSelf?: typeof FlexAlignSelf;
    flexFill?: boolean;
    growthType?: FlexGrowthType;
    wrap?: FlexWrap;
    order?: number;
    alignContent?: FlexAlignContent;
    className?: string;
    margin?: string;
    marginLeft?: string;
    marginRight?: string;
    marginTop?: string;
    padding?: string;
    paddingLeft?: string;
    paddingRight?: string;
    paddingTop?: string;
    gap?: string;
}

export const FlexBox: React.FC<IFlexBoxProps> = (props) => {
    const {
        inline,
        direction,
        justifyContent,
        alignItems,
        alignSelf,
        flexFill,
        growthType,
        wrap,
        order,
        alignContent,
        className,
        children,
        ...boxProps
    } = props;

    return (
        <div
            style={{
                display: "flex",
                flexDirection: direction,
                flexWrap: wrap,
                justifyContent: justifyContent,
                alignItems: alignItems,
                alignContent: alignContent,
                margin: props.margin,
                marginLeft: props.marginLeft,
                marginRight: props.marginRight,
                marginTop: props.marginTop,
                padding: props.padding,
                paddingLeft: props.paddingLeft,
                paddingRight: props.paddingRight,
                paddingTop: props.paddingTop,
                gap: props.gap,
            }}
            {...boxProps}
            className={className}
        >
            {children}
        </div>
    );
};
