import * as React from "react";
import { FlexBox } from "../../../core/core-widgets/boxes/FlexBox/FlexBox";
import { Label } from "../../../core/core-widgets/label/Label";
import { FormattedPoint } from "../../../core/core-widgets/data-displays/formatting/FormattedPoint";
import { FormattedDMS } from "../../../core/core-widgets/data-displays/formatting/FormattedDMS";
import { FlexJustifyContent } from "../../../core/core-widgets/boxes/FlexBox/types";
import { Point } from "../../../rootState";

export enum PointFormat {
    POINT = "point",
    DMS = "dms",
}

interface PointInfoProps {
    title: string;
    point: Point;
    format?: PointFormat;
}

export const PointInfo = ({ title, point, format = PointFormat.POINT }: PointInfoProps) => {
    console.log("Point", point.pixel);
    return (
        <>
            <Label>{title}</Label>
            <FlexBox justifyContent={FlexJustifyContent.BETWEEN}>
                <FormattedPoint x={point.pixel.x} y={point.pixel.y} />
                <FormattedDMS longitude={point.latlng.lng} latitude={point.latlng.lat} />
            </FlexBox>
        </>
    );
};
