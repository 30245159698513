import { Feature, FeatureCollection, FeatureGeometry, GeometryCoordinates } from "./types";
import uuid from "uuid";
import { Geometry, Position } from "geojson";
import {
    isGeometryCollection,
    isLineString,
    isMultiLineString,
    isMultiPoint,
    isMultiPolygon,
    isPoint,
    isPolygon,
} from "./assertions";

export const mapFeatureToCollection = (feature: Feature): FeatureCollection => {
    const id = feature.properties.id ?? uuid();
    if (
        !isGeometryCollection(feature.geometry) &&
        (!feature.geometry.coordinates || feature.geometry.coordinates.length <= 0)
    ) {
        const defaultCoordinates = mapGeometryCoordinates(feature.geometry);
        return {
            type: "FeatureCollection",
            features: [
                {
                    ...feature,
                    properties: {
                        ...feature.properties,
                        id: id,
                    },
                    geometry: {
                        ...feature.geometry,
                        coordinates: defaultCoordinates,
                    } as FeatureGeometry,
                },
            ],
        };
    }
    return {
        type: "FeatureCollection",
        features: [
            {
                ...feature,
                properties: {
                    ...feature.properties,
                    id: id,
                },
            },
        ],
    };
};

const mapGeometryCoordinates = (geometry: Geometry): GeometryCoordinates => {
    if (isPoint(geometry)) {
        return [0, 0];
    }
    if (isMultiPoint(geometry)) {
        return [
            [0, 0],
            [0, 0],
        ] as Position[];
    }
    if (isLineString(geometry)) {
        return [
            [0, 0],
            [0, 0],
        ] as Position[];
    }

    if (isMultiLineString(geometry)) {
        return [
            [
                [0, 0],
                [0, 0],
                [0, 0],
            ],
        ] as Position[][];
    }

    if (isPolygon(geometry)) {
        return [
            [
                [0, 0],
                [0, 0],
                [0, 0],
            ],
        ] as Position[][];
    }

    if (isMultiPolygon(geometry)) {
        return [
            [
                [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                ],
            ],
        ] as Position[][][];
    }
    throw new Error(`Unknown geometry type: ${geometry.type}`);
};
