import { Action } from "redux";
import { EpicFactory } from "../../../core/core-redux/reduxTypes";
import { MAP_CONFIG_LOADED } from "../../../../MapStore2/web/client/actions/config";
import { RootState } from "../../../rootState";
import { setGeometryStyleAction } from "./setGeometryStyle.action";
import { getFeatureStyle } from "../../util/featureStyle";

export const setGeometryStyleEpic: EpicFactory<Action, RootState, Action> = (action, store) =>
    action.ofType(MAP_CONFIG_LOADED).map(() => {
        const state = store.getState();
        return setGeometryStyleAction(getFeatureStyle(state));
    });
