const CENTER_MAP_TO_GEOCALCULATION = 'CENTER_MAP_TO_GEOCALCULATION';

const centerMapOnGeoCalculationAction = (calculationId) => {
    return {
        type: CENTER_MAP_TO_GEOCALCULATION,
        calculationId: calculationId
    };
};

export {CENTER_MAP_TO_GEOCALCULATION, centerMapOnGeoCalculationAction};
