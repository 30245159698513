import { AnyAction, IPartialReducer } from "../../../core/core-redux/reduxTypes";
import { EditFeatureState } from "../editFeature.reducer";
import { EditMode } from "../../types";
import { Feature, FeatureProps, GeometryType } from "../../../core/geo/types";
import { isGeometryCollection, isPolygon } from "../../../core/geo/assertions";
import { mapFeatureToCollection } from "../../../core/geo/geometryMapper";
import Logger from "../../../common/util/Logger";

const logger = new Logger("editGeometryPartialReducer");

const appendFirstElementToEnd = (feature: Feature) => {
    if (isPolygon(feature.geometry) && feature.geometry.coordinates && feature.geometry.coordinates.length > 0) {
        const coordinates = [...feature.geometry.coordinates];
        const lastElementIndex = coordinates[0].length - 1;
        for (const groupIndex in coordinates) {
            if (
                coordinates[groupIndex][0][0] !== coordinates[groupIndex][lastElementIndex][0] &&
                coordinates[groupIndex][0][1] !== coordinates[groupIndex][lastElementIndex][1]
            ) {
                coordinates[groupIndex].push(coordinates[groupIndex][0]);
            }
        }
    }
};

const isNewFeature = (feature: Feature) => {
    if (isGeometryCollection(feature.geometry)) {
        if (feature.properties.isCircle) {
            return feature.geometry.geometries.length === 0;
        }
        return feature.geometry.geometries === undefined || feature.geometry.geometries.length === 0;
    }
    return !feature.geometry.coordinates || feature.geometry.coordinates.length === 0;
};

export const editGeometryPartialReducer: IPartialReducer<EditFeatureState> = (state, action: AnyAction) => {
    const densifyGeometry = state.options?.densifyGeometry ?? true;
    const featureProperties: FeatureProps = {
        ...action.geoJsonFeature.properties,
        style: state.style,
        densifyGeometry: densifyGeometry,
    };
    const feature: Feature = {
        ...action.geoJsonFeature,
        properties: featureProperties,
    };
    if (!isGeometryCollection(feature.geometry)) {
        logger.info(
            `adding feature with id '${feature.properties.featureId}', feature type '${feature.properties.featureType}', geometry type '${feature.geometry.type}', coordinates: '${feature.geometry.coordinates}' to edit mode`
        );
    } else {
        logger.info(
            `adding new feature with type '${feature.properties.featureType}', geometry type '${feature.geometry.type}' to edit mode`
        );
    }
    appendFirstElementToEnd(feature);
    return {
        ...state,
        editedFeatures: mapFeatureToCollection(feature),
        editMode: EditMode.GRAPHICAL,
        geometryType: feature.geometry.type as GeometryType,
        newFeature: isNewFeature(action.geoJsonFeature),
        options: {
            ...state.options,
            editPaused: false,
            densifyGeometry: densifyGeometry,
        },
    };
};
