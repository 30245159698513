import { IAsyncDataState } from "../../../core/core-redux/reduxTypes";
import { GET_CAPABILITIES } from "./getCapabilities.action";
import { GetCapabilitiesServiceParams, GetCapabilitiesServiceResponse } from "./getCapabilities.service";
import { asyncFetchDataReducerFactory } from "../../../core/core-redux/reducer/asyncFetchDataReducer";

export interface GetCapabilitiesDataState
    extends IAsyncDataState<GetCapabilitiesServiceResponse, any, GetCapabilitiesServiceParams> {}

export const getCapabilitiesReducer = asyncFetchDataReducerFactory<
    GetCapabilitiesServiceResponse,
    any,
    GetCapabilitiesServiceParams
>(GET_CAPABILITIES);
