import React from 'react';
import PropTypes from 'prop-types';

class PaginatorElement extends React.Component {

    static propTypes = {
        className: PropTypes.string,
        display: PropTypes.bool,
        text: PropTypes.string,
        onClick: PropTypes.func
    };

    render() {
        if (this.props.display) {
            return <div className={this.props.className} onClick={this.props.onClick}>{this.props.text}</div>;
        }
        return <div/>;
    }
}

class Paginator extends React.Component {

    static propTypes = {
        pageNumber: PropTypes.number,
        totalPages: PropTypes.number,
        onPageChange: PropTypes.func
    };

    static defaultProps = {
        pageNumber: 1,
        totalPages: 1
    };

    render() {

        if (this.props.onPageChange) {
            return (<div className={"paging"}>
                <PaginatorElement text={'⮜'}
                                  className={"arrow prev"}
                                  display={this.props.pageNumber > 1}
                                  onClick={() => {
                                      this.props.onPageChange(this.props.pageNumber - 1)
                                  }}/>
                <PaginatorElement text={`page ${this.props.pageNumber} of ${this.props.totalPages}`}
                                  className={"pagenum"}
                                  display={this.props.totalPages > 0}/>
                <PaginatorElement text={'⮞'}
                                  className={"arrow next"}
                                  display={this.props.pageNumber < this.props.totalPages}
                                  onClick={() => {this.props.onPageChange(this.props.pageNumber + 1);}}/>
            </div>);
        }
        return null;
    }
}

export default Paginator;
