import { asyncFetchDataAwaitableActionFactory } from "../../../core/core-redux/actions/asyncFetchDataAction";
import { getDataSetFiltersService, GetDataSetFiltersServiceParams } from "./getDataSetFilters.service";
import DataSetFilterItem from "../../../filter/DataSetFilter/dto/DataSetFilterItem";

export const GET_DATASET_FILTERS = "GET_DATASET_FILTERS";

export const getDatasetFiltersActionCreator = asyncFetchDataAwaitableActionFactory<
    GetDataSetFiltersServiceParams,
    DataSetFilterItem[],
    any
>(GET_DATASET_FILTERS, getDataSetFiltersService);
