import moment from "moment";
import { Moment } from "moment-timezone";

function parseDateTime(value: any): Moment | undefined {
    let date = moment(value);
    if (typeof value === "string") {
        if (date.isValid()) {
            return date;
        }
    }
    date = moment(value, "YYYY-MM-DDTHH:mm:ss.SSS");
    if (date.isValid()) {
        return date;
    }

    return undefined;
}

export function formatAsDateTimeWithTimeZone(value: any): string | undefined {
    return parseDateTime(value)?.utc().format("YYYY-MM-DDTHH:mm:ssZ");
}

export function formatAsDateTimeWithMiliSeconds(value: any): string | undefined {
    return parseDateTime(value)?.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
}

export const formatDate = (moment: Moment) => moment.utc().format("YYYY-MM-DDTHH:mm:ssZ");

export const today = () => moment();

export const formattedToday = () => today().utc().format("YYYY-MM-DDTHH:mm:ssZ");
