import React from "react";
import PropTypes from "prop-types";

import GlyphButton from "../../layers/component/GlyphButton";
import GlyphIcons from "../../common/constants/GlyphIcons";
import TextButton from "../../layers/component/TextButton";
import TooltipIcon from "../../layers/component/TooltipIcon";
import Item from "../../layers/dto/Item";

const ITEM_BUTTON_CLASS_NAME = "itemButton";
const HIDE_BUTTON_CLASS_NAME = "hideButton";
const ADD_BUTTON_CLASS_NAME = "addButton";
const ITEM_TEXT_CLASS_NAME = "itemText";
const REMOVE_BUTTON_CLASS_NAME = "removeButton";
const LAYER_ITEM_CLASS_NAME = "layerItem";

class LayerItem extends React.Component {
    static propTypes = {
        item: PropTypes.instanceOf(Item),
        className: PropTypes.string,
        itemVisible: PropTypes.bool,
        itemExpanded: PropTypes.bool,
        warningText: PropTypes.string,
        onHide: PropTypes.func,
        onAdd: PropTypes.func,
        onRemove: PropTypes.func,
        onClick: PropTypes.func,
        onExpand: PropTypes.func,
        onRefresh: PropTypes.func,
    };

    render() {
        const warning = this.props.item && this.props.item.warning;
        return (
            <div
                id={`${this.props.className}${this.props.item.id}`}
                className={`${LAYER_ITEM_CLASS_NAME} ${this.props.className} ${warning ? "layerItemWarning" : ""}`}
                key={this.props.item.id}
            >
                <GlyphButton
                    id={HIDE_BUTTON_CLASS_NAME + this.props.item.id}
                    itemId={this.props.item.id}
                    onClick={this.props.onHide ? this.props.onHide : null}
                    className={`${ITEM_BUTTON_CLASS_NAME} ${HIDE_BUTTON_CLASS_NAME}`}
                    glyph={this.props.itemVisible ? GlyphIcons.EYE_OPEN : GlyphIcons.EYE_CLOSE}
                    boolParameter={this.props.itemVisible}
                    tooltip={"Toggle Visibility"}
                />
                <TextButton
                    id={ITEM_TEXT_CLASS_NAME + this.props.item.id}
                    className={ITEM_TEXT_CLASS_NAME}
                    onClick={this.props.onClick}
                    itemId={this.props.item.id}
                    messageKey={this.props.item.messageKey}
                    text={this.props.item.name}
                />
                <TooltipIcon
                    id={"warning" + this.props.item.id}
                    itemId={this.props.item.id}
                    className={`${ITEM_BUTTON_CLASS_NAME} warning`}
                    tooltip={warning}
                    glyph={GlyphIcons.WARNING}
                />
                <GlyphButton
                    id={ADD_BUTTON_CLASS_NAME + this.props.item.id}
                    itemId={this.props.item.id}
                    onClick={this.props.onAdd ? this.props.onAdd : null}
                    className={`${ITEM_BUTTON_CLASS_NAME} ${ADD_BUTTON_CLASS_NAME}`}
                    glyph={GlyphIcons.PLUS}
                    tooltip={"Add Item"}
                />
                <GlyphButton
                    id={REMOVE_BUTTON_CLASS_NAME + this.props.item.id}
                    itemId={this.props.item.id}
                    onClick={this.props.item.removable && this.props.onRemove ? this.props.onRemove : null}
                    className={`${ITEM_BUTTON_CLASS_NAME} ${REMOVE_BUTTON_CLASS_NAME}`}
                    glyph={GlyphIcons.REMOVE}
                    tooltip={"Remove Item"}
                />
                <GlyphButton
                    id={"expand" + this.props.item.id}
                    itemId={this.props.item.id}
                    onClick={this.props.onExpand ? this.props.onExpand : null}
                    className={`${ITEM_BUTTON_CLASS_NAME} expand`}
                    glyph={this.props.itemExpanded ? GlyphIcons.FOLDER_OPEN : GlyphIcons.FOLDER_CLOSE}
                    boolParameter={this.props.itemExpanded}
                    tooltip={this.props.itemExpanded ? "Close Group" : "Open Group"}
                />
                <GlyphButton
                    id={"refresh" + this.props.item.id}
                    itemId={this.props.item.id}
                    onClick={this.props.onRefresh ? this.props.onRefresh : null}
                    className={`${ITEM_BUTTON_CLASS_NAME} refresh`}
                    glyph={GlyphIcons.REFRESH}
                    tooltip={"Refresh Items"}
                />
            </div>
        );
    }
}

export default LayerItem;
