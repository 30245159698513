import * as React from "react";
import { PointInfo } from "./PointInfo";
import { PointsDistance } from "./PointsDistance";
import { Point } from "../../../rootState";

interface DrawResultsProps {
    points?: Point[];
}

export const DrawResults = (props: DrawResultsProps) => {
    const { points } = props;
    return (
        <>
            {points && points.map((point, index) => <PointInfo title={`Point ${index + 1}`} point={point} />)}
            {points && <PointsDistance point1={points[0]} point2={points[1]} />}
        </>
    );
};
