import { api } from "../../apiProvider";
import { WfsServiceService } from "./wfsService.service";
import { EPSG_4326 } from "../../../common/constants/Projections";

export const wfsServiceAdapter: WfsServiceService = async (params) => {
    const defaultParams = {
        service: "wfs",
        version: "2.0.0",
        request: "GetFeature",
        outputFormat: "application/json",
        srsName: EPSG_4326,
    };

    const merged = { ...defaultParams, ...params };

    const requestParams = Object.keys(merged)
        .map((key) => {
            return key + "=" + (merged as any)[key];
        })
        .join("&");
    const resp = await api.wfsService(requestParams);

    return {
        ...resp.data,
        features: resp.data.features.map((apiFeature) => ({
            ...apiFeature,
            properties: {
                id: `${apiFeature.properties.FEATURE_ID}`,
                featureType: apiFeature.properties.FEATURE_FORM,
                featureId: apiFeature.properties.FEATURE_ID,
                featureForm: apiFeature.properties.FEATURE_FORM,
                featureShortName: apiFeature.properties.FEATURE_SHORT_NAME,
                slotId: apiFeature.properties.SLOT_ID,
                naturalKey: apiFeature.properties.NATURAL_KEY,
                layer: apiFeature.properties.LAYER_NAME,
            },
        })),
    };
};
