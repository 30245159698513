const React = require('react');
const PropTypes = require('prop-types');
const moment = require('moment-timezone');
const DatePicker = require("react-datepicker").default;

const PERM_STRING = "2222-02-02";
const PERM = moment(PERM_STRING);
const DAYS_IN_AIRAC_CYCLE = 28;
const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS";

require("react-datepicker/dist/react-datepicker.css")

const setLocalZone = (date, timezone) => {
    const dateWithoutZone = moment
        .tz(date, timezone)
        .format(DATE_TIME_FORMAT)
    const localZone = moment(dateWithoutZone).format("Z")
    const dateWithLocalZone = [dateWithoutZone, localZone].join("")

    return new Date(dateWithLocalZone)
}

const setOtherZone = (date, timezone) => {
    const dateWithoutZone = moment(date).format(DATE_TIME_FORMAT)
    const otherZone = moment.tz(date, timezone).format("Z")
    const dateWithOtherZone = [dateWithoutZone, otherZone].join("")

    return new Date(dateWithOtherZone)
}

const airacDates = () => {
    const daysHighlighted = [];
    const firstAiracDate = moment("2009-01-15");
    let i = 1;
    while (daysHighlighted.length === 0 || daysHighlighted[daysHighlighted.length - 1] < PERM) {
        daysHighlighted.push(firstAiracDate.add(DAYS_IN_AIRAC_CYCLE, 'days').toDate())
        i++;
    }
    return daysHighlighted;
}


class AeroDatePicker extends React.Component {
    static propTypes = {
        selectedDate: PropTypes.object,
        onDateChange: PropTypes.func,
        timezone: PropTypes.string,
        highlightAiracDates: PropTypes.bool,
        dateFormat: PropTypes.string
    };

    render() {
        return (
            <DatePicker
                selected={this.props.selectedDate ? setLocalZone(this.props.selectedDate, this.props.timezone) : new Date()}
                onChange={(value) => {
                    this.props.onDateChange(setOtherZone(value, this.props.timezone))
                }}
                highlightDates={this.props.highlightAiracDates ? [{"highlightedDate": airacDates()}] : []}
                dateFormat={this.props.dateFormat}
                showYearDropdown={true}
                showMonthDropdown={true}
                dropdownMode="select"
                {...this.props}
            />
        );
    }

}
module.exports = AeroDatePicker;
