const GEOCALC_GEOMETRY_REMOVED = 'GEOCALC_GEOMETRY_REMOVED';

const geoCalcGeometryRemovedAction = (geoCalculationId) => {
    return {
        type: GEOCALC_GEOMETRY_REMOVED,
        geoCalculation: geoCalculationId
    };
};

export {GEOCALC_GEOMETRY_REMOVED, geoCalcGeometryRemovedAction};
