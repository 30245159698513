import { Feature } from "../../core/geo/types";
import { standardApiHandler } from "../../core/core-api/apiTools";
import { loadAllFeaturesInfoAdapter } from "./loadAllFeaturesInfo.adapter";

export type LoadAllFeaturesInfoServiceParams = {
    wmsParams: { url: string; identifier: string; ticket: string | undefined }[];
    wfsParams?: {
        typeName: string;
        CQL_FILTER: string;
        ENV: string;
        wizardSuiteProperties: boolean;
        ticket: string | undefined;
    };
};
export type LoadAllFeaturesInfoServiceResponse = Feature[];

export type LoadAllFeaturesInfoService = (
    params: LoadAllFeaturesInfoServiceParams
) => Promise<LoadAllFeaturesInfoServiceResponse>;
export const loadAllFeaturesInfoService: LoadAllFeaturesInfoService = standardApiHandler(loadAllFeaturesInfoAdapter);
