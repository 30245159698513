import { LoadSlotChangesService } from "./loadSlotChanges.service";
import { api } from "../../apiProvider";
import moment from "moment";
import { formatDate } from "../../../core/helpers/dateTime";

export const loadSlotChangesAdapter: LoadSlotChangesService = async (params) => {
    let queryString;
    if (typeof params.slotIds === "string") {
        queryString = `slotIds=${params.slotIds}`;
    } else {
        queryString = params.slotIds.map((slotId) => `slotIds=${slotId}`).join("&");
    }
    const resp = await api.loadSlotChanges(`${queryString}&forceReload=${params.forceReload}`);
    return formatDate(moment(resp.data));
};
