import { Action } from "redux";

export const DISABLE_BASELINE_LAYERS = "DISABLE_BASELINE_LAYERS";

export interface DisableBaselineLayersAction extends Action {
    disabled: boolean;
}

export const disabledBaselineLayersAction = (disabled: boolean): DisableBaselineLayersAction => {
    return {
        type: DISABLE_BASELINE_LAYERS,
        disabled: disabled,
    };
};
