import { RootState } from "../rootState";
import { connect } from "react-redux";
import { projectionDefsSelector, projectionSelector } from "../../MapStore2/web/client/selectors/map";
import { ProjectionSelector, ProjectionSelectorDispatchProps, ProjectionSelectorProps } from "./ProjectionSelector";
import { changeMapCrs } from "../../MapStore2/web/client/actions/map";
import ConfigUtils from "../../MapStore2/web/client/utils/ConfigUtils";

const mapStateToProps = (state: RootState): ProjectionSelectorProps => {
    const plugins = ConfigUtils.getConfigProp("plugins");
    const projectionConfig = plugins.desktop.find(
        (item: string | { name: string; cfg: any }) =>
            typeof item !== "string" && item?.name && item.name === "ProjectionSelector"
    );
    return {
        projectionDefs: projectionDefsSelector(state),
        selected: projectionSelector(state),
        ...projectionConfig.cfg,
    };
};

const mapDispatchToProps = (dispatch: any): ProjectionSelectorDispatchProps => {
    return {
        onSelect: (content: string) => dispatch(changeMapCrs(content)),
    };
};

export const ConnectedProjectionSelector = connect(mapStateToProps, mapDispatchToProps)(ProjectionSelector);
