import * as React from "react";
import { GlobalSpinnerIndicator } from "../core/core-widgets/feedback/spinners/GlobalSpinner/GlobalSpinnerIndicator";
import { GlobalSpinnerProvider } from "../core/core-widgets/feedback/spinners/GlobalSpinner/GlobalSpinnerContext";

interface SpinnerProps {
    show: boolean;
}

export const Spinner = (props: SpinnerProps) => {
    const { show } = props;
    return <GlobalSpinnerProvider>{show && <GlobalSpinnerIndicator />}</GlobalSpinnerProvider>;
};
