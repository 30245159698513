import { show, warning } from "../../../../MapStore2/web/client/actions/notifications";
import { Action } from "redux";

export type NotificationPosition = "tc" | "bc";
export const NotificationPosition = {
    TOP_CENTER: "tc" as NotificationPosition,
    BOTTOM_CENTER: "bc" as NotificationPosition,
};

export interface Notification {
    title: string;
    message: string;
    autoDismiss?: number;
    position?: NotificationPosition;
}

export const showError = (error: Notification) =>
    show(
        {
            title: error.title,
            message: error.message,
            autoDismiss: error.autoDismiss ?? 1,
            position: error.position ?? "tc",
        },
        "error"
    ) as Action;

export const showWarning = (notification: Notification) => {
    return warning(notification);
};
