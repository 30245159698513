import { RootState } from "../../../rootState";
import { EpicFactory } from "../../../core/core-redux/reduxTypes";
import { Action } from "redux";
import { FINISH_VISUAL_GEOMETRY_EDIT } from "../../action/finishVisualGeometryEditAction";
import { viewGeometryAction } from "../viewGeometry";
import { portalDispatcher } from "../../../portal/dispatcher";

export const finishVisualGeometryEpic: EpicFactory<Action, RootState, Action> = (action, store) =>
    action.ofType(FINISH_VISUAL_GEOMETRY_EDIT).map(() => {
        portalDispatcher.finishVisualGeometryEditing();
        const editFeature = store.getState().editFeature;
        return viewGeometryAction(editFeature?.editedFeatures?.features[0]!);
    });
