import React from "react";
import { AsyncState } from "../core/core-redux/reduxTypes";

export interface AutoLoginDispatchProps {
    loginToWizardSuite: () => Promise<string>;
    storeTicket: (ticket: string) => void;
    showError: (title: string, message: string) => void;
}

export interface AutoLoginStateProps {
    autoLogin: boolean;
    axiosInitialized: boolean;
    loginState: AsyncState;
}

export const AutoLogin = (props: AutoLoginDispatchProps & AutoLoginStateProps) => {
    const { autoLogin, loginToWizardSuite, storeTicket, axiosInitialized, loginState, showError } = props;

    React.useEffect(() => {
        if (loginState === AsyncState.ERROR) {
            showError("Auto Login Failed", "Login to Wizard Suite failed");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginState]);

    React.useEffect(() => {
        const handleLoginToWizardSuite = async () => {
            const ticket = await loginToWizardSuite();
            storeTicket(ticket);
        };

        if (autoLogin && axiosInitialized) {
            handleLoginToWizardSuite();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoLogin, axiosInitialized]);

    return null;
};
