import * as React from "react";
import { OverlayTrigger as RBOverlayTrigger, OverlayTriggerProps as RBOverlayTriggerProps } from "react-bootstrap";
import { TooltipPosition } from "./types";

interface OverlayTriggerProps extends RBOverlayTriggerProps {
    overlay: JSX.Element;
    position: TooltipPosition;
}

export const OverlayTrigger = (props: OverlayTriggerProps) => {
    const { overlay, position } = props;
    return (
        <RBOverlayTrigger overlay={overlay} placement={position}>
            {props.children}
        </RBOverlayTrigger>
    );
};
