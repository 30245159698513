import {MAP_CONFIG_LOAD_ERROR} from '@mapstore/actions/config';
import {error} from "@mapstore/actions/notifications";

const Logger = require('@js/common/util/Logger');
const logger = new Logger("initFeatureToLayerMappingEpic");

const configurationErrorEpic = (action$) =>
    action$.ofType(MAP_CONFIG_LOAD_ERROR)
        .map((action) => {
            const errorText = `Failed to load '${action.mapId}' map configuration`;
            logger.error(errorText);
            return error({
                title: "Configuration Load Error",
                message: errorText,
                autoDismiss: 0,
                position: "tc"
            });
        });
export default configurationErrorEpic;
