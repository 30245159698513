import React from 'react';
import PropTypes from 'prop-types';

class Filter extends React.Component {

    static propTypes = {
        id: PropTypes.string,
        placeholder: PropTypes.string,
        onChange: PropTypes.func,
        value: PropTypes.string
    };

    static defaultProps = {
        placeholder: "Filter Value"
    };

    render() {
        if (this.props.onChange) {
            return (
                <div className={"textFilter"}>
                    <input id={this.props.id} type="text" onChange={(e) => this.props.onChange(e)}
                           value={this.props.value} placeholder={this.props.placeholder}/>
                </div>
            );
        }

        return null;
    }
}

export default Filter;
