import { EditFeatureState } from "../editFeature.reducer";
import { IPartialReducer } from "../../../core/core-redux/reduxTypes";
import Logger from "../../../common/util/Logger";
import { isGeometryCollection } from "../../../core/geo/assertions";
import { mapFeatureToCollection } from "../../../core/geo/geometryMapper";

const logger = new Logger("insertFeaturePartialReducer");

export const insertFeaturePartialReducer: IPartialReducer<EditFeatureState> = (state, action) => {
    const feature = action.feature;
    if (isGeometryCollection(feature.geometry)) {
        logger.info(`inserting feature ${feature.properties.id} coordinates: ${feature.geometry}`);
    } else {
        logger.info(`inserting feature ${feature.properties.id} coordinates: ${feature.geometry.coordinates}`);
    }
    const stateFeature = state.editedFeatures?.features[0]!;
    const updatedFeature = {
        ...stateFeature,
        geometry: feature.geometry,
        properties: {
            ...stateFeature.properties,
            radius: feature.properties.radius,
        },
    };

    return {
        ...state,
        editedFeatures: mapFeatureToCollection(updatedFeature),
        newFeature: false,
    };
};
