const { updateViewTypeAction } = require("@js/changes/action/updateViewTypeAction");
const { MAP_CONFIG_LOADED } = require("@mapstore/actions/config");

function getViewType(state) {
    if (state.workspaceChanges.view) {
        return state.workspaceChanges.view;
    } else if (state.urlParams.slots.haveSlotIds() && state.urlParams.viewType !== "base") {
        return "workspace";
    } else {
        return "base";
    }
}

const viewTypeEpic = (action$, store) =>
    action$.ofType(MAP_CONFIG_LOADED).map(() => {
        const state = store.getState();

        return updateViewTypeAction(getViewType(state));
    });

module.exports = viewTypeEpic;
