import { CoordinateChange } from "./types";
import { numberComparator } from "../../../core/comparators/numberComparator";
import { GeometryCoordinates } from "../../../core/geo/types";

const getCoordinateIndex = (coordinatesArray: any[], coordinate: any) => {
    for (const index in coordinatesArray) {
        if (
            numberComparator(coordinatesArray[index][0], coordinate[0]) &&
            numberComparator(coordinatesArray[index][1], coordinate[1])
        )
            return index;
    }
    return undefined;
};

const getChangedCoordinates = (coordinatesToLookIn: any[], coordinatesToLookFor: any[]) => {
    return coordinatesToLookFor.filter((coordinate) => !getCoordinateIndex(coordinatesToLookIn, coordinate));
};

export const getChangedPointWithIndex = (
    oldCoordinates: GeometryCoordinates,
    newCoordinates: GeometryCoordinates
): CoordinateChange | undefined => {
    const coordinatesToLookFor = oldCoordinates.length > newCoordinates.length ? oldCoordinates : newCoordinates;
    const coordinatesToLookIn = oldCoordinates.length > newCoordinates.length ? newCoordinates : oldCoordinates;
    const changedPoint = getChangedCoordinates(coordinatesToLookIn, coordinatesToLookFor)[0];
    if (changedPoint) {
        const changedPointIndex = getCoordinateIndex(coordinatesToLookFor, changedPoint);
        return {
            coordinates: changedPoint,
            pointIndex: changedPointIndex as any,
        };
    }
    return undefined;
};
