import {
    featureLayersMappingActionCreator,
    colorMappingActionCreator,
    geoCalculationsByIdsActionCreator,
    loadSlotChangesActionCreator,
    loginToWizardSuiteActionCreator,
    getCapabilitiesActionCreator,
    featureLayersMappingReducer,
    getCapabilitiesReducer,
} from "../api/api-packages";
import { connect } from "react-redux";
import { registerPlugin } from "../common/mapstore/registerPlugin";
import { Startup, StartupDispatchProps, StartupStateProps } from "./Startup";
import { RootState } from "../rootState";
import { showError } from "../common/mapstore/action";
import { setAxiosInitializedReducer } from "./redux/setAxiosInitialized.reducer";
import { setAxiosInitializedAction } from "./redux/setAxiosInitialized.action";

const mapStateToProps = (state: RootState): StartupStateProps => {
    return {
        ticket: state.session?.ticket ?? state.urlParams.ticket,
        config: state.mapInitialConfig,
        urlParams: state.urlParams,
        axiosInitialized: state.axiosState.initialized,
    };
};

const mapDispatchToProps = (dispatch: any): StartupDispatchProps => {
    return {
        featureToLayersMapping: () => dispatch(featureLayersMappingActionCreator({})),
        changeTypeColorMapping: () => dispatch(colorMappingActionCreator({})),
        loadGeoCalculationsByIds: (params) => dispatch(geoCalculationsByIdsActionCreator(params)),
        loadSlotChanges: (params) => dispatch(loadSlotChangesActionCreator(params)),
        loginToWS: () => dispatch(loginToWizardSuiteActionCreator({})),
        getCapabilities: () => dispatch(getCapabilitiesActionCreator({})),
        showError: (title, message) => dispatch(showError({ title: title, message: message, autoDismiss: 13 })),
        setAxiosInitialized: (initialized) => dispatch(setAxiosInitializedAction(initialized)),
    };
};

export const ConnectedStartup = connect(mapStateToProps, mapDispatchToProps)(Startup);

export const StartupPlugin = {
    name: "StartupPlugin",
    config: {
        component: ConnectedStartup,
        reducers: {
            featureLayersMapping: featureLayersMappingReducer,
            gisServerCapabilities: getCapabilitiesReducer,
            axiosState: setAxiosInitializedReducer,
            //session2: loginToWizardSuiteReducer,
            //colorMapping: colorMappingReducer,
            //workspaceChangesGeneratedAt: loadSlotChangesReducer,
            //geoCalculations2: geoCalculationsByIdsReducer,
        },
    },
};

export const register = () => registerPlugin(StartupPlugin);
