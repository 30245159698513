import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

export class ChangeTypeCheckbox extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        displayName: PropTypes.string,
        changeTypeValue: PropTypes.bool,
        changeTypeChangedFunction: PropTypes.func,
        disabled: PropTypes.bool,
        layerColor: PropTypes.string,
    };

    render() {
        const id = "changeTypeCheckbox" + this.props.name;
        const layerColorStyle = this.props.layerColor
            ? { backgroundColor: this.props.layerColor }
            : { display: "none" };

        return (
            <div key={this.props.name}>
                <input
                    type={"checkbox"}
                    name={this.props.name}
                    id={id}
                    disabled={this.props.disabled}
                    onChange={(event) => this.props.changeTypeChangedFunction(this.props.name, event.target.checked)}
                    checked={this.props.changeTypeValue}
                />
                <label htmlFor={id}>
                    <span className={"layerColor"} style={layerColorStyle} />
                    <FormattedMessage id={`workspaceChanges.changeTypeCheckbox.${this.props.displayName}`} />
                </label>
            </div>
        );
    }
}
