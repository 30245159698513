import React from "react";
import { DropdownButton } from "../core/core-widgets/buttons/DropdownButton";
import { filterCRSList, getAvailableCRS, normalizeSRS } from "../../MapStore2/web/client/utils/CoordinatesUtils";
import { GlyphIcon } from "../core/core-widgets/icons";
import { Tooltip, TooltipPosition } from "../core/core-widgets/tooltips";

export interface ProjectionSelectorProps {
    projectionDefs: string[];
    filterAllowedCRS?: string[];
    additionalCRS?: any;
    selected: string;
}

export interface ProjectionSelectorDispatchProps {
    onSelect: (content: string) => void;
}

export const ProjectionSelector = (props: ProjectionSelectorProps & ProjectionSelectorDispatchProps) => {
    if (props.filterAllowedCRS === undefined && props.additionalCRS === undefined) {
        return null;
    }
    const getProjections = (filterAllowedCRS?: string[], additionalCRS?: string[], projectionDefs?: string[]) => {
        return Object.keys(filterCRSList(getAvailableCRS(), filterAllowedCRS, additionalCRS, projectionDefs));
    };
    return (
        <Tooltip body={"Select Projection"} position={TooltipPosition.LEFT}>
            <DropdownButton
                id={"projectionSelectorDropdown"}
                options={getProjections(props.filterAllowedCRS!, props.additionalCRS, props.projectionDefs).map(
                    (projection) => ({
                        value: projection,
                        label: projection,
                    })
                )}
                activeItem={normalizeSRS(props.selected, props.filterAllowedCRS!)}
                onItemSelect={(item) => props.onSelect(item.value)}
                pullRight
                icon={GlyphIcon.PROJECTION}
                buttonStyle={{
                    width: 25,
                    height: 25,
                    padding: 0,
                    marginRight: 5,
                    marginTop: 2,
                }}
                noCaret
                dropup
            />
        </Tooltip>
    );
};
