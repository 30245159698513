import { FeatureGeometry, FeatureStyle, GeometryType } from "./types";
import { geometryStyle } from "./geometryStyle";
import { Layer } from "../../development/components/Drawing/redux/drawLayersReducer";
import { CRS, Projection } from "../../common/types";
import { LineString, Point, Position } from "geojson";
import uuid from "uuid";

export const createPoint = (position: Position): Point => ({
    type: GeometryType.POINT,
    coordinates: position,
});

export const createLineString = (position: Position[]): LineString => ({
    type: GeometryType.LINE_STRING,
    coordinates: position,
});

export const createLayer = (
    geometry: FeatureGeometry,
    projection: Projection,
    featuresCrs: CRS,
    style: FeatureStyle = geometryStyle[geometry.type]
): Layer => {
    return {
        type: "vector",
        style: style,
        projection: projection,
        featuresCrs: featuresCrs,
        features: [
            {
                type: "Feature",
                geometry: geometry,
                properties: {
                    id: uuid(),
                },
            },
        ],
    };
};
