import Wfs3dProperties from "../dto/Wfs3dProperties";

export const extractWfs3dProperties = (entity: any) => {
    const upperLimit = entity._properties.UPPER_LIMIT || 0;
    const lowerLimit = entity._properties.LOWER_LIMIT || 0;
    const leftWidth = entity._properties.LEFT_WIDTH || 0;
    const rightWidth = entity._properties.RIGHT_WIDTH || 0;
    return new Wfs3dProperties(lowerLimit, upperLimit, leftWidth, rightWidth);
};

export const extractWfs3dPropertiesWithHeightMultiplier = (entity: any, heightMultiplier: number) => {
    const upperLimit = entity._properties.UPPER_LIMIT * heightMultiplier || 0;
    const lowerLimit = entity._properties.LOWER_LIMIT * heightMultiplier || 0;
    const leftWidth = entity._properties.LEFT_WIDTH || 0;
    const rightWidth = entity._properties.RIGHT_WIDTH || 0;
    return new Wfs3dProperties(lowerLimit, upperLimit, leftWidth, rightWidth);
}
