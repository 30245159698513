import { Action } from "redux";

export const DESELECT_FEATURE_FROM_PORTAL = "DESELECT_FEATURE_FROM_PORTAL";

export interface DeselectFeatureFromPortalAction extends Action {}

export const deselectFeatureFromPortalAction = (): DeselectFeatureFromPortalAction => {
    return {
        type: DESELECT_FEATURE_FROM_PORTAL,
    };
};
