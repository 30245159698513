import { SET_AXIOS_INITIALIZED } from "./setAxiosInitialized.action";
import { AnyAction, IPartialReducer } from "../../core/core-redux/reduxTypes";
import { createReducer } from "../../core/core-redux/reducer/createReducer";

export interface AxiosState {
    initialized: boolean;
}

const setAxiosInitializedPartialReducer: IPartialReducer<AxiosState> = (state: AxiosState, action: AnyAction) => {
    return {
        initialized: action.initialized,
    };
};

export const setAxiosInitializedReducer = createReducer<AxiosState>(
    {
        initialized: false,
    },
    { [SET_AXIOS_INITIALIZED]: setAxiosInitializedPartialReducer }
);
