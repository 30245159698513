const EPSG_4326 = 'EPSG:4326';
const EPSG_3857 = 'EPSG:3857';
const EPSG_900913 = 'EPSG:900913';
const GLOBE_3D_PROJECTION = "3D";

module.exports = {
    EPSG_4326,
    EPSG_3857,
    EPSG_900913,
    GLOBE_3D_PROJECTION
};
