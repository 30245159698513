import { asyncFetchDataPromiseActionFactory } from "../../../core/core-redux/actions/asyncFetchDataAction";
import { geoCalculationsByIdsService, GeoCalculationsByIdsServiceParams } from "./geoCalculationsByIds.service";
import GeoCalculationItem from "../../../geocalc/dto/GeoCalculationItem";

export const GEO_CALCULATIONS_BY_IDS = "GEO_CALCULATIONS_BY_IDS";

export const geoCalculationsByIdsActionCreator = asyncFetchDataPromiseActionFactory<
    GeoCalculationsByIdsServiceParams,
    GeoCalculationItem[],
    any
>(GEO_CALCULATIONS_BY_IDS, geoCalculationsByIdsService);
