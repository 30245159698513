import { initState } from "../../../common/util/StateUtil";
import { CLICK_ON_MAP } from "../../../../MapStore2/web/client/actions/map";
import assign from "object-assign";

export function clickedPointReducer(initialState: any, action: any) {
    const state = initState(initialState);
    if (action.type === CLICK_ON_MAP) {
        return assign({}, state, { point: action.point });
    }
    return state;
}
