import * as React from "react";
import { ModalSize } from "./types";
import { Modal as RBModal, ModalProps } from "react-bootstrap";
import { ModalHeader, ModalHeaderType } from "../ModalHeader/ModalHeader";
import { ModalFooter } from "../ModalFooter/ModalFooter";

const modalSizeToClassnameMap = {
    SMALL: "modal-sm",
    MEDIUM: "",
    LARGE: "modal-lg",
};

interface IModalProps extends Omit<ModalProps, "size" | "onHide"> {
    headerType?: ModalHeaderType;
    size?: ModalSize;
    closeButton?: boolean;
    modalTitle?: React.ReactNode;
    onClose: () => void;
    footer?: React.ReactNode;
}

export const Modal: React.FC<IModalProps> = (props) => {
    const { children, modalTitle, headerType, size = ModalSize.MEDIUM, closeButton = true, footer, onClose } = props;
    return (
        <RBModal show={true} onHide={onClose} backdrop={"static"} className={modalSizeToClassnameMap[size]}>
            {modalTitle && (
                <ModalHeader headerType={headerType} closeButton={closeButton}>
                    {modalTitle}
                </ModalHeader>
            )}
            {children}
            {footer && <ModalFooter>{footer}</ModalFooter>}
        </RBModal>
    );
};
