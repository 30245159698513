import { asyncFetchDataPromiseActionFactory } from "../../../core/core-redux/actions/asyncFetchDataAction";
import {
    loadSlotChangesService,
    LoadSlotChangesServiceParams,
    LoadSlotChangesServiceResponse,
} from "./loadSlotChanges.service";

export const LOAD_SLOT_CHANGES = "LOAD_SLOT_CHANGES";

export const loadSlotChangesActionCreator = asyncFetchDataPromiseActionFactory<
    LoadSlotChangesServiceParams,
    LoadSlotChangesServiceResponse,
    any
>(LOAD_SLOT_CHANGES, loadSlotChangesService);
