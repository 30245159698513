export const allGlyphIcons = [
    "1-bearing",
    "1-bring-down",
    "1-bring-up",
    "1-catalog",
    "1-circle-add",
    "1-circle-dashed",
    "1-circle",
    "1-close",
    "1-compass-alt",
    "1-compass-arrow",
    "1-compass",
    "1-drag",
    "1-full-screen",
    "1-group-add",
    "1-group-cancel",
    "1-group-mod",
    "1-group",
    "1-layer",
    "1-line-add",
    "1-line-dashed",
    "1-line",
    "1-map",
    "1-mark-alt",
    "1-mark",
    "1-max-size",
    "1-measure-area",
    "1-measure-lenght",
    "1-measure-path",
    "1-menu-manage",
    "1-pdf",
    "1-point-add",
    "1-point-dashed",
    "1-point",
    "1-poligon",
    "1-polygon-add",
    "1-polygon-dashed",
    "1-polygon",
    "1-position-1",
    "1-raster",
    "1-ruler-alt-2",
    "1-ruler-alt",
    "1-ruler",
    "1-screen-backward",
    "1-screen-forward",
    "1-set-squadre",
    "1-social-fb",
    "1-social-tw",
    "1-stilo",
    "1-time-user",
    "1-user-add",
    "1-user-cancel",
    "1-user-mod",
    "1-vector-alt",
    "1-vector",
    "3d",
    "add-dashboard",
    "add-folder",
    "add-geostory",
    "add-layer",
    "add-map",
    "add-row-after",
    "add-row-before",
    "adjust",
    "admin-geofence",
    "alert",
    "align-center",
    "align-justify",
    "align-left",
    "align-right",
    "arrow-down",
    "arrow-left",
    "arrow-right",
    "arrow-up",
    "asterisk",
    "back",
    "backward",
    "ban-circle",
    "barcode",
    "bold",
    "book",
    "bookmark",
    "bulb-off",
    "bulb-on",
    "calendar",
    "camera",
    "check",
    "chevron-down",
    "chevron-left",
    "chevron-right",
    "chevron-up",
    "circle-arrow-down",
    "circle-arrow-left",
    "circle-arrow-right",
    "circle-arrow-up",
    "clear-brush",
    "clear-filter",
    "cloud-download",
    "cloud-upload",
    "cloud",
    "code",
    "cog",
    "collapse-down",
    "collapse-up",
    "comment",
    "compressed",
    "copy",
    "counter",
    "crs",
    "dashboard-save",
    "dashboard",
    "download-alt",
    "download",
    "dropper",
    "duplicate",
    "edit",
    "envelope",
    "exclamation-mark",
    "exclamation-sign",
    "expand",
    "export",
    "ext-empty",
    "ext-html",
    "ext-json",
    "ext-pdf",
    "ext-txt",
    "ext-wmc",
    "eye-close",
    "eye-open",
    "fast-backward",
    "fast-forward",
    "features-grid-download",
    "features-grid-set",
    "features-grid",
    "file",
    "filter-layer",
    "filter",
    "fit-contain",
    "fit-cover",
    "flag",
    "flash",
    "floppy-disk",
    "floppy-open",
    "floppy-remove",
    "floppy-save",
    "floppy-saved",
    "folder-close",
    "folder-open",
    "font",
    "forward",
    "geoserver",
    "geostory",
    "globe",
    "gray-scale",
    "hand-down",
    "hand-left",
    "hand-right",
    "hand-up",
    "hdd",
    "heart",
    "height-auto",
    "height-view",
    "home",
    "hourglass",
    "import",
    "inbox",
    "info-sign",
    "italic",
    "leaf",
    "level-up",
    "line-dash",
    "line-minus",
    "line-plus",
    "line-remove",
    "line-trash",
    "line",
    "link",
    "list-alt",
    "list",
    "lock",
    "log-in",
    "log-out",
    "magnet",
    "map-edit",
    "map-filter",
    "map-marker",
    "map-synch",
    "maps-catalog",
    "menu-hamburger",
    "minus-sign",
    "minus",
    "mouse",
    "move-row-after",
    "move-row-before",
    "move",
    "new-window",
    "next",
    "off",
    "ok-circle",
    "ok-sign",
    "ok",
    "open",
    "option-horizontal",
    "option-vertical",
    "paperclip",
    "paste",
    "pause",
    "pencil-add",
    "pencil-edit",
    "pencil",
    "phone",
    "picture",
    "pie-chart",
    "plane",
    "play-circle",
    "play",
    "playback",
    "plug",
    "plus-sign",
    "plus-square",
    "plus",
    "point-dash",
    "point-minus",
    "point-plus",
    "point-remove",
    "point-trash",
    "point",
    "polygon-dash",
    "polygon-minus",
    "polygon-plus",
    "polygon-remove",
    "polygon-trash",
    "polygon",
    "polyline-dash",
    "polyline-minus",
    "polyline-plus",
    "polyline-remove",
    "polyline-trash",
    "polyline",
    "preview",
    "print",
    "pushpin",
    "qrcode",
    "question-sign",
    "random",
    "range-end",
    "range-start",
    "record",
    "redo",
    "refresh",
    "remove-circle",
    "remove-sign",
    "remove-square",
    "remove",
    "repeat",
    "resize-full",
    "resize-horizontal",
    "resize-small",
    "resize-vertical",
    "retweet",
    "rgb",
    "road",
    "row-add",
    "row-trash",
    "save",
    "saved",
    "scissors",
    "screenshot",
    "search-coords",
    "search",
    "send",
    "share-alt",
    "share",
    "sheet",
    "shopping-cart",
    "signal",
    "size-extra-large",
    "size-large",
    "size-medium",
    "size-small",
    "sort-by-alphabet-alt",
    "sort-by-alphabet",
    "sort-by-attributes-alt",
    "sort-by-attributes",
    "sort",
    "star-empty",
    "star",
    "stats",
    "step-backward",
    "step-forward",
    "stop",
    "story-immersive-content",
    "story-immersive-section",
    "story-media-section",
    "story-paragraph-section",
    "story-title-section",
    "story-webpage-section",
    "tag",
    "tags",
    "tasks",
    "text-background",
    "text-colour",
    "text-height",
    "text-width",
    "th-large",
    "th-list",
    "th",
    "thumbs-down",
    "thumbs-up",
    "time-current",
    "time-offset",
    "time",
    "tint",
    "transfer",
    "trash-square",
    "trash",
    "unchecked",
    "undo",
    "unplug",
    "upload",
    "usd",
    "user",
    "vert-dashed",
    "warning-sign",
    "webpage",
    "wrench",
    "zoom-in",
    "zoom-out",
    "zoom-to",
    "1-bearing-content",
    "1-bring-down-content",
    "1-bring-up-content",
    "1-catalog-content",
    "1-circle-add-content",
    "1-circle-dashed-content",
    "1-circle-content",
    "1-close-content",
    "1-compass-alt-content",
    "1-compass-arrow-content",
    "1-compass-content",
    "1-drag-content",
    "1-full-screen-content",
    "1-group-add-content",
    "1-group-cancel-content",
    "1-group-mod-content",
    "1-group-content",
    "1-layer-content",
    "1-line-add-content",
    "1-line-dashed-content",
    "1-line-content",
    "1-map-content",
    "1-mark-alt-content",
    "1-mark-content",
    "1-max-size-content",
    "1-measure-area-content",
    "1-measure-lenght-content",
    "1-measure-path-content",
    "1-menu-manage-content",
    "1-pdf-content",
    "1-point-add-content",
    "1-point-dashed-content",
    "1-point-content",
    "1-poligon-content",
    "1-polygon-add-content",
    "1-polygon-dashed-content",
    "1-polygon-content",
    "1-position-1-content",
    "1-raster-content",
    "1-ruler-alt-2-content",
    "1-ruler-alt-content",
    "1-ruler-content",
    "1-screen-backward-content",
    "1-screen-forward-content",
    "1-set-squadre-content",
    "1-social-fb-content",
    "1-social-tw-content",
    "1-stilo-content",
    "1-time-user-content",
    "1-user-add-content",
    "1-user-cancel-content",
    "1-user-mod-content",
    "1-vector-alt-content",
    "1-vector-content",
    "3d-content",
    "add-dashboard-content",
    "add-folder-content",
    "add-geostory-content",
    "add-layer-content",
    "add-map-content",
    "add-row-after-content",
    "add-row-before-content",
    "adjust-content",
    "admin-geofence-content",
    "alert-content",
    "align-center-content",
    "align-justify-content",
    "align-left-content",
    "align-right-content",
    "arrow-down-content",
    "arrow-left-content",
    "arrow-right-content",
    "arrow-up-content",
    "asterisk-content",
    "back-content",
    "backward-content",
    "ban-circle-content",
    "barcode-content",
    "bold-content",
    "book-content",
    "bookmark-content",
    "bulb-off-content",
    "bulb-on-content",
    "calendar-content",
    "camera-content",
    "check-content",
    "chevron-down-content",
    "chevron-left-content",
    "chevron-right-content",
    "chevron-up-content",
    "circle-arrow-down-content",
    "circle-arrow-left-content",
    "circle-arrow-right-content",
    "circle-arrow-up-content",
    "clear-brush-content",
    "clear-filter-content",
    "cloud-download-content",
    "cloud-upload-content",
    "cloud-content",
    "code-content",
    "cog-content",
    "collapse-down-content",
    "collapse-up-content",
    "comment-content",
    "compressed-content",
    "copy-content",
    "counter-content",
    "crs-content",
    "dashboard-save-content",
    "dashboard-content",
    "download-alt-content",
    "download-content",
    "dropper-content",
    "duplicate-content",
    "edit-content",
    "envelope-content",
    "exclamation-mark-content",
    "exclamation-sign-content",
    "expand-content",
    "export-content",
    "ext-empty-content",
    "ext-html-content",
    "ext-json-content",
    "ext-pdf-content",
    "ext-txt-content",
    "ext-wmc-content",
    "eye-close-content",
    "eye-open-content",
    "fast-backward-content",
    "fast-forward-content",
    "features-grid-download-content",
    "features-grid-set-content",
    "features-grid-content",
    "file-content",
    "filter-layer-content",
    "filter-content",
    "fit-contain-content",
    "fit-cover-content",
    "flag-content",
    "flash-content",
    "floppy-disk-content",
    "floppy-open-content",
    "floppy-remove-content",
    "floppy-save-content",
    "floppy-saved-content",
    "folder-close-content",
    "folder-open-content",
    "font-content",
    "forward-content",
    "geoserver-content",
    "geostory-content",
    "globe-content",
    "gray-scale-content",
    "hand-down-content",
    "hand-left-content",
    "hand-right-content",
    "hand-up-content",
    "hdd-content",
    "heart-content",
    "height-auto-content",
    "height-view-content",
    "home-content",
    "hourglass-content",
    "import-content",
    "inbox-content",
    "info-sign-content",
    "italic-content",
    "leaf-content",
    "level-up-content",
    "line-dash-content",
    "line-minus-content",
    "line-plus-content",
    "line-remove-content",
    "line-trash-content",
    "line-content",
    "link-content",
    "list-alt-content",
    "list-content",
    "lock-content",
    "log-in-content",
    "log-out-content",
    "magnet-content",
    "map-edit-content",
    "map-filter-content",
    "map-marker-content",
    "map-synch-content",
    "maps-catalog-content",
    "menu-hamburger-content",
    "minus-sign-content",
    "minus-content",
    "mouse-content",
    "move-row-after-content",
    "move-row-before-content",
    "move-content",
    "new-window-content",
    "next-content",
    "off-content",
    "ok-circle-content",
    "ok-sign-content",
    "ok-content",
    "open-content",
    "option-horizontal-content",
    "option-vertical-content",
    "paperclip-content",
    "paste-content",
    "pause-content",
    "pencil-add-content",
    "pencil-edit-content",
    "pencil-content",
    "phone-content",
    "picture-content",
    "pie-chart-content",
    "plane-content",
    "play-circle-content",
    "play-content",
    "playback-content",
    "plug-content",
    "plus-sign-content",
    "plus-square-content",
    "plus-content",
    "point-dash-content",
    "point-minus-content",
    "point-plus-content",
    "point-remove-content",
    "point-trash-content",
    "point-content",
    "polygon-dash-content",
    "polygon-minus-content",
    "polygon-plus-content",
    "polygon-remove-content",
    "polygon-trash-content",
    "polygon-content",
    "polyline-dash-content",
    "polyline-minus-content",
    "polyline-plus-content",
    "polyline-remove-content",
    "polyline-trash-content",
    "polyline-content",
    "preview-content",
    "print-content",
    "pushpin-content",
    "qrcode-content",
    "question-sign-content",
    "random-content",
    "range-end-content",
    "range-start-content",
    "record-content",
    "redo-content",
    "refresh-content",
    "remove-circle-content",
    "remove-sign-content",
    "remove-square-content",
    "remove-content",
    "repeat-content",
    "resize-full-content",
    "resize-horizontal-content",
    "resize-small-content",
    "resize-vertical-content",
    "retweet-content",
    "rgb-content",
    "road-content",
    "row-add-content",
    "row-trash-content",
    "save-content",
    "saved-content",
    "scissors-content",
    "screenshot-content",
    "search-coords-content",
    "search-content",
    "send-content",
    "share-alt-content",
    "share-content",
    "sheet-content",
    "shopping-cart-content",
    "signal-content",
    "size-extra-large-content",
    "size-large-content",
    "size-medium-content",
    "size-small-content",
    "sort-by-alphabet-alt-content",
    "sort-by-alphabet-content",
    "sort-by-attributes-alt-content",
    "sort-by-attributes-content",
    "sort-content",
    "star-empty-content",
    "star-content",
    "stats-content",
    "step-backward-content",
    "step-forward-content",
    "stop-content",
    "story-immersive-content-content",
    "story-immersive-section-content",
    "story-media-section-content",
    "story-paragraph-section-content",
    "story-title-section-content",
    "story-webpage-section-content",
    "tag-content",
    "tags-content",
    "tasks-content",
    "text-background-content",
    "text-colour-content",
    "text-height-content",
    "text-width-content",
    "th-large-content",
    "th-list-content",
    "th-content",
    "thumbs-down-content",
    "thumbs-up-content",
    "time-current-content",
    "time-offset-content",
    "time-content",
    "tint-content",
    "transfer-content",
    "trash-square-content",
    "trash-content",
    "unchecked-content",
    "undo-content",
    "unplug-content",
    "upload-content",
    "usd-content",
    "user-content",
    "vert-dashed-content",
    "warning-sign-content",
    "webpage-content",
    "wrench-content",
    "zoom-in-content",
    "zoom-out-content",
    "zoom-to-content",
];
