export default class CqlFilterUtil {
    static applyCqlFilterToLayer(layer: any, cqlFilter: any) {
        if (cqlFilter) {
            let filter = "";
            for (let i = 0; i < layer.name.split(",").length; i++) {
                filter += cqlFilter + ";";
                layer.params.CQL_FILTER = filter.slice(0, -1);
            }
        } else {
            delete layer.params.CQL_FILTER;
        }
    }
}
