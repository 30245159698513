import { standardApiHandler } from "../../../core/core-api/apiTools";
import { colorMappingAdapter } from "./colorMapping.adapter";

export type ColorMappingServiceParams = {};
export type ColorMappingServiceResponse = {
    [key: string]: string;
};

export type ColorMappingService = ({}) => Promise<ColorMappingServiceResponse>;
export const colorMappingService: ColorMappingService = standardApiHandler(colorMappingAdapter);
