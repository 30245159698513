const mapOptionsSelector = (state) => state.mapInitialConfig && state.mapInitialConfig.mapOptions;

const workspaceModeSelector = (state) => mapOptionsSelector(state) && state.mapInitialConfig.mapOptions.workspaceMode;

const defaultChangeTypesSelector = (state) =>
    mapOptionsSelector(state) &&
    mapOptionsSelector(state).changes &&
    mapOptionsSelector(state).changes.defaultChangeTypes;

const featureInfoCountSelector = (state) =>
    mapOptionsSelector(state) && state.mapInitialConfig.mapOptions.featureInfoCount;

const gisServerUrlSelector = (state) => state.layerOptions.gisServerUrl;
const wizardPortalUrlSelector = (state) =>
    mapOptionsSelector(state) && state.mapInitialConfig.mapOptions.wizardPortalUrl;

const geoCalcOptionsSelector = (state) => mapOptionsSelector(state) && state.mapInitialConfig.mapOptions.geoCalc;
const geoCalcStyleSelector = (state) =>
    geoCalcOptionsSelector(state) && state.mapInitialConfig.mapOptions.geoCalc.style;
const geoCalcEnabledSelector = (state) => geoCalcOptionsSelector(state) && geoCalcOptionsSelector(state).enabled;
const editedFeatureStyleSelector = (state) =>
    mapOptionsSelector(state) &&
    mapOptionsSelector(state).editFeature &&
    mapOptionsSelector(state).editFeature.editedFeatureStyle;
const layersContainerExpandedSelector = (state) =>
    mapOptionsSelector(state) &&
    mapOptionsSelector(state).layersContainer &&
    mapOptionsSelector(state).layersContainer.expanded;

const featureFilterEnabledSelector = (state) =>
    mapOptionsSelector(state) &&
    mapOptionsSelector(state).featureFilter &&
    mapOptionsSelector(state).featureFilter.enabled;
const changesEnabledSelector = (state) =>
    mapOptionsSelector(state) && mapOptionsSelector(state).changes && mapOptionsSelector(state).changes.enabled;
const wfsMaxFeatureCountSelector = (state) => mapOptionsSelector(state) && mapOptionsSelector(state).wfsMaxFeatureCount;
const wfs3dRouteSelector = (state) =>
    mapOptionsSelector(state) && mapOptionsSelector(state).wfs3d && mapOptionsSelector(state).wfs3d.route;

const isFooterEnabled = (state) => mapOptionsSelector(state) && mapOptionsSelector(state).footer?.enabled;

const dataSetFilterSelector = (state) => mapOptionsSelector(state) && mapOptionsSelector(state).dataSetFilter;

const isArcModeEnabledSelector = (state) => mapOptionsSelector(state)?.arc?.enabled;

module.exports = {
    workspaceModeSelector,
    defaultChangeTypesSelector,
    featureInfoCountSelector,
    gisServerUrlSelector,
    wizardPortalUrlSelector,
    geoCalcStyleSelector,
    editedFeatureStyleSelector,
    layersContainerExpandedSelector,
    geoCalcEnabledSelector,
    featureFilterEnabledSelector,
    changesEnabledSelector,
    wfsMaxFeatureCountSelector,
    wfs3dRouteSelector,
    isFooterEnabled,
    dataSetFilterSelector,
    isArcModeEnabledSelector,
};
