import { RawUrlParams } from "./redux";

export const isUrlParams = (rawUrlParams: any): rawUrlParams is RawUrlParams => {
    const isTicketOrAutoLogin = rawUrlParams.ticket !== undefined || isUrlParamTrue(rawUrlParams.autoLogin);
    const isSlotEffective = rawUrlParams.slotEffective !== undefined && isTicketOrAutoLogin;
    const isSlotId = rawUrlParams.slotId !== undefined && isSlotEffective;
    const isQueryAllSlots = rawUrlParams.queryAllSlots !== undefined && isSlotEffective;
    const isGeoCalcId = rawUrlParams.geocalcid !== undefined && isTicketOrAutoLogin;
    const isSlotName = rawUrlParams.slotName && isSlotId;
    const isEffectiveFrom = rawUrlParams.effectiveFrom !== undefined;
    const isBranchId = rawUrlParams.branchId !== undefined && isEffectiveFrom;
    const isEffectiveTo = rawUrlParams.effectiveTo !== undefined && isEffectiveFrom;

    return (
        isSlotEffective ||
        isGeoCalcId ||
        isSlotId ||
        isQueryAllSlots ||
        isSlotName ||
        isEffectiveFrom ||
        isBranchId ||
        isEffectiveTo
    );
};

export const isUrlParamTrue = (param: any): param is true => {
    return param === "true";
};

export const isSlotIdNotEmpty = (value: null | undefined | string): value is string => {
    return value !== null && value !== undefined && value !== "" && value !== "0";
};
