import { EpicFactory } from "../../../core/core-redux/reduxTypes";
import { FINISH_GEOMETRY_EDIT, FinishGeometryEditAction } from "./finishGeometryEdit.action";
import { RootState } from "../../../rootState";
import { cleanFeaturesFromEditing } from "../../../common/mapstore/action/draw";
import { Action } from "redux";

export const finishGeometryEpic: EpicFactory<FinishGeometryEditAction, RootState, Action> = (action, store) =>
    action.ofType(FINISH_GEOMETRY_EDIT).switchMap(() => {
        return [cleanFeaturesFromEditing()];
    });
