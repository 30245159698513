import Logger from "../../../common/util/Logger";
import Cesium from "../../../../MapStore2/web/client/libs/cesium";
import { extractWfs3dProperties } from "../util/Wfs3dPropertiesExtractor";


const logger = new Logger("AirspaceParser");

const AIRSPACE_COLOR = Cesium.Color.LIGHTGRAY;
const AIRSPACE_OUTLINE_COLOR = Cesium.Color.GRAY;

export const parseAirspace = (entity: any) => {
    const clonedEntity = Cesium.clone(entity);

    const properties = extractWfs3dProperties(entity);

    if (!entity.polygon) {
        logger.warn("not polygon entity tried to be parsed as Airspace");
    } else {
        clonedEntity.polygon.extrudedHeight = properties.upperLimit;
        clonedEntity.polygon.height = properties.lowerLimit;
        clonedEntity.polygon.material = AIRSPACE_COLOR.withAlpha(0.2);
        clonedEntity.polygon.outlineColor = AIRSPACE_OUTLINE_COLOR;
    }
    return clonedEntity;
};
