import uuid from "uuid";
import {EPSG_3857} from '@js/common/constants/Projections';

const INPUT_GEOMETRY_TYPE = 'INPUT';
const FEATURE_TYPE = "Feature";

function removeOutputsThatAreAlsoInputs(geoCalculation) {
    const inputs = geoCalculation.geometry.features.filter(feature => feature.properties.type === INPUT_GEOMETRY_TYPE).map(feature => feature.properties.name);
    return geoCalculation.geometry.features.filter(feature => feature.properties.type === INPUT_GEOMETRY_TYPE || !inputs.includes(feature.properties.name));
}

const createFeatureCollection = (features, calculationId, style) => {
    return features.map(feature => {
        return {
            id: feature.id,
            calculationId: calculationId,
            type: FEATURE_TYPE,
            geometry: feature.geometry,
            properties: {
                style: feature.properties.type === INPUT_GEOMETRY_TYPE ? {...style.inputStyle} : {...style.outputStyle},
            }
        };
    });
};

const createLabelCollection = (features, calculationId, style) => {
    return features.map(feature => {
        return {
            id: feature.id + "_text",
            calculationId: calculationId,
            type: FEATURE_TYPE,
            geometry: feature.geometry,
            properties: {
                isText: true,
                style: feature.properties.type === INPUT_GEOMETRY_TYPE ? {...style.inputStyle} : {...style.outputStyle},
                valueText: feature.properties.name
            }
        };
    });
};

const getGeoCalculationCreationFunction = (type) => {
    if (type === "feature") {
        return createFeatureCollection;
    }
    return createLabelCollection;
};

const createGeoCalculationCollection = (previousFeatures, geoCalculations, style, type) => {
    const geoCalculationCreationFunction = getGeoCalculationCreationFunction(type);
    return {
        type: "vector",
        featuresCrs: EPSG_3857,
        id: uuid(),
        features: [{
            type: "FeatureCollection",
            id: uuid(),
            features: [...previousFeatures, ...geoCalculations.reduce((acc, geoCalculation) => acc.concat(geoCalculationCreationFunction(removeOutputsThatAreAlsoInputs(geoCalculation), geoCalculation.id, style)), [])],
            properties: {}
        }]
    };
};

export {createGeoCalculationCollection};
