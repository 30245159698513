import { GetFeatureCountService } from "./getFeatureCount.service";
import { api } from "../../api/apiProvider";
import { EPSG_4326 } from "../../common/constants/Projections";
import { parseXml } from "../../common/util/xmlParser";

export const getFeatureCountAdapter: GetFeatureCountService = async (params) => {
    const defaultParams = {
        service: "wfs",
        version: "2.0.0",
        request: "GetFeature",
        outputFormat: "application/json",
        srsName: EPSG_4326,
    };

    const merged = { ...defaultParams, ...params };

    const requestParams = Object.keys(merged)
        .map((key) => {
            return key + "=" + (merged as any)[key];
        })
        .join("&");
    const resp = await api.wfsService(requestParams);
    const wfsResponseAsJson: any = parseXml(resp.data as unknown as string);
    const featureCount = parseInt(wfsResponseAsJson["wfs:FeatureCollection"].$.numberMatched, 10);
    return featureCount;
};
