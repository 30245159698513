import { RawUrlParams, UrlParams } from "./redux";
import { isSlotIdNotEmpty, isUrlParams, isUrlParamTrue } from "./assertions";
import url from "url";
import { formatAsDateTimeWithTimeZone, formattedToday } from "../core/helpers/dateTime";
import moment from "moment";
import { Slots } from "../common/slot/slots";

const parseSlotIds = (slotIds: string | string[] = []): string[] => {
    return parseParamAsArray(slotIds).filter(isSlotIdNotEmpty);
};

const parseParamAsArray = (urlParam: string | string[] = []): string[] => {
    return typeof urlParam === "string" ? [urlParam] : urlParam;
};

export const parseUrlParams = (params: string): UrlParams => {
    const { query } = url.parse(params, true, false);
    if (!isUrlParams(query)) {
        throw new Error("Could not parse URL params.");
    }
    const rawUrlParams: RawUrlParams = query;
    const effectiveDate = formatAsDateTimeWithTimeZone(rawUrlParams.slotEffective || rawUrlParams.effectiveFrom);
    let validTo = formatAsDateTimeWithTimeZone(rawUrlParams.validTo || rawUrlParams.effectiveTo);
    const slotIds = parseSlotIds(rawUrlParams.slotId || rawUrlParams.branchId);

    if (validTo && moment(validTo).isBefore(effectiveDate)) {
        validTo = effectiveDate;
    }

    return {
        effectiveDate: effectiveDate ?? formattedToday(),
        validTo: validTo ?? undefined,
        slots: new Slots(slotIds, rawUrlParams.slotName ?? "", rawUrlParams.queryAllSlots ?? false),
        ticket: rawUrlParams.ticket ?? undefined,
        embeddedInPortal: isUrlParamTrue(rawUrlParams.embeddedInPortal),
        developmentMode: isUrlParamTrue(rawUrlParams.developmentMode),
        autoLogin: isUrlParamTrue(rawUrlParams.autoLogin),
        workspaceName: rawUrlParams.workspaceName,
        geoCalcIds: rawUrlParams.geocalcid,
        filterIds: parseParamAsArray(rawUrlParams.wfsRequest),
    };
};
