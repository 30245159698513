import { useEffect } from "react";
import { connect } from "react-redux";
import { registerPlugin } from "../common/mapstore/registerPlugin";

import { wfsDataCountEpic } from "./epic/wfsDataCountEpic";
import { wfs3dLayersEpic } from "./epic/wfs3dLayersEpic";
import { wfs3dLayersVisibilityEpic } from "./epic/wfs3dLayersVisibilityEpic";
import FilterSelector from "../filter/FeatureFilter/redux/selector/FilterSelector";
import { updateWfsFilterAction } from "./update-wfs-filter/updateWfsFilter.action";
import { wfs3dLayersReducer } from "./reducer/wfs3dLayersReducer";
import { RootState } from "../rootState";

export const Wfs3dLayers = (props: any) => {
    useEffect(() => {
        props.onUpdateWfsFilter(props.filter);
    }, [props.filter]);

    return null;
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onUpdateWfsFilter: (filter: any) => dispatch(updateWfsFilterAction(filter)),
    };
};

export const ConnectedWfs3dLayers = connect((state: RootState) => {
    return { filter: FilterSelector.getDataSetFilters(state) };
}, mapDispatchToProps)(Wfs3dLayers);

export const Wfs3dLayersPlugin = {
    name: "Wfs3dLayersPlugin",
    config: {
        component: ConnectedWfs3dLayers,
        reducers: { wfs3dLayers: wfs3dLayersReducer },
        epics: { wfsDataCountEpic, wfs3dLayersEpic, wfs3dLayersVisibilityEpic },
    },
};
export const register = () => registerPlugin(Wfs3dLayersPlugin);
