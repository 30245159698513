const { saveChangeTypeColoring } = require("@js/changes/action/saveChangeTypeColoringAction");
const axios = require("@mapstore/libs/ajax");
const Rx = require("rxjs");
const { doNothingAction } = require("@js/common/action/doNothingAction");
const { gisServerUrlSelector } = require("@js/common/selector/configurationSelector");
const Logger = require("@js/common/util/Logger");
const { STORE_GIS_SERVER_URL } = require("@js/layers/redux/gis-server-url/storeGisServerUrl.action");

const logger = new Logger("changeColoringEpic");

function getChangeTypeMapping(url) {
    const retrieveFlow = () =>
        Rx.Observable.defer(() =>
            axios({
                method: "get",
                url: url,
            })
        );
    return retrieveFlow().map((response) => ({ data: response.data }));
}

const changeColoringEpic = (action$, store) =>
    action$.ofType(STORE_GIS_SERVER_URL).switchMap(() => {
        const state = store.getState();
        const gisServerUrl = gisServerUrlSelector(state);
        const gisServerRestUrl = `${gisServerUrl}/rest/sdo/changeTypeColorMapping`;

        return getChangeTypeMapping(gisServerRestUrl)
            .map((response) => saveChangeTypeColoring(response.data))
            .catch((e) => {
                logger.error("Error retrieving change type coloring from GIS Server.", e);
                return [doNothingAction()];
            });
    });

module.exports = changeColoringEpic;
