import { GeoCalculationsByIdsService } from "./geoCalculationsByIds.service";
import { api } from "../../apiProvider";
import GeoCalculationItem from "../../../geocalc/dto/GeoCalculationItem";
import Item from "../../../layers/dto/Item";

export const geoCalculationsByIdsAdapter: GeoCalculationsByIdsService = async (params) => {
    const queryString = `geoCalculationIds=${params.geoCalcIds.join()}`;
    // const queryString = params.geoCalcIds.map((geoCalcId) => `geoCalculationIds=${geoCalcId}`).join("&");

    const resp = await api.geoCalculationsByIds(queryString);
    // TODO map geoCalcResponse correctly,....
    const calculations = resp.data.map(
        (calculation: { calculationId: number; calculationName: string; geometry: string }) =>
            new GeoCalculationItem(
                new Item(calculation.calculationId, calculation.calculationName, true, true, false, true),
                JSON.parse(calculation.geometry)
            )
    );
    return calculations as GeoCalculationItem[];
};
