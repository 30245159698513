import { AxiosInstance, default as Axios } from "axios";
import { getStore } from "../../MapStore2/web/client/utils/StateUtils";
import { getGisServerUrl } from "./gisServerUrl";

export interface SystemConfig {
    gisServerUrl: string;
    wizardPortalUrl: string;
}

interface AxiosInstanceWrapper {
    instance?: AxiosInstance;
}

export const apiWrapper: AxiosInstanceWrapper = {
    instance: undefined,
};

export const initAxiosInstance = async () => {
    apiWrapper.instance = Axios.create({
        baseURL: await getGisServerUrl(),
    });
    apiWrapper.instance?.interceptors.request.use((config) => {
        config.headers.ticket = getStore().getState().session?.ticket;
        return config;
    });
};

export const updateHeaders = (headers: any) => {
    if (!apiWrapper.instance) return;

    const currentHeaders = apiWrapper.instance.defaults.headers;
    apiWrapper.instance.defaults.headers = {
        ...currentHeaders,
        ...headers,
    };
};
