import { createReducer } from "../../../core/core-redux/reducer/createReducer";

import { cqlFilterPartialReducer } from "../cql-filter/cqlFilter.reducer";
import { SET_CQL_FILTER } from "../cql-filter/setCqlFilter.action";
import { STORE_GIS_SERVER_URL } from "../gis-server-url/storeGisServerUrl.action";
import { storeGisServerUrlPartialReducer } from "../gis-server-url/gisServerUrl.reducer";
export interface LayersPropertiesState {
    gisServerUrl?: string;
    cqlFilter?: string;
}

export const layerPropertiesReducer = createReducer<LayersPropertiesState>(
    {
        gisServerUrl: undefined,
        cqlFilter: undefined,
    },
    { [STORE_GIS_SERVER_URL]: storeGisServerUrlPartialReducer, [SET_CQL_FILTER]: cqlFilterPartialReducer }
);
