import { Reducer } from "redux";

import { AsyncState, IAsyncDataAction, IAsyncDataState, IPartialReducer } from "../reduxTypes";
import { createReducer } from "./createReducer";
import {
    RECEIVE_ASYNC_DATA_ERROR_ACTION_TYPE,
    RECEIVE_ASYNC_DATA_RESPONSE_ACTION_TYPE,
    REQUEST_ASYNC_DATA_ACTION_TYPE,
} from "../actions/asyncFetchDataAction";

export const INITIAL_ASYNC_STATE: IAsyncDataState<any, any, any> = {
    state: AsyncState.INITIAL,
};

/**
 * This is a Reducer factory for constructing a reducer which handles data that is fetched asynchronously.
 * The reducer handles actions of interface IAsyncDataAction<DATA, ERROR, PARAMS>, and is intended to be used together with asyncFetchDataActionFactory.
 *
 * @param {string} prefix Action type prefix for distinguishing between various items that can be fetched
 */
export const asyncFetchDataReducerFactory = <DATA, ERROR, PARAMS = {}>(
    prefix: string
): Reducer<IAsyncDataState<DATA, ERROR, PARAMS>> => {
    const requestAsyncDataPartialReducer: IPartialReducer<IAsyncDataState<DATA, ERROR, PARAMS>> = (
        state: IAsyncDataState<DATA, ERROR, PARAMS>,
        action: IAsyncDataAction<DATA, ERROR, PARAMS>
    ): IAsyncDataState<DATA, ERROR, PARAMS> => ({
        state: AsyncState.IN_PROGRESS,
        receivedData: state.receivedData /* Do not clear the existing data during fetching new data */,
        error: undefined,
        parameters: action.parameters,
        status: undefined,
    });

    const receiveAsyncDataResponsePartialReducer: IPartialReducer<IAsyncDataState<DATA, ERROR, PARAMS>> = (
        state: IAsyncDataState<DATA, ERROR, PARAMS>,
        action: IAsyncDataAction<DATA, ERROR, PARAMS>
    ): IAsyncDataState<DATA, ERROR, PARAMS> => ({
        state: AsyncState.COMPLETED,
        receivedData: action.response,
        error: undefined,
        parameters: action.parameters,
        status: action.status,
    });

    const receiveAsyncDataErrorPartialReducer: IPartialReducer<IAsyncDataState<DATA, ERROR, PARAMS>> = (
        state: IAsyncDataState<DATA, ERROR, PARAMS>,
        action: IAsyncDataAction<DATA, ERROR, PARAMS>
    ): IAsyncDataState<DATA, ERROR, PARAMS> => ({
        state: AsyncState.ERROR,
        receivedData: undefined,
        error: action.error,
        parameters: action.parameters,
        status: action.status,
    });

    return createReducer(INITIAL_ASYNC_STATE, {
        [prefix + REQUEST_ASYNC_DATA_ACTION_TYPE]: requestAsyncDataPartialReducer,
        [prefix + RECEIVE_ASYNC_DATA_RESPONSE_ACTION_TYPE]: receiveAsyncDataResponsePartialReducer,
        [prefix + RECEIVE_ASYNC_DATA_ERROR_ACTION_TYPE]: receiveAsyncDataErrorPartialReducer,
    });
};
