const assign = require("object-assign");
const { SET_CHANGES_GENERATED } = require("@js/changes/action/reloadChangesAction");
const { VIEW_TYPE_CHANGED } = require("@js/changes/action/updateViewTypeAction");
const { UPDATE_CONTEXT } = require("@js/changes/action/updateContextAction");
const { initState } = require("@js/common/util/StateUtil");

function workspaceChangesReducer(initialState, action) {
    const state = initState(initialState);
    switch (action.type) {
        case SET_CHANGES_GENERATED: {
            return assign({}, state, { dateOfGeneration: action.date });
        }
        case VIEW_TYPE_CHANGED: {
            return assign({}, state, { view: action.viewType });
        }
        case UPDATE_CONTEXT: {
            return assign({}, state, { context: action.context });
        }
        default:
            return state;
    }
}

module.exports = workspaceChangesReducer;
