import { IPartialReducer } from "../../../core/core-redux/reduxTypes";
import { createReducer } from "../../../core/core-redux/reducer/createReducer";
import { INIT_URL_PARAMS, UrlParams } from "./initUrlParams.action";

export interface InitUrlParamsState {
    urlParams?: UrlParams;
}

const initUrlParamsPartialReducer: IPartialReducer<InitUrlParamsState> = (state, action) => {
    return action.urlParams;
};

export const initUrlParamsReducer = createReducer<InitUrlParamsState>(
    {
        urlParams: undefined,
    },
    { [INIT_URL_PARAMS]: initUrlParamsPartialReducer }
);
