import { InjectedIntl } from "react-intl";

export const formatNumber = (
    intl: InjectedIntl,
    value?: number,
    fullPrecision?: boolean,
    fractionDecimalDigits?: number
) => {
    if (value === undefined || value === null) {
        return value;
    }

    const params: any = {
        maximumFractionDigits: fractionDecimalDigits !== undefined ? fractionDecimalDigits : 20,
    };

    if (fullPrecision !== undefined) {
        params.minimumFractionDigits = fullPrecision ? fractionDecimalDigits || 0 : 0;
    }

    return intl.formatNumber(value, params);
};
