import React, { useEffect } from "react";
import { connect } from "react-redux";
import { registerPlugin } from "../common/mapstore/registerPlugin";
import { initUrlParamsAction, initUrlParamsReducer, InitUrlParamsState, UrlParams as Params } from "./redux";
import { Dispatch } from "redux";
import { storeTicketAction } from "../session/redux/store-ticket/storeTicket.action";
import { parseUrlParams } from "./urlParamsParser";

interface UrlParamsState extends InitUrlParamsState {
    router: {
        location: {
            search: string;
        };
    };
}

export interface UrlParamsStateProps {
    searchQuery: string;
}

export interface UrlParamsDispatchProps {
    storeUrlParams: (params: Params) => void;
    storeTicket: (ticket: string) => void;
}

export const UrlParams = (props: UrlParamsStateProps & UrlParamsDispatchProps) => {
    const { searchQuery, storeUrlParams, storeTicket } = props;

    useEffect(() => {
        const urlParams = parseUrlParams(searchQuery);
        console.log("URL params passed:", urlParams);
        storeUrlParams(urlParams);
        if (urlParams.ticket) {
            storeTicket(urlParams.ticket);
        }
    }, [searchQuery]);

    return <></>;
};

const mapStateToProps = (state: UrlParamsState): UrlParamsStateProps => {
    return { searchQuery: state.router.location.search };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): UrlParamsDispatchProps => {
    return {
        storeUrlParams: (params) => dispatch(initUrlParamsAction(params)),
        storeTicket: (ticket) => dispatch(storeTicketAction(ticket)),
    };
};

export const ConnectedUrlParams = connect(mapStateToProps, mapDispatchToProps)(UrlParams);

export const UrlParamsPlugin = {
    name: "UrlParamsPlugin",
    config: {
        component: ConnectedUrlParams,
        reducers: { urlParams: initUrlParamsReducer },
    },
};

export const register = () => registerPlugin(UrlParamsPlugin);
