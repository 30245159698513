import { IAsyncDataState } from "../../../core/core-redux/reduxTypes";
import { LOAD_SLOT_CHANGES } from "./loadSlotChanges.action";
import { LoadSlotChangesServiceParams, LoadSlotChangesServiceResponse } from "./loadSlotChanges.service";
import { asyncFetchDataReducerFactory } from "../../../core/core-redux/reducer/asyncFetchDataReducer";

export interface LoadSlotChangesDataState
    extends IAsyncDataState<LoadSlotChangesServiceResponse, any, LoadSlotChangesServiceParams> {}

export const loadSlotChangesReducer = asyncFetchDataReducerFactory<
    LoadSlotChangesServiceResponse,
    any,
    LoadSlotChangesServiceParams
>(LOAD_SLOT_CHANGES);
