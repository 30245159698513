import { Action } from "redux";
import { Feature } from "../../../core/geo/types";

export const UPDATE_FEATURE_GEOMETRY = "UPDATE_FEATURE_GEOMETRY";

export interface UpdateFeatureAction extends Action {
    featureUid: string | number | undefined;
    feature: Feature;
}

export const updateFeatureAction = (
    featureUid: string | number | undefined,
    feature: Feature
): UpdateFeatureAction => ({
    type: UPDATE_FEATURE_GEOMETRY,
    featureUid: featureUid,
    feature: feature,
});
