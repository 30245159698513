import { validateSessionActionCreator } from "../api/api-packages/validate-session/validateSession.action";
import { showError } from "../common/mapstore/action";
import { connect } from "react-redux";
import { storeTicketReducer } from "./redux/store-ticket/storeTicket.reducer";
import { registerPlugin } from "../common/mapstore/registerPlugin";
import { SessionValidator, SessionValidatorDispatchProps, SessionValidatorStateProps } from "./SessionValidator";
import { validateSessionReducer } from "../api/api-packages/validate-session/validateSession.reducer";
import { RootState } from "../rootState";
import { AsyncState } from "../core/core-redux/reduxTypes";

const mapStateToProps = (state: RootState): SessionValidatorStateProps => {
    return {
        sessionInvalid:
            state.validateSession.state === AsyncState.ERROR ||
            (state.validateSession.state === AsyncState.COMPLETED && !state.validateSession.receivedData),
        axiosInitialized: state.axiosState.initialized,
        autoLogin: state.urlParams.autoLogin,
    };
};

const mapDispatchToProps = (dispatch: any): SessionValidatorDispatchProps => {
    return {
        validateSession: () => dispatch(validateSessionActionCreator({})),
        showError: (title, message) => dispatch(showError({ title: title, message: message, autoDismiss: 55 })),
    };
};

export const ConnectedSessionValidator = connect(mapStateToProps, mapDispatchToProps)(SessionValidator);

export const SessionValidatorPlugin = {
    name: "SessionValidatorPlugin",
    config: {
        component: ConnectedSessionValidator,
        reducers: {
            session: storeTicketReducer,
            validateSession: validateSessionReducer,
        },
    },
};
export const register = () => registerPlugin(SessionValidatorPlugin);
