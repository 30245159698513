import { SET_CHANGES_GENERATED } from "../../changes/action/reloadChangesAction";
import CqlFilterUtil from "../util/CqlFilterUtil";
import ReloadLayerUtil from "../util/ReloadLayerUtil";
import { RELOAD_MAP } from "../action/reloadMapAction";
import { APPLY_FEATURE_FILTER } from "../../filter/FeatureFilter/redux/apply-feature-filter/applyFeatureFilter.action";
import { CHANGE_TYPE_CHANGED } from "../../changes/action/updateChangeTypeAction";
import { gisServerUrlSelector, workspaceModeSelector } from "../../common/selector/configurationSelector";
import { viewSelector } from "../../changes/selector/workspaceChangesSelector";
import { WORKSPACE_VIEW } from "../../changes/changesConstants";
import { CHANGE_MAP_VIEW } from "../../../MapStore2/web/client/actions/map";
import { CHANGE_GROUP_PROPERTIES, CHANGE_LAYER_PROPERTIES } from "../../common/mapstore/action/layers";
import { VIEW_TYPE_CHANGED } from "../../changes/action/updateViewTypeAction";
import { UPDATE_VALID_FROM, VALIDITY_INTERVAL_CHANGE } from "../../portal/action/updateValidityIntervalAction";
import { SHOW_SELECTED_FEATURE } from "../../feature/feature-detail-frame/redux/show-selected-feature/showSelectedFeature.action";
import { DESELECT_FEATURES } from "../../feature/feature-detail-frame/redux/deselect-features/deselectFeatures.action";
import { DESELECT_ALL_FEATURES } from "../../feature/feature-detail-frame/redux/deselect-all-features/deselectAllFeatures.action";
import { ADD_DATA_SET_FILTER_TO_TREE_VIEW } from "../../filter/DataSetFilter/redux/add-dataset-filter/addDataSetFilterToTreeView.action";
import { TOGGLE_DATA_SET_FILTER_VISIBILITY } from "../../filter/DataSetFilter/redux/toggle-dataset-filter-visibility/toggleDataSetFilterVisibility.action";
import { TOGGLE_FEATURE_FILTER_VISIBILITY } from "../../filter/FeatureFilter/redux/toggle-feature-filter-visibility/toggleFeatureFilterVisibility.action";
import { REMOVE_DATA_SET_FILTER_FROM_TREE_VIEW } from "../../filter/DataSetFilter/redux/remove-dataset-filter/removeDataSetFilterFromTreeView.action";
import { REMOVE_FEATURE_FILTER } from "../../filter/FeatureFilter/redux/remove-feature-filter/removeFeatureFilter.action";
import {
    queriableWmsBaselineLayerSelector,
    queriableWmsWorkspaceLayerSelector,
} from "../../common/selector/layerSelector";
import FilterSelector from "../../filter/FeatureFilter/redux/selector/FilterSelector";
import { BASELINE_LAYER_ID, SLOT_LAYER_ID } from "../constants/layerConstants";
import EnvParamsRetriever from "../../common/util/EnvParamsRetriever";
import { isCesium } from "../../../MapStore2/web/client/selectors/maptype";
import { invokeSingleWmsRequestAction } from "../action/invokeSingleWmsRequestAction";
import { SET_CQL_FILTER } from "../redux/cql-filter/setCqlFilter.action";

const cqlFilterSelector = (state: any) => state.layerOptions && state.layerOptions.cqlFilter;

function joinLayers(
    layers: any,
    layerId: any,
    forceReload: any,
    envParams: any,
    cqlFilter: any,
    dataSetFilter: any,
    isCesiumMode: any,
    ticket: number,
    gisServerUrl?: string
) {
    const singleLayer = { ...layers[0] };
    singleLayer.id = layerId;
    singleLayer.name = layers.map((layer: any) => layer.name).join();
    singleLayer.params = {
        ...singleLayer.params,
        ticket: ticket,
    };

    if (gisServerUrl) {
        singleLayer.url = `${gisServerUrl}/wms`;
    }

    if (dataSetFilter) {
        singleLayer.params.dataSetFilters = dataSetFilter;
    }

    singleLayer.singleTile = !isCesiumMode;

    singleLayer.params.ENV = envParams.toRequestParams();

    CqlFilterUtil.applyCqlFilterToLayer(singleLayer, cqlFilter);

    if (forceReload) {
        ReloadLayerUtil.forceReloadLayer(singleLayer);
    }
    return singleLayer;
}

function shouldForceReload(action: any) {
    return (
        action.type === SET_CHANGES_GENERATED ||
        action.type === RELOAD_MAP ||
        action.type === APPLY_FEATURE_FILTER ||
        action.type === CHANGE_TYPE_CHANGED
    );
}

function workspaceLayersExist(state: any, workspaceLayers: any) {
    return (workspaceModeSelector(state) || viewSelector(state) === WORKSPACE_VIEW) && workspaceLayers.length > 0;
}

const layersEpic = (action$: any, store: any) =>
    action$
        .ofType(
            CHANGE_TYPE_CHANGED,
            CHANGE_MAP_VIEW,
            CHANGE_GROUP_PROPERTIES,
            CHANGE_LAYER_PROPERTIES,
            SET_CHANGES_GENERATED,
            VIEW_TYPE_CHANGED,
            UPDATE_VALID_FROM,
            VALIDITY_INTERVAL_CHANGE,
            SET_CQL_FILTER,
            RELOAD_MAP,
            SHOW_SELECTED_FEATURE,
            DESELECT_FEATURES,
            DESELECT_ALL_FEATURES,
            ADD_DATA_SET_FILTER_TO_TREE_VIEW,
            TOGGLE_DATA_SET_FILTER_VISIBILITY,
            APPLY_FEATURE_FILTER,
            TOGGLE_FEATURE_FILTER_VISIBILITY,
            REMOVE_DATA_SET_FILTER_FROM_TREE_VIEW,
            REMOVE_FEATURE_FILTER
        )
        .switchMap((action: any) => {
            const state = store.getState();

            const baselineLayers = queriableWmsBaselineLayerSelector(state);
            const workspaceLayers = queriableWmsWorkspaceLayerSelector(state);

            const dataSetFilter = FilterSelector.getDataSetFiltersAsString(state);

            let baselineSingleLayer = null;
            const forceReload = shouldForceReload(action);
            if (baselineLayers.length > 0) {
                baselineSingleLayer = joinLayers(
                    baselineLayers,
                    BASELINE_LAYER_ID,
                    forceReload,
                    EnvParamsRetriever.getBaselineEnvParams(state),
                    cqlFilterSelector(state),
                    dataSetFilter,
                    isCesium(state),
                    state.session?.ticket,
                    gisServerUrlSelector(state)
                );
            }

            let workspaceSingleLayer = null;
            if (workspaceLayersExist(state, workspaceLayers)) {
                workspaceSingleLayer = joinLayers(
                    workspaceLayers,
                    SLOT_LAYER_ID,
                    forceReload,
                    EnvParamsRetriever.getWorkspaceEnvParams(state),
                    cqlFilterSelector(state),
                    dataSetFilter,
                    isCesium(state),
                    state.session?.ticket,
                    gisServerUrlSelector(state)
                );
            }

            return [invokeSingleWmsRequestAction(baselineSingleLayer, workspaceSingleLayer)];
        });

export default layersEpic;
