const CHANGE_TYPE_CHANGED = "CHANGE_TYPE_CHANGED";
const SET_CHANGE_TYPES = "SET_CHANGE_TYPES";

function updateChangesTypesAction(changeType, value) {
    return {
        type: CHANGE_TYPE_CHANGED,
        changeType: changeType,
        value: value,
    };
}

function setChangeTypesAction(changeTypes) {
    return {
        type: SET_CHANGE_TYPES,
        changeTypes: changeTypes,
    };
}

module.exports = {
    CHANGE_TYPE_CHANGED,
    updateChangesTypesAction,
    SET_CHANGE_TYPES,
    setChangeTypesAction,
};
