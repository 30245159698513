import { GEOCALC_GEOMETRY_REQUESTED } from "@js/geocalc/action/requestGeoCalcGeometryAction";
import { geoCalcGeometryLoadedAction } from "@js/geocalc/action/geoCalcGeometryLoadedAction";
import { doNothingAction } from "@js/common/action/doNothingAction";
import { geoCalcStyleSelector } from "@js/common/selector/configurationSelector";
import GeoCalculationItem from "@js/geocalc/dto/GeoCalculationItem";
import Logger from "@js/common/util/Logger";
import Item from "@js/layers/dto/Item";
import { invokeRestServiceWithParameters } from "@js/common/util/web-service/gisServerServices.ts";

const logger = new Logger("geoCalculationGeometryLoadEpic");

const geoCalculationGeometryLoadEpic = (action$, store) =>
    action$.ofType(GEOCALC_GEOMETRY_REQUESTED).switchMap((action) => {
        const state = store.getState();
        const restOptions = {
            geoCalculationIds: action.calculationId,
        };

        return invokeRestServiceWithParameters(state, "sdo/geoCalculationsByIds", restOptions)
            .map((response) => {
                const calculations = response.data.map(
                    (calculation) =>
                        new GeoCalculationItem(
                            new Item(calculation.calculationId, calculation.calculationName, true, true, false, true),
                            JSON.parse(calculation.geometry)
                        )
                );
                return geoCalcGeometryLoadedAction(calculations, geoCalcStyleSelector(state));
            })
            .catch((e) => {
                logger.error("Error retrieving geoCalculation geometry from GIS Server.", e);
                return [doNothingAction()];
            });
    });

export default geoCalculationGeometryLoadEpic;
