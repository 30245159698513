const GEOCALC_GEOMETRY_LOADED = 'GEOCALC_GEOMETRY_LOADED';

const geoCalcGeometryLoadedAction = (geoCalculations, style) => {
    return {
        type: GEOCALC_GEOMETRY_LOADED,
        geoCalculations: geoCalculations,
        style: style
    };
};

export {GEOCALC_GEOMETRY_LOADED, geoCalcGeometryLoadedAction};
