import { initState } from "../../common/util/StateUtil";
import assign from "object-assign";
import { SINGLE_WMS_REQUEST } from "../action/invokeSingleWmsRequestAction";

function singleWmsRequestReducer(initialState: any, action: any) {
    const state = initState(initialState);
    if (action.type === SINGLE_WMS_REQUEST) {
        const baselineLayer = action.baselineLayer;
        const workspaceLayer = action.workspaceLayer;
        return assign({}, state, {
            workspaceLayer: action.workspaceLayer,
            baselineLayer: action.baselineLayer,
            baselineEnv: baselineLayer && baselineLayer.params.ENV,
            workspaceEnv: workspaceLayer && workspaceLayer.params.ENV,
        });
    }
    return state;
}

export default singleWmsRequestReducer;
