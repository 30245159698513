import * as React from "react";

interface ContentContainerProps {}

export const ContentContainer: React.FC<ContentContainerProps> = (props) => {
    return (
        <div className={"px-4"} style={{ overflow: "auto" }}>
            {props.children}
        </div>
    );
};
