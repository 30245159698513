import { connect } from "react-redux";
import { validityIntervalReducer } from "./reducer/validityIntervalReducer";
import { selectFeatureToPortalEpic } from "./epic/selectFeatureToPortalEpic";
import { centerMapOnFeaturesEpic } from "./epic/centerMapOnFeaturesEpic";
import { updateFeatureGeometryOnPortalEpic } from "./epic/updateFeatureGeometryOnPortalEpic";
import { registerPlugin } from "../common/mapstore/registerPlugin";
import {
    PortalCommunicator,
    PortalCommunicatorDispatchProps,
    PortalCommunicatorStateProps,
} from "./PortalCommunicator";
import { RootState } from "../rootState";
import { editGeometryAction } from "../editFeature/redux/editGeometry";
import { viewGeometryAction } from "../editFeature/redux/viewGeometry";
import { updateValidityIntervalAction } from "./action/updateValidityIntervalAction";
import { selectFeatureFromPortalAction } from "./redux/select-feature/selectFeatureFromPortal.action";
import { reloadMapAction } from "../layers/action/reloadMapAction";
import { finishGeometryEditAction } from "../editFeature/redux/finishGeometryEdit";
import { reloadChangesAction } from "../changes/action/reloadChangesAction";
import { deselectAllFeaturesAction } from "../feature/feature-detail-frame/redux/deselect-all-features/deselectAllFeatures.action";
import { allLayersSelector } from "../common/selector/layerSelector";
import EnvParamsRetriever from "../common/util/EnvParamsRetriever";
import { portalReducer } from "./redux/portalReducer";
import { setSelectedLayersVisibleEpic } from "./epic/setSelectedLayersVisibleEpic";
import { sessionSelector } from "../session/redux/sessionSelector";
import { deselectFeatureFromPortalAction } from "./redux/deselect-feature/deselectFeatureFromPortal.action";
import { getDatasetFilterByIdActionCreator } from "../api/api-packages/getDataSetFilterById";
import { applyFeatureFilterAction } from "../filter/FeatureFilter/redux/apply-feature-filter/applyFeatureFilter.action";
import DataSetFilterItem from "../filter/DataSetFilter/dto/DataSetFilterItem";
import { validFromSelector } from "../common/selector/validityIntervalSelector";
import { viewSelector } from "../changes/selector/workspaceChangesSelector";
import { BASELINE_VIEW } from "../changes/changesConstants";
import { setCqlFilterAction } from "../layers/redux/cql-filter/setCqlFilter.action";

const mapStateToProps = (state: RootState): PortalCommunicatorStateProps => {
    let envData = "";
    if (state.featureLayersMapping?.receivedData) {
        envData = EnvParamsRetriever.getWorkspaceEnvParams(state).toRequestParams();
    }

    return {
        embeddedInPortal: state.urlParams.embeddedInPortal,
        featureLayersMapping: state.featureLayersMapping?.receivedData,
        visibleLayerNames: allLayersSelector(state).map((layer: { name: string }) => layer.name),
        envParams: envData,
        mapPresent: state.map?.present,
        ticket: sessionSelector(state),
        validFrom: validFromSelector(state),
        slots: viewSelector(state) === BASELINE_VIEW ? undefined : state.urlParams.slots,
    };
};

const deselectAllFeatures = (dispatch: any) => {
    dispatch(deselectAllFeaturesAction());
    dispatch(deselectFeatureFromPortalAction());
};

const mapDispatchToProps = (dispatch: any): PortalCommunicatorDispatchProps => {
    return {
        onUpdateValidityInterval: (from, to) => dispatch(updateValidityIntervalAction(from, to)),
        onFeaturesSelectedFromPortal: (features) => {
            deselectAllFeatures(dispatch);
            dispatch(selectFeatureFromPortalAction(features));
        },
        onDeselectAllFeatures: () => {
            deselectAllFeatures(dispatch);
        },
        onEditGeometry: (feature) => {
            deselectAllFeatures(dispatch);
            dispatch(editGeometryAction(feature));
        },
        onViewGeometry: (feature) => {
            deselectAllFeatures(dispatch);
            dispatch(viewGeometryAction(feature));
        },
        onReloadMap: () => {
            dispatch(setCqlFilterAction(undefined));
            dispatch(reloadMapAction());
        },
        onCancelGeometryEdit: () => {
            dispatch(setCqlFilterAction(undefined));
            dispatch(reloadMapAction());
            dispatch(finishGeometryEditAction());
            dispatch(reloadChangesAction());
        },
        onApplyFeatureFilter: (filterId, validFrom, slots) =>
            dispatch(getDatasetFilterByIdActionCreator({ id: filterId, validFrom: validFrom, slots: slots })),
        onApplyFeatureFilterAction: (item: DataSetFilterItem, filter: any) => {
            dispatch(applyFeatureFilterAction(item, filter));
        },
    };
};

export const ConnectedPortalCommunicator = connect(mapStateToProps, mapDispatchToProps)(PortalCommunicator);

export const PortalCommunicatorPlugin = {
    name: "PortalCommunicatorPlugin",
    config: {
        component: ConnectedPortalCommunicator,
        reducers: {
            validityInterval: validityIntervalReducer,
            selectedFeaturesFromPortal: portalReducer,
        },
        epics: {
            selectFeatureToPortalEpic: selectFeatureToPortalEpic,
            centerMapOnFeaturesEpic: centerMapOnFeaturesEpic,
            updateFeatureGeometryOnPortalEpic: updateFeatureGeometryOnPortalEpic,
            setSelectedLayersVisibleEpic,
        },
    },
};
export const register = () => registerPlugin(PortalCommunicatorPlugin);
